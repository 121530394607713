import React, { Component } from 'react'
import { Button, Progress, Tooltip } from 'antd'
import Tick from '../../../assets/images/tick.png'
import { COOKIE, getCookie } from '../../../utils/cookie'
import cssconstants from '../../../constants/cssconstants'
import { pages as title, stepDetails } from '../../../constants/constants'
import { get_demo_msg_common, resetTablesCommon } from '../../../utils/comsteps'
import { InfoCircleFilled } from '@ant-design/icons'

class StepsCommon extends Component {
  constructor(props) {
    super(props)
    this.state = {

      timezone: getCookie(COOKIE.TIMEZONE),
      station: getCookie(COOKIE.STATION),
      reset_time: getCookie(COOKIE.RESET_TIME) || null,
      demo_buttons: getCookie(COOKIE.DEMO_BUTTONS) || null,
      ecibooks: [],
      manifests: [],
      flight_events: [],
      fsus: [],
      ffms: [],
      ssim: [],

      send_ecibooks: [],
      send_manifests: [],
      send_flight_events: [],
      send_fsus: [],
      send_ffms: [],
      percent1: 0,
      percent2: 0,
      percent3: 0,
      percent4: 0,
      percent5: 0,
      percent6: 0,
      percent7: 0,
      percent8: 0,
      percent9: 0,
      percent10: 0,
      percent11: 0,
      percent12: 0,
      percent13: 0,
      percent14: 0,
      percent15: 0,
      percent16: 0,
      percent17: 0,
      percent18: 0,
      percent19: 0,
      percent20: 0,

      fetchInterval: null,
      rediscount: 0,
      redismaxcount: 0,
      percentage: 0,

    }
    this._queue = []
    this._isBusy = false
    const bars = [
      {
        handlePercent: 'handlePercent1',
        percent: 'percent1'
      },
      {
        handlePercent: 'handlePercent2',
        percent: 'percent2'
      },
      {
        handlePercent: 'handlePercent3',
        percent: 'percent3'
      },
      {
        handlePercent: 'handlePercent4',
        percent: 'percent4'
      },
      {
        handlePercent: 'handlePercent5',
        percent: 'percent5'
      },
      {
        handlePercent: 'handlePercent6',
        percent: 'percent6'
      },
      {
        handlePercent: 'handlePercent7',
        percent: 'percent7'
      },
      {
        handlePercent: 'handlePercent8',
        percent: 'percent8'
      },
      {
        handlePercent: 'handlePercent9',
        percent: 'percent9'
      },
      {
        handlePercent: 'handlePercent10',
        percent: 'percent10'
      },
      {
        handlePercent: 'handlePercent11',
        percent: 'percent11'
      },
      {
        handlePercent: 'handlePercent12',
        percent: 'percent12'
      },
      {
        handlePercent: 'handlePercent13',
        percent: 'percent13'
      },
      {
        handlePercent: 'handlePercent14',
        percent: 'percent14'
      },
      {
        handlePercent: 'handlePercent15',
        percent: 'percent15'
      },
      {
        handlePercent: 'handlePercent16',
        percent: 'percent16'
      },
      {
        handlePercent: 'handlePercent17',
        percent: 'percent17'
      },
      {
        handlePercent: 'handlePercent18',
        percent: 'percent18'
      },
      {
        handlePercent: 'handlePercent19',
        percent: 'percent19'
      },
      {
        handlePercent: 'handlePercent20',
        percent: 'percent20'
      },

    ]
    bars.forEach((bar) => {
      this[bar.handlePercent] = (time) => {
        let percent = 0
        if(bar.handlePercent === 'handlePercent2'){
          this.setState({ percent2: 10, })
        }
        const interval = setInterval(() => {
          percent += 10
          if (percent >= 100) {
            clearInterval(interval)
            this.setState({ [bar.percent]: 0, })
          } else {
            this.setState({ [bar.percent]: percent })
          }
        }, time)
      }
    })
  }

  componentDidMount() {

  }
  componentWillUnmount() {
  }

  moduleName = (module) => {
    return <div className='text-center mr-4 fs-12 steps-head-label'>{module} </div>
  }


  render() {
    return (

      <div className='steps-head-btn w-100 mx-4 step-collapse'>
        <div>
          {
            // !getCookie('ssim') ?
            //   <div className='position-relative'>
            //     {this.moduleName(title.INBOUND)}
            //     <Button className='custom-button send mr-0' onClick={() => get_demo_msg_common('ssim', '', this)}>SSIM
            //       <Progress percent={this.state.percent1} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
            //     </Button>
            //     <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.ssim}><InfoCircleFilled className='ml-1' /></Tooltip>
            //     {getCookie('ssim') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
            //   </div> : 
            !getCookie('inbound_start') ?
              (<div className='position-relative'>
                {this.moduleName(title.INBOUND)}
                <Button className='custom-button send mr-0' onClick={() => get_demo_msg_common('Inbound', 'START', this)}>Start
                  <Progress percent={this.state.percent2} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                </Button>
                <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_start}><InfoCircleFilled className='ml-1' /></Tooltip>
                {getCookie('inbound_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
              </div>) : !getCookie('inbound_booking') ?
                (<div className='position-relative'>
                  {this.moduleName(title.INBOUND)}
                  <Button className='custom-button send' onClick={() => get_demo_msg_common('Inbound', 'BOOKING', this)}>Booking Confirmed
                    <Progress percent={this.state.percent3} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                  </Button>
                  <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_booking}><InfoCircleFilled className='ml-1' /></Tooltip>
                  {getCookie('inbound_booking') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                </div>) :
                !getCookie('inbound_flight_events') ?
                  (<>
                    {this.moduleName(title.INBOUND)}
                    <Button className='custom-button send' onClick={() => get_demo_msg_common('Inbound', 'FLIGHT EVENT', this)}>Flight Events
                      <Progress percent={this.state.percent4} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                    </Button>
                    <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_flight_events}><InfoCircleFilled className='ml-1' /></Tooltip>
                    {getCookie('inbound_flight_events') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                  </>) :
                  !getCookie('inbound_flight_completed') ?
                    (<>
                      {this.moduleName(title.INBOUND)}
                      <Button className='custom-button send' onClick={() => get_demo_msg_common('Inbound', 'FLIGHT COMPLETED', this)}>Flight Completed
                        <Progress percent={this.state.percent5} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                      </Button>
                      <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_flight_completed}><InfoCircleFilled className='ml-1' /></Tooltip>
                      {getCookie('inbound_flight_completed') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                    </>) :
                    !getCookie('inbound_checkin') ?
                      <>
                        {this.moduleName(title.recovery_dashboard)}
                        <Button className='custom-button send' onClick={() => get_demo_msg_common('Inbound', 'CHECKIN', this)}>Check In
                          <Progress percent={this.state.percent6} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                        </Button>
                        <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_checkin}><InfoCircleFilled className='ml-1' /></Tooltip>
                        {getCookie('inbound_checkin') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                      </> :
                      !getCookie('inbound_dlv') ?
                        <>
                          {this.moduleName(title.recovery_dashboard)}
                          <Button className='custom-button send' onClick={() => get_demo_msg_common('Inbound', 'DLV', this)}>DLV
                            <Progress percent={this.state.percent7} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                          </Button>
                          <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_dlv}><InfoCircleFilled className='ml-1' /></Tooltip>
                          {getCookie('inbound_dlv') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                        </> :
                        !getCookie('inbound_move_bd') ?
                          <>
                            {this.moduleName(title.recovery_dashboard)}
                            <Button className='custom-button send' onClick={() => get_demo_msg_common('Inbound', 'MOVE BD', this)}>Move to Breakdown
                              <Progress percent={this.state.percent8} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                            </Button>
                            <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.inbound_move_bd}><InfoCircleFilled className='ml-1' /></Tooltip>
                            {getCookie('inbound_move_bd') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                          </> :
                          !getCookie('breakdown_start') ?
                            <>
                              {this.moduleName(title.breakdown_control)}
                              <Button className='custom-button send' onClick={() => get_demo_msg_common('breakdown', 'START', this)} >Start
                                <Progress percent={this.state.percent9} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                              </Button>
                              <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.breakdown_start}><InfoCircleFilled className='ml-1' /></Tooltip>
                              {getCookie('breakdown_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                            </> :
                            !getCookie('breakdown_nfd') ?
                              <>
                                {this.moduleName(title.breakdown_control)}
                                <Button className='custom-button send' onClick={() => get_demo_msg_common('breakdown', 'NFD', this)} >NFD
                                  <Progress percent={this.state.percent10} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                </Button>
                                <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.breakdown_nfd}><InfoCircleFilled className='ml-1' /></Tooltip>
                                {getCookie('breakdown_nfd') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                              </> :
                              !getCookie('breakdown') ?
                                <>
                                  {this.moduleName(title.breakdown_control)}
                                  <Button className='custom-button send' onClick={() => get_demo_msg_common('breakdown', 'BREARKDOWN', this)}>Breakdown
                                    <Progress percent={this.state.percent11} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                  </Button>
                                  <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.breakdown}><InfoCircleFilled className='ml-1' /></Tooltip>
                                  {getCookie('breakdown') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                </> :
                                !getCookie('breakdown_move_bu') ?
                                  <>
                                    {this.moduleName(title.breakdown_control)}
                                    <Button className='custom-button send' onClick={() => get_demo_msg_common('breakdown', 'MOVE BU', this)}>Move to Buildup
                                      <Progress percent={this.state.percent12} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                    </Button>
                                    <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.breakdown_move_bu}><InfoCircleFilled className='ml-1' /></Tooltip>
                                    {getCookie('breakdown_move_bu') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                  </> :
                                  !getCookie('buildup_start') ?
                                    <>
                                      {this.moduleName('FCP' + ' / ' + title.buildup_control)}
                                      <Button className='custom-button send' onClick={() => get_demo_msg_common('buildup', 'START', this)}>Start Buildup
                                        <Progress percent={this.state.percent13} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                      </Button>
                                      <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.buildup_start}><InfoCircleFilled className='ml-1' /></Tooltip>
                                      {getCookie('buildup_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                    </> :
                                    !getCookie('buildup_build_uld') ?
                                      <>
                                        {this.moduleName('FCP' + ' / ' + title.buildup_control)}
                                        <Button className='custom-button send' onClick={() => get_demo_msg_common('buildup', 'BUILD ULD', this)}>Build ULD
                                          <Progress percent={this.state.percent14} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                        </Button>
                                        <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.buildup_build_uld}><InfoCircleFilled className='ml-1' /></Tooltip>
                                        {getCookie('buildup_build_uld') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                      </> :
                                      !getCookie('buildup_build_cart') ?
                                        <>
                                          {this.moduleName('FCP' + ' / ' + title.buildup_control)}
                                          <Button className='custom-button send' onClick={() => get_demo_msg_common('buildup', 'BUILD CART', this)}>Build Cart
                                            <Progress percent={this.state.percent15} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                          </Button>
                                          <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.buildup_build_cart}><InfoCircleFilled className='ml-1' /></Tooltip>
                                          {getCookie('buildup_build_cart') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                        </> :
                                        !getCookie('buildup_asgn_flt') ?
                                          <>
                                            {this.moduleName('FCP' + ' / ' + title.buildup_control)}
                                            <Button className='custom-button send' onClick={() => get_demo_msg_common('buildup', 'ASSIGNED FLT', this)}>Assigned Flight
                                              <Progress percent={this.state.percent16} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                            </Button>
                                            <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.buildup_asgn_flt}><InfoCircleFilled className='ml-1' /></Tooltip>
                                            {getCookie('buildup_asgn_flt') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                          </> :
                                          !getCookie('buildup_pre') ?
                                            <>
                                              {this.moduleName('FCP' + ' / ' + title.buildup_control)}
                                              <Button className='custom-button send' onClick={() => get_demo_msg_common('buildup', 'PRE', this)}>PRE
                                                <Progress percent={this.state.percent17} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                              </Button>
                                              <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.buildup_pre}><InfoCircleFilled className='ml-1' /></Tooltip>
                                              {getCookie('buildup_pre') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                            </> :
                                            !getCookie('haulout_start') ?
                                              <>
                                                {this.moduleName(title.haulout_dashboard)}
                                                <Button className='custom-button send' onClick={() => get_demo_msg_common('haulout', 'START', this)}>Start
                                                  <Progress percent={this.state.percent18} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                                </Button>
                                                <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.haulout_start}><InfoCircleFilled className='ml-1' /></Tooltip>
                                                {getCookie('haulout_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                              </> :
                                              !getCookie('haulout_gt_mismatch') ?
                                                <>
                                                  {this.moduleName(title.haulout_dashboard)}
                                                  <Button className='custom-button send' onClick={() => get_demo_msg_common('haulout', 'GATE MISMATCH', this)}>Gate Mismatch
                                                    <Progress percent={this.state.percent19} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                                  </Button>
                                                  <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.haulout_gt_mismatch}><InfoCircleFilled className='ml-1' /></Tooltip>
                                                  {getCookie('haulout_gt_mismatch') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                                </> :
                                                !getCookie('haulout_depart') ?
                                                  <>
                                                    {this.moduleName(title.haulout_dashboard)}
                                                    <Button className='custom-button send' onClick={() => get_demo_msg_common('haulout', 'DEPART', this)}>Depart
                                                      <Progress percent={this.state.percent20} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                                                    </Button>
                                                    <Tooltip overlayInnerStyle={{ fontSize: cssconstants.stepsTooltip }} placement='right' title={stepDetails.haulout_depart}><InfoCircleFilled className='ml-1' /></Tooltip>
                                                    {getCookie('haulout_depart') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
                                                  </> : ''}
        </div>
        <div className='f-column'>
          <small className='text-center steps-head-label'>
            {getCookie(COOKIE.RESET_TIME) ? getCookie(COOKIE.RESET_TIME) + 'EST' : <small className='transparent'>-</small>} </small>
          <Button type='link' className='custom-button send ml-0' onClick={() => resetTablesCommon(this)} >Reset Steps</Button>
        </div>
      </div>

    )
  }
}

export default StepsCommon
