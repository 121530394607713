import React, { Component } from 'react'
import {
  Row,
  Col,
  Typography,
  Switch ,
  notification,
  Select, Layout, Menu
} from 'antd'
import { Link } from 'react-router-dom'
import { timezone, timezones, pagelist } from '../constants/constants'
import './header.css'
import { /*BellOutlined,*/ UploadOutlined } from '@ant-design/icons'
import Cookies from 'universal-cookie'
import { COOKIE, getCookie, logout, createCookie, deleteCookie } from '../utils/cookie'
import moment from 'moment'
import Notifications from '../pages/notifications/notifications'
import StepsCommon from '../pages/demo/tabs/stepscommon'

const { Header, Sider } = Layout
const { Title, Text } = Typography
const cookies = new Cookies()
class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      key: location.pathname?.split('/')[1],
      collapsed: true,
      stat: cookies.get('station'),
      dept: cookies.get('department'),
      name: cookies.get('name'),
      role: cookies.get('role'),
      weight: cookies.get('weight') === 'LB' ? true : false,
      title: '',
      zone: cookies.get('station') ? timezone?.find((item) => item?.value === cookies.get('station'))?.zone : '00',
      clock: '',
      intervalID: null,
      routes: pagelist,
      time_left: cookies.get('expiry'),
      notificationsOpen: false,

      percent1: 0,
      percent2: 0,
      percent3: 0,
      percent4: 0,
      percent5: 0,
      percent6: 0,
      percent7: 0,
      percent8: 0,
      percent9: 0,
      percent10: 0,
      percent11: 0,
      percent12: 0,
      percent13: 0,
      percent14: 0,
      percent15: 0,
      percent16: 0,
      percent17: 0,
      percent18: 0,
      percent19: 0,
      percent20: 0,

    }

    this._queue = []
    this._isBusy = false
    const bars = [
      {
        handlePercent: 'handlePercent1',
        percent: 'percent1' 
      },
      {
        handlePercent: 'handlePercent2',
        percent: 'percent2' 
      },
      {
        handlePercent: 'handlePercent3',
        percent: 'percent3' 
      },
      {
        handlePercent: 'handlePercent4',
        percent: 'percent4' 
      },
      {
        handlePercent: 'handlePercent5',
        percent: 'percent5' 
      },
      {
        handlePercent: 'handlePercent6',
        percent: 'percent6' 
      },
      {
        handlePercent: 'handlePercent7',
        percent: 'percent7' 
      },
      {
        handlePercent: 'handlePercent8',
        percent: 'percent8' 
      },
      {
        handlePercent: 'handlePercent9',
        percent: 'percent9' 
      },
      {
        handlePercent: 'handlePercent10',
        percent: 'percent10' 
      },
      {
        handlePercent: 'handlePercent11',
        percent: 'percent11' 
      },
      {
        handlePercent: 'handlePercent12',
        percent: 'percent12' 
      },
      {
        handlePercent: 'handlePercent13',
        percent: 'percent13' 
      },
      {
        handlePercent: 'handlePercent14',
        percent: 'percent14' 
      },
      {
        handlePercent: 'handlePercent15',
        percent: 'percent15' 
      },
      {
        handlePercent: 'handlePercent16',
        percent: 'percent16' 
      },
      {
        handlePercent: 'handlePercent17',
        percent: 'percent17' 
      },
      {
        handlePercent: 'handlePercent18',
        percent: 'percent18' 
      },
      {
        handlePercent: 'handlePercent19',
        percent: 'percent19' 
      },
      {
        handlePercent: 'handlePercent20',
        percent: 'percent20' 
      },
      
    ]
    bars.forEach((bar) => {
      this[bar.handlePercent] = (time) => {
        let percent = 0
        const interval = setInterval(() => {
          percent += 10
          if (percent >= 100) {
            clearInterval(interval)
            this.setState({ [bar.percent]: 0, })
          } else {
            this.setState({ [bar.percent]: percent })
          }
        }, time)
      }
    })
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed, })
    if(this.state.collapsed){
      document.body.style.overflow = 'hidden'
    }
    else {
      document.body.style.overflow = 'unset'
    }
  }

  clockTimerInterval = () => {
    let clockInterval = setInterval(() => {
      this.clockTimerLogic()
    }, 60000)
    this.setState({ intervalID: clockInterval })
  }

  clockTimerLogic = () => {
    //CLOCK LOGIC
    this.setState({
      clock: moment().tz(this.state.zone).format('DD MMM YYYY | hh:mm A'),
      timeout: moment(this.state.time_left).diff(moment(), 'minutes')
    }, () => {
      //TIMER LOGIC
      if(this.state.timeout <= 10 &&
       (this.state.timeout === 10 || this.state.timeout === 5)) {
        notification.destroy()
        notification.warn({
          message: 'Session expiry',
          description: `You will be logged out in ${this.state.timeout} minutes.`,
          duration: 0
        })
      }
    })
  }

  componentDidMount() {
    this.setState({ clock: moment().tz(this.state.zone).format('DD MMM YYYY | hh:mm A') }, () => {
      this.clockTimerLogic()
      this.clockTimerInterval()
    })

    if(window.location.pathname.includes('system-parameter')){
      if(this.state.title !== 'System Parameters' )
        this.setState({ title: 'System Parameters' })
    } else if(window.location.pathname.includes('inbound-flight')){
      if(this.state.title !== 'Inbound Flight Preparation' )
        this.setState({ title: 'Inbound Flight Preparation' })
    } else if(window.location.pathname.includes('rebooking-queue')){
      if(this.state.title !== 'Rebooking Queue' )
        this.setState({ title: 'Rebooking Queue' })
    } else if(window.location.pathname.includes('unknown')){
      if(this.state.title !== 'Breakdown Control - Unknown' )
        this.setState({ title: 'Breakdown Control - Unknown' })
    } else if(window.location.pathname.includes('breakdown-control')){
      if(this.state.title !== 'Breakdown Control' )
        this.setState({ title: 'Breakdown Control' })
    } else if(window.location.pathname.includes('notes')){
      if(this.state.title !== 'Notes' )
        this.setState({ title: 'Notes' })
    } else if(window.location.pathname.includes('gatekeeper')){
      if(this.state.title !== 'Gatekeeper' )
        this.setState({ title: 'Gatekeeper' })
    } else if(window.location.pathname.includes('manage-users')){
      if(this.state.title !== 'Manage Users' )
        this.setState({ title: 'Manage Users' })
    } else if(window.location.pathname.includes('change-log')){
      if(this.state.title !== 'Change Log' )
        this.setState({ title: 'Change Log' })
    } else if(window.location.pathname.includes('flight-capacity-planning')){
      if(this.state.title !== 'Flight Capacity Planning' )
        this.setState({ title: 'Flight Capacity Planning' })
    } else if(window.location.pathname.includes('buildup-control')){
      if(this.state.title !== 'Buildup Control' )
        this.setState({ title: 'Buildup Control' })
    }else if(window.location.pathname.includes('manage-dashboard')){
      if(this.state.title !== 'Manage Dashboard' )
        this.setState({ title: 'Manage Dashboard' })
    }else if(window.location.pathname.includes('reports')){
      if(this.state.title !== 'Reports' )
        this.setState({ title: 'Reports' })
    }else if(window.location.pathname.includes('developer-domain')){
      if(this.state.title !== 'Developer Domain' )
        this.setState({ title: 'Developer Domain' })
    }else if(window.location.pathname.includes('demo')){
      if(this.state.title !== 'Message Interface' )
        this.setState({ title: 'Message Interface' })
    }
    
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalID)
  }
  handleStationChange(e)
  {
    //let zone = timezone?.find((item) => item?.value === e)?.zone || ''
    if(timezone) {
      cookies.set('station', e, { path: '/' })
      //cookies.set('timezone', timezones[e], { path: '/' })
      deleteCookie('timezone')
      createCookie(COOKIE.TIMEZONE, timezones[e],'','/')
      this.setState({ stat: e })
      window.location.href =  window.location.pathname
    } else {
      //IF STATION NOT FOUND SET IT BACK TO ATL
      cookies.set('station', 'ATL', { path: '/' })
      cookies.set('timezone', 'America/New_York', { path: '/' })
      this.setState({ stat: 'ATL' })
    }
  }

  handleDepartmentChange(e)
  {
    cookies.set('department', e, { path: '/' })
    this.setState({ dept: e })
  }

  closeNotificationsModal = () => {
    this.setState({ notificationsOpen: false })
  }

  renderMenuRoutes = (routes) =>
    routes.map((it) =>{
      if(it.is_menu){
        return it.roles.indexOf(String(getCookie(COOKIE.ROLE))) > '-1'  ? (
          <Menu.Item
            className={it.className}
            key={it.key}
          >
            <a href={it.link}>{it.title}</a>
          </Menu.Item>
        ) : null
      }
    })
  render() {
    return (
      <Layout className=''>
        <div className={ this.state.collapsed ? '' : 'overlay'} onClick={ this.toggle }></div>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} collapsedWidth={0} width={310} className='sidbar'>
          <div className='f-spacebtw user-details-outer'>
            <div className='user-details'>
              <p className='username'>{this.state.name}</p>
              <p className='user-position'>{this.state.role}</p>
            </div>
            <Link className='signout f-middle f-column' onClick={logout} to={'#'} >
              <UploadOutlined />
              <p className='signout-text'>Sign Out</p>
            </Link>
          </div>
          <div className='f-justify-end fv-middle mr-3 weight-toggle'>
            <span className='toggle-label'>lbs</span>
            <Switch
              checked={this.state.weight}
              checkedChildren='LB'
              unCheckedChildren='KG'
              onChange={(e) => {
                this.setState({ weight: e })
                cookies.set('weight', e === true ? 'LB' : 'KG', { path: '/' })
              }}
            />
            <span className='toggle-label'>kgs</span>
          </div>
          <div className='menu-title'>
            Menu
          </div>
          <Menu theme='dark' mode='inline' selectedKeys={[this.state.key]} className='slider-menu'>
            {this.renderMenuRoutes(this.state.routes)}
          </Menu>
        </Sider>
        <Layout className='site-layout'>
          <Header className='site-layout-background header-outer h-mt no-print'>
            <Row className='header-container'>
              <Col flex='310px' className='gutter-row header-first'>
                <Row className='h-100'>
                  <Col span={24} className='fs-0 mb-2 ml-2'>
                    <Title level={4} className='company-title mb-0'>
                      CARGO HUB CONTROL
                    </Title>
                  </Col>
                  <Col flex='auto' className='mt-auto f-spacebtw'>
                    <span className='menu-text' onClick={this.toggle}>MENU</span>
                    <Text className='mt-auto ml-auto page-title'>{this.state.title}</Text>
                  </Col>
                </Row>
              </Col>
              <Col flex='auto' className='gutter-row header-middle text-center f-middle'>            
                <StepsCommon />
              </Col>
              <Col flex='310px' className='gutter-row header-userdetails f-middle px-4 position-relative'>
                <Text className='sub-title'>
                  {this.state.clock}
                </Text>
                {/* <Button icon={<BellOutlined />} type='link' onClick={() => this.setState({ notificationsOpen: true })} /> */}
                { this.state.role === 'Super Admin' || this.state.role === 'Station Leader' ?
                  <Select
                    defaultValue='Select Station'
                    size='small'
                    style={{ width: 120 }}
                    value={this.state.stat}
                    onChange={e=>this.handleStationChange(e)}
                    options={[{ 
                      value: 'ATL',
                      label: 'ATL' 
                    }]}                  
                  />
                  :
                  <Select
                    className='transparent-disabled-select'
                    defaultValue='Select Station'
                    size='small'
                    style={{ width: 120 }}
                    value={this.state.stat}
                    onChange={e=>this.handleStationChange(e)}
                    disabled
                    options={[{
                      value: 'ATL',
                      label: 'ATL' 
                    }]}                  
                  />
                }
              </Col>
            </Row>
          </Header>
        </Layout>
        {this.state.notificationsOpen && <Notifications visible={this.state.notificationsOpen} closeModal={this.closeNotificationsModal} />}
      </Layout>
    )
  }
}

export default Main
