import { pagelist } from '../constants/constants'

'use strict'

/**************************************
 * operations related to cookie
 ***************************************/

var cookie = {
  OTP: 'otp',
  STATION: 'station',
  TIMEZONE: 'timezone',
  NAME: 'name',
  ROLE: 'role',
  JOB_TITLE: 'job_title',
  USERNAME: 'username',
  ID: 'id',
  FIREBASETOKEN: 'firebasetoken',
  WEIGHT: 'weight',
  PPR: 'ppr',
  EXPIRY: 'expiry',
  TILES: 'tiles',
  TILES_BU: 'tiles_bu',
  DEMO_DATA: 'demo_data',
  RESET_TIME: 'reset_time',
  DEMO_BUTTONS: 'demo_buttons',
}

// create cookie by name with specific path, domain and expiry time (if paased)
function createCookie(name, value, hours, path, domain) {
  //var cookie = name + "=" + escape(value) + ";";
  var cookie = name + '=' + value + ';'
  // if (expires) {
  //   // If it's a date
  //   if (expires instanceof Date) {
  //     // If it isn't a valid date
  //     if (isNaN(expires.getTime())) expires = new Date();
  //   } else
  //     expires = new Date(
  //       new Date().getTime() + parseInt(expires) * 1000 * 60 * 60
  //     );
  //   cookie += "expires=" + expires.toGMTString() + ";";
  // }
  var expires = ''
  if (hours) {
    var date = new Date()
    date.setTime(date.getTime() + hours * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  cookie += expires + ';'

  if (path) cookie += 'path=' + path + ';'
  if (domain) cookie += 'domain=' + domain + ';'

  if (typeof window !== 'undefined') {
    document.cookie = cookie
  }
}

// get cookie value from name
function getCookie(name) {
  var nameEQ = name + '='
  var ca
  if (typeof window !== 'undefined') {
    ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

// delete specific cookie by name
function deleteCookie(name) {
  // If the cookie exists
  if (getCookie(name)) {
    //createCookie(name, '', -1, path, domain);
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
  }
}

function logout() {
  deleteCookie(COOKIE.OTP)
  deleteCookie(COOKIE.STATION)
  deleteCookie(COOKIE.TIMEZONE)
  deleteCookie(COOKIE.ROLE)
  deleteCookie(COOKIE.JOB_TITLE)
  deleteCookie(COOKIE.NAME)
  deleteCookie(COOKIE.USERNAME)
  deleteCookie(COOKIE.WEIGHT)
  deleteCookie(COOKIE.ID)
  deleteCookie(COOKIE.PPR)
  deleteCookie(COOKIE.EXPIRY)
  deleteCookie(COOKIE.DEMO_DATA)
  window.location.href = '/login'
}

function pageHandler(url, role) {
  if(!role){
    logout()
  }else {
    let row = pagelist.find((item) => item?.link === url)
    if (row){
      if(row.roles.indexOf(role) > '-1'){
        //do nothing
      } else {
        if(role === 'Super Admin' || role === 'Station Leader'){
          window.location.href = '/system-parameter/fleet'
        } else if(role === 'User' || role === 'Re-booking Team'){
          window.location.href = '/rebooking-queue'
        } else if(role === 'Warehouse TV'){
          window.location.href = '/landing-page'
        } else {
          logout()
        }
      }
    } else {
      if(role === 'Super Admin' || role === 'Station Leader'){
        window.location.href = '/system-parameter/fleet'
      } else if(role === 'User' || role === 'Re-booking Team'){
        window.location.href = '/rebooking-queue'
      } else if(role === 'Warehouse TV'){
        window.location.href = '/landing-page'
      } else {
        logout()
      }
    }
  }
}
let COOKIE = cookie
// module.exports = {
//   COOKIE: cookie,
//   createCookie: createCookie,
//   getCookie: getCookie,
//   deleteCookie: deleteCookie,
//   pageHandler: pageHandler,
// }

export { COOKIE, createCookie, getCookie, deleteCookie, pageHandler, logout }
