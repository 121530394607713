import React, { Component } from 'react'
import { Form, Input, Button, Typography, Row, Col, notification } from 'antd'
import Logo from '../../assets/images/logo.png'
import User from '../../assets/images/user.svg'
import Locked from '../../assets/images/locked.svg'
import moment from 'moment'
import './login.css'
import Http from '../../utils/http'
import {
  COOKIE,
  createCookie,
  getCookie,
  pageHandler
} from '../../utils/cookie'
import { timezones } from '../../constants/constants'

const { Title } = Typography

class Login extends Component {
  formRef = React.createRef()
  state = {
    username: '',
    password: '',
    otp: '',
    validate: false,
    forgetpass: false,
    validatereset: false,
  }
  componentDidMount() {
    if (getCookie(COOKIE.OTP) != null) {
      //this.props.history.push('/*')
      pageHandler('',getCookie(COOKIE.ROLE))
    }
  }

  login = async () => {
    let formdata = {
      username: this.state.username,
      password: this.state.password
    }
    await Http.post('/user_login', formdata)
      .then((data) => {
        if (data && data.status === 200) {
          this.setState({ validate: true })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  validateOtp = async () => {
    let formdata = {
      username: this.state.username,
      otp: this.state.otp
    }
    await Http.post('/otp_verify', formdata)
      .then((data) => {
        if (data && data.status === 200) {
          let station = data?.data?.data?.station ? data?.data?.data?.station : 'ATL'
          let ttl = 24
          let days = 1
          if(data?.data?.data?.role === 'Warehouse TV'){
            ttl = 24 * 6
            days = 6
          }
          createCookie(COOKIE.OTP, this.state.otp, ttl,'/')
          createCookie(COOKIE.STATION, station, ttl)
          createCookie(COOKIE.TIMEZONE, timezones[station], ttl)
          createCookie(COOKIE.NAME, `${data?.data?.data?.name?.first_name} ${data?.data?.data?.name?.last_name}` , ttl,'/')
          createCookie(COOKIE.ROLE, data?.data?.data?.role, ttl,'/')
          createCookie(COOKIE.JOB_TITLE, data?.data?.data?.job_title, ttl,'/')
          createCookie(COOKIE.USERNAME, data?.data?.data?.username, ttl,'/')
          createCookie(COOKIE.ID, data?.data?.data?.id, ttl,'/')
          createCookie(COOKIE.WEIGHT, 'LB', ttl,'/')
          createCookie(COOKIE.EXPIRY, moment().add(days, 'days').format('YYYY-MM-DD HH:mm:ss'), ttl,'/')
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
          pageHandler('', getCookie(COOKIE.ROLE))
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  validateEmail = async () => {
    let formdata = { username: this.state.username, }
    await Http.post('/verify_reset_email', formdata)
      .then((data) => {
        if (data && data.status === 200) {
          this.setState({ validatereset: true })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  resetpassword = async () => {
    let formdata = {
      username: this.state.username,
      otp: this.state.otp
    }
    await Http.post('/reset_password', formdata)
      .then((data) => {
        if (data && data.status === 200) {
          this.setState({
            username: '',
            password: '',
            otp: '',
            validate: false,
            forgetpass: false,
            validatereset: false,
          },()=>this.onReset())
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  onReset = () => {
    this.formRef.current.resetFields()
  }

  render() {
    return (
      <div className='login-container-outer text-center m-auto'>
        <Row className='login-container'>
          <Col md={24} lg={12} className='login-content'>
            <div>
              <Title level={1} className='login-company-title text-center'>CARGO HUB CONTROL</Title>
              <Title level={5} className='login-company-subtitle text-right'>TIME TO CONNECT</Title>
            </div>
            <div className='divider my-4 w-100'></div>
            <Row className='w-100 logo-wrap-gap'>
              <Col flex='100px'>
                <img src={Logo} alt='Logo' className='login-logo' />
              </Col>
              <Col className='login-form-width'>
                <Title level={2} className='text-left login-heading'>{this.state.forgetpass ? 'FORGOT PASSWORD' : 'LOGIN'}</Title>

                <Form
                  name='basic'
                  initialValues={{ remember: true }}
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete='off'
                  className='m-auto text-center login-form'
                  ref={this.formRef}
                >
                  {  !this.state.validate && !this.state.validatereset && (
                    <Form.Item
                      label=''
                      name='username'
                      rules={[{
                        required: true,
                        message: 'Enter username!'
                      }]}
                    >
                      <Input
                        prefix={<img src={User} />}
                        className='login-input username-input'
                        value={this.state.username}
                        onChange={(e) => this.setState({ username: e.target.value })}
                        placeholder='Enter Username'
                      />
                    </Form.Item>
                  )}
                  {  !this.state.validate && !this.state.forgetpass && (
                    <Form.Item
                      label=''
                      name='password'
                      rules={[{
                        required: true,
                        message: 'Enter password!'
                      }]}
                    >
                      <Input.Password
                        prefix={<img src={Locked} />}
                        className='login-input password-input'
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        placeholder='Enter Password'
                      />
                    </Form.Item>
                  )}
                  {  this.state.validate || this.state.validatereset ? (
                    <Form.Item
                      label=''
                      name='otp'
                      rules={[{
                        required: true,
                        message: 'Enter Access Code!'
                      }]}
                    >
                      <Input
                        prefix={<img src={Locked} />}
                        className='login-input username-input'
                        value={this.state.otp}
                        onChange={(e) => this.setState({ otp: e.target.value })}
                        placeholder='Enter Access Code'
                      />
                    </Form.Item>
                  ) : null}
                  <Form.Item
                  >
                    <Button
                      type='primary'
                      className='w-100 submit-btn'
                      onClick={() =>
                        this.state.validate? this.validateOtp() :
                          this.state.forgetpass ? this.state.validatereset ?
                            this.resetpassword() : this.validateEmail()
                            :this.login()
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                { !this.state.forgetpass ?
                  <a className='forgot-link' onClick={() => this.setState({ forgetpass: true })} >Forgot Password</a>
                  : <a className='forgot-link' onClick={() => this.setState({ forgetpass: false })} >User Login</a>
                }
              </Col>
            </Row>
          </Col>
          <Col lg={12} className='f-middle bck-img'>
          </Col>
        </Row>
      </div>
    )
  }
}
export default Login
