import React, { Component } from 'react'
import { pages as PAGE } from '../../constants/constants'
import { Row, Col, Typography, Form, Card, Table, Select, Space, notification, Spin, Statistic, InputNumber, Divider, Modal, Button, Radio, Popover, Popconfirm, Tooltip, Switch, Input, Badge } from 'antd'
import { LoadingOutlined, CaretRightOutlined, CaretLeftOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons'
import { weightCalculator, getLeastAWB, handlingTimes, sort } from '../../utils/common'
// import markcompletedIcon from '../../assets/images/input-tick.svg'
// import addIcon from '../../assets/images/add.svg'
// import editIcon from '../../assets/images/edit.svg'
// import deleteIcon from '../../assets/images/delete.svg'
import cssconstants from '../../constants/cssconstants'
// import constants from '../../constants/constants'
import Http from '../../utils/http'
import HeaderSearch from '../search'
import Cookies from 'universal-cookie'
import './index.css'
import moment from 'moment'
import { CountUp } from '../../utils/timer'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { cloneDeep } from 'lodash'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
//const dateFormat = 'DDMMMYY HH:mm'
// eslint-disable-next-line no-duplicate-imports
import { constants, timezone } from '../../constants/constants'
import { pageHandler, getCookie, COOKIE, createCookie } from '../../utils/cookie'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const dateFormat = 'DDMMMYY HH:mm'
const CHARNUM3COMMA = '^[0-9a-zA-Z]{3}(,([0-9a-zA-Z]{3}))*$'
const CHARNUM3COMMAerr = 'field must contain 3 character alpha-numeric value seperated by a comma'

const ULD_CARGO = ['BLK', 'PTU', 'SLU', 'ULD']
const ULD_TYPES = ['N/A', 'Local', 'Mixed', 'Transit', 'Transit-Potential']

const { Title } = Typography
const { Option } = Select
const { Countdown } = Statistic

function isValidULD (ULD_DISPO) {
  return ULD_DISPO === 'BREAKDOWN' || ULD_DISPO === 'MOVE AS THRU UNIT' || ULD_DISPO === 'KEEP INTACT FOR DELIVERY'
}
//FORMAT FLIGHT NO/CONNEX NO
function formatFlightConnexNo(flight_no, flight_date) {
  let formatted = ''
  formatted = flight_no +'/'+ moment.utc(flight_date).format('DDMMMYY')
  return formatted
}
function formatElapsedTime(info, status, type) {
  let today = moment().utc(), time_diff = ''

  if(info && info?.fps) {
    let fps_date = moment.utc(info.fps, 'YYYY-MM-DD HH:mm')
    let end_date = moment.utc(info.end, 'YYYY-MM-DD HH:mm')

    if(status?.toUpperCase() !== 'COMPLETED') {
      time_diff = Math.abs(today.diff(fps_date, 'minutes'))
    } else {
      time_diff = Math.abs(end_date.diff(fps_date, 'minutes'))
    }

    var hours = Math.floor(time_diff / 60)
    var minutes = time_diff % 60
    var seconds = minutes % 60
    var days = Math.floor(hours / 24)

    today.add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds')

    if(type === 'STATIC') {
      return `${days}d${hours}h${minutes}m`
    } else if(type === 'DYNAMIC') {
      return today.local().format('YYYY-MM-DD HH:mm:ss')
    }
  } else return ''
}
function getProdCode(awbs, priorityData) {
  let prodCodes = []
  if(awbs?.length > 0) {
    if(awbs.length === 1) {
      return awbs[0].awb.prod_code || null
    } else {
      awbs.map((awb) => {
        if(awb?.awb?.prod_code) prodCodes.push(awb.awb.prod_code)
      })

      if(priorityData?.length === 0) return prodCodes[0]
      else {
        let topPriority = { name: '' }

        let checkedData = []
        for(var i=0;i<prodCodes.length;i++) {
          for(var j=0;j<priorityData.length;j++) {
            if(prodCodes[i] === priorityData[j].p_code) {
              checkedData.push(priorityData[j])
              break
            }
          }
        }

        for(var a=0;a<prodCodes.length;a++) {
          for(var b=0;b<checkedData.length;b++) {
            let object = checkedData[b]
            let top = checkedData[0].priority

            if(prodCodes[a] === object.p_code && object.priority <= top) {
              topPriority.name = object.p_code
            }
          }
        }
        return topPriority.name || null
      }
    }
  } else {
    return null
  }
}
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}
function formatWeightString(breakdown_wgt, usual_wgt, unit) {
  return `${breakdown_wgt.toFixed(1)+unit}/${usual_wgt.toFixed(1)+unit}`
}
function formatPcsString(breakdown_pcs, usual_pcs) {
  return `${breakdown_pcs}/${usual_pcs}`
}
function getFirstFlightOutDate(arrayOfDates) {
  arrayOfDates?.sort((a, b) => b?.flight_date < a?.flight_date ? 1: -1)
  return arrayOfDates[0]
}

class BreakdownControl extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this),
    this.previous = this.previous.bind(this),
    this.state = {
      weight_unit: 'KG',
      loading: false,
      isFilterChecked: false,
      filterValue: null,
      breakdownControlData: [],
      prodPriorityData: [],
      workAreaData: [],
      ULDGridData: [],
      AWBGridDataForBreakdown: [],
      AWBGridDataBrokendown: [],
      recoveryActionsData: [],
      systemParameters: [],
      defaultSystemParameters: [],
      stationsData: [],
      amber: 0,
      uldRow: null,
      uldRowId: null,
      awbRow: null,
      awbRowId: null,
      type: null,
      search: '',
      sort: 'handling_time',
      flightHistoryVisible: false,
      isSearch: false,
      isListening: false,
      cookieInterval: null,
      tileInterval: null,
      incorrectLoc: false,
      incorrectLocationRecord: null,
      location: '',
      radioValue: 1,
      servererror: false,
      isCompleted: false,
      hours: 6,
      spinnerForEvents: false,
      spinnerForUlds: false,
      switch: false,
      openMultiselect:false,
      sliderLoad : false,
      uldSpinner: true,

      station: new Cookies().get('station'),
      timezone: new Cookies().get('timezone'),
      user_id: new Cookies().get('id'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      timezone_cty: timezone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York',

      uldFilters: [],
      typeFilters: [],
      workAreaFilters: [],
      workAreaOptions: [],
      displayWorkAreaOptions: [],
      prodCodeFilters: [],
      codeList: [],
      displayCodeList: [],
      locationFilters: [],
      locationList: [],
      displayLocationList: [],
      exclusionArray: [],
      exclusionList: [],
      exclusionSearch: '',
      prodValue: '',

      checkAllProdCodes: true,
      checkAllLocations: true,
      breakdownFilter: {},
    }
    this._queue = []
    this._isBusy = false
  }
  //CDM
  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    //WEIGHT COOKIE INTERVAL
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })
    //UPDATE TILE INTERVAL
    if(!this.state.isSearch && !this.state.isCompleted) {
      this.setState({ tileInterval: setInterval(this.updateSummaryOnEvent, 5000) })
    }
    // const system_params_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    const breakdown_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=bd&stn=${this.state.station}`)

    breakdown_events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        this.enqueue(myFakeAsyncJob)
      }
    }
    breakdown_events.onerror = (error) => {
      if(error?.type === 'error') {
        this.setState({ servererror : true })
      }
    }
    // system_params_events.onmessage = (event) => {
    //   const parsedData = JSON.parse(event.data)
    //   if(!this.state.isListening) this.setState({ isListening: true })
    //   else {
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   }
    // }
    this.fetchSystemParameters()
    this.setState({ sliderLoad : true,  })
  }
  componentWillUnmount() {
    this.setState({
      cookieInterval: clearInterval(this.state.cookieInterval),
      tileInterval: clearInterval(this.state.tileInterval)
    })
  }
  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }
  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.Msg === 'ULD UPDATE'){
      this.updateULDOnEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'FSU') {
      this.processFSUEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'status') {
      this.spliceULDOnStatusEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE') {
      this.processAWBUpdateEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'BOOKING_UPDATE') {
      this.processBookingUpdateEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'DEFAULT_HANDLING_TIMES') {
      this.fetchDefaultSystemParameters('EVENT')
    }
    else if(parsedData?.TYPE === 'WORK_AREA') {
      this.fetchWorkAreas('EVENT')
    }
    else if(parsedData?.TYPE === 'STATIONS') {
      this.fetchStations('EVENT')
    }
    else if(parsedData?.TYPE === 'STATION_DETAILS') {
      this.fetchAmberValue('EVENT')
    }
    else if(parsedData?.TYPE === 'HANDLING_TIMES') {
      this.fetchSystemParameters('EVENT')
    }
    else if(parsedData?.TYPE === 'PRODUCT_PRIORITY') {
      this.fetchProductPriority('EVENT')
    }
    else if(parsedData?.TYPE === 'RECOVERY_ACTION') {
      this.fetchRecoveryActionsData('EVENT')
    }
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this.setState({ spinnerForEvents: true })
      this._isBusy = true
      next
        .job()
        .then((value) => {
          this.eventList(value)
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          this.setState({ spinnerForEvents: false })
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    } else {
      this.setState({ spinnerForEvents: false })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */

  //PROCESS EVENT API CALLS ONLY
  /**************************************************************************************************** */

  checkIfSearchMatch = (record) => {
    let flag = false
    let search = this.state.search?.split('=')[1]
    if(search?.includes('/')) {
      let flight_no = `${record?.flights_in?.flight_no}/${moment(record?.flights_in?.flight_date).format('DDMMMYY')}`
      if(flight_no?.toUpperCase() === search?.toUpperCase()) {
        flag = true
      }
    } else if(search?.toUpperCase() === record?.uld_no?.toUpperCase()) {
      flag = true
    } else if(record?.awbs_in?.length > 0) {
      let awb_flag = false
      record.awbs_in.map((awb) => {
        if(awb_flag === false) {
          if(awb?.awb_no?.toUpperCase() === search?.replace(/[^a-zA-Z ]/g, '')?.toUpperCase()) {
            awb_flag = true
          }
        }
      })

      if(awb_flag === true) {
        flag = true
      }
    } else {
      flag = false
    }

    return flag
  }
  updateAllTileOnEventSearch = (DATA, DETAILS, ACTION, IS_SELECTED) => {
    let flag = this.checkIfSearchMatch(DATA)
    if(flag && DATA?.deliver_to) {
      let breakdownControlData = cloneDeep(this.state.breakdownControlData)
      let data_index = breakdownControlData?.findIndex(p => p.area_name?.toUpperCase() == 'ALL')
      let uld_index = breakdownControlData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

      if(ACTION === 'ADD' || ACTION === 'UPDATE') {
        if(breakdownControlData[data_index].ulds && breakdownControlData[data_index].ulds?.length > 0) {
          if(uld_index > -1) {
            breakdownControlData[data_index].ulds[uld_index] = this.processIndividualULD(DATA)
          } else {
            breakdownControlData[data_index].ulds.push(this.processIndividualULD(DATA))
          }
        } else {
          if(uld_index > -1) {
            breakdownControlData[data_index].ulds[uld_index] = this.processIndividualULD(DATA)
          } else {
            breakdownControlData[data_index].ulds = []
            breakdownControlData[data_index].ulds.push(this.processIndividualULD(DATA))
          }
        }
      } else if(ACTION === 'DELETE') {
        if(uld_index > -1 && data_index > -1) {
          breakdownControlData[data_index].ulds.splice(uld_index, 1)
        }
      }
      this.sortertimer(breakdownControlData[data_index].ulds)
      this.sortGrid(breakdownControlData[data_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)

      //IF ALL WAS CURRENTLY SELECTED UPDATE ULDS ELSE DON'T
      if(IS_SELECTED) {
        this.setState({
          breakdownControlData: [],
          ULDGridData: [],
        }, () => this.setState({
          breakdownControlData: breakdownControlData,
          ULDGridData: breakdownControlData[data_index].ulds,
        }, () => {
          if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
            this.setState({
              AWBGridDataBrokendown: [],
              AWBGridDataForBreakdown: []
            }, () => this.setState({
              AWBGridDataBrokendown: breakdownControlData[data_index].ulds[uld_index].brokendown_awb,
              AWBGridDataForBreakdown: breakdownControlData[data_index].ulds[uld_index].for_breakdown,
            }))
          }

          if(!this.state.isCompleted) {
            this.updateWorkAreaSummaryOnSearch()
          }
        }))
      } else {
        this.setState({ breakdownControlData: [] }, () => this.setState({ breakdownControlData: breakdownControlData }, () => {
          if(!this.state.isCompleted) {
            this.updateWorkAreaSummaryOnSearch()
          }
        }))
      }
    }
  }
  updateULDOnEvent = async (DETAILS) => {
    let breakdownControlData = cloneDeep(this.state.breakdownControlData)
    let latestBreakdownData = breakdownControlData
    let data_index = latestBreakdownData?.findIndex(p => p.area_name == this.state.type)
    let filtered_data = latestBreakdownData?.filter((item) => item.area_name === this.state.type)
    let uld_index = filtered_data?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
    let isAllTileSelected = false

    if(this.state.type?.toUpperCase() === 'ALL') {
      isAllTileSelected = true
    }

    if(this.state.isSearch || this.state.isCompleted) {
      if(DETAILS?.old_deliver_to === DETAILS?.new_deliver_to) {
        if(isValidULD(DETAILS?.uld_dispo?.toUpperCase())) {
          let headers = {
            station: this.state.station,
            page: PAGE.BREAKDOWN,
          }
          await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
            if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
              if(this.ifSearchedMatch(resp?.data?.data[0]) || (this.state.isCompleted && (resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true))) {
                latestBreakdownData = this.state.breakdownControlData
                var new_wa_index = latestBreakdownData.findIndex(p => p.area_name == DETAILS?.new_deliver_to)
                data_index = latestBreakdownData?.findIndex(p => p.area_name == this.state.type)

                if(new_wa_index > -1) {
                  var new_wa_uld_index = latestBreakdownData[new_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
                  if(new_wa_uld_index > -1) {
                    latestBreakdownData[new_wa_index].ulds[new_wa_uld_index] = this.processIndividualULD(resp?.data?.data[0])
                    this.sortertimer(latestBreakdownData[new_wa_index].ulds)
                    this.sortGrid(latestBreakdownData[new_wa_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      //UPDATE ALL TILE
                      this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'UPDATE', isAllTileSelected)

                      if(!this.state.isCompleted) {
                        this.updateWorkAreaSummaryOnSearch()
                      }
                    }))
                  } else {
                    if(latestBreakdownData[new_wa_index].ulds && latestBreakdownData[new_wa_index].ulds?.length > 0) {
                      latestBreakdownData[new_wa_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                      this.sortertimer(latestBreakdownData[new_wa_index].ulds)
                      this.sortGrid(latestBreakdownData[new_wa_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                      this.setState({
                        breakdownControlData: [],
                        ULDGridData: [],
                      }, () => this.setState({
                        breakdownControlData: latestBreakdownData,
                        ULDGridData: latestBreakdownData[data_index].ulds,
                      }, () => {
                        if(this.state.isFilterChecked && this.state.filterValue) {
                          this.getFilteredData()
                        }

                        //ADD ALL TILE
                        this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'ADD', isAllTileSelected)

                        if(!this.state.isCompleted) {
                          this.updateWorkAreaSummaryOnSearch()
                        }
                      }))
                    } else {
                      latestBreakdownData[new_wa_index].ulds = []
                      latestBreakdownData[new_wa_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                      this.sortertimer(latestBreakdownData[new_wa_index].ulds)
                      this.sortGrid(latestBreakdownData[new_wa_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                      this.setState({
                        breakdownControlData: [],
                        ULDGridData: [],
                      }, () => this.setState({
                        breakdownControlData: latestBreakdownData,
                        ULDGridData: latestBreakdownData[data_index].ulds,
                      }, () => {
                        if(this.state.isFilterChecked && this.state.filterValue) {
                          this.getFilteredData()
                        }

                        //ADD ALL TILE
                        this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'ADD', isAllTileSelected)

                        if(!this.state.isCompleted) {
                          this.updateWorkAreaSummaryOnSearch()
                        }
                      }))
                    }
                  }
                }
              }
            }
          })
        } else {
          latestBreakdownData = this.state.breakdownControlData
          var new_wa_index = latestBreakdownData.findIndex(p => p.area_name == DETAILS?.new_deliver_to)

          if(new_wa_index > -1) {
            data_index = latestBreakdownData?.findIndex(p => p.area_name == this.state.type)
            uld_index = latestBreakdownData[new_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

            if(uld_index > -1 && new_wa_index > -1) {
              latestBreakdownData[new_wa_index].ulds.splice(uld_index, 1)
              if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                this.setState({
                  AWBGridDataBrokendown: [],
                  AWBGridDataForBreakdown: [],
                  awbRow: null,
                  awbRowId: null,
                  uldRow: null,
                  uldRowId: null,
                }, () => this.setState({
                  breakdownControlData: [],
                  ULDGridData: [],
                }, () => this.setState({
                  breakdownControlData: latestBreakdownData,
                  ULDGridData: latestBreakdownData[data_index].ulds,
                }, () => {
                  if(this.state.isFilterChecked && this.state.filterValue) {
                    this.getFilteredData()
                  }

                  //UPDATE ALL TILE
                  this.updateAllTileOnEventSearch(null, DETAILS, 'DELETE', isAllTileSelected)

                  if(!this.state.isCompleted) {
                    this.updateWorkAreaSummaryOnSearch()
                  }
                })))
              } else {
                this.setState({
                  breakdownControlData: [],
                  ULDGridData: [],
                }, () => this.setState({
                  breakdownControlData: latestBreakdownData,
                  ULDGridData: latestBreakdownData[data_index].ulds,
                }, () => {
                  if(this.state.isFilterChecked && this.state.filterValue) {
                    this.getFilteredData()
                  }

                  //UPDATE ALL TILE
                  this.updateAllTileOnEventSearch(null, DETAILS, 'DELETE', isAllTileSelected)

                  if(!this.state.isCompleted) {
                    this.updateWorkAreaSummaryOnSearch()
                  }
                }))
              }
            }
          }
        }
      }
      else if(isValidULD(DETAILS?.uld_dispo?.toUpperCase()) && DETAILS?.old_deliver_to !== DETAILS?.new_deliver_to) {
        latestBreakdownData = this.state.breakdownControlData
        let new_wa_index = latestBreakdownData.findIndex(p => p.area_name == DETAILS?.new_deliver_to)
        let response_data = null

        if(new_wa_index > -1) {
          let headers = {
            station: this.state.station,
            page: PAGE.BREAKDOWN,
          }
          await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
            if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
              response_data = resp?.data?.data
              if(this.ifSearchedMatch(resp?.data?.data[0]) || (this.state.isCompleted && (resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true))) {
                latestBreakdownData = this.state.breakdownControlData
                data_index = latestBreakdownData?.findIndex(p => p.area_name == this.state.type)
                new_wa_index = latestBreakdownData.findIndex(p => p.area_name == DETAILS?.new_deliver_to)

                let new_wa_uld_index = latestBreakdownData[new_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
                if(new_wa_uld_index > -1) {
                  latestBreakdownData[new_wa_index].ulds[new_wa_uld_index] = this.processIndividualULD(resp?.data?.data[0])
                  this.sortertimer(latestBreakdownData[new_wa_index].ulds)
                  this.sortGrid(latestBreakdownData[new_wa_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                  this.setState({
                    breakdownControlData: [],
                    ULDGridData: [],
                  }, () => this.setState({
                    breakdownControlData: latestBreakdownData,
                    ULDGridData: latestBreakdownData[data_index].ulds,
                  }, () => {
                    if(this.state.isFilterChecked && this.state.filterValue) {
                      this.getFilteredData()
                    }

                    if(!this.state.isCompleted) {
                      this.updateWorkAreaSummaryOnSearch()
                    }
                  }))
                } else {
                  if(latestBreakdownData[new_wa_index].ulds && latestBreakdownData[new_wa_index].ulds?.length > 0) {
                    latestBreakdownData[new_wa_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                    this.sortertimer(latestBreakdownData[new_wa_index].ulds)
                    this.sortGrid(latestBreakdownData[new_wa_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      if(!this.state.isCompleted) {
                        this.updateWorkAreaSummaryOnSearch()
                      }
                    }))
                  } else {
                    latestBreakdownData[new_wa_index].ulds = []
                    latestBreakdownData[new_wa_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                    this.sortertimer(latestBreakdownData[new_wa_index].ulds)
                    this.sortGrid(latestBreakdownData[new_wa_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      if(!this.state.isCompleted) {
                        this.updateWorkAreaSummaryOnSearch()
                      }
                    }))
                  }
                }
              }
            }
          })
        }

        let old_wa_index = latestBreakdownData.findIndex(p => p.area_name == DETAILS?.old_deliver_to)
        if(old_wa_index > -1) {
          uld_index = latestBreakdownData[old_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

          if(uld_index > -1 && old_wa_index > -1) {
            latestBreakdownData[old_wa_index].ulds.splice(uld_index, 1)
            if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
              this.setState({
                AWBGridDataBrokendown: [],
                AWBGridDataForBreakdown: [],
                awbRow: null,
                awbRowId: null,
                uldRow: null,
                uldRowId: null,
              }, () => this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                //UPDATE ALL TILE
                this.updateAllTileOnEventSearch(response_data[0], DETAILS, 'UPDATE', isAllTileSelected)

                if(!this.state.isCompleted) {
                  this.updateWorkAreaSummaryOnSearch()
                }
              })))
            } else {
              this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                //UPDATE ALL TILE
                this.updateAllTileOnEventSearch(response_data[0], DETAILS, 'UPDATE', isAllTileSelected)

                if(!this.state.isCompleted) {
                  this.updateWorkAreaSummaryOnSearch()
                }
              }))
            }
          }
        } else {
          //UPDATE ALL TILE
          this.updateAllTileOnEventSearch(response_data[0], DETAILS, 'UPDATE', isAllTileSelected)
        }
      }
    }
    else {
      //IF OLD AND NEW IS SAME - UPDATE ULD
      if((filtered_data[0]?.area_name === DETAILS?.old_deliver_to && filtered_data[0]?.area_name === DETAILS?.new_deliver_to) || isAllTileSelected) {
        if(isValidULD(DETAILS?.uld_dispo?.toUpperCase())) {
          let headers = {
            station: this.state.station,
            page: PAGE.BREAKDOWN,
          }
          await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
            if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
              if(resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) {
                latestBreakdownData = this.state.breakdownControlData
                uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
                if(uld_index > -1) {
                  latestBreakdownData[data_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])
                  this.sortertimer(latestBreakdownData[data_index].ulds)
                  this.sortGrid(latestBreakdownData[data_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                  if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                      AWBGridDataBrokendown: [],
                      AWBGridDataForBreakdown: []
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                      AWBGridDataBrokendown: latestBreakdownData[data_index]?.ulds[uld_index]?.brokendown_awb,
                      AWBGridDataForBreakdown: latestBreakdownData[data_index]?.ulds[uld_index]?.for_breakdown,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      // if(!this.state.isSearch) {
                      //   this.updateSummaryOnEvent()
                      // }
                    }))
                  } else {
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      // if(!this.state.isSearch) {
                      //   this.updateSummaryOnEvent()
                      // }
                    }))
                  }
                } else {
                  if(latestBreakdownData[data_index].ulds && latestBreakdownData[data_index].ulds?.length > 0) {
                    latestBreakdownData[data_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                    this.sortertimer(latestBreakdownData[data_index].ulds)
                    this.sortGrid(latestBreakdownData[data_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      // if(!this.state.isSearch) {
                      //   this.updateSummaryOnEvent()
                      // }
                    }))
                  } else {
                    latestBreakdownData[data_index].ulds = []
                    latestBreakdownData[data_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                    this.sortertimer(latestBreakdownData[data_index].ulds)
                    this.sortGrid(latestBreakdownData[data_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      // if(!this.state.isSearch) {
                      //   this.updateSummaryOnEvent()
                      // }
                    }))
                  }
                }
              } else if(resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true){
                latestBreakdownData = this.state.breakdownControlData
                uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
                if(uld_index > -1 && data_index > -1) {
                  latestBreakdownData[data_index].ulds.splice(uld_index, 1)
                  if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                    this.setState({
                      AWBGridDataBrokendown: [],
                      AWBGridDataForBreakdown: [],
                      awbRow: null,
                      awbRowId: null,
                      uldRow: null,
                      uldRowId: null,
                    }, () => this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      // if(!this.state.isSearch) {
                      //   this.updateSummaryOnEvent()
                      // }
                    })))
                  } else {
                    this.setState({
                      breakdownControlData: [],
                      ULDGridData: [],
                    }, () => this.setState({
                      breakdownControlData: latestBreakdownData,
                      ULDGridData: latestBreakdownData[data_index].ulds,
                    }, () => {
                      if(this.state.isFilterChecked && this.state.filterValue) {
                        this.getFilteredData()
                      }

                      // if(!this.state.isSearch) {
                      //   this.updateSummaryOnEvent()
                      // }
                    }))
                  }
                }
              }
            }
          })
        } else {
          latestBreakdownData = this.state.breakdownControlData
          uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
          if(uld_index > -1 && data_index > -1) {
            latestBreakdownData[data_index].ulds.splice(uld_index, 1)
            if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
              this.setState({
                AWBGridDataBrokendown: [],
                AWBGridDataForBreakdown: [],
                awbRow: null,
                awbRowId: null,
                uldRow: null,
                uldRowId: null,
              }, () => this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                // if(!this.state.isSearch) {
                //   this.updateSummaryOnEvent()
                // }
              })))
            } else {
              this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                // if(!this.state.isSearch) {
                //   this.updateSummaryOnEvent()
                // }
              }))
            }
          }
        }
      }
      //IF OLD IS SAME AND NEW DIFFERENT - DELETE ULD
      else if((isValidULD(DETAILS?.uld_dispo?.toUpperCase()) && filtered_data[0]?.area_name === DETAILS?.old_deliver_to && filtered_data[0]?.area_name !== DETAILS?.new_deliver_to)) {
        latestBreakdownData = this.state.breakdownControlData
        uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

        if(uld_index > -1 && data_index > -1) {
          latestBreakdownData[data_index].ulds.splice(uld_index, 1)
          if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
            this.setState({
              AWBGridDataBrokendown: [],
              AWBGridDataForBreakdown: [],
              awbRow: null,
              awbRowId: null,
              uldRow: null,
              uldRowId: null,
            }, () => this.setState({
              breakdownControlData: [],
              ULDGridData: [],
            }, () => this.setState({
              breakdownControlData: latestBreakdownData,
              ULDGridData: latestBreakdownData[data_index].ulds,
            }, () => {
              if(this.state.isFilterChecked && this.state.filterValue) {
                this.getFilteredData()
              }

              // if(!this.state.isSearch) {
              //   this.updateSummaryOnEvent()
              // }
            })))
          } else {
            this.setState({
              breakdownControlData: [],
              ULDGridData: [],
            }, () => this.setState({
              breakdownControlData: latestBreakdownData,
              ULDGridData: latestBreakdownData[data_index].ulds,
            }, () => {
              if(this.state.isFilterChecked && this.state.filterValue) {
                this.getFilteredData()
              }

              // if(!this.state.isSearch) {
              //   this.updateSummaryOnEvent()
              // }
            }))
          }
        }
      }
      //IF OLD IS DIFFERENT AND NEW SAME - ADD ULD
      else if((isValidULD(DETAILS?.uld_dispo?.toUpperCase()) && filtered_data[0]?.area_name !== DETAILS?.old_deliver_to && filtered_data[0]?.area_name === DETAILS?.new_deliver_to)) {
        let headers = {
          station: this.state.station,
          page: PAGE.BREAKDOWN,
        }
        await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
          if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
            if(resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) {
              latestBreakdownData = this.state.breakdownControlData

              if(latestBreakdownData[data_index].ulds && latestBreakdownData[data_index].ulds?.length > 0) {
                uld_index = latestBreakdownData[data_index]?.ulds?.findIndex((p) => p.id === resp?.data?.data[0]?.id)

                if(uld_index > -1) {
                  latestBreakdownData[data_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])
                } else {
                  latestBreakdownData[data_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                }
                this.sortertimer(latestBreakdownData[data_index].ulds)
                this.sortGrid(latestBreakdownData[data_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)

                this.setState({
                  breakdownControlData: [],
                  ULDGridData: [],
                }, () => this.setState({
                  breakdownControlData: latestBreakdownData,
                  ULDGridData: latestBreakdownData[data_index].ulds,
                }, () => {
                  if(this.state.isFilterChecked && this.state.filterValue) {
                    this.getFilteredData()
                  }

                  // if(!this.state.isSearch) {
                  //   this.updateSummaryOnEvent()
                  // }
                }))
              } else {
                latestBreakdownData[data_index].ulds = []
                latestBreakdownData[data_index].ulds.push(this.processIndividualULD(resp?.data?.data[0]))
                this.sortertimer(latestBreakdownData[data_index].ulds)
                this.sortGrid(latestBreakdownData[data_index].ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
                this.setState({
                  breakdownControlData: [],
                  ULDGridData: [],
                }, () => this.setState({
                  breakdownControlData: latestBreakdownData,
                  ULDGridData: latestBreakdownData[data_index].ulds,
                }, () => {
                  if(this.state.isFilterChecked && this.state.filterValue) {
                    this.getFilteredData()
                  }

                  // if(!this.state.isSearch) {
                  //   this.updateSummaryOnEvent()
                  // }
                }))
              }
            }
          }
        })
      }
    }
  }
  ifSearchedMatch = (uld) => {
    let { search } = this.state
    search = search?.split('=')[1]

    if(uld?.uld_no === search?.trim()) {
      return true
    } else if(formatFlightConnexNo(uld?.flights_in.flight_no, uld?.flights_in.flight_date) === search?.trim()) {
      return true
    } else {
      if(uld?.awbs_in?.length > 0) {
        let flag = false
        uld.awbs_in.map((awb) => {
          if(awb?.awb_no === search?.trim()) {
            flag = true
          }
        })
        return flag
      } else {
        return false
      }
    }
  }
  processFSUEvent = async (DETAILS) => {
    let breakdownControlData = cloneDeep(this.state.breakdownControlData)
    let latestBreakdownData = breakdownControlData
    let data_index = latestBreakdownData?.findIndex(p => p.area_name == this.state.type)
    let uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
    let new_wa_index = null

    let isAllTileSelected = false

    if(this.state.type?.toUpperCase() === 'ALL') {
      isAllTileSelected = true
    }

    if(uld_index > -1 && data_index > -1) {
      if(((DETAILS?.status?.toUpperCase() === 'COMPLETED' || DETAILS?.is_completed === true) || (!DETAILS?.work_area_fps || (DETAILS?.new_deliver_to !== this.state.type && this.state.type?.toUpperCase() !== 'ALL'))) && !this.state.isSearch && !this.state.isCompleted) {
        latestBreakdownData[data_index].ulds.splice(uld_index, 1)
        if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
          this.setState({
            breakdownControlData: [],
            ULDGridData: [],
            AWBGridDataBrokendown: [],
            AWBGridDataForBreakdown: [],
            awbRow: null,
            awbRowId: null,
            uldRow: null,
            uldRowId: null,
          }, () => this.setState({
            breakdownControlData: latestBreakdownData,
            ULDGridData: latestBreakdownData[data_index].ulds,
          }, () => {
            if(this.state.isFilterChecked && this.state.filterValue) {
              this.getFilteredData()
            }

            // if(!this.state.isSearch && !this.state.isCompleted) {
            //   this.updateSummaryOnEvent()
            // }
          }))
        } else {
          this.setState({
            breakdownControlData: [],
            ULDGridData: [],
          }, () => this.setState({
            breakdownControlData: latestBreakdownData,
            ULDGridData: latestBreakdownData[data_index].ulds,
          }, () => {
            if(this.state.isFilterChecked && this.state.filterValue) {
              this.getFilteredData()
            }

            // if(!this.state.isSearch && !this.state.isCompleted) {
            //   this.updateSummaryOnEvent()
            // }
          }))
        }
      } else {
        let headers = {
          station: this.state.station,
          page: PAGE.BREAKDOWN,
        }
        await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
          if (resp && resp?.data && resp?.data?.data?.length > 0 &&
            (((resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) && !this.state.isSearch && !this.state.isCompleted) ||
            (this.state.isSearch) || ((resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true) && this.state.isCompleted))) {
            latestBreakdownData = this.state.breakdownControlData

            if(this.state.isSearch || this.state.isCompleted) {
              new_wa_index = latestBreakdownData?.findIndex(p => p.area_name == resp?.data?.data[0]?.deliver_to)
              uld_index = latestBreakdownData[new_wa_index ? new_wa_index : data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
            }

            if(uld_index > -1) {
              latestBreakdownData[new_wa_index ? new_wa_index : data_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])

              this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                if(this.state.isSearch) {
                  //UPDATE ALL TILE
                  this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'UPDATE', isAllTileSelected)
                  this.updateWorkAreaSummaryOnSearch()
                }
                // if(!this.state.isSearch && !this.state.isCompleted) {
                //   this.updateSummaryOnEvent()
                // } else if(this.state.isSearch) {
                //   //UPDATE ALL TILE
                //   this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'UPDATE', isAllTileSelected)
                //   this.updateWorkAreaSummaryOnSearch()
                // }
              }))

              if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                this.setState({
                  AWBGridDataBrokendown: [],
                  AWBGridDataForBreakdown: [],
                }, () => this.setState({
                  AWBGridDataBrokendown: latestBreakdownData[data_index].ulds[uld_index].brokendown_awb,
                  AWBGridDataForBreakdown: latestBreakdownData[data_index].ulds[uld_index].for_breakdown,
                }))
              }
            }
          } else {
            if(!this.state.isCompleted) {
              latestBreakdownData[data_index]?.ulds?.splice(uld_index, 1)
              if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                this.setState({
                  AWBGridDataBrokendown: [],
                  AWBGridDataForBreakdown: [],
                  awbRow: null,
                  awbRowId: null,
                  uldRow: null,
                  uldRowId: null,
                }, () => this.setState({
                  breakdownControlData: latestBreakdownData,
                  ULDGridData: latestBreakdownData[data_index].ulds,
                }, () => {
                  if(this.state.isFilterChecked && this.state.filterValue) {
                    this.getFilteredData()
                  }

                  // if(!this.state.isSearch) {
                  //   this.updateSummaryOnEvent()
                  // }
                }))
              }
            }
          }
        })
      }
    } else {
      DETAILS.new_deliver_to = DETAILS?.work_area_fps
      DETAILS.old_deliver_to = DETAILS?.old_work_area_fps
      this.updateULDOnEvent(DETAILS)
    }
  }
  processAWBUpdateEvent = async (DETAILS) => {
    let breakdownControlData = cloneDeep(this.state.breakdownControlData)
    let latestBreakdownData = breakdownControlData
    var data_index = breakdownControlData?.findIndex(p => p.area_name == this.state.type)
    var uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
    let isAllTileSelected = false
    let new_wa_index = null

    if(this.state.type?.toUpperCase() === 'ALL') {
      isAllTileSelected = true
    }

    if(uld_index > -1 || this.state.isSearch) {
      let headers = {
        station: this.state.station,
        page: PAGE.BREAKDOWN,
      }
      await Http.get(`awbs?id=${parseInt(DETAILS?.goods_info_id)}`, { headers }).then((resp) => {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          latestBreakdownData = this.state.breakdownControlData

          let awb_index = null
          if(this.state.isSearch) {
            new_wa_index = latestBreakdownData?.findIndex(p => p.area_name == DETAILS?.deliver_to)
            if(new_wa_index > -1) {
              uld_index = latestBreakdownData[new_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
              if(uld_index > -1) {
                awb_index = latestBreakdownData[new_wa_index]?.ulds[uld_index]?.awbs_in?.findIndex(p => p.id == parseInt(DETAILS?.goods_info_id))
                if(awb_index > -1) {
                  latestBreakdownData[new_wa_index].ulds[uld_index].awbs_in[awb_index] = this.processIndividualAWB(resp?.data?.data[0])
                  latestBreakdownData[new_wa_index].ulds[uld_index] = this.processIndividualULD(latestBreakdownData[data_index].ulds[uld_index])
                }
              }
            }
          } else {
            awb_index = latestBreakdownData[data_index]?.ulds[uld_index]?.awbs_in?.findIndex(p => p.id == parseInt(DETAILS?.goods_info_id))
            if(awb_index > -1) {
              latestBreakdownData[data_index].ulds[uld_index].awbs_in[awb_index] = this.processIndividualAWB(resp?.data?.data[0])
              latestBreakdownData[data_index].ulds[uld_index] = this.processIndividualULD(latestBreakdownData[data_index].ulds[uld_index])
            }
          }

          if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
            this.setState({
              AWBGridDataBrokendown: [],
              AWBGridDataForBreakdown: []
            }, () => this.setState({
              AWBGridDataBrokendown: latestBreakdownData[data_index].ulds[uld_index].brokendown_awb,
              AWBGridDataForBreakdown: latestBreakdownData[data_index].ulds[uld_index].for_breakdown,
            }))
          }
        }

        this.setState({
          breakdownControlData: [],
          ULDGridData: [],
        }, () => this.setState({
          breakdownControlData: latestBreakdownData,
          ULDGridData: latestBreakdownData[data_index].ulds,
        }, () => {
          if(this.state.isFilterChecked && this.state.filterValue) {
            this.getFilteredData()
          }

          if(this.state.isSearch && new_wa_index && uld_index && latestBreakdownData[new_wa_index]?.ulds[uld_index]) {
            //UPDATE ALL TILE
            this.updateAllTileOnEventSearch(latestBreakdownData[new_wa_index].ulds[uld_index], DETAILS, 'UPDATE', isAllTileSelected)
          }
        }))
      })
    }
  }
  processBookingUpdateEvent = async (DETAILS) => {
    let breakdownControlData = cloneDeep(this.state.breakdownControlData)
    let latestBreakdownData = breakdownControlData
    var data_index = breakdownControlData?.findIndex(p => p.area_name == this.state.type)
    var uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
    let new_wa_index = null
    let isAllTileSelected = false

    if(this.state.type?.toUpperCase() === 'ALL') {
      isAllTileSelected = true
    }

    if(uld_index > -1 || this.state.isSearch) {
      let headers = {
        station: this.state.station,
        page: PAGE.BREAKDOWN,
      }
      await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
        if (resp && resp?.data && resp?.data?.data?.length > 0 &&
          (((resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) && !this.state.isSearch && !this.state.isCompleted) ||
          (this.state.isSearch) || ((resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true) && this.state.isCompleted))) {
          latestBreakdownData = this.state.breakdownControlData

          if(this.state.isSearch || this.state.isCompleted) {
            new_wa_index = latestBreakdownData?.findIndex(p => p.area_name == resp?.data?.data[0]?.deliver_to)

            if(new_wa_index > -1) {
              uld_index = latestBreakdownData[new_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
            } else {
              uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
            }
          }

          if(isValidULD(resp?.data?.data[0]?.uld_dispo?.toUpperCase())) {
            if(new_wa_index > -1 && uld_index > -1) {
              if(latestBreakdownData[new_wa_index]?.ulds?.length > 0) {
                latestBreakdownData[new_wa_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])
              }
            } else {
              if(data_index > -1 && uld_index > -1) {
                if(latestBreakdownData[new_wa_index]?.ulds?.length > 0) {
                  latestBreakdownData[data_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])
                }
              }
            }

            if(data_index > -1) {
              this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                if(this.state.isSearch) {
                  //UPDATE ALL TILE
                  this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'UPDATE', isAllTileSelected)
                }
              }))

              if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                this.setState({
                  AWBGridDataBrokendown: [],
                  AWBGridDataForBreakdown: [],
                }, () => this.setState({
                  AWBGridDataBrokendown: latestBreakdownData[data_index].ulds[uld_index].brokendown_awb,
                  AWBGridDataForBreakdown: latestBreakdownData[data_index].ulds[uld_index].for_breakdown,
                }))
              }
            }
          } else {
            if(uld_index > -1 && uld_index > -1) {
              latestBreakdownData[data_index]?.ulds?.splice(uld_index, 1)

              this.setState({
                breakdownControlData: [],
                ULDGridData: [],
              }, () => this.setState({
                breakdownControlData: latestBreakdownData,
                ULDGridData: latestBreakdownData[data_index].ulds,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }

                if(this.state.isSearch) {
                  //UPDATE ALL TILE
                  this.updateAllTileOnEventSearch(null, DETAILS, 'DELETE', isAllTileSelected)
                }

                if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                  this.setState({
                    AWBGridDataBrokendown: [],
                    AWBGridDataForBreakdown: [],
                    awbRow: null,
                    awbRowId: null,
                    uldRow: null,
                    uldRowId: null,
                  }, () => {
                    if(this.state.isSearch) {
                      this.updateWorkAreaSummaryOnSearch()
                    }
                    // if(!this.state.isSearch && !this.state.isCompleted) {
                    //   this.updateSummaryOnEvent()
                    // } else if(this.state.isSearch) {
                    //   this.updateWorkAreaSummaryOnSearch()
                    // }
                  })
                } else {
                  if(this.state.isSearch) {
                    this.updateWorkAreaSummaryOnSearch()
                  }
                  // if(!this.state.isSearch && !this.state.isCompleted) {
                  //   this.updateSummaryOnEvent()
                  // } else if(this.state.isSearch) {
                  //   this.updateWorkAreaSummaryOnSearch()
                  // }
                }
              }))
            }
          }
        }
      })
    }
  }
  spliceULDOnStatusEvent = async (DETAILS) => {
    let breakdownControlData = cloneDeep(this.state.breakdownControlData)
    let latestBreakdownData = breakdownControlData
    var data_index = breakdownControlData?.findIndex(p => p.area_name == this.state.type)
    var uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
    let isAllTileSelected = false
    let new_wa_index = null

    if(this.state.type?.toUpperCase() === 'ALL') {
      isAllTileSelected = true
    }

    if(DETAILS?.status?.toUpperCase() === 'COMPLETED' || DETAILS?.is_completed === true) {
      if(uld_index > -1 && data_index > -1) {
        if(this.state.isSearch || this.state.isCompleted) {
          let headers = {
            station: this.state.station,
            page: PAGE.BREAKDOWN,
          }
          //UPDATE ULD
          await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers }).then((resp) => {
            if(resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true) {
              new_wa_index = latestBreakdownData?.findIndex(p => p.area_name == resp?.data?.data[0]?.deliver_to)

              if(new_wa_index > -1) {
                uld_index = latestBreakdownData[new_wa_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
              } else {
                uld_index = latestBreakdownData[data_index]?.ulds?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
              }

              if(isValidULD(resp?.data?.data[0]?.uld_dispo?.toUpperCase())) {
                if(new_wa_index > -1 && uld_index > -1) {
                  if(latestBreakdownData[new_wa_index]?.ulds?.length > 0) {
                    latestBreakdownData[new_wa_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])
                  }
                } else {
                  if(data_index > -1 && uld_index > -1) {
                    if(latestBreakdownData[new_wa_index]?.ulds?.length > 0) {
                      latestBreakdownData[data_index].ulds[uld_index] = this.processIndividualULD(resp?.data?.data[0])
                    }
                  }
                }

                if(data_index > -1) {
                  this.setState({
                    breakdownControlData: [],
                    ULDGridData: [],
                  }, () => this.setState({
                    breakdownControlData: latestBreakdownData,
                    ULDGridData: latestBreakdownData[data_index].ulds,
                  }, () => {
                    if(this.state.isFilterChecked && this.state.filterValue) {
                      this.getFilteredData()
                    }

                    if(this.state.isSearch) {
                      //UPDATE ALL TILE
                      this.updateAllTileOnEventSearch(resp?.data?.data[0], DETAILS, 'UPDATE', isAllTileSelected)
                    }
                  }))

                  if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                    this.setState({
                      AWBGridDataBrokendown: [],
                      AWBGridDataForBreakdown: [],
                    }, () => this.setState({
                      AWBGridDataBrokendown: latestBreakdownData[data_index].ulds[uld_index].brokendown_awb,
                      AWBGridDataForBreakdown: latestBreakdownData[data_index].ulds[uld_index].for_breakdown,
                    }))
                  }
                }
              } else {
                if(uld_index > -1 && uld_index > -1) {
                  latestBreakdownData[data_index]?.ulds?.splice(uld_index, 1)

                  this.setState({
                    breakdownControlData: [],
                    ULDGridData: [],
                  }, () => this.setState({
                    breakdownControlData: latestBreakdownData,
                    ULDGridData: latestBreakdownData[data_index].ulds,
                  }, () => {
                    if(this.state.isFilterChecked && this.state.filterValue) {
                      this.getFilteredData()
                    }

                    if(this.state.isSearch) {
                      //UPDATE ALL TILE
                      this.updateAllTileOnEventSearch(null, DETAILS, 'DELETE', isAllTileSelected)
                    }

                    if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
                      this.setState({
                        AWBGridDataBrokendown: [],
                        AWBGridDataForBreakdown: [],
                        awbRow: null,
                        awbRowId: null,
                        uldRow: null,
                        uldRowId: null,
                      }, () => {
                        if(this.state.isSearch) {
                          this.updateWorkAreaSummaryOnSearch()
                        }
                      })
                    } else {
                      if(this.state.isSearch) {
                        this.updateWorkAreaSummaryOnSearch()
                      }
                    }
                  }))
                }
              }
            }
          })
        } else {
          latestBreakdownData[data_index]?.ulds?.splice(uld_index, 1)
          this.setState({ 
            breakdownControlData: latestBreakdownData,
            ULDGridData: latestBreakdownData[data_index].ulds 
          },()=> {
            if(this.state.uldRowId === parseInt(DETAILS?.uld_id)) {
              this.setState({
                AWBGridDataBrokendown: [],
                AWBGridDataForBreakdown: [],
                awbRow: null,
                awbRowId: null,
                uldRow: null,
                uldRowId: null,
              }, () => {
                if(this.state.isFilterChecked && this.state.filterValue) {
                  this.getFilteredData()
                }
              })
            }
          })
        }
      }
    }
  }
  updateSummaryOnEvent = async () => {
    let headers = {
      station: this.state.station,
      page: PAGE.BREAKDOWN,
    }
    await Http.get('breakdown_control', { headers }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        let data = resp?.data?.data.map((item) => {
          return {
            ...item,
            isMaster: true,
          }
        })
        data = this.createAllTile(data)
        let latestBreakdownData = cloneDeep(this.state.breakdownControlData)
        var latest_data_index = data.findIndex(p => p.area_name == this.state.type)
        var data_index = latestBreakdownData?.findIndex(p => p.area_name == this.state.type)

        if(latest_data_index > -1 && data_index > -1) {
          data[latest_data_index].ulds = latestBreakdownData[data_index]?.ulds
        }
        this.setState({ breakdownControlData: [] }, () => this.setState({ breakdownControlData: data }))
      }
    })
  }
  sort = (array) => {
    array?.sort(function(a, b) {
      var keyA = a?.area_name
      var keyB = b?.area_name
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    const sorter = (a, b) => {
      if(!a['isMaster'] && b['isMaster']){
        return -1
      }
      if(a['isMaster'] && !b['isMaster']){
        return 1
      }
      return a['area_name'] - b['area_name']
    }
    array.sort(sorter)
  }

  /**************************************************************************************************** */

  //FETCH HANDLING TIMES
  fetchSystemParameters = async (event) => {
    if(!event) this.setState({ loading: true })
    await Http.get(`handling_times?station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ systemParameters: data })
        } else {
          this.setState({ systemParameters: [] })
        }
        if(!event) this.fetchProductPriority()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH PRODUCT PRIORITY
  fetchProductPriority = async (event) => {
    Http.get(`/products_priority?station=${this.state.station}`)
      .then((data) => {
        if (data && data.status === 200) {
          if(data && data.data && data.data.data[0] && data.data.data[0].prod_prior_list.length>0){
            this.setState({ prodPriorityData : data.data.data[0].prod_prior_list })
          } else {
            this.setState({ prodPriorityData : [] })
          }
          if(!event) this.fetchDefaultSystemParameters()
          else {
            if(this.state.isSearch) this.getSearchResults(this.state.search)
            else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
            this.setState({ loading: false })
          }
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async (event) => {
    await Http.get(`handling_times?default=true&station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ defaultSystemParameters: data })
        } else {
          this.setState({ defaultSystemParameters: [] })
        }
        if(!event) this.fetchRecoveryActionsData()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchRecoveryActionsData = async (event) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BREAKDOWN,
    }
    await Http.get('recovery_actions', { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ recoveryActionsData: data })
        } else {
          this.setState({ recoveryActionsData: [] })
        }
        if(!event) this.fetchWorkAreas()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchWorkAreas = async (event) => {
    let headers = { 
      station: this.state.station, 
      module: 'inbound' 
    }
    await Http.get('work_areas', { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let  filterWorkAreaOptions = []
          let data = resp?.data?.data.map((item, index) => {
            filterWorkAreaOptions.push(item?.area_name)
            return {
              ...item,
              key: index
            }
          })

          this.setState({
            workAreaData: data,
            workAreaOptions: filterWorkAreaOptions,
            displayWorkAreaOptions: filterWorkAreaOptions,
          })
        } else {
          this.setState({
            workAreaData: [],
            workAreaOptions: [],
            displayWorkAreaOptions: [],
          })
        }
        if(!event) this.fetchStations()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH STATIONS
  fetchStations = async (event) => {
    await Http.get('stations', { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ stationsData: data })
        } else {
          this.setState({ stationsData: [] })
        }
        if(!event) this.fetchAmberValue()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH AMBER VALUE
  fetchAmberValue = async (event) => {
    Http.get(`/station_details?station=${this.state.station}`)
      .then((data) => {
        if(data?.data?.data?.length > 0) {
          this.setState({ amber: data.data.data[0]?.indicator_time || 0 })
        }
        if(!event) this.fetchFilters(false)
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.updateState(this.state.breakdownControlData, this.state.type, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      })
  }
  //FETCH USER FILTERS
  fetchFilters = async (user_update) => {
    await Http.get(`/page_filter/${this.state.user_id}`).then(async (data) => {
      if (data && data?.status === 200) {
        let res_data = data?.data?.data?.page_filters?.breakdown_filter && data?.data?.data?.page_filters?.breakdown_filter[this.state.station]
        if(res_data) {
          this.setState({
            breakdownFilter: data?.data?.data?.page_filters?.breakdown_filter,
            uldFilters: res_data.uld_type?.length > 0 ? res_data.uld_type : ULD_TYPES,
            workAreaFilters: res_data.deliver_to?.length > 0 ? res_data.deliver_to : this.state.workAreaOptions,
            typeFilters: res_data.uld_no?.length > 0 ? res_data.uld_no : ULD_CARGO,
            prodCodeFilters: res_data.prod_code || [],
            locationFilters: res_data.location || [],
            exclusionArray: res_data.exclusion || [],
            exclusionList: res_data.exclusion || [],

            checkAllProdCodes: res_data.prod_code && res_data.prod_code?.length > 0 ? false : true,
            checkAllLocations: res_data.location && res_data.location?.length > 0 ? false : true,
          })
        } else {
          this.setState({
            breakdownFilter: data?.data?.data?.page_filters?.breakdown_filter || {},
            uldFilters: ULD_TYPES,
            workAreaFilters: this.state.workAreaOptions,
            typeFilters: ULD_CARGO,
            prodCodeFilters: [],
            locationFilters: [],
            exclusionArray: [],
            exclusionList: [],

            checkAllProdCodes: true,
            checkAllLocations: true,
          })
        }
        if(!user_update) this.fetchBreakdownControlData(res_data?.area_name || '')
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  //FETCH BREAKDOWN CONTROL DATA
  fetchBreakdownControlData = async (type) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BREAKDOWN,
    }
    let url = 'breakdown_control'
    await Http.get(url, { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item) => {
            return {
              ...item,
              isMaster: true,
            }
          })
          //CREATE ALL TILE
          data = this.createAllTile(data)
          this.setState({ breakdownControlData: [] },() => this.setState({ breakdownControlData: data }, () => this.fetchULDData(type ? type : data[0].area_name, null, this.state.isCompleted)))
        } else {
          this.setState({
            breakdownControlData: [],
            ULDGridData: [],
            AWBGridDataForBreakdown: [],
            AWBGridDataBrokendown: [],
            type: null,
            loading: false
          }, () => {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //CREATE ALL TILE
  createAllTile = (data) => {
    let allTileObj = {
      area_name: 'All',
      data: {
        ulds: 0,
        awbs: 0,
        count: 0,
        ember: 0,
        wgt: [],
        stat: false
      },
      ids: [],
      locations: [],
      station: this.state.station,
      isMaster: false,
    }

    let uld_count = 0, awb_count = 0, awb_red_count = 0, amber_count = 0, wgt = []
    data?.map((item) => {
      uld_count = uld_count + item?.data?.ulds
      awb_count = awb_count  + item?.data?.awbs
      awb_red_count = awb_red_count + item?.data?.count
      amber_count = amber_count + item?.data?.ember
      if(item?.data?.wgt?.length > 0) {
        item.data.wgt.map((wgtObj) => {
          wgt.push(wgtObj)
        })
      }
    })

    allTileObj.data.ulds = uld_count
    allTileObj.data.awbs = awb_count
    allTileObj.data.wgt = wgt
    allTileObj.data.stat = data?.filter((item) => item?.data?.stat === true)?.length > 0 ? true : false
    allTileObj.data.count = awb_red_count
    allTileObj.data.ember = amber_count
    data.push(allTileObj)
    this.sort(data)

    //SET TILES ORDER BASED ON SET COOKIES
    data = this.processWorkAreas(data)
    return data
  }
  //UPDATE DATA
  updateState = (data, type, uldRow, awbRow) => {
    data = this.manipulatedata(data, type)
    //SORT DATA TO SHOW HANDLING TIMES DESCENDING
    data.map((dataItem) => {
      if(dataItem?.ulds?.length > 0) {
        this.sortertimer(dataItem.ulds)
        this.sortGrid(dataItem.ulds, this.state.sort, this.state.sort === 'type' ? false : true, true)
      }
    })
    let filtered = [], filteredULD = null, filteredAWB = null
    if(type) filtered = data.find((item) => item.area_name === type)
    if(uldRow) filteredULD = filtered?.ulds.find((item) => item.id === uldRow.id)
    if(awbRow) filteredAWB = filteredULD?.awbs_in.find((item) => item.id === awbRow.id)
    this.setState({
      breakdownControlData: [],
      //ULDGridData: [],
      AWBGridDataForBreakdown: filteredULD ? filteredULD?.for_breakdown : [],
      AWBGridDataBrokendown: filteredULD ? filteredULD?.brokendown_awb : [],
      type : type ? type : null,
      uldRow: filteredULD ? filteredULD : null,
      uldRowId: filteredULD ? filteredULD.id : null,
      awbRow: filteredAWB ? filteredAWB : null,
      awbRowId: filteredAWB ? filteredAWB.id : null,
      loading: false,
    }, () => this.setState({
      breakdownControlData: data,
      ULDGridData: filtered?.ulds?.length > 0 ? filtered.ulds : []
    }, () => {
      if(this.state.isFilterChecked && this.state.filterValue) {
        this.getFilteredData()
      } else {
        this.exclusionFilter(false)
      }
    }))
  }

  //FETCH ULD DATA
  fetchULDData = async (type, last_id, is_completed) => {
    let { hours } = this.state
    this.setState({ spinnerForUlds: true })
    let url = ''
    let respdata = []

    if (last_id){
      url = `break_down_list?work_area=${type?.toUpperCase() === 'ALL' ? '' : type}&last_id=${last_id}`
      if(is_completed) {
        url = url + `&status=${is_completed}&hours=${hours}`
      }
    } else{
      url = `break_down_list?work_area=${type?.toUpperCase() === 'ALL' ? '' : type}`
      if(is_completed) {
        url = url + `&status=${is_completed}&hours=${hours}`
      }
    }
    let headers = {
      station: this.state.station,
      page: PAGE.BREAKDOWN,
    }
    await Http.get(url, { headers }).then(async resp => {
      this.setState({ uldSpinner: true })
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        respdata = cloneDeep(resp?.data?.data)
        if((this.state.type && type !== this.state.type) || (is_completed !== this.state.isCompleted) || (this.state.isSearch)){ // If work area was change, don't update or call next page
          //do nothing
        } else {
          let { uldRow, awbRow, breakdownControlData } = this.state   //Defined let here inorder to get latest state after response comes from API
          breakdownControlData.map((item, index) => {
            if(item.area_name === type) {
              if(item?.ulds?.length > 0 && last_id) {
                breakdownControlData[index].ulds = item.ulds.concat(resp?.data?.data)
              } else {
                breakdownControlData[index].ulds = resp?.data?.data
              }

              //CHECK FOR DUPLICATE ENTRIES
              let newData = []
              breakdownControlData[index]?.ulds?.map((item) => {
                var id = newData.findIndex(p => p.id == parseInt(item.id))
                if(id > -1) {
                  //DO NOTHING
                } else {
                  newData.push(item)
                }
              })
              breakdownControlData[index].ulds = newData
              this.updateState(breakdownControlData, type, uldRow, awbRow)
            }
          })
          // await this.updateState(breakdownControlData, type, uldRow, awbRow)
          if (this.state.ULDGridData.length > 0) {
            this.setState({ uldSpinner: false })
          }
          this.fetchULDData(type, respdata[respdata.length-1].id, is_completed)  // get next page
        }
      } else {
        if(!last_id) {
          let breakdownControlData = this.state.breakdownControlData
          breakdownControlData.map((item, index) => {
            if(item.area_name === type) {
              breakdownControlData[index].ulds = []
            }
          })

          this.setState({
            breakdownControlData: breakdownControlData,
            ULDGridData: [],
            AWBGridDataBrokendown: [],
            AWBGridDataForBreakdown: [],
            awbRow: null,
            awbRowId: null,
            uldRow: null,
            uldRowId: null,
            loading: false,
            spinnerForUlds: false,
            type: type,
            uldSpinner: false
          })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: 'No Data Found',
          })
        } else {
          this.setState({
            loading: false,
            spinnerForUlds: false,
            uldSpinner: false,
            type: type
          })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true,
        spinnerForUlds: false,
        uldSpinner: false
      })
    })
  }
  //POPULATE GRID BASED ON CARD SELECTION, DEFAULTS TO FIRST CARD IF ANY
  manipulatedata = (data, type) => {
    let work_area_index = data?.findIndex((p) => p.area_name === type)

    if(work_area_index > -1) {
      let ulds = data[work_area_index]?.ulds?.length > 0 ? data[work_area_index].ulds : []
      if(ulds.length > 0) {
        ulds.map((uld, index) => {
          data[work_area_index].ulds[index] = this.processIndividualULD(uld)
        })
        sort(data[work_area_index].ulds, 'id')
        sort(data[work_area_index].ulds, this.state.sort)
      }
    }
    return data
  }
  //UPDATE INDIVIDUAL ULD
  processIndividualULD = (uld) => {
    uld.uld_type = uld?.uld_type ? uld.uld_type : 'N/A'
    uld.type = this.checkULDType(uld)
    uld.weight_kg = 0
    uld.weight_lbs = 0
    uld.weight_kg_breakdown = 0
    uld.weight_lbs_breakdown = 0
    uld.breakdown_pcs = 0
    uld.pcs = 0
    uld.prod_code = getProdCode(uld?.awbs_in, this.state.prodPriorityData)
    if(uld?.prod_code) {
      this.pushProdCode(uld.prod_code)
    }
    if(uld?.location) {
      this.pushLocation(uld.location)
    }
    let flights_out_dates = [], array_of_dates = []
    let awbs = uld?.awbs_in?.length > 0 ? uld.awbs_in : []
    awbs.map((awb, awb_index) => {
      awbs[awb_index] = this.processIndividualAWB(awb)

      //IF ULD DELIVER TO IS EMPTY UPDATE AWB STATUS COLOR/MESSAGE
      // if(!uld?.work_areas_id) {
      //   awb.handling_time_color = 'red'
      // }

      if(awb?.first_flights_out) flights_out_dates.push(awb.first_flights_out)
      array_of_dates.push(awb.handling_time)
      uld.weight_lbs+=awb.weight_lbs
      uld.weight_kg+=awb.weight_kg
      uld.weight_kg_breakdown+=awb.breakdown_wgt_kg
      uld.weight_lbs_breakdown+=awb.breakdown_wgt_lbs

      uld.breakdown_pcs+=awb.breakdown_pcs //ADD ULD PCS
      uld.pcs+=awb.pcs
    })
    sort(awbs, 'id')
    sort(awbs, 'handling_time')

    uld.brokendown_awb = uld?.awbs_in?.length > 0 ? uld.awbs_in.filter((awb) => awb?.status?.toUpperCase() === 'COMPLETED' || awb?.is_completed === true) : []
    uld.for_breakdown = uld?.awbs_in?.length > 0 ? uld.awbs_in.filter((awb) => awb?.status?.toUpperCase() !== 'COMPLETED' && awb?.is_completed === false) : []

    //TO SET EARLIEST ULD TIME ON FRONT END(FIRST PRIORITY ARE THE AWBS WHICH ARE NOT COMPLETED)
    if(uld?.for_breakdown?.length > 0) {
      let least_awb = getLeastAWB(uld?.for_breakdown)
      uld.show_handling_time_after_tta = least_awb.flag
      uld.handling_time_as_timestamp = least_awb.time
      uld.handling_time = least_awb.ht
      uld.amber_countdown = least_awb?.amber_countdown
      uld.amber_flag = least_awb?.amber_flag
      uld.handling_time_color = uld?.for_breakdown.filter((item) => item.handling_time_color !== 'green')?.length > 0 ? false : true
      //ASSIGN FLIGHTS_IN OF AWB FOR ULD
      if(!uld?.flights_in && least_awb?.flights_in) {
        uld.awb_flights_in = least_awb.flights_in
      }
    } else if(uld?.brokendown_awb?.length > 0) {
      let least_awb = getLeastAWB(uld?.brokendown_awb)
      uld.show_handling_time_after_tta = least_awb.flag
      uld.handling_time_as_timestamp = least_awb.time
      uld.handling_time = least_awb.ht
      uld.amber_countdown = least_awb?.amber_countdown
      uld.amber_flag = least_awb?.amber_flag
      uld.handling_time_color = uld?.brokendown_awb.filter((item) => item.handling_time_color !== 'green')?.length > 0 ? false : true
      //ASSIGN FLIGHTS_IN OF AWB FOR ULD
      if(!uld?.flights_in && least_awb?.flights_in) {
        uld.awb_flights_in = least_awb.flights_in
      }
    }

    uld.first_flights_out = flights_out_dates?.length > 0 ? getFirstFlightOutDate(flights_out_dates) : '' //TO CHECK

    //ADD DATA TO SORT
    uld.sort_infos = {}
    uld.sort_infos.ex_flight = uld.first_flights_out?.flight_date
    uld.sort_infos.handling_time = uld.handling_time
    uld.sort_infos.type = uld?.uld_type
    uld.time_since_created = formatElapsedTime(uld?.info, uld?.status, 'DYNAMIC')
    uld.time_since_completed = formatElapsedTime(uld?.info, uld?.status, 'STATIC')

    uld.ulds_historys = this.processULDHistory(uld?.ulds_historys, uld?.deliver_to)
    uld.incorrect_delivery = uld.ulds_historys?.length > 0 ? uld.ulds_historys[0]?.is_incorrect_location : false
    return uld
  }
  //PUSH PROD CODE FOR FILTER
  pushProdCode = (code) => {
    let prodCodeList = this.state.codeList
    let index = prodCodeList?.findIndex((p) => p === code)
    //PUSH N/A
    let na_index = prodCodeList?.findIndex((p) => p === 'N/A')
    if(na_index === -1) {
      prodCodeList.push('N/A')

      this.setState({
        codeList: prodCodeList,
        displayCodeList: prodCodeList,
      })

      if(this.state.checkAllProdCodes) {
        this.setState({ prodCodeFilters: prodCodeList })
      }
    }
    //PUSH OTHER PROD CODES
    if(index === -1) {
      prodCodeList.push(code)

      this.setState({
        codeList: prodCodeList,
        displayCodeList: prodCodeList,
      })

      if(this.state.exclusionArray?.length > 0) {
        this.setState({ displayCodeList: prodCodeList?.filter((item) => !this.state.exclusionArray?.includes(item)) })
      }

      if(this.state.checkAllProdCodes) {
        this.setState({ prodCodeFilters: prodCodeList })
      }
    }
  }
  //PUSH PROD CODE FOR FILTER
  pushLocation = (location) => {
    let locationsList = cloneDeep(this.state.locationList)
    let index = locationsList?.findIndex((p) => p === location)
    if(index === -1) {
      locationsList.push(location)
      this.setState({
        locationList: [],
        displayLocationList: [],
      }, () => this.setState({
        locationList: locationsList,
        displayLocationList: locationsList,
      }))

      if(this.state.checkAllLocations) {
        this.setState({ locationFilters: locationsList })
      }
    }
  }
  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if(uld?.blk) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN' && uld?.master) {
        type = 'PTU'
      }
    }
    return type
  }
  //PROCESS ULD HISTORY
  processULDHistory = (history, deliver_to) => {
    let history_arr = []
    if(history && history?.length > 0) {
      history.map((item, index) => {
        if(item?.event_type === 'FSU') {
          //NEW LOGIC
          if(index === 0) {
            //CHECK IF LATEST MESSAGE WORK AREA MATCHES CURRENT INBOUND WORK AREA
            if(item?.status?.work_location) {
              if((item?.status?.work_area !== item?.status?.work_area_fps) && (deliver_to === item?.status?.work_area_fps) && (item?.status.status?.toUpperCase() === 'NOT STARTED')) {
                item.is_incorrect_location = true
              } else {
                item.is_incorrect_location = false
              }
            } else {
              item.is_incorrect_location = false
            }
          }

          if(!item?.status?.work_location) {
            item.message = `${item?.createdAt ? moment.utc(item.createdAt).tz(this.state.timezone).format(dateFormat) + ' '+this.state.ext : ''} at ${item?.status?.work_area_fps}/${item?.status?.location} - Breakdown ${item?.status?.status}`
          } else if((item?.status?.work_location) && (item?.status?.work_area !== item?.status?.work_area_fps) && (item?.status.status?.toUpperCase() === 'NOT STARTED')){
            item.message = `${item?.createdAt ? moment.utc(item.createdAt).tz(this.state.timezone).format(dateFormat) + ' '+this.state.ext : ''} at ${item?.status?.work_area_fps}/${item?.status?.location} - Incorrect Delivery Location`
          } else if((item?.status?.work_location) && (item?.status?.work_area === item?.status?.work_area_fps)) {
            item.message = `${item?.createdAt ? moment.utc(item.createdAt).tz(this.state.timezone).format(dateFormat) + ' '+this.state.ext : ''} at ${item?.status?.work_area_fps}/${item?.status?.location} - Breakdown ${item?.status?.status}`
          }
          history_arr.push(item)
        }
      })
    }
    return history_arr
  }
  //UPDATE INDIVIDUAL AWB
  processIndividualAWB = (awb) => {
    awb.first_flights_out = awb?.flights_out ? awb.flights_out : awb?.segs_out ? awb.segs_out : null
    awb.check_breakdown = awb?.status?.toUpperCase() === 'COMPLETED' || awb?.is_completed === true ? true : false
    handlingTimes(awb,
      this.state.systemParameters && this.state.systemParameters.filter((item) => item.station === this.state.station),
      this.state.defaultSystemParameters && this.state.defaultSystemParameters.filter((item) => item.station === this.state.station), this.state.stationsData, 'BREAKDOWN')

    //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
    if(moment().valueOf() > moment(awb?.handling_time)?.valueOf()) {
      awb.handling_time_color = 'red'
      awb.ht_minutes = null
    }

    if(awb.wgt.unit === 'LBS' || awb.wgt.unit === 'LBR') {
      awb.weight_lbs = awb.wgt.value
      awb.weight_kg = weightCalculator(awb.wgt.value, 'LBS')
    } else if(awb.wgt.unit === 'KGM') {
      awb.weight_kg = awb.wgt.value
      awb.weight_lbs = weightCalculator(awb.wgt.value, 'KGM')
    }

    //CALC PCS, WEIGHT FOR AWB
    awb.breakdown_pcs = 0
    awb.breakdown_wgt_kg = 0
    awb.breakdown_wgt_lbs = 0
    if(awb?.break_down) {
      if(awb?.break_down?.wgt?.length > 0) {
        awb?.break_down?.wgt?.map((item) => {
          if(item?.unit === 'LBS' || item?.unit === 'LBR') {
            awb.breakdown_wgt_lbs = awb.breakdown_wgt_lbs + item.value
            awb.breakdown_wgt_kg = awb.breakdown_wgt_kg + weightCalculator(item.value, 'LBS')
          } else if(item?.unit === 'KGM') {
            awb.breakdown_wgt_kg = awb.breakdown_wgt_kg + item.value
            awb.breakdown_wgt_lbs = awb.breakdown_wgt_lbs + weightCalculator(item.value, 'KGM')
          }
        })
      }
      awb.breakdown_pcs = awb?.break_down?.pcs

      if(awb.breakdown_pcs !== awb?.pcs && (awb?.status?.toUpperCase() === 'COMPLETED' || awb?.is_completed === true)) {
        if(awb.wgt.unit === 'LBS' || awb.wgt.unit === 'LBR') {
          awb.breakdown_wgt_lbs = awb.wgt.value
          awb.breakdown_wgt_kg = weightCalculator(awb.wgt.value, 'LBS')
        } else if(awb.wgt.unit === 'KGM') {
          awb.breakdown_wgt_kg = awb.wgt.value
          awb.breakdown_wgt_lbs = weightCalculator(awb.wgt.value, 'KGM')
        }
      }
    } else if(!awb?.break_down && (awb?.status?.toUpperCase() === 'COMPLETED' || awb?.is_completed === true)) {
      if(awb.wgt.unit === 'LBS' || awb.wgt.unit === 'LBR') {
        awb.breakdown_wgt_lbs+= awb.wgt.value
        awb.breakdown_wgt_kg+= weightCalculator(awb.wgt.value, 'LBS')
      } else if(awb.wgt.unit === 'KGM') {
        awb.breakdown_wgt_kg+= awb.wgt.value
        awb.breakdown_wgt_lbs+= weightCalculator(awb.wgt.value, 'KGM')
      }
    }
    if((awb?.status?.toUpperCase() === 'COMPLETED' || awb?.is_completed === true)) {
      awb.breakdown_pcs = awb?.pcs
    }

    awb.amber_countdown = moment(awb?.handling_time_after_tta).subtract(this.state.amber, 'minutes')

    if(moment(awb.handling_time).valueOf() < moment().valueOf()) {
      awb.amber_flag = false
    } else {
      awb.amber_flag = moment(awb.amber_countdown).valueOf() < moment().valueOf() ? true : false
    }
    return awb
  }
  //AWB RECOVERY ACTION CHANGE
  handleRecoveryActionChange = async (record, value, rebook) => {
    if(value) {
      let formdata = {}
      formdata.recovery_actions_id = value
      formdata.is_gen_rebook = rebook
      formdata.awb_stat = record.amber_flag? 'amber' : record?.handling_time_color || record?.stat || null
      formdata.reason = record?.is_amber? 'Withing Amber scope' : record?.handling_time_color || record?.stat ? 'Handling Time Elapsed' : null
      formdata.connex_flt = record?.flights_out ? record.flights_out.flight_no : record?.segs_out ? record.segs_out.flight_no :  null
      formdata.connex_date = record?.flights_out ? record.flights_out.flight_date : record?.segs_out ? record.segs_out.flight_date : null
      formdata.rb_trigger = 'Breakdown'
      let headers = {
        station: this.state.station,
        page: PAGE.BREAKDOWN,
        user_id: this.state.user_id,
        page_title: PAGE.breakdown_control,
      }

      await Http.put(`/awb/${parseInt(record.id)}`, formdata, { headers: headers })
        .then(async (data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
    }
  }
  //DELITER TO UPDATE
  handleDeliverToChange = async () => {
    let { incorrectLocationRecord, radioValue, location } = this.state
    let currentLocation = incorrectLocationRecord?.ulds_historys[0]?.status?.work_area_fps

    let formdata = new FormData()
    formdata.append('deliver_to', radioValue === 1 ? currentLocation : location)

    await Http.put(`/uld/${incorrectLocationRecord?.id}`, formdata)
      .then((data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
          this.closeIncorrectLoc()
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }
  //SEND AWB FOR BREAKDOWN
  sendForBreakdown = async (ID, status) => {
    let headers = {
      user_id: this.state.user_id,
      station: this.state.station,
      page: PAGE.BREAKDOWN,
      role: this.state.role,
      page_title: PAGE.breakdown_control,
    }
    let formdata = new FormData()
    formdata.append('status', status)

    await Http.put(`/awb/${parseInt(ID)}`, formdata, { headers })
      .then(async (data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }
  //SET AWB DATA BASED ON ULD ROW CLICKED
  handleRowClick = async (record) => {
    this.setState({
      AWBGridDataBrokendown: [],
      AWBGridDataForBreakdown: []
    }, () => this.setState({
      AWBGridDataBrokendown: record.brokendown_awb,
      AWBGridDataForBreakdown: record.for_breakdown,
    }))
  }
  //HANDLE WORK AREA CLICK
  handleWorkAreaClick = (record) => {
    let { breakdownControlData, uldRow, awbRow, isSearch, isCompleted } = this.state

    this.setState({
      ULDGridData: [],
      AWBGridDataForBreakdown: [],
      AWBGridDataBrokendown: [],
    },()=> {
      if(isSearch === false) {
        this.fetchULDData(record.area_name, null, isCompleted)
      } else {
        this.updateState(breakdownControlData, record.area_name, uldRow, awbRow)
      }
    })
  }
  //SET ROW CLASSNAME ON ROW CLICK
  setRowClassName = (record, type) => {
    return record.id === this.state[type] ? 'selected pointer' : 'pointer'
  }
  //SEARCH LOGIC
  getSearchedData = async (url) => {
    let workAreaArray = this.state.breakdownControlData
    if(url) {
      this.setState({
        filterValue: '',
        isFilterChecked: false,
        loading: true,
        search: url,
        tileInterval: clearInterval(this.state.tileInterval)
      }, async () => {
        let headers = {
          station: this.state.station,
          page: PAGE.BREAKDOWN,
        }
        await Http.get(`break_down_list${url}`, { headers }).then(resp => {
          if (resp && resp?.data && resp?.data?.data?.length > 0) {
            workAreaArray?.map((workArea, index) => {
              workAreaArray[index].ulds = []
              workAreaArray[index].data = {
                ulds: 0,
                awbs: 0,
                count: 0,
                ember: 0,
                wgt: [],
                stat: false,
              }
            })

            resp.data.data.map((uld) => {
              let index = workAreaArray?.findIndex((item) => item.area_name === uld?.deliver_to)
              if(index > -1) {
                let red_count = 0, amber_count = 0
                uld = this.processIndividualULD(uld)
                uld?.awbs_in?.map((awb) => {
                  if(awb?.wgt) {
                    workAreaArray[index].data.wgt.push(awb?.wgt)
                  }
                  if(awb?.handling_time_color === 'red') {
                    workAreaArray[index].data.stat = true
                    red_count = red_count + 1
                  }
                  if(awb?.amber_flag === true) {
                    amber_count = amber_count + 1
                  }
                })

                let temp = workAreaArray[index].ulds
                temp.push(uld)
                workAreaArray[index].ulds = temp
                workAreaArray[index].data.ulds = workAreaArray[index]?.data?.ulds + 1
                workAreaArray[index].data.awbs = workAreaArray[index]?.data?.awbs + uld?.awbs_in?.length
                workAreaArray[index].data.count = red_count
                workAreaArray[index].data.ember = amber_count
              }
              let all_index = workAreaArray?.findIndex((item) => item.area_name?.toUpperCase() === 'ALL')
              if(all_index > -1 && uld?.deliver_to) { //DONT ADD ULDs WITHOUT DELIVER TOs TO ALL TILE
                let red_count = 0, amber_count = 0
                uld = this.processIndividualULD(uld)
                uld?.awbs_in?.map((awb) => {
                  if(awb?.wgt) {
                    workAreaArray[all_index].data.wgt.push(awb?.wgt)
                  }
                  if(awb?.handling_time_color === 'red') {
                    workAreaArray[all_index].data.stat = true
                    red_count = red_count + 1
                  }
                  if(awb?.amber_flag === true) {
                    amber_count = amber_count + 1
                  }
                })

                let temp = workAreaArray[all_index].ulds
                temp.push(uld)
                workAreaArray[all_index].ulds = temp
                workAreaArray[all_index].data.ulds = workAreaArray[all_index]?.data?.ulds + 1
                workAreaArray[all_index].data.awbs = workAreaArray[all_index]?.data?.awbs + uld?.awbs_in?.length
                workAreaArray[all_index].data.count = red_count
                workAreaArray[all_index].data.ember = amber_count
              }
            })

            this.sort(workAreaArray)
            //KEEP SEARCHED WORK AREA SELECTED AND DISPLAY RESPECTIVE ULDS
            let work_area_index = workAreaArray?.findIndex((work_area) => work_area.ulds?.length > 0)

            this.setState({
              search: url,
              isSearch: true,
              isCompleted: false,
              breakdownControlData: [],
              ULDGridData: [],
              AWBGridDataBrokendown: [],
              AWBGridDataForBreakdown: [],
              awbRow: null,
              awbRowId: null,
              uldRow: null,
              uldRowId: null,
              loading: false,
            }, () => this.setState({
              breakdownControlData: workAreaArray,
              ULDGridData: work_area_index > -1 ? workAreaArray[work_area_index]?.ulds : workAreaArray[0]?.ulds,
              type: work_area_index > -1 ? workAreaArray[work_area_index]?.area_name : workAreaArray[0]?.area_name
            }))
          } else if(this.state.search) {
            this.setState({
              loading: false,
              //search: ''
              isSearch: true,
            }, () => {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: 'ULD/AWB not found',
              })
            })
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
      })
    } else {
      this.setState({
        isSearch: false,
        isCompleted: false,
        search: '',
        ULDGridData: [],
        AWBGridDataBrokendown: [],
        AWBGridDataForBreakdown: [],
        awbRow: null,
        awbRowId: null,
        uldRow: null,
        uldRowId: null,
        type: null,
        loading: true,
        filterValue: 1,
        isFilterChecked: true,
      }, () => this.setState({ tileInterval: setInterval(this.updateSummaryOnEvent, 5000) }, () => this.fetchBreakdownControlData()))
    }
  }
  //UPDATE WORK AREA SUMMARY IF SEARCH
  updateWorkAreaSummaryOnSearch = () => {
    let latestBreakdownData = this.state.breakdownControlData

    if(latestBreakdownData?.length > 0) {
      latestBreakdownData?.map((wa_item, wa_index) => {
        let uld_count = 0, awb_count = 0, weight_arr = [], flag = false
        let awb_red_count = latestBreakdownData[wa_index]?.data?.count || 0, amber_count = latestBreakdownData[wa_index]?.data?.ember || 0
        wa_item?.ulds?.map((uld) => {
          uld_count = uld_count + 1
          awb_count = awb_count + uld?.awbs_in?.length
          uld?.awbs_in?.map((awb) => {
            weight_arr.push(awb?.wgt)

            if(awb?.stat === true) {
              flag = true
            }
          })
        })

        latestBreakdownData[wa_index].data.ulds = uld_count
        latestBreakdownData[wa_index].data.awbs = awb_count
        latestBreakdownData[wa_index].data.wgt = weight_arr
        latestBreakdownData[wa_index].data.stat = flag
        latestBreakdownData[wa_index].data.count = awb_red_count
        latestBreakdownData[wa_index].data.ember = amber_count
      })
      this.setState({ breakdownControlData: [] }, () => this.setState({ breakdownControlData: latestBreakdownData }))
    }
  }
  //SORTER
  sortGrid = (array, sort, isDate, initial) => {
    array && array.sort(function(a, b) {
      let objA = a.sort_infos
      let objB = b.sort_infos
      var keyA = isDate ? moment(objA[sort]) : objA[sort]
      var keyB = isDate ? moment(objB[sort]) : objB[sort]
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
    if(initial === false) {
      this.setState({
        ULDGridData: [],
        AWBGridDataForBreakdown: [],
        AWBGridDataBrokendown: [],
      }, () => this.setState({ ULDGridData: array }))
    }
  }
  sortertimer = (array) => {
    array && array.sort(function(a, b) {
      // Compare the fields
      if (moment(a?.time_since_created) > moment(b?.time_since_created)) return -1
      if (moment(a?.time_since_created) < moment(b?.time_since_created)) return 1
      return 0
    })
  }
  //GET FILTERED DATA BY HANDLING TIMES
  getFilteredData = () => {
    if(this.state.filterValue) {
      let data = []
      if(this.state.switch === true) {
        data = this.state.breakdownControlData.find((item) => item.area_name === this.state.type)?.ulds?.filter((item) => moment(item.handling_time).diff(moment(), 'minutes') <= this.state.filterValue)
      } else if(this.state.switch === false) {
        data = this.state.breakdownControlData.find((item) => item.area_name === this.state.type)?.ulds?.filter((item) => moment(item.handling_time).diff(moment(), 'minutes') >= this.state.filterValue)
      }

      if(data?.length > 0) {
        this.setState({ ULDGridData: [] }, () => this.setState({ ULDGridData: data }, () => {
          let uld_index = data.findIndex((p) => p.id === this.state.uldRowId)
          if(uld_index > -1) {
            //DO NOTHING
          } else {
            this.setState({
              AWBGridDataForBreakdown: [],
              AWBGridDataBrokendown: [],
              uldRow: null,
              awbRow: null,
              uldRowId: null,
              awbRowId: null,
            })
          }
          this.exclusionFilter(false)
        }))
      } else {
        this.setState({
          ULDGridData: [],
          AWBGridDataForBreakdown: [],
          AWBGridDataBrokendown: [],
          uldRow: null,
          awbRow: null,
          uldRowId: null,
          awbRowId: null,
        })
      }
    } else {
      this.setState({
        ULDGridData: this.state.breakdownControlData.find((item) => item.area_name === this.state.type)?.ulds,
        filterValue: ''
      }, () => this.exclusionFilter(false))
    }
  }
  //EXCLUDE PRODUCT CODE FILTER
  exclusionFilter = (action) => {
    let ULDs = this.state.ULDGridData
    let exclusions = this.state.exclusionArray
    let arr = []
    if(action) {
      this.saveFilters()
      this.getFilteredData()
    } else {
      arr = ULDs.filter(function(el) {
        return !exclusions?.includes(el?.prod_code)
      })

      if(exclusions?.length > 0) {
        this.setState({ displayCodeList: this.state.codeList?.filter((item) => !exclusions?.includes(item)) })
      } else {
        this.setState({ displayCodeList: this.state.codeList })
      }
      this.setState({ ULDGridData: [] }, () => this.setState({ ULDGridData: arr }))
    }
  }
  //SAVE USER FILTERS
  saveFilters = async () => {
    let exclusions = this.state.exclusionArray?.toString()

    if(exclusions && !exclusions?.match(CHARNUM3COMMA)) {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: CHARNUM3COMMAerr,
      })
    } else {
      this.setState({ exclusionList: cloneDeep(this.state.exclusionArray) })
      let breakdown_filter = cloneDeep(this.state.breakdownFilter)
      breakdown_filter[this.state.station] = {
        area_name: this.state.type,
        uld_type: this.state.uldFilters,
        deliver_to: this.state.workAreaFilters,
        uld_no: this.state.typeFilters,
        prod_code: this.state.prodCodeFilters,
        location: this.state.locationFilters,
        exclusion: this.state.exclusionArray,
      }
      let formdata = { breakdown_filter: breakdown_filter }
      await Http.put(`/page_filter/${this.state.user_id}`, formdata).then(async (data) => {
        if (data && data?.status === 200) {
          //DO NOTHING
          this.setState({ multiselect: false })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror : true })
      })
    }
  }
  //SHOW FLIGHT HISTORY
  showFlightHistory = (history) => {
    let { flightHistoryVisible } = this.state
    if(flightHistoryVisible && history?.length > 0) {
      return (
        <div>
          {history.map((item, index) => (
            <p
              key={index}>
              {item.message}
            </p>
          ))}
        </div>
      )
    }
  }
  //CALC WEIGHT IN TONS
  calcTons = (weight_arr) => {
    let weight_lbs = 0, weight_kg = 0
    weight_arr?.map((item) => {
      if(item.unit === 'LBS' || item.unit === 'LBR') {
        weight_lbs += item.value //ROUNDED TO NEAREST INTEGER
        weight_kg += weightCalculator(item.value, 'LBS')
      } else if(item.unit === 'KGM') {
        weight_kg += item.value //ROUNDED TO NEAREST INTEGER
        weight_lbs += weightCalculator(item.value, 'KGM')
      }
    })
    return `${(this.state.weight_unit === 'KG' ? weight_kg/1000 : weight_lbs/1000).toFixed(2)}`
  }
  // INCORRECT LOCATION MODAL
  closeIncorrectLoc = () => {
    this.setState ({
      incorrectLocationRecord: null,
      location: '',
      radioValue: 1
    }, () => this.setState({ incorrectLoc: false }))
  }
  //CHECKBOX CHANGE
  handleCheckboxChange =(e, type) => {
    let filters = cloneDeep(this.state[type])
    let index = filters?.findIndex((p) => p === e.target.value)
    if(!e.target.checked && index > -1) {
      filters.splice(index, 1)
    } else if(e.target.checked && index === -1) {
      filters.push(e.target.value)
    }
    this.setState({ [type]: [] }, () => this.setState({ [type]: filters }, () => {
      this.saveFilters()
    }))
  }
  // MULTISELECT MODAL
  openMultiselect = () => {
    this.setState ({
      multiselect: true,
      exclusionArray: cloneDeep(this.state.exclusionList),
    })
  }
  closeMultiselect = () => {
    this.setState ({
      multiselect: false,
      exclusionArray: cloneDeep(this.state.exclusionList),
    })
  }
  //FILTER BY PROD VALUE
  filterByProdValue = () => {
    if(this.state.prodValue) {
      let filteredData = this.state.displayCodeList?.filter((code) => code?.includes(this.state.prodValue))
      if(filteredData?.length > 0) {
        return (
          filteredData?.map((code) => (
            <Checkbox key={code} checked={filteredData?.includes(code)} value={code} onChange={(e) => this.handleCheckboxChange(e, 'prodCodeFilters')}>{code}</Checkbox>
          ))
        )
      }
    } else {
      return (
        this.state.displayCodeList?.map((code) => (
          <Checkbox key={code} checked={this.state.prodCodeFilters?.includes(code)} value={code} onChange={(e) => this.handleCheckboxChange(e, 'prodCodeFilters')}>{code}</Checkbox>
        ))
      )
    }
  }
  //GENERATE ULD COLUMNS
  generateULDColumns = () => {
    let obj = [
      {
        title: 'ULD #',
        dataIndex: 'uld_no',
        key: 'uld_no',
        className: 'space-nowrap',
        filteredValue: this.state.typeFilters,
        filterDropdown: () => (
          <div className='fliter-dd'>
            <Checkbox checked={this.state.typeFilters?.includes('BLK')} value={'BLK'} onChange={(e) => this.handleCheckboxChange(e, 'typeFilters')}>BLK</Checkbox>
            <Checkbox checked={this.state.typeFilters?.includes('SLU')} value={'SLU'} onChange={(e) => this.handleCheckboxChange(e, 'typeFilters')}>SLU</Checkbox>
            <Checkbox checked={this.state.typeFilters?.includes('PTU')} value={'PTU'} onChange={(e) => this.handleCheckboxChange(e, 'typeFilters')}>PTU</Checkbox>
            <Checkbox checked={this.state.typeFilters?.includes('ULD')} value={'ULD'} onChange={(e) => this.handleCheckboxChange(e, 'typeFilters')}>ULD</Checkbox>
            <div className='filter-reset f-spacebtw p-2'>
              <Button className='link-btn' onClick={() => this.setState({ typeFilters: [] }, () => this.saveFilters())} type='link'>Reset</Button>
              <Button className='custom-button' onClick={() => this.setState({ typeFilters: ULD_CARGO }, () => this.saveFilters())} type='primary'>Select All</Button>
            </div>
          </div>
        ),
        onFilter: (value, record) => (value === 'ULD' && record?.type === '') || record?.type?.startsWith(value),
        render: (_, record) => (
          <span>{record?.uld_no ? record?.type ? record.type +' - '+ record.uld_no : record.uld_no : 'N/A'}</span>
        )
      },
      {
        title: 'Flt In',
        dataIndex: 'flight_in',
        key: 'flight_in',
        render: (_, record) => (
          <span>{record?.flights_in ? formatFlightConnexNo(record.flights_in.flight_no, record.flights_in.flight_date) :
            record?.awb_flights_in ? formatFlightConnexNo(record.awb_flights_in.flight_no, record.awb_flights_in.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: '1st Flt Out',
        dataIndex: 'flight_out',
        key: 'flight_out',
        render: (_, record) => (
          <span>{record?.first_flights_out ? formatFlightConnexNo(record.first_flights_out.flight_no, record.first_flights_out.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: 'Type',
        dataIndex: 'uld_type',
        key: 'uld_type',
        filteredValue: this.state.uldFilters,
        filterDropdown: () => (
          <div className='fliter-dd'>
            <Checkbox checked={this.state.uldFilters?.includes('N/A')} value={'N/A'} onChange={(e) => this.handleCheckboxChange(e, 'uldFilters')}>N/A</Checkbox>
            <Checkbox checked={this.state.uldFilters?.includes('Local')} value={'Local'} onChange={(e) => this.handleCheckboxChange(e, 'uldFilters')}>Local</Checkbox>
            <Checkbox checked={this.state.uldFilters?.includes('Mixed')} value={'Mixed'} onChange={(e) => this.handleCheckboxChange(e, 'uldFilters')}>Mixed</Checkbox>
            <Checkbox checked={this.state.uldFilters?.includes('Transit')} value={'Transit'} onChange={(e) => this.handleCheckboxChange(e, 'uldFilters')}>Transit</Checkbox>
            <Checkbox checked={this.state.uldFilters?.includes('Transit-Potential')} value={'Transit-Potential'} onChange={(e) => this.handleCheckboxChange(e, 'uldFilters')}>Transit-Potential</Checkbox>
            <div className='filter-reset f-spacebtw p-2'>
              <Button className='link-btn' size='small' onClick={() => this.setState({ uldFilters: [] }, () => this.saveFilters())} type='link'>Reset</Button>
              <Button className='custom-button' size='small' onClick={() => this.setState({ uldFilters: ULD_TYPES }, () => this.saveFilters())} type='primary'>Select All</Button>
            </div>
          </div>
        ),
        onFilter: (value, record) => record?.uld_type?.startsWith(value),
        render: (_, record) => (
          <span>{record?.uld_type ? record.uld_type : 'N/A'}</span>
        )
      },
      {
        title: 'PROD',
        dataIndex: 'prod_code',
        key: 'prod_code',
        filteredValue: this.state.prodCodeFilters,
        filterDropdown: () => (
          <div className='fliter-dd'>
            <Input
              prefix={<SearchOutlined />}
              value={this.state.prodValue}
              className='filter-search'
              size='small'
              onChange={(e) => this.setState({ prodValue: e.target.value?.toUpperCase() })}
            />
            {
              this.filterByProdValue()
            }
            <div className='filter-reset f-spacebtw p-2'>
              <Button className='link-btn' size='small' onClick={() => this.setState({ prodCodeFilters: [] }, () => this.saveFilters())} type='link'>Reset</Button>
              <Button className='custom-button' size='small' onClick={() => this.setState({ prodCodeFilters: this.state.displayCodeList }, () => this.saveFilters())} type='primary'>Select All</Button>
            </div>
          </div>
        ),
        onFilter: (value, record) => (value === 'N/A' && record?.prod_code === null) || record?.prod_code?.startsWith(value),
        render: (_, record) => (
          <span>{record?.prod_code ? record.prod_code : 'N/A'}</span>
        )
      },
      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{formatPcsString(record?.breakdown_pcs, record?.pcs)}</span>
        )
      },
      {
        title: 'Awbs',
        dataIndex: 'awbs',
        key: 'awbs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.awbs_in ? record.awbs_in.length : 0}</span>
        )
      },
      {
        title: 'Wgt',
        dataIndex: 'weight',
        key: 'weight',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{this.state.weight_unit === 'KG' ?
            formatWeightString(record?.weight_kg_breakdown, record?.weight_kg, 'KG') :
            formatWeightString(record?.weight_lbs_breakdown, record?.weight_lbs, 'LBS')}
          </span>
        )
      },
      {
        title: 'HT',
        dataIndex: 'handling_times',
        key: 'handling_times',
        render: (_, record) => (
          <span className={record.amber_flag ? 'countdown amber' : record.handling_time_color === true ? 'countdown green' : 'countdown red'}>
            {record?.show_handling_time_after_tta === true ?
              <>
                <Countdown className='hidden-countdown' onFinish={() => this.handledAmberTrigger(record, 'ULD', true)} value={moment(record.amber_countdown)} format='HH:mm' />
                <Countdown onFinish={() => this.handledAmberTrigger(record, 'ULD', false)} value={moment(record.handling_time)} format='HH:mm' />
              </>
              : record.handling_time_as_timestamp}
          </span>
        )
      },
    ]
    if(this.state.type?.toUpperCase() === 'ALL') {
      obj.push(
        {
          title: 'Workarea',
          dataIndex: 'deliver_to',
          key: 'deliver_to',
          className: 'min-width-45',
          filteredValue: this.state.workAreaFilters,
          filterDropdown: () => (
            <div className='fliter-dd'>
              <Input prefix={<SearchOutlined />} className='filter-search' size='small' onChange={(e) => this.setState({ displayWorkAreaOptions: this.state.workAreaOptions?.filter((item) => item?.toUpperCase()?.includes(e.target.value?.toUpperCase())) })} />
              {
                this.state.displayWorkAreaOptions?.map((area) => (
                  <Checkbox key={area} checked={this.state.workAreaFilters?.includes(area)} value={area} onChange={(e) => this.handleCheckboxChange(e, 'workAreaFilters')}>{area}</Checkbox>
                ))
              }
              <div className='filter-reset f-spacebtw p-2'>
                <Button className='link-btn' onClick={() => this.setState({ workAreaFilters: [] }, () => this.saveFilters())} type='link'>Reset</Button>
                <Button className='custom-button' onClick={() => this.setState({ workAreaFilters: this.state.displayWorkAreaOptions }, () => this.saveFilters())} type='primary'>Select All</Button>
              </div>
            </div>

          ),
          onFilter: (value, record) => record?.deliver_to?.startsWith(value),
          render: (_, record) => (
            <span>{record?.deliver_to ? record.deliver_to : 'N/A'}</span>
          )
        },
      )
    } else {
      obj.push(
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          className: 'min-width-45',
          filteredValue: this.state.locationFilters,
          filterDropdown: () => (
            <div className='fliter-dd'>
              <Input prefix={<SearchOutlined />} className='filter-search' size='small'onChange={(e) => this.setState({ displayLocationList: this.state.locationList?.filter((item) => item?.toUpperCase()?.includes(e.target.value?.toUpperCase())) })} />
              {
                this.state.displayLocationList?.map((location) => (
                  <Checkbox key={location} checked={this.state.locationFilters?.includes(location)} value={location} onChange={(e) => this.handleCheckboxChange(e, 'locationFilters')}>{location}</Checkbox>
                ))
              }
              <div className='filter-reset f-spacebtw p-2'>
                <Button className='link-btn' onClick={() => this.setState({ locationFilters: [] }, () => this.saveFilters())} type='link'>Reset</Button>
                <Button className='custom-button' onClick={() => this.setState({ locationFilters: this.state.displayLocationList }, () => this.saveFilters())} type='primary'>Select All</Button>
              </div>
            </div>
          ),
          onFilter: (value, record) => record?.location?.startsWith(value),
          render: (_, record) => (
            <span>{record?.location ? record.location : 'N/A'}</span>
          )
        },
      )
    }
    obj.push(
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <>
            {
              <Popover
                onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
                placement='rightTop'
                content={() => this.showFlightHistory(record?.ulds_historys)}
                title='Status history'
                trigger='click'
                overlayClassName='status-popover'>
                <span>{record?.status === null ? 'Not started' : record.status}</span>
              </Popover>
            }
          </>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => (
          <span className='countdowns'>{(record?.status?.toUpperCase() !== 'COMPLETED' && record?.is_completed === false) ?
            <CountUp expiryTimestamp={moment(record?.time_since_created, 'YYYY-MM-DD HH:mm:ss')} /> :
            record?.time_since_completed}</span>
        )
      },
    )
    return obj
  }
  //TRIGGER AMBER COLOR FOR ULD/AWB
  handledAmberTrigger = (record, type, flag) => {
    let breakdownControlData = this.state.breakdownControlData

    let data_index = breakdownControlData.findIndex((item) => item.area_name === this.state.type)

    if(data_index > -1) {
      if(type === 'ULD') {
        let uld_index = breakdownControlData[data_index]?.ulds?.findIndex((p) => p.id === record.id)
        if(uld_index > -1) {
          //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
          if(flag === false) {
            breakdownControlData[data_index].ulds[uld_index].handling_time_color = false
          }
          breakdownControlData[data_index].ulds[uld_index].amber_flag = flag

          this.setState({
            breakdownControlData: breakdownControlData,
            ULDGridData: []
          }, () => this.setState({ ULDGridData: breakdownControlData[data_index].ulds }))
        }
      } else if(type === 'AWB_BREAKDOWN') {
        let uld_index = breakdownControlData[data_index]?.ulds?.findIndex((p) => p.id === record.uld_in)
        if(uld_index > -1) {
          let awb_index = breakdownControlData[data_index]?.ulds[uld_index]?.for_breakdown?.findIndex((p) => p.id === record.id)
          if(awb_index > -1) {
            //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
            if(flag === false) {
              breakdownControlData[data_index].ulds[uld_index].for_breakdown[awb_index].handling_time_color = 'red'
            }
            breakdownControlData[data_index].ulds[uld_index].for_breakdown[awb_index].amber_flag = flag

            this.setState({ breakdownControlData: breakdownControlData })

            if(this.state.uldRowId === record.uld_in) {
              this.setState({ AWBGridDataForBreakdown: [] }, () => this.setState({ AWBGridDataForBreakdown: breakdownControlData[data_index].ulds[uld_index].for_breakdown }))
            }
          }
        }
      } else if(type === 'AWB_BROKENDOWN') {
        let uld_index = breakdownControlData[data_index]?.ulds?.findIndex((p) => p.id === record.uld_in)

        if(uld_index > -1) {
          let awb_index = breakdownControlData[data_index]?.ulds[uld_index]?.brokendown_awb?.findIndex((p) => p.id === record.id)

          if(awb_index > -1) {
            //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
            if(flag === false) {
              breakdownControlData[data_index].ulds[uld_index].for_breakdown[awb_index].handling_time_color = 'red'
            }
            breakdownControlData[data_index].ulds[uld_index].brokendown_awb[awb_index].amber_flag = flag

            this.setState({ breakdownControlData: breakdownControlData })

            if(this.state.uldRowId === record.uld_in) {
              this.setState({ AWBGridDataBrokendown: [] }, () => this.setState({ AWBGridDataBrokendown: breakdownControlData[data_index].ulds[uld_index].brokendown_awb }))
            }
          }
        }
      }
    }
  }

  showHandlingTimeHistory = (record, type) => {
    let isLocal = record?.flights_in?.dest?.arriv === this.state.station && record?.flights_in?.dest?.arriv === record?.dest ? true : false
    if(type === 'ULD') {
      return (
        <div>
          <p>PHT: {`${record?.info?.handling_time_bd}`}<br />
          CONNEX: {`${record?.info?.handling_time_bd}`}</p>
        </div>
      )
    } else if(type === 'AWB') {
      let flight_out = 'N/A', nfd = 'N/A', fpe = 'N/A'
      if(!isLocal && record?.info?.flights_out_date_2) {
        //CHECK ORIGINAL FLIGHT OUT DATE
        let flights_out_date = false
        if(record?.flights_out?.dept?.act || record?.flights_out?.dept?.eta || record?.flights_out?.dept?.schdl) {
          flights_out_date = record?.flights_out?.dept?.act ? parseInt(record.flights_out.dept.act) :
            record?.flights_out?.dept?.eta ? parseInt(record.flights_out.dept.eta) :
              record?.flights_out?.dept?.schdl ? parseInt(record.flights_out.dept.schdl) : false
        } else {
          if(record?.flights_out?.flight_date) {
            //ADD FLIGHTS OUT DATE
            flights_out_date = moment.tz(`${moment(record?.flights_out?.flight_date).format('YYYY-MM-DD')} ${record?.flights_out?.org?.scheduled_dept_time?.substr(0, 2)}:${record?.flights_out?.org?.scheduled_dept_time?.substr(2)}:00`, this.state.timezone_cty).utc().format('YYYY-MM-DD HH:mm')
          } else if(record?.segs_out?.flight_date) {
            //ADD SEG OUT DATE
            flights_out_date = moment.tz(`${moment(record?.segs_out?.flight_date).format('YYYY-MM-DD')} ${record?.segs_out?.dept_time?.substr(0, 2)}:${record?.segs_out?.dept_time?.substr(2)}:00`, this.state.timezone_cty).utc().format('YYYY-MM-DD HH:mm')
          }
        }

        //CHECK IF CONNEX IS PASSED
        if(moment.utc(record?.info?.flights_out_date_2).tz(this.state.timezone).valueOf() < moment().valueOf()) {
          flight_out = 'Oops! This flight has departed'
        } else {
          flight_out = `${moment.utc(flights_out_date).tz(this.state.timezone).format(dateFormat)} ${this.state.ext}`
        }

        if(record?.info?.fpe) {
          fpe = `${record?.info?.fpe} mins`
        }
      } else {
        flight_out = 'Flight Info Missing'

        //CHECK IF LOCAL FOR NFD
        if(isLocal && record?.info?.conn2) {
          nfd = `${moment.utc(record?.info?.conn2).tz(this.state.timezone).format(dateFormat)} ${this.state.ext}`
        }
      }

      return (
        !isLocal ?
          <div>
            <p>
              PHT: {`${record?.info?.handling_time_bd} mins`}<br />
              CNX: {flight_out}<br />
              FPE: {fpe}</p>
          </div> :
          <div>
            <p>
              PHT: {`${record?.info?.handling_time_bd} mins`}<br />
              NFD: {nfd}</p>
          </div>
      )
    }
  }
  //SHIFT TILE
  moveTile = (work_area, from, to) => {
    let workAreaTiles = this.state.breakdownControlData
    let work_area_index = workAreaTiles?.findIndex((p) => p.area_name === work_area)

    let cookieArr = []
    if(work_area_index > -1) {
      workAreaTiles?.splice(from, 1, workAreaTiles?.splice(to, 1, workAreaTiles[from])[0])
      workAreaTiles?.map((wa) => cookieArr.push(wa?.area_name))
      createCookie(COOKIE.TILES, cookieArr, '', '/')
      this.setState({ breakdownControlData: [] }, () => this.setState({ breakdownControlData: workAreaTiles }))
    }
  }

  processWorkAreas = (work_area_list) => {
    let work_areas_cookies = new Cookies().get('tiles')
    let cookieArr = []
    if(work_areas_cookies && work_areas_cookies?.length > 0) {
      work_area_list.sort((a, b) => work_areas_cookies.indexOf(a?.area_name) - work_areas_cookies.indexOf(b?.area_name))
      work_area_list?.map((wa) => cookieArr.push(wa?.area_name))
    } else {
      work_area_list?.map((wa) => cookieArr.push(wa?.area_name))
    }
    createCookie(COOKIE.TILES, cookieArr, '', '/')
    return work_area_list
  }

  renderTileInfo = (item) => {
    //GREEN BADGE
    if(item?.data?.stat === false && item?.data?.ulds > 0) {
      return (
        <>
          <h1>{item.area_name}</h1>
          <span className='d-flex gap-10'>
            {item?.data?.ember > 0 && <span className='badge-circle badge-yellow'>
              {item?.data?.ember}
            </span>}
            <span className='badge-circle badge-green'></span>
          </span>
        </>
      )
    }
    //RED BADGE
    else if(item?.data?.stat === true) {
      return (
        <>
          <h1>{item.area_name}</h1>
          <span className='d-flex gap-10'>
            {item?.data?.ember > 0 && <span className='badge-circle badge-yellow'>
              {item?.data?.ember}
            </span>}
            <span className={item?.data?.count > 0 && item?.data?.count < 10 ? 'badge-circle badge-red' : 'badge-capsule badge-red'}>
              {item?.data?.count || 0}
            </span>
          </span>
        </>
      )
    }
    //GREY BADGE
    else {
      return (
        <>
          <h1>{item.area_name}</h1>
          <span className='badge-circle badge-grey'></span>
        </>
      )
    }
  }

  handleChange = (pagination, filters) => {
    this.setState({
      uldFilters: filters?.uld_type || [],
      workAreaFilters: filters?.deliver_to || [],
      typeFilters: filters?.uld_no || [],
      prodCodeFilters: filters?.prod_code || [],
      locationFilters: filters?.location || [],
    }, () => this.saveFilters())
  }

  handleOnSelectExclusion = (value) => {
    let exclusions = this.state.exclusionArray
    if(value && !exclusions?.includes(value)) {
      exclusions.push(value)
      this.setState({
        exclusionSearch: '',
        exclusionArray: exclusions,
      })
    } else {
      this.setState({ exclusionSearch: '' })
    }
  }

  popExclusion = (value) => {
    let exclusions = this.state.exclusionArray
    let index = exclusions?.findIndex((p) => p === value)
    if(index > -1) {
      exclusions?.splice(index, 1)
      this.setState({ exclusionArray: exclusions })
    }
  }

  next(props) {
    this.slider.slickNext()
    this.setState({ sliderLoad : false,  })
    const { className, style, onClick } = props
    return (
      <div
        className={`opacity ${ className }`}
        style={{
          ...style,
          display: 'block',
        }}
        onClick={onClick}
      />
    )
  }

  previous(props) {
    this.slider.slickPrev()
    this.setState({ sliderLoad : false,  })
    const { className, style, onClick } = props
    return (
      <div
        className={`opacity ${ className }`}
        style={{
          ...style,
          display: 'block',
        }}
        onClick={onClick}
      />
    )
  }

  render() {
    let { workAreaData, ULDGridData, AWBGridDataBrokendown, AWBGridDataForBreakdown , recoveryActionsData, breakdownControlData, incorrectLocationRecord, type, sort } = this.state

    const uldColumns = this.generateULDColumns()

    const awbColumns = [
      {
        title: 'Awb #',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap min-width-100',
      },

      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
      },
      {
        title: 'ORG-DST',
        dataIndex: 'org_dst',
        key: 'org_dst',
        className: 'min-width-80',
      },
      {
        title: 'PROD',
        dataIndex: 'spl_code',
        key: 'spl_code',
        className: 'min-width-60',
      },
      {
        title: 'Connex',
        dataIndex: 'connex',
        key: 'Connex',
        className: 'min-width-120',
      },
      {
        title: 'HT',
        dataIndex: 'handling_time',
        key: 'handling_time',
        className: 'min-width-60',
      },
      {
        title: 'Recovery Action',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        className: 'min-width-150',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: 'min-width-60',
        align: 'center',
      },
    ]
    const awbColumnsBreakdown = [
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap min-width-100',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        )
      },

      {
        title: '',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{formatPcsString(record?.breakdown_pcs, record?.pcs)}</span>
        )
      },
      {
        title: '',
        dataIndex: 'org_dst',
        key: 'org_dst',
        className: 'min-width-80',
        render: (_, record) => (
          <span>{record?.org ? record.org +'-'+ record.dest : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'spl_code',
        key: 'spl_code',
        className: 'min-width-60',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'connex',
        key: 'Connex',
        className: 'min-width-120',
        render: (_, record) => (
          <span>{record?.flights_out ? formatFlightConnexNo(record.flights_out.flight_no, record.flights_out.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'handling_time',
        key: 'handling_time',
        className: 'min-width-60',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
            placement='leftTop'
            content={() => this.showHandlingTimeHistory(record, 'AWB')}
            title=''
            trigger='hover'
            overlayClassName='status-popover'>
            <span className={record.amber_flag ? 'countdown amber' : record.handling_time_color === 'green' ? 'countdown green' : 'countdown red'}>
              {record?.show_handling_time_after_tta === true ?
                <>
                  <Countdown className='hidden-countdown' onFinish={() => this.handledAmberTrigger(record, 'AWB_BREAKDOWN', true)} value={moment(record.amber_countdown)} format='HH:mm' />
                  <Countdown onFinish={() => this.handledAmberTrigger(record, 'AWB_BREAKDOWN', false)} value={moment(record.handling_time_after_tta)} format='HH:mm' />
                </>
                : record.handling_time_as_timestamp}
            </span>
          </Popover>
        )
      },
      {
        title: '',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        align: 'center',
        className: 'min-width-150',
        render: (_, record) =>
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e, { rebook }) => this.handleRecoveryActionChange(record, e, rebook)}
              value={record?.recovery_actions_id}
            >
              {recoveryActionsData && recoveryActionsData.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: 'min-width-60',
        align: 'center',
        render: (_, record) =>
          <div className='action-column'>
            <Popconfirm
              placement='topLeft'
              title='Are you sure you want to confirm this AWB as Brokendown?'
              okText='Yes'
              cancelText='No'
              onConfirm={() => this.sendForBreakdown(record?.id, 'completed')}
            >
              <Tooltip title='Mark as brokendown' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <Checkbox checked={record?.check_breakdown} />
              </Tooltip>
            </Popconfirm>
          </div>
      },
    ]
    const awbColumnsBrokendown = [
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap min-width-100',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        )
      },

      {
        title: '',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{formatPcsString(record?.breakdown_pcs, record?.pcs)}</span>
        )
      },
      {
        title: '',
        dataIndex: 'org_dst',
        key: 'org_dst',
        className: 'min-width-80',
        render: (_, record) => (
          <span>{record?.org ? record.org +'-'+ record.dest : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'spl_code',
        key: 'spl_code',
        className: 'min-width-60',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'connex',
        key: 'Connex',
        className: 'min-width-120',
        render: (_, record) => (
          <span>{record?.flights_out ? formatFlightConnexNo(record.flights_out.flight_no, record.flights_out.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'handling_time',
        key: 'handling_time',
        className: 'min-width-60',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
            placement='leftTop'
            content={() => this.showHandlingTimeHistory(record, 'AWB')}
            title=''
            trigger='hover'
            overlayClassName='status-popover'>
            <span className={record.amber_flag ? 'countdown amber' : record.handling_time_color === 'green' ? 'countdown green' : 'countdown red'}>
              {record?.show_handling_time_after_tta === true ?
                <>
                  <Countdown className='hidden-countdown' onFinish={() => this.handledAmberTrigger(record, 'AWB_BROKENDOWN', true)} value={moment(record.amber_countdown)} format='HH:mm' />
                  <Countdown onFinish={() => this.handledAmberTrigger(record, 'AWB_BROKENDOWN', false)} value={moment(record.handling_time_after_tta)} format='HH:mm' />
                </>
                : record.handling_time_as_timestamp}
            </span>
          </Popover>
        )
      },
      {
        title: '',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        align: 'center',
        className: 'min-width-150',
        render: (_, record) =>
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e, { rebook }) => this.handleRecoveryActionChange(record, e, rebook)}
              value={record?.recovery_actions_id}
            >
              {recoveryActionsData && recoveryActionsData.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: 'min-width-60',
        align: 'center',
        render: (_, record) =>
          <div className='action-column'>
            <Popconfirm
              placement='topLeft'
              title='Are you sure you want to confirm?'
              okText='Yes'
              cancelText='No'
              onConfirm={() => this.sendForBreakdown(record?.id, 'not started')}
            >
              <Tooltip title='' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <Checkbox checked={record?.check_breakdown} disabled />
              </Tooltip>
            </Popconfirm>
          </div>
      },
    ]
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [

        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 654,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    }

    return (
      <div>
        {/* {
          servererror ?
            <p className='dis-server'>Disconnected from the server, Please refresh the page.</p> : null
        } */}
        {/* {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)} */}
        <HeaderSearch searchResults={this.getSearchedData} page='BREAKDOWN' />
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >

          <Col span={24} className='p-0 breakdown-control'>
            <Row gutter={[{
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 24
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 12
            },]}>
              <Slider  ref={c => (this.slider = c)} {...settings} className={'custom-slider bc-slider ' + (this.state.sliderLoad ? 'slider-transition ' : ' ')}>
                {breakdownControlData && breakdownControlData.map((item, index) => (
                  <Col xs className='position-relative' key={index}>
                    {index > 0 && <div className={type === item.area_name ? 'sitem-selected sitem-left' : 'sitem-left'}><CaretLeftOutlined onClick={() => this.moveTile(item.area_name, index, index - 1)} /></div>}
                    <Card
                      hoverable
                      size='small'
                      title={<span className='title-wrapper'>{this.renderTileInfo(item)}</span>}
                      className={type === item.area_name ? 'info-card' : 'info-card disabled-info-card'}
                      onClick={() => this.setState({
                        type: item.area_name,
                        uldRow: null,
                        uldRowId:null,
                        awbRowId: null,
                        sort: 'handling_time',
                        isFilterChecked: true,
                        filterValue: 1,
                        loading: true,
                        prodCodeFilters: [],
                        codeList: [],
                        displayCodeList: [],
                        locationList: [],
                        locationFilters: [],
                        displayLocationList: [],
                      },() => {
                        this.saveFilters()
                        this.handleWorkAreaClick(item)
                      })}
                    >
                      <div className='text-center'>
                        <p className='info-data'>{item?.data?.awbs ? item.data.awbs : 0}</p>
                        <small className='info-title'>AWBS</small>
                      </div>
                      <div className='text-center'>
                        <p className='info-data'>{item?.data?.ulds ? item?.data?.ulds : 0}</p>
                        <small className='info-title'>ULDS</small>
                      </div>
                      <div className='text-center'>
                        <p className='info-data'>{this.calcTons(item?.data?.wgt)}</p>
                        <small className='info-title'>TONS</small>
                      </div>
                    </Card>
                    {index < breakdownControlData?.length - 1 && <div className={type === item.area_name ? 'sitem-selected sitem-right' : 'sitem-right'}><CaretRightOutlined onClick={() => this.moveTile(item.area_name, index, index + 1)} /></div>}
                  </Col>))}
              </Slider>
              <div>
                <button className='prev-button bd-slick-prev slick-prev' onClick={this.previous}>
                  Previous
                </button>
                <button className='next-button bd-slick-next slick-next' onClick={this.next}>
                  Next
                </button>
              </div>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14} className='breakdown-control'>
            <Spin spinning={this.state.uldSpinner}>
              <Card className='custom-card h-100'>
                <Row gutter={[{
                  xs: 0,
                  sm: 0,
                  md: 12,
                  lg: 12
                }, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12
                },]} className='with-table'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                    <Title level={5} className='mb-0'>
                      ULDs
                    </Title>
                    <Spin spinning={this.state.spinnerForUlds || this.state.spinnerForEvents} indicator={antIcon} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className='breakdown-filter'>
                    <Row gutter={[{
                      xs: 12,
                      sm: 12,
                      md: 24,
                      lg: 24
                    }, {
                      xs: 24,
                      sm: 24,
                      md: 12,
                      lg: 12
                    },]} className='filterht'>
                      <Col xs={24} sm={24} md={11} lg={7} xl={11} xxl={7} className='space-nowrap'>
                        <div>
                          <Checkbox
                            disabled={this.state.isSearch}
                            checked={this.state.isCompleted}
                            onChange={(e) => this.setState({
                              isCompleted: e.target.checked,
                              loading: true,
                            }, () => this.fetchULDData(this.state.type, null, e.target.checked))}>
                            Show Completed ULDs for last
                          </Checkbox>
                          <Select disabled={!this.state.isCompleted} value={this.state.hours} onChange={(e) => {
                            this.setState({
                              hours: e,
                              loading: true,
                            }, () => this.fetchULDData(this.state.type, null, this.state.isCompleted))
                          }} className='min-width-70 small-input pl-2 uld-time'>
                            <Option value='6'>6 Hrs</Option>
                            <Option value='12'>12 Hrs</Option>
                            <Option value='18'>18 Hrs</Option>
                            <Option value='24'>24 Hrs</Option>
                          </Select>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={13} lg={9} xl={13} xxl={9} className='fliter-text-align pr-3'>
                        <span><Checkbox
                          onChange={(e) => {
                            this.setState({
                              isFilterChecked: e.target.checked,
                              filterValue: e.target.checked === false ? '' : this.state.filterValue
                            }, () =>this.getFilteredData())
                          }}
                          checked={this.state.isFilterChecked}
                        > Filter ULDs with <InputNumber
                            disabled={!this.state.isFilterChecked}
                            className='w-50px small-input'
                            onChange={(e) => { this.setState({ filterValue: e }, () => this.getFilteredData()) }}
                            controls={false}
                            min={0}
                            value={this.state.filterValue}
                          />
                          <span className='pl-1'>
                          Mins or<Switch className='uld-switch mx-2' style={{ backgroundColor: '#0a1227' }} disabled={!this.state.isFilterChecked} onChange={(e) => this.setState({ switch: e }, () => this.getFilteredData())} checked={this.state.switch} unCheckedChildren='more' checkedChildren='less' />
                          handling time</span></Checkbox></span>
                      </Col>
                      <Col xs={24} sm={14} md={14} lg={6} xl={12} xxl={6} className=''>
                        <Form.Item label='Sort By' className='mb-0 sort-select'>
                          <Select
                            allowClear
                            size='small'
                            placeholder='Sort By'
                            className='br-input sort-ip'
                            onChange={(e, { type }) => {
                              sort = e
                              this.setState({
                                sort,
                                uldRowId: null,
                                uldRow: null
                              })
                              this.sortertimer(ULDGridData)
                              this.sortGrid(ULDGridData, e, type, false) //UPDATE SECOND PARAM BASED ON DATE COLUMN NAME
                            }}
                            value={sort}
                          >
                            <Option value='handling_time' type={true}>Handling Time (default)</Option>
                            <Option value='ex_flight' type={true}>1st Flight Out</Option>
                            <Option value='type' type={false}>Type</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='f-justify-end ml-auto'>
                        <Badge className='custom-badge' size='default' count={this.state.exclusionList?.length}>
                          <Tooltip title='Product Exclusion list' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                            <Button className='custom-icon-button ml-auto d-block' icon={<FilterOutlined />} onClick={this.openMultiselect}></Button>
                          </Tooltip>
                        </Badge>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='table-overflow'
                  >
                    <div className='table-outer'>
                      <Table
                        className='custom-table w-100 breakdown-uld-table'
                        dataSource={ULDGridData}
                        columns={uldColumns}
                        pagination={false}
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              this.setState({
                                uldRowId: record.id,
                                uldRow: record,
                                awbRowId: null,
                                awbRow: null,
                              })
                              this.handleRowClick(record)
                            }
                          }}
                        }
                        rowClassName={(record) => this.setRowClassName(record, 'uldRowId')}
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10} className='breakdown-control'>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table awb-withtable'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>AWBs</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='awb-sync'>
                  <ScrollSync>
                    <div className='scrollsync-outer breakdown-scrollsync-outer table-outer'>
                      <ScrollSyncPane>
                        <div className='scrollsynctable-1 breakdown-scrollsynctable-1 overflow-auto'>
                          <Table
                            className='custom-table w-100 breakdown-awb-table no-data-table'
                            dataSource={''}
                            columns={awbColumns}
                          />
                        </div>
                      </ScrollSyncPane>
                      <ScrollSyncPane>
                        <div className='scrollsynctable-2 breakdown-scrollsynctable-2 overflow-auto'>
                          <h4 className='mb-0 sticky-title'>For Breakdown</h4>
                          <Table
                            className='custom-table w-100 breakdown-awb-table no-header-table'
                            dataSource={AWBGridDataForBreakdown}
                            columns={awbColumnsBreakdown}
                            pagination={false}
                            onRow={(record) => {
                              return {
                                onClick: () => {
                                  this.setState({
                                    awbRow: record,
                                    awbRowId: record.id
                                  })
                                }
                              }}
                            }
                            rowClassName={(record) => this.setRowClassName(record, 'awbRowId')}
                          />
                        </div>
                      </ScrollSyncPane>
                      <Divider className='my-0 bd-divider' />
                      <ScrollSyncPane>
                        <div className='scrollsynctable-3 breakdown-scrollsynctable-3 overflow-auto'>
                          <h4 className='mb-0 sticky-title'>Brokendown AWB</h4>
                          <Table
                            className='custom-table w-100 breakdown-awb-table no-header-table'
                            dataSource={AWBGridDataBrokendown}
                            columns={awbColumnsBrokendown}
                            pagination={false}
                            onRow={(record) => {
                              return {
                                onClick: () => {
                                  this.setState({
                                    awbRow: record,
                                    awbRowId: record.id
                                  })
                                }
                              }}
                            }
                            rowClassName={(record) => this.setRowClassName(record, 'awbRowId')}
                          />
                        </div>
                      </ScrollSyncPane>
                    </div>
                  </ScrollSync>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* INCORRECT LOCATION MODAL  */}
        <Modal
          title='Incorrect delivery location'
          destroyOnClose
          visible={this.state.incorrectLoc}
          onOk={() => this.setState ({ incorrectLoc: true })}
          onCancel={this.closeIncorrectLoc}
          footer={false}
          width={'470px'}
          centered
          className='custom-modal incorrect-loc pb-0'
        >
          <Row gutter={[{
            xs: 0,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          },]} className='with-table'>
            <Col
              xs={24}
              className=''
            >
              <p className='mb-0'>
                This ULD or AWB was planned for processing at&nbsp;
                {incorrectLocationRecord?.ulds_historys?.length > 0 ? incorrectLocationRecord?.ulds_historys[0]?.status?.work_area : ''}
                &nbsp;but was scanned at&nbsp;
                {incorrectLocationRecord?.ulds_historys?.length > 0 ? incorrectLocationRecord?.ulds_historys[0]?.status?.work_area_fps : ''}
                &nbsp;instead. Please select the appropriate action.
              </p>
            </Col>
            <Col
              xs={24}
              className=''
            >
              <Radio.Group value={this.state.radioValue} onChange={(e) => this.setState({ radioValue: e.target.value }) }>
                <Space direction='vertical'>
                  <Radio value={1}>Process at current location ({incorrectLocationRecord?.ulds_historys?.length > 0 ? incorrectLocationRecord?.ulds_historys[0]?.status?.work_area_fps : ''})</Radio>
                  <Radio value={2}>Resend to</Radio>
                  <Select onChange={(e) => this.setState({ location: e })} className='ml-2' placeholder='Select new delivery location'>
                    {
                      workAreaData && workAreaData.map((item) => (
                        <Option key={item.area_name} value={item.area_name}>{item.area_name}</Option>
                      ))
                    }
                  </Select>
                </Space>
              </Radio.Group>

            </Col>
            <Col xs={24}>
              <Button onClick={this.handleDeliverToChange} disabled={this.state.radioValue === 2 && this.state.location === ''} className='custom-button w-100'>Update</Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title='Product Exclusion list'
          destroyOnClose
          visible={this.state.multiselect}
          footer={false}
          width={'600px'}
          centered
          className='custom-modal pb-0'
          onCancel={this.closeMultiselect}
        >
          <Row gutter={[{
            xs: 0,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          },]} className='with-table'>
            <Col
              xs={24}
              className=''
            >
              <p className='mb-0'>
                Saved product codes: {this.state.exclusionList?.toString()}
              </p>
            </Col>
            <Col
              xs={24}
              className=''
            >
              <Select
                mode='tags'
                onSearch={(e) => this.setState({ exclusionSearch: e?.toUpperCase() })}
                onSelect={(e) => this.handleOnSelectExclusion(e)}
                onChange={() => this.handleOnSelectExclusion('')}
                onDeselect={(e) => this.popExclusion(e)}
                tokenSeparators={[',',' ','\t']}
                style={{ width: '100%' }}
                value={this.state.exclusionArray}
                searchValue={this.state.exclusionSearch}
              >
                {
                  this.state.prodPriorityData?.map((item) => (
                    <Option key={item?.p_code} value={item?.p_code}>{item?.p_code}</Option>
                  ))
                }
              </Select>
            </Col>
            <Col xs={24}>
              <Button onClick={() => this.exclusionFilter(true)} className='custom-button w-100'>Update</Button>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default BreakdownControl
