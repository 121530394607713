/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Comment, Form, Button, List, Input, Popover, notification, Upload, Tooltip } from 'antd'
import { LikeOutlined, PaperClipOutlined, LikeTwoTone, CloseOutlined, FilePdfOutlined } from '@ant-design/icons'
import moment from 'moment'
import Cookies from 'universal-cookie'
import Http from '../../utils/http'
import reply from '../../assets/images/reply.svg'
import arrowIcon from '../../assets/images/arrow.svg'
import cssconstants from '../../constants/cssconstants'
import './index.css'
import { pageHandler, getCookie, COOKIE } from '../../utils/cookie'

const cookies = new Cookies()

const checkLike=(obj, id)=>{
  let ret = false
  obj.map(i=>{
    if(i.users_id == id)
    {
      ret = true
    }
  })
  return ret
}

const Content = (cont) => {
  return (
    <>
      {cont && cont?.map(c => (
        <>
          <div className='like-po-wrapper'>
            <div className='like-popover'>
              <p className='like-name'>{c?.name}</p>
              <p className='like-position'>{c?.role}</p>
            </div>
            <LikeTwoTone twoToneColor='#7d9bc1' />
          </div>
        </>
      ))}
    </>
  )
}
const CommentList = ({ comments, fetchNote, handleReply, name, role, user_id }) =>
{
  const addLike = async(id) => {
    let formData = {
      notes_id: id,
      users_id: user_id,
      name: name,
      role: role
    }
    await Http.post('/like', formData)
      .then((data) => {
        if (data && data?.status === 200) {
          fetchNote()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }
  return(
    <List
      dataSource={comments}
      // header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout='horizontal'
      renderItem={props => {
        let note = {
          author: <span className='f-spacebtw'>
            <span>{moment(props?.createdAt).format('D MMM Y H:mm').toUpperCase()} | {props?.name} | {props?.role}</span>
          </span>,
          content:
          <div className='view-icon-outer'>
            <p>{props?.note}</p>
            {props?.attach && (<Tooltip
              title='View Attachment'
              placement='right'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
            ><a className='view-attachment pointer' target='_blank' href={props?.attach?.path}  rel='noreferrer' ><FilePdfOutlined /><p className='attachment-info'>{props?.attach?.name}</p></a>
            </Tooltip>)}
          </div>
          ,
          actions: [
            <>
              <span key='comment-nested-reply-to' onClick={()=>handleReply({
                note_type: 'comment',
                ref_note_id: props?.id,
                ref_comm_id: null,
                prefix: props?.name
              })}><img src={reply} /></span>
              <span><LikeOutlined onClick={()=>addLike(props?.id)} className={checkLike(props?.likes || [], user_id)? 'liked': ''} />
                <Popover content={Content(props?.likes || [])} title={false} trigger='click' placement='rightTop' className='like-po'>
                  <span className='likes'>{props?.likes?.length || 0}</span>
                </Popover>
              </span>
            </>
          ],
        }
        let replies = []
        if (props?.ref_note) {
          props?.ref_note.map(ref => {
            replies.push({
              author: <span className='f-spacebtw'>
                <span>{moment(ref?.createdAt).format('D MMM Y H:mm').toUpperCase()} | {ref?.name} | {ref?.role}</span>
              </span>,
              content: <div className='view-icon-outer'>
                <p>{ref?.note}</p>
                {ref?.attach && (<Tooltip
                  title='View Attachment'
                  placement='right'
                  overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                ><a className='view-attachment pointer' target='_blank' href={ref?.attach?.path} rel='noreferrer' ><FilePdfOutlined /><p className='attachment-info'>{ref?.attach?.name}</p></a>
                </Tooltip>)}
              </div>,
              actions: [
                <>
                  <span key='comment-nested-reply-to' onClick={()=>handleReply({
                    note_type: 'comment',
                    ref_note_id: ref?.ref_note_id,
                    ref_comm_id: ref?.id,
                    prefix: ref?.name
                  })}><img src={reply} /></span>
                  <span ><LikeOutlined onClick={()=>addLike(ref?.id)} twoToneColor={checkLike(ref?.likes || [], user_id)? 'liked': ''} />
                    <Popover content={Content(ref?.likes || [])} title={false} trigger='click' placement='rightTop' className='like-po'>
                      <span className='likes'>{ref?.likes?.length || 0}</span>
                    </Popover>
                  </span>
                </>
              ],
            })
          })
        }
        return (
          <div><Comment {...note} > {replies && replies.map((r, i) => (<Comment key={i} {...r} />))}</Comment> </div>
        )
      }}
    />
  )
}

const Editor = ({ onChange, onFileChange, onSubmit, value, fileList, prefix, clearPrefix, disabled }) => (
  <>
    <Form.Item className='mb-0'>
      <Input placeholder='Add Notes here...' prefix={prefix? <div className='reply-to'>{prefix} <CloseOutlined onClick={()=>clearPrefix()} /></div>: false} addonAfter={<Button onClick={onSubmit} disabled={disabled}><img src={arrowIcon} /></Button>} className='add-notes br-input' onChange={onChange} value={value} disabled={disabled} />
      <Upload
        className='custom-upload-button'
        beforeUpload={() => false}
        fileList={fileList}
        onChange={onFileChange}>
        <Button className='custom-icon-button float-right' icon={<PaperClipOutlined />} disabled={disabled}></Button>
      </Upload>
    </Form.Item>
    {/* <Form.Item>
      <Button htmlType='submit' loading={submitting} onClick={onSubmit} type='primary'>
        Add Comment
      </Button>
    </Form.Item> */}
  </>
)

class Notes extends Component {
  constructor() {
    super()
    this.state = {
      name: cookies.get(COOKIE.NAME),
      role: cookies.get(COOKIE.ROLE),
      user_id: cookies.get(COOKIE.ID),
      station: cookies.get(COOKIE.STATION),
      search: '',
      type: '',
      id: '',
      fileList: [],
      disabled: true,
      attachment: null,
      comments: [],
      submitting: false,
      value: '',
      like: 0,
      dislike: 0,
      action: null,
      note_type: 'note',
      ref_note_id: null,
      ref_comm_id: null,
      prefix: false,
    }
  }

  handleSubmit = async () => {
    if (!this.state.value && !this.state.disabled) {
      return
    }
    this.setState({ submitting: true, })

    let formdata = new FormData()
    formdata.append('type', this.state.type)
    formdata.append(this.state.type == 'flight' ? 'inbound_flights_id' : this.state.type == 'uld' ? 'ulds_id' : 'awbs_id', this.state.id)
    formdata.append('users_id', this.state.user_id)
    formdata.append('note', this.state.value)
    formdata.append('attachment', this.state.attachment)
    formdata.append('note_type', this.state.note_type)
    formdata.append('ref_note_id', this.state.ref_note_id)
    formdata.append('ref_comm_id', this.state.ref_comm_id)
    formdata.append('name', this.state.name)
    formdata.append('role', this.state.role)
    await Http.post('/note', formdata)
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({
            attachment: null,
            note_type: 'note',
            ref_note_id: null,
            ref_comm_id: null,
            prefix: false,
            value: null,
            fileList: []
          })
          this.fetchNote()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ submitting: false })
      })

    // setTimeout(() => {
    //   this.setState({
    //     submitting: false,
    //     value: '',
    //     comments: [
    //       ...this.state.comments,
    //       {
    //         actions:[
    //           <>
    //             <span key='comment-nested-reply-to'><img src={reply} /></span>
    //             <span><LikeOutlined />
    //               <Popover content={content} title={false} trigger='click' placement='rightTop' className='like-po'>
    //                 <span className='likes'>2</span>
    //               </Popover>
    //             </span>
    //           </>
    //         ],
    //         author: <span className='f-spacebtw'>
    //           <span>12APR21 1146  | Michael Smith | Hub Control Supervisor</span>
    //           <span>TO: Day of Departure</span>
    //         </span>,
    //         // avatar: 'https://joeschmoe.io/api/v1/random',
    //         content: <p>{this.state.value}</p>,
    //       },
    //     ],
    //   })
    // }, 1000)
  }
  componentDidMount()
  {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    if (this.props.search != this.state.search ) {
      this.setState({
        search: this.props.search,
        type: this.props.type,
        id: this.props.id,
        attachment: null,
        note_type: 'note',
        ref_note_id: null,
        ref_comm_id: null,
        prefix: false,
        value: null,
        fileList: []
      }, () => this.fetchNote())
    }
  }

  componentDidUpdate() {
    if (this.props.search != this.state.search ) {
      this.setState({
        search: this.props.search,
        type: this.props.type,
        id: this.props.id,
        attachment: null,
        note_type: 'note',
        ref_note_id: null,
        ref_comm_id: null,
        prefix: false,
        value: null,
        fileList: []
      }, () => this.fetchNote())
    }
  }

  fetchNote = async () => {
    let url = 'notes'

    if(this.state.search) {
      url+=`?search=${this.state.search}`
    } else if(this.state.type && this.state.id) {
      url+=`?type=${this.state.type}&id=${this.state.id}`
    }

    await Http.get(url, { headers: { station: this.state.station } }).then(resp => {
      if (resp && resp?.data?.data?.type && resp?.data?.data) {
        this.setState({
          disabled: false,
          type: resp?.data?.data?.type,
          id: resp?.data?.data?.data?.id,
          comments: resp?.data?.data?.notes || []
        })
      } else {
        this.setState({
          disabled: true,
          comments: []
        }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        })
      }
    })
  }

  handleChange = e => {
    this.setState({ value: e.target.value, })
  }

  handleReply= (r) => {
    this.setState({ ...r })
  }

  onFileChange = e =>{
    this.setState( {
      attachment: e?.fileList[0]?.originFileObj || null,
      fileList: e?.fileList
    })
  }

  clearPrefix= () =>{
    this.setState({
      note_type: 'note',
      ref_note_id: null,
      ref_comm_id: null,
      prefix: false
    })
  }

  render() {
    const { comments, submitting, value, disabled, fileList } = this.state

    return (
      <>
        <div className='comment-section'>
          {!disabled && comments?.length == 0 && <span>Notes Not Found!</span>}
          {!disabled && comments?.length > 0 && <CommentList comments={comments} fetchNote={()=>this.fetchNote()} handleReply={this.handleReply} name={this.state.name} role={this.state.role} user_id={this.state.user_id} />}
          <Comment
            // avatar={<Avatar src='https://joeschmoe.io/api/v1/random' alt='Han Solo' />}
            className='editor-comment'
            content={
              <Editor
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onFileChange={this.onFileChange}
                submitting={submitting}
                addonAfter={'sub'}
                fileList={fileList}
                value={value}
                prefix={this.state.prefix}
                clearPrefix={this.clearPrefix}
                disabled={disabled}
              />
            }
          />
        </div>
      </>
    )
  }
}

export default Notes
