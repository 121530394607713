import React, { Component } from 'react'
import Http from '../../../utils/http'
import JSONTree from 'react-json-tree'
import { jsonTheme } from '../../../constants/constants'
import { Row, Col, Card, Table, notification, Button, Radio, Select, DatePicker, TimePicker } from 'antd'
import '../index.css'
import { cloneDeep } from 'lodash'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const cookie = new Cookies()
import moment from 'moment'

let format = 'DDMMMYY HH:mm:ss'
const { Option } = Select

let jsondata = [
  {
    flight_no: 'YY1263',
    diffm: '-30',
    arriv_date: null,
    arriv_time: null,
    dept_date: null,
    dept_time: null,
    awbs: [ '111-71026362', '111-71026852', '111-71027946', '111-71028042', '111-71028425' ],
    out_fno: null
  },
  {
    flight_no: 'YY2035',
    diffm: '30',
    arriv_date: null,
    arriv_time: null,
    dept_date: null,
    dept_time: null,
    awbs: ['111-71036232', '111-71036324', '111-71028705', '111-71027003'],
    out_fno: [
      { 
        fno: 'YY1196L', 
        diffm: '360' 
      }, 
      { 
        fno: 'YY2496L', 
        diffm: '480' 
      }
    ]
  },
  {
    flight_no: 'YY2541',
    diffm: '-30',
    arriv_date: null,
    arriv_time: null,
    dept_date: null,
    dept_time: null,
    awbs: ['111-71051680', '111-71063451', '111-71023120'],
    out_fno: [
      { 
        fno: 'YY2120', 
        diffm: '360' 
      }, 
      { 
        fno: 'YY2820L', 
        diffm: '480' 
      }
    ]
  },
  {
    flight_no: 'YY726',
    diffm: '30',
    arriv_date: null,
    arriv_time: null,
    dept_date: null,
    dept_time: null,
    awbs: ['111-71021215', '111-71029280'	],
    out_fno: [{ 
      fno: 'YY1332L', 
      diffm: '360' 
    }]
  },
  {
    flight_no: 'YY980',
    diffm: '30',
    arriv_date: null,
    arriv_time: null,
    dept_date: null,
    dept_time: null,
    awbs: ['111-61141640', '111-61141640', '111-60793880', '111-55222440', '111-55222440'],
    out_fno: [
      { 
        fno: 'YY014', 
        diffm: '480' 
      }, 
      { 
        fno: 'YY032', 
        diffm: '360' 
      }
    ]
  }
]
//FORMAT AWB NO
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}

class AirlineMessages extends Component {
  constructor() {
    super()
    this.state = {
      timezone: new Cookies().get('timezone'),
      station: new Cookies().get('station'),
      startDate: '',
      endDate: '',
      displayData: [],
      filteredData:[],
      displayJson: '',
      search: '',
      stationVal: [],
      awbKey: '',
      message_list: [],
      selected_scenerio: 'Inbound',
      msg_type: 'ECIBOOK',
      jsondata: cookies.get('demo_data') || jsondata,
      counters: {},
      countersmanifest: {},
      countersevents: {},
      countersfsu: {},
    }
  }

  componentDidMount() {
    this.fetchDemo()
  }
  //FETCH DEMO LIST
  fetchDemo = async () => {
    if(!this.state.selected_scenerio){
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Select Scenerio',
      })
    } else if(!this.state.msg_type){
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Select Message Type',
      })
    } else {
      let url = 'demo'
      url = `${url}?scenerio=${this.state.selected_scenerio}&msg_type=${this.state.msg_type}`
      let headers = {}
      // {
      //   // station: station,
      //   //search: this.state.search
      // }
      await Http.get( url, { headers }).then(resp => {
        if(resp?.data?.data?.length > 0 ) {
          this.setState({ message_list: resp?.data?.data })
          let orgArray = []
          this.processAllMsgs(resp?.data?.data, 0, orgArray)
          this.setState({ displayData: orgArray })

        } else {
          this.setState({ 
            message_list: [], 
            displayData: [] 
          })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: 'No Data found',
          })
        }
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
    }
  }
  onChangeType = (e) => {
    this.setState({
      msg_type: e.target.value,
      moduleLabel: e.target.label,
    },()=>{
      this.fetchDemo()
    })
  }
  resetTables = async () =>{
    let njsondata = cloneDeep(jsondata)
    njsondata.map(async (item, i) => {
      //let diffmins = (30 *(i -1))
      let diffmins = item?.diffm || (30 *(i -1))
      //in
      njsondata[i].dept_date = moment().tz(this.state.timezone).subtract(4,'hours').add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      njsondata[i].dept_time = moment().tz(this.state.timezone).subtract(4,'hours').add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      njsondata[i].arriv_date = moment().tz(this.state.timezone).add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      njsondata[i].arriv_time = moment().tz(this.state.timezone).add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      
      // //out
      // njsondata[i].odept_date = moment().tz(this.state.timezone).add(10,'hours').subtract(4,'hours').add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      // njsondata[i].odept_time = moment().tz(this.state.timezone).add(10,'hours').subtract(4,'hours').add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      // njsondata[i].oarriv_date = moment().tz(this.state.timezone).add(10,'hours').add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')
      // njsondata[i].oarriv_time = moment().tz(this.state.timezone).add(10,'hours').add(diffmins,'minutes').format('YYYY-MM-DD HH:mm')

      //out
      if(item?.out_fno && item?.out_fno.length >0){
        item.out_fno.map(async (item2, j) => {
          njsondata[i].out_fno[j].odept_date = moment().tz(this.state.timezone).add(item2.diffm,'minutes').format('YYYY-MM-DD HH:mm')
          njsondata[i].out_fno[j].odept_time = moment().tz(this.state.timezone).subtract(4,'hours').add(item2.diffm,'minutes').format('YYYY-MM-DD HH:mm')
          njsondata[i].out_fno[j].oarriv_date = moment().tz(this.state.timezone).add(4,'hours').add(item2.diffm,'minutes').format('YYYY-MM-DD HH:mm')
          njsondata[i].out_fno[j].oarriv_time = moment().tz(this.state.timezone).add(4,'hours').add(item2.diffm,'minutes').format('YYYY-MM-DD HH:mm')
        })
      }
    })
    this.setState({ 
      jsondata: njsondata,
      counters: {},
      countersmanifest: {},
      countersevents: {},
      countersfsu: {},
    },()=>{
      cookie.set('demo_data', njsondata, { path: '/' })
      Promise.all([
        Http.get('/simulator/_resetTables', { headers: '' }),
        Http.get('/simulator/_resetFlightTables', { headers: '' }),
        //Http.get('/simulator/_resetMessageLogs', { headers: '' }),
      ]).then((response) => {
        if(response){
          notification.destroy()
          notification.success({
            message: 'Success',
            description: 'Table Data Cleared',
          })
          this.fetchDemo()
        }
      })
    })
  }

  updateDates = async (date, record, key, rindex, oindex ) => {
    let data = cloneDeep(this.state.displayData)
    if(record?.msg_type === 'ECIBOOK' ){
      let index = data.findIndex(p => p.id === record.id)
      if(index > -1) {
        if(oindex > -1){
          data[index].segs[rindex].out_fno[oindex][key] = date
        } else {
          data[index].segs[rindex][key] = date
        }
      }
    } 
    else {
      let index = data.findIndex(p => p.id === record.id)
      if(index > -1) {
        data[index].segs[key] = date
      }
    }
    this.setState({ displayData: data })
  }

  sendmsg = async (record) => {    
    if(record?.msg_type === 'ECIBOOK'){
      if(record?.segs && record.segs.length>0){
        record.segs.map((rec) => {
          let out_fno = rec.out_fno
          let segarr = record.msg.bkginfo.segs.seg
          // record.msg.bkginfo.segs.seg[i].fltinfo.date = rec?.new_date ? moment(rec?.new_date).format('DDMMMYY') : null
          // record.msg.bkginfo.segs.seg[i].fltinfo.dep.time = rec?.new_dep_time ? moment(rec?.new_dep_time).format('HHmm') : null
          // record.msg.bkginfo.segs.seg[i].fltinfo.arr.date = rec?.new_arr_date ? moment(rec?.new_arr_date).format('DDMMMYY') : null
          // record.msg.bkginfo.segs.seg[i].fltinfo.arr.time = rec?.new_arr_time ? moment(rec?.new_arr_time).format('HHmm') : null
          // //if(record.msg.bkginfo.segs.seg[i].dest.station === this.state.station){
          // if(rec.flt_no){
          //   record.msg.bkginfo.segs.seg[i].fltinfo.arr.date = rec?.new_arr_date ? moment(rec?.new_arr_date).format('DDMMMYY') : null
          //   record.msg.bkginfo.segs.seg[i].fltinfo.arr.time = rec?.new_arr_time ? moment(rec?.new_arr_time).format('HHmm') : null
          //   record.msg.bkginfo.segs.seg[i].fltinfo.dep.time = rec?.new_dep_time ? moment(rec?.new_dep_time).format('HHmm') : null
          // //} else if(record.msg.bkginfo.segs.seg[i].origin.station === this.state.station){
          // } else if(i>0){
          //   record.msg.bkginfo.segs.seg[i].fltinfo.arr.date = record.segs[0]?.onew_arr_date ? moment(record.segs[0]?.onew_arr_date).format('DDMMMYY') : null
          //   record.msg.bkginfo.segs.seg[i].fltinfo.arr.time = record.segs[0]?.onew_arr_time ? moment(record.segs[0]?.onew_arr_time).format('HHmm') : null
          //   record.msg.bkginfo.segs.seg[i].fltinfo.dep.time = record.segs[0]?.onew_dep_time ? moment(record.segs[0]?.onew_dep_time).format('HHmm') : null
          //   record.msg.bkginfo.segs.seg[i].fltinfo.date = record.segs[0]?.onew_date ? moment(record.segs[0]?.onew_date).format('DDMMMYY') : null
          // }
          if(out_fno && out_fno.length > 0 && segarr && segarr.length>0){
            segarr.map((item, j) => {
              out_fno.map((item2) => {
                if(item?.fltinfo?.flight === item2.fno){
                  record.msg.bkginfo.segs.seg[j].fltinfo.dep.time = item2?.odept_time ? moment(item2?.odept_time).format('HHmm') : null
                  record.msg.bkginfo.segs.seg[j].fltinfo.date = item2?.odept_date ? moment(item2?.odept_date).format('DDMMMYY') : null
                }
              })
            })
          }
        })
      }
    } else if(record?.msg_type === 'MANIFEST' || record?.msg_type === 'FFM'){ 
      let flight = record?.msg?.businessHeaderDocument?.id?.value?.split('/')
      flight[1]= moment(record?.segs?.new_flight_date).format('YYYYMMDD')
      record.msg.arrivalEvent.arrivalOccurrenceDateTime = moment.tz(moment(record?.segs?.new_arrivalOccurrenceDate).format('YYYY-MM-DD')+' '+moment(record?.segs?.new_arrivalOccurrenceDateTime).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      record.msg.logisticsTransportMovement.departureEvent.departureOccurrenceDateTime = moment.tz(moment(record?.segs?.new_departureOccurrenceDate).format('YYYY-MM-DD')+' '+moment(record?.segs?.new_departureOccurrenceDateTime).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone).valueOf()
      record.msg.businessHeaderDocument.id.value = flight.join('/')
    } else if(record?.msg_type === 'FLIGHT EVENT'){ 
      record.msg.flightLegOrFlight.actlOutGmtTs = moment.tz(moment(record?.segs?.dept_act_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.dept_act_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      record.msg.flightLegOrFlight.schdDprtGmtTs = moment.tz(moment(record?.segs?.dept_schdl_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.dept_schdl_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      record.msg.flightLegOrFlight.estdDprtGmtTs = moment.tz(moment(record?.segs?.dept_eta_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.dept_eta_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()

      record.msg.flightLegOrFlight.actlInGmtTs = moment.tz(moment(record?.segs?.arr_act_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.arr_act_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      record.msg.flightLegOrFlight.schdArrGmtTs = moment.tz(moment(record?.segs?.arr_schdl_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.arr_schdl_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      record.msg.flightLegOrFlight.estdArrGmtTs = moment.tz(moment(record?.segs?.arr_eta_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.arr_eta_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      record.msg.flightLegOrFlight.fltOrigDt = moment.tz(moment(record?.segs?.dept_schdl_dt).format('YYYY-MM-DD')+' '+moment(record?.segs?.dept_schdl_tm).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
    } else if(record?.msg_type === 'FSU'){ 
      if(record?.sub_type === 'RCF'){
        record.msg.masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].scheduledArrivalEvent.scheduledOccurrenceDateTime = moment.tz(moment(record?.segs?.scheduledOccurrenceDate).format('YYYY-MM-DD')+' '+moment(record?.segs?.scheduledOccurrenceTime).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      } else if(record?.sub_type === 'MAN'){
        record.msg.masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].arrivalEvent.arrivalOccurrenceDateTime = moment.tz(moment(record?.segs?.arrivalOccurrenceDate).format('YYYY-MM-DD')+' '+moment(record?.segs?.arrivalOccurrenceTime).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
        record.msg.masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].departureEvent.departureOccurrenceDateTime = moment.tz(moment(record?.segs?.departureOccurrenceDate).format('YYYY-MM-DD')+' '+moment(record?.segs?.departureOccurrenceTime).format('HH:mm'), ('YYYY-MM-DD HH:mm'), this.state.timezone ).valueOf()
      } 
    }
    await Http.post('/runDemo', record.msg).then((response) => {
      if(response && response.status === 200){
        if(record?.msg_type === 'ECIBOOK'){
          this.handleClick(record.id)
        }
        if(record?.msg_type === 'MANIFEST'){
          this.handleManifestClick(record.id)
        }
        if(record?.msg_type === 'FLIGHT EVENT'){
          this.handleEventsClick(record.id)
        }
        if(record?.msg_type === 'FSU'){
          this.handleFSUClick(record.id)
        }
        // eslint-disable-next-line no-console
        console.log('response',response)
        notification.destroy()
        notification.success({
          message: 'Success',
          description: `${record?.msg_type} sent`,
        })
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }
  processAllMsgs(allsegs, index, orgArray)  {
    if(index < (allsegs.length) ){
      let awb_nos = []
      let segarr = []
      let info = null
      info = this.generateInfo(allsegs[index]) 
      if(this.state.msg_type === 'ECIBOOK'){
        awb_nos.push( formatAWBNo(allsegs[index]?.awbinfo?.awb))
        this.processSeg(allsegs[index]?.bkginfo?.segs?.seg, 0, segarr)
      } else if(this.state.msg_type === 'MANIFEST'|| this.state.msg_type === 'FFM'){
        let arr1 = allsegs[index]?.arrivalEvent?.associatedTransportCargo || []
        if(arr1 && arr1.length>0){
          arr1.map((a) => {
            if(a?.includedMasterConsignment && a?.includedMasterConsignment?.length>0){
              a.includedMasterConsignment.map((b) => {
                if( b?.transportContractDocument?.id?.value ){
                  awb_nos.push(b?.transportContractDocument?.id?.value)
                }
              })
            }
          })
        }
        if(this.state.jsondata && this.state.jsondata.length>0) {
          let cjsondata = cloneDeep(this.state.jsondata)
          let findex = null
          if(this.state.msg_type === 'MANIFEST'){
            findex = cjsondata.findIndex(p => p.flight_no === allsegs[index]?.businessHeaderDocument?.id?.value?.split('/')?.[0]) 
          }
          else if(this.state.msg_type === 'FFM'){
            findex = cjsondata.findIndex(p => p.awbs.some(r=> awb_nos.indexOf(r) >= 0))
          }
          if(findex > -1) {
            segarr = {
              new_arrivalOccurrenceDate: this.state.msg_type === 'MANIFEST' ? cjsondata[findex].arriv_date : null /* cjsondata[findex].oarriv_date */ ,
              new_departureOccurrenceDate: this.state.msg_type === 'MANIFEST' ? cjsondata[findex].dept_date : null /* cjsondata[findex].odept_date */ ,
              new_arrivalOccurrenceDateTime: this.state.msg_type === 'MANIFEST' ? cjsondata[findex].arriv_time : null /* cjsondata[findex].oarriv_time */ ,
              new_departureOccurrenceDateTime: this.state.msg_type === 'MANIFEST' ? cjsondata[findex].dept_time : null /* cjsondata[findex].odept_time */ ,
              new_flight_date: cjsondata[findex].dept_date,
              flight_no: allsegs[index]?.businessHeaderDocument?.id?.value?.split('/')?.[0]
            }
          }
        }
        // segarr = {
        //   old_arrivalOccurrenceDateTime: cloneDeep(allsegs[index].arrivalEvent.arrivalOccurrenceDateTime),
        //   old_departureOccurrenceDateTime: cloneDeep(allsegs[index].logisticsTransportMovement.departureEvent.departureOccurrenceDateTime),
        //   old_flight_date: allsegs[index]?.businessHeaderDocument?.id?.value ? moment(allsegs[index]?.businessHeaderDocument?.id?.value?.split('/')?.[1]).format('YYYY-MM-DD') : null,
        //   new_arrivalOccurrenceDate: moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD') ,
        //   new_departureOccurrenceDate: moment().tz(this.state.timezone).format('YYYY-MM-DD'),
        //   new_arrivalOccurrenceDateTime: moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        //   new_departureOccurrenceDateTime: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        //   new_flight_date: moment().tz(this.state.timezone).format('YYYY-MM-DD'),
        //   flight_no: allsegs[index]?.businessHeaderDocument?.id?.value?.split('/')?.[0]
        // }
      } else if(this.state.msg_type === 'FLIGHT EVENT'){
        if(this.state.jsondata && this.state.jsondata.length>0) {
          let cjsondata = cloneDeep(this.state.jsondata) 
          let findex = cjsondata.findIndex(p => p.flight_no === (allsegs[index]?.flightLegOrFlight?.eqpOwnrCrrCd+allsegs[index]?.flightLegOrFlight?.fltNbr))
          if(findex > -1) {
            segarr = {             
              dept_schdl_dt: cjsondata[findex].dept_date,
              dept_schdl_tm: cjsondata[findex].dept_time,
              arr_schdl_dt:  cjsondata[findex].arriv_date,
              arr_schdl_tm:  cjsondata[findex].arriv_time,

              dept_act_dt: null,
              dept_act_tm: null,
              arr_act_dt: null,
              arr_act_tm: null,

              dept_eta_dt: null,
              dept_eta_tm: null,
              arr_eta_dt: null,
              arr_eta_tm: null,

            }
          }
        }
        // segarr = {
        // dept_act_dt:   moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        // dept_schdl_dt: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        // dept_eta_dt: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        // dept_act_tm:   moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        // dept_schdl_tm: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        // dept_eta_tm: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
        // arr_act_dt:  moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        // arr_schdl_dt:  moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        // arr_eta_dt:  moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        // arr_act_tm:  moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        // arr_schdl_tm:  moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        // arr_eta_tm:  moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') ,
        // }
      } else if(this.state.msg_type === 'FSU'){
        if(allsegs[index]?.businessHeaderDocument?.id?.value){
          awb_nos.push(allsegs[index]?.businessHeaderDocument?.id?.value)
        }
        if(allsegs[index]?.sub_type === 'RCF'){
          if(this.state.jsondata && this.state.jsondata.length>0) {
            let cjsondata = cloneDeep(this.state.jsondata) 
            let findex = cjsondata.findIndex(p => p.flight_no === (allsegs[index]?.masterConsignment?.reportedStatus[0]?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value))
            if(findex > -1) {
              segarr = { 
                scheduledOccurrenceDate: cjsondata[findex].arriv_date,
                scheduledOccurrenceTime: cjsondata[findex].arriv_time
              }
            }
          }
          // segarr = { 
          //   scheduledOccurrenceDate: moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm'),
          //   scheduledOccurrenceTime: moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm')
          // }
        } else if(allsegs[index]?.sub_type === 'MAN'){
          if(this.state.jsondata && this.state.jsondata.length>0) {
            let cjsondata = cloneDeep(this.state.jsondata) 
            // Get by AWB
            let findex = cjsondata.findIndex(p => p.awbs.includes( allsegs[index]?.businessHeaderDocument?.id?.value))
            if(findex > -1) {
              segarr = { 
                arrivalOccurrenceDate: cjsondata[findex].arriv_date,
                arrivalOccurrenceTime: cjsondata[findex].arriv_time,
                departureOccurrenceDate: cjsondata[findex].dept_date,
                departureOccurrenceTime: cjsondata[findex].dept_time
              }
            }
          }
          // segarr = { 
          //   arrivalOccurrenceDate: moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm'),
          //   arrivalOccurrenceTime: moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm'),
          //   departureOccurrenceDate: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm'),
          //   departureOccurrenceTime: moment().tz(this.state.timezone).format('YYYY-MM-DD HH:mm')
          // }
        }
      }
      let obj = {
        id: index,
        msg: allsegs[index],
        msg_type: cloneDeep(allsegs[index]?.msg_type),
        sub_type: cloneDeep(allsegs[index]?.sub_type),
        awb: awb_nos,
        segs: segarr,
        info: info
      }
      orgArray.push(obj)
      //delete allsegs[index].msg_type
      this.processAllMsgs(allsegs, index+1, orgArray )
    } else return true
  }

  processSeg(segs, index, segarr ){
    if(index < (segs.length) ){
      if(this.state.jsondata && this.state.jsondata.length>0) {
        let cjsondata = cloneDeep(this.state.jsondata) 
        let findex = cjsondata.findIndex(p => p.flight_no === segs[index]?.fltinfo?.flight)
        if(findex > -1) {
          let org = segs[index]?.origin?.station
          let dest = segs[index]?.dest?.station
          let flt_no = segs[index]?.fltinfo?.flight
          let depTime = segs[index]?.fltinfo?.dep?.time.match(/.{1,2}/g)
          let arrtime = segs[index]?.fltinfo?.arr?.time.match(/.{1,2}/g)
    
          let old_date = moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')
          let old_dep_time = moment(moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')+' '+depTime[0]+':'+depTime[1]).format('YYYY-MM-DD HH:mm')
          let old_arr_time = moment(moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')+' '+arrtime[0]+':'+arrtime[1]).format('YYYY-MM-DD HH:mm')
          let old_arr_date =moment(segs[index]?.fltinfo?.arr?.date, 'DDMMMYY').format('YYYY-MM-DD')
          
          // let ardate = moment.tz(moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')+' '+arrtime[0]+':'+arrtime[1],this.state.timezone).format('YYYY-MM-DD HH:mm')
          // let depdate = moment.tz(moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')+' '+depTime[0]+':'+depTime[1],this.state.timezone).format('YYYY-MM-DD HH:mm')
          // let diff = moment.tz(ardate,this.state.timezone).diff(moment.tz(depdate,this.state.timezone), 'mins')
          // let new_date = moment().tz(this.state.timezone).format('YYYY-MM-DD')
          // let new_dep_time = segs[index]?.origin?.station === this.state.station ? moment().tz(this.state.timezone).add(4,'hours').format('YYYY-MM-DD HH:mm') : moment(moment().format('YYYY-MM-DD')+' '+depTime[0]+':'+depTime[1]).format('YYYY-MM-DD HH:mm')
          // let new_arr_time = segs[index]?.origin?.station === this.state.station ? moment().tz(this.state.timezone).add(diff, 'mins').add(4,'hours').format('YYYY-MM-DD HH:mm'): moment(moment().format('YYYY-MM-DD')+' '+arrtime[0]+':'+arrtime[1]).format('YYYY-MM-DD HH:mm')
          // let new_arr_date = segs[index]?.fltinfo?.arr?.date ? moment().tz(this.state.timezone).add(1,'days').format('YYYY-MM-DD') : null
          
          let new_date = cjsondata[findex].dept_date
          let new_dep_time = cjsondata[findex].dept_time
          let new_arr_date = cjsondata[findex].arriv_date
          let new_arr_time = cjsondata[findex].arriv_time

          let temp_out_fno = []
          if(cjsondata[findex].out_fno && cjsondata[findex].out_fno.length >0){
            
            segs.map(async (item) => {
              cjsondata[findex].out_fno.map(async (item2) => {
                if(item.fltinfo?.flight === item2.fno){
                  temp_out_fno.push(item2)
                }
              })
            })
          }
          let out_fno = temp_out_fno
          // let onew_date = cjsondata[findex].odept_date
          // let onew_dep_time = cjsondata[findex].odept_time
          // let onew_arr_date = cjsondata[findex].oarriv_date
          // let onew_arr_time = cjsondata[findex].oarriv_time
    
          let obj = {
            org,
            dest,
            flt_no,
            old_date,
            old_dep_time,
            old_arr_time,
            old_arr_date,
            new_date,
            new_dep_time,
            new_arr_time,
            new_arr_date,

            out_fno,
            // onew_date,
            // onew_dep_time,
            // onew_arr_date,
            // onew_arr_time
          }
          segarr.push(obj) 
        } 
        // else if(index > 0){
        //   let org = segs[index]?.origin?.station
        //   let dest = segs[index]?.dest?.station
        //   let flt_no = null
        //   let depTime = segs[index]?.fltinfo?.dep?.time.match(/.{1,2}/g)
        //   let arrtime = segs[index]?.fltinfo?.arr?.time.match(/.{1,2}/g)
        //   let old_date = moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')
        //   let old_dep_time = moment(moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')+' '+depTime[0]+':'+depTime[1]).format('YYYY-MM-DD HH:mm')
        //   let old_arr_time = moment(moment(segs[index]?.fltinfo?.date, 'DDMMMYY').format('YYYY-MM-DD')+' '+arrtime[0]+':'+arrtime[1]).format('YYYY-MM-DD HH:mm')
        //   let old_arr_date =moment(segs[index]?.fltinfo?.arr?.date, 'DDMMMYY').format('YYYY-MM-DD')

        //   let obj = {
        //     org,
        //     dest,
        //     flt_no,
        //     old_date,
        //     old_dep_time,
        //     old_arr_time,
        //     old_arr_date,
        //     new_date: null,
        //     new_dep_time: null,
        //     new_arr_time: null,
        //     new_arr_date: null,

        //     onew_date: null,
        //     onew_dep_time: null,
        //     onew_arr_date: null,
        //     onew_arr_time: null
        //   }
        //   segarr.push(obj)
        // }
      }
      this.processSeg(segs, index+1, segarr )
    } else {
      return true
    }
  }

  //GENERATE INFO OBJECT
  generateInfo = (data) => {
    let body = data
    let r = {}
    let type = `${data?.msg_type}/${data?.sub_type}`
    if (data?.msg_type == 'ECIBOOK') {
      r.awb_no = body?.awbinfo?.awb || ''
      r.db_key = body?.awbinfo?.keyinfo?.dbkey || ''
      r.revision = body?.awbinfo?.keyinfo?.revision || null
      body?.bkginfo?.segs?.seg?.map(s => {
        if (s?.origin?.station == 'ATL' || s?.dest?.station == 'ATL' ||
        s?.origin?.station == 'LAX' || s?.dest?.station == 'LAX' ||
        s?.origin?.station == 'BOS' || s?.dest?.station == 'BOS'||
        s?.origin?.station == 'JFK' || s?.dest?.station == 'JFK'||
        s?.origin?.station == 'SEA' || s?.dest?.station == 'SEA'||
        s?.origin?.station == 'DTW' || s?.dest?.station == 'DTW'||
        s?.origin?.station == 'MSP' || s?.dest?.station == 'MSP'||
        s?.origin?.station == 'SLC' || s?.dest?.station == 'SLC'||
        s?.origin?.station == 'ORD' || s?.dest?.station == 'ORD'||
        s?.origin?.station == 'HNL' || s?.dest?.station == 'HNL'||
        s?.origin?.station == 'SEA' || s?.dest?.station == 'SEA') {
          r.origin = s?.origin?.station
          r.dest = s?.dest?.station
          //r.flight_no = s?.fltinfo?.flight + s?.fltinfo?.date
          r.flight_no = s?.fltinfo?.flight
          r.pcs = s?.space?.pcs
          r.wgt = s?.space?.wgt
          r.status = s?.status?.fct
          r.createdAt = moment.tz(body?.awbinfo?.datetime?.creation?.date?.content+' '+body?.awbinfo?.datetime?.creation?.time, 'DDMMMYY HHmm', this.state.timezone).utc().format(format) + ' UTC'
        }
      })
    } else if (data?.msg_type == 'MANIFEST' || data?.msg_type === 'FFM') {
      r = []
      let org_stn = body?.logisticsTransportMovement?.departureEvent?.occurrenceDepartureLocation?.id?.value
      data.org_stn = org_stn
      body?.arrivalEvent?.associatedTransportCargo?.map(uld => {
        uld?.includedMasterConsignment?.map(awb => {
          let d= {}
          d.issueDateTime = body?.messageHeaderDocument?.issueDateTime ? moment.utc(body?.messageHeaderDocument?.issueDateTime).format(format) + ' UTC': ''
          d.uld_no = uld?.typeCode?.value == 'BLK' ? 'bulk' : uld?.utilizedUnitLoadTransportEquipment?.characteristicCode?.value + uld?.utilizedUnitLoadTransportEquipment?.id?.value + uld?.utilizedUnitLoadTransportEquipment?.operatingParty?.primaryID?.value
          d.awb_no = awb?.transportContractDocument?.id?.value
          d.totalPieceQuantity = awb?.totalPieceQuantity?.value
          d.grossWeightMeasureValue = awb?.grossWeightMeasure?.value
          d.grossWeightMeasureUnit = awb?.grossWeightMeasure?.unitCode
          d.grossVolumeMeasureValue = awb?.grossVolumeMeasure?.value
          d.grossVolumeMeasureUnit = awb?.grossVolumeMeasure?.unitCode
          r.push(d)
        })
      })
    } else if (type == 'FSU/RCF' || type == 'FSU/FPS' || type == 'FSU/NFD' || type == 'FSU/RCS' || type == 'FSU/FOH' || type == 'FSU/PRE' || type == 'FSU/RCT' || type == 'FSU/DLV' || type == 'FSU/DIS'){
      let reportedStatus = body?.masterConsignment?.reportedStatus[0]
      let uldData = reportedStatus?.associatedStatusConsignment[0]?.utilizedUnitLoadTransportEquipment?.[0] || {}
      let uld_no = uldData?.characteristicCode?.value + uldData?.id?.value + uldData?.operatingParty?.primaryID?.value || null
      let fromLocation = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.fromLocation || 'UNKNOWN'
      let toLocation = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.toLocation || 'UNKNOWN'
      let spl = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.splCodes?.toString() || ''

      r.issueDateTime = body?.messageHeaderDocument?.issueDateTime ? moment.utc(body?.messageHeaderDocument?.issueDateTime).format(format) + ' UTC': ''
      if(uld_no) {
        r.uld_no = uld_no
      }
      r.awb_no = body?.businessHeaderDocument?.id?.value
      r.pieceQuantity = reportedStatus?.associatedStatusConsignment[0]?.pieceQuantity?.value
      r.grossMeasureWeight = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.value
      r.grossMeasureUnit = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.unitCode
      r.spl = spl
      if(type !== 'FSU/NFD') {
        //r.specifiedLogisticsTransportMovement = type == 'FSU/RCF' ? reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value + '/' + moment(reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.departureEvent?.departureOccurrenceDateTime).format('YYYY-MM-DD HH:mm:ss'): ''
        r.specifiedLogisticsTransportMovement = type == 'FSU/RCF' ? reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value : ''
        r.fromLocation = fromLocation
        r.toLocation = toLocation
      }
      if(type === 'FSU/DIS') {
        // r.flight_no = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value + '/' + moment(reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.departureEvent?.departureOccurrenceDateTime).utc().format('YYYY-MM-DD HH:mm:ss')
        r.flight_no = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value
      }
    } else if (type == 'FSU/MAN') {
      let reportedStatus = body?.masterConsignment?.reportedStatus[0]
      let uldData = reportedStatus?.associatedStatusConsignment[0]?.utilizedUnitLoadTransportEquipment?.[0] || {}
      let uld_no = uldData?.characteristicCode?.value + uldData?.id?.value + uldData?.operatingParty?.primaryID?.value || null
      let spl = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.splCodes?.toString()

      r.issueDateTime = body?.messageHeaderDocument?.issueDateTime ? moment.utc(body?.messageHeaderDocument?.issueDateTime).format(format) + ' UTC': ''
      if(uld_no) {
        r.uld_no = uld_no
      }
      r.awb_no = body?.businessHeaderDocument?.id?.value
      r.pieceQuantity = reportedStatus?.associatedStatusConsignment[0]?.pieceQuantity?.value
      r.grossWeightMeasureValue = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.value
      r.grossWeightMeasureUnit = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.unitCode
      r.spl = spl
      //r.flight_no = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value + '/' + moment(reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.departureEvent?.departureOccurrenceDateTime).utc().format('YYYY-MM-DD HH:mm:ss')
      r.flight_no = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value
    } else {
      r.createdAt = data?.createdAt
      r.type = data?.msg_type
      r.err = data?.log?.err
    }
    return r
  }
  
  generateBookingDates = async (segs) => {
    if(segs && segs.length > 0){ 
      segs.map((item) => {
        item.flt_no
      })
    } else {
      return []
    }

  }

  // COUNTER FOR BOOKINGS
  handleClick = async (itemId) => {
    this.setState((prevState) => ({
      counters: {
        ...prevState.counters,
        [itemId]: (prevState.counters[itemId] || 0) + 1,
      },
    }))
  }

  // COUNTER FOR MANIFEST
  handleManifestClick = async (itemId) => {
    this.setState((prevState) => ({
      countersmanifest: {
        ...prevState.countersmanifest,
        [itemId]: (prevState.countersmanifest[itemId] || 0) + 1,
      },
    }))
  }

  // COUNTER FOR FLIGHT EVENTS
  handleEventsClick = async (itemId) => {
    this.setState((prevState) => ({
      countersevents: {
        ...prevState.countersevents,
        [itemId]: (prevState.countersevents[itemId] || 0) + 1,
      },
    }))
  }
  
  // COUNTER FOR FSU
  handleFSUClick = async (itemId) => {
    this.setState((prevState) => ({
      countersfsu: {
        ...prevState.countersfsu,
        [itemId]: (prevState.countersfsu[itemId] || 0) + 1,
      },
    }))
  }

  render() {
    const columns = []
    const { counters, countersmanifest, countersevents, countersfsu } = this.state
    if(this.state.msg_type !== 'FLIGHT EVENT'){
      columns.push(
        {
          title: 'Awb Number',
          dataIndex: '',
          key: 'awb',
          width: '130px',
          className: 'lineht-1dot8 min-width-100',           
          render: (_, record) => {
            return (
              <span>{ record?.awb.join(', ') }</span>
            )
          }
        },
      )
    }
    if(this.state.msg_type === 'FSU'){
      columns.push(
        {
          title: 'Type',
          dataIndex: '',
          key: 'type',
          width: '100px',
          render: (_, record) => {
            return <span> {record?.sub_type ? record?.sub_type  : record?.msg_type} </span>
          }
        },
      )
    }
    columns.push(
      {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        className: 'min-width-200',
        render: (_, record) => (
          <>
            {record?.info ? <JSONTree data={record?.info} theme={jsonTheme} invertTheme={true} className='json-tree' /> : ''}
          </>
        )
      },
      {
        title: 'Message',
        dataIndex: '',
        key: 'message',
        className: 'min-width-200',
        render: (_, record) => {  
          return(<>
            <JSONTree data={record.msg} datatheme={jsonTheme} invertTheme={true} className='json-tree' /> 
          </>)
        }
      },
      {
        title: 'Date Time',
        dataIndex: '',
        key: 'date_time',
        className: 'min-width-350',
        //render: (_, record) => { return this.generateBookingDates(record?.segs) }
        render: (_, record) => {
          return (
            <span>
              {
                record?.msg_type === 'ECIBOOK' ? 
                  record?.segs.map((item, i) => item.out_fno && item.out_fno.length>0 ?
                    item.out_fno.map((item2, j) => item2 ?
                      <span key={i} className='dt-col'>
                        <div>{'FLT No: '+item2.fno} </div>
                        <Row gutter={8}>
                          <Col className='min-width-55'>{'Dept Dt:'}</Col>
                          <Col>
                            <DatePicker
                              allowClear={false}
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'odept_date', i, j )}
                              value={moment.tz(moment(item2.odept_date,'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              allowClear={false}
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'odept_time', i, j )}
                              value={moment.tz(item2?.odept_time ,this.state.timezone)}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                      </span> : null
                    ) : null
                  )
                  : record?.msg_type === 'MANIFEST' || record?.msg_type === 'FFM'? 
                    <span className='dt-col'>  
                      <div>{'FLT No: '+record?.segs?.flight_no} </div>
                      <Row gutter={8}>
                        <Col className='min-width-55'>{'FLT date:'}</Col>
                        <Col>
                          <DatePicker
                            allowClear={false}
                            size='small'  
                            onChange={(date) => this.updateDates( date, record, 'new_flight_date' )}
                            value={moment.tz(moment(record?.segs?.new_flight_date,'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                            getPopupContainer={trigger => trigger.parentNode}
                          />
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className='min-width-55'>{'Dept Dt:'}</Col>
                        <Col>
                          <DatePicker
                            allowClear={false}
                            size='small'
                            onChange={(date) => this.updateDates( date, record, 'new_departureOccurrenceDate' )}
                            value={moment.tz(moment((record?.segs?.new_departureOccurrenceDate),'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                            getPopupContainer={trigger => trigger.parentNode}
                          />
                        </Col>
                        <Col>
                          <TimePicker
                            allowClear={false}
                            format={'HH:mm'}
                            onChange={(date) => this.updateDates( date, record, 'new_departureOccurrenceDateTime' )}
                            value={moment.tz(record?.segs?.new_departureOccurrenceDateTime, this.state.timezone)}
                            getPopupContainer={trigger => trigger.parentNode}
                          />
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className='min-width-55'>{'Arriv Dt:'}</Col>
                        <Col>
                          <DatePicker
                            allowClear={false}
                            size='small'
                            onChange={(date) => this.updateDates( date, record, 'new_arrivalOccurrenceDate' )}
                            value={moment.tz(moment((record?.segs?.new_arrivalOccurrenceDate),'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                            getPopupContainer={trigger => trigger.parentNode}
                          />
                        </Col>
                        <Col>
                          <TimePicker
                            allowClear={false}
                            format={'HH:mm'}
                            onChange={(date) => this.updateDates( date, record, 'new_arrivalOccurrenceDateTime' )}
                            value={moment.tz(record?.segs?.new_arrivalOccurrenceDateTime, this.state.timezone)}
                            getPopupContainer={trigger => trigger.parentNode}
                          />
                        </Col>
                      </Row>
                    </span>
                    : record?.msg_type === 'FLIGHT EVENT' ?
                      <span className='dt-col'>  
                        <div>{'FLT No: '+record?.msg?.flightLegOrFlight?.eqpOwnrCrrCd+record?.msg?.flightLegOrFlight?.fltNbr} </div>
                        <Row gutter={8}>
                          <Col className='min-width-95'>{'Dept Scheduled:'}</Col>
                          <Col>
                            <DatePicker
                              allowClear={false}
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'dept_schdl_dt' )}
                              value={moment.tz(record?.segs?.dept_schdl_dt,this.state.timezone)}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              allowClear={false}
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'dept_schdl_tm' )}
                              value={moment.tz(record?.segs?.dept_schdl_tm, this.state.timezone)}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col className='min-width-95'>{'Dept ETA:'}</Col>
                          <Col>
                            <DatePicker
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'dept_eta_dt' )}
                              value={record?.segs?.dept_eta_dt ? moment.tz(record?.segs?.dept_eta_dt,this.state.timezone) : null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'dept_eta_tm' )}
                              value={record?.segs?.dept_eta_tm ? moment.tz(record?.segs?.dept_eta_tm, this.state.timezone): null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col className='min-width-95'>{'Dept actual:'}</Col>
                          <Col>
                            <DatePicker
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'dept_act_dt' )}
                              value={record?.segs?.dept_act_dt ? moment.tz(record?.segs?.dept_act_dt,this.state.timezone) : null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'dept_act_tm' )}
                              value={record?.segs?.dept_act_tm ? moment.tz(record?.segs?.dept_act_tm, this.state.timezone) : null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col className='min-width-95'>{'Arriv Scheduled:'}</Col>
                          <Col>
                            <DatePicker
                              allowClear={false}
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'arr_schdl_dt' )}
                              value={moment.tz(record?.segs?.arr_schdl_dt,this.state.timezone)}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              allowClear={false}
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'arr_schdl_tm' )}
                              value={moment.tz(record?.segs?.arr_schdl_tm, this.state.timezone)}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col className='min-width-95'>{'Arriv ETA:'}</Col>
                          <Col>
                            <DatePicker
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'arr_eta_dt' )}
                              value={record?.segs?.arr_eta_dt ? moment.tz(record?.segs?.arr_eta_dt,this.state.timezone): null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'arr_eta_tm' )}
                              value={record?.segs?.arr_eta_tm ? moment.tz(record?.segs?.arr_eta_tm, this.state.timezone): null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col className='min-width-95'>{'Arriv actual:'}</Col>
                          <Col>
                            <DatePicker
                              size='small'  
                              onChange={(date) => this.updateDates( date, record, 'arr_act_dt' )}
                              value={record?.segs?.arr_act_dt ? moment.tz(record?.segs?.arr_act_dt,this.state.timezone) : null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                          <Col>
                            <TimePicker
                              format={'HH:mm'}
                              onChange={(date) => this.updateDates( date, record, 'arr_act_tm' )}
                              value={record?.segs?.arr_act_tm ? moment.tz(record?.segs?.arr_act_tm, this.state.timezone) : null}
                              getPopupContainer={trigger => trigger.parentNode}
                            />
                          </Col>
                        </Row>
                      </span>
                      : record?.msg_type === 'FSU' ?
                        record?.sub_type === 'RCF' ?
                          <span>
                            <div>{'FLT No: '+record?.msg?.masterConsignment?.reportedStatus[0]?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value} </div>
                            <Row gutter={8} className='mt-2'>
                              <Col>{'scheduledOccurrenceDate:'}</Col>
                              <Col>
                                <DatePicker
                                  allowClear={false}
                                  size='small'
                                  onChange={(date) => this.updateDates( date, record, 'scheduledOccurrenceDate' )}
                                  value={moment.tz(moment((record?.segs?.scheduledOccurrenceDate),'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                                  getPopupContainer={trigger => trigger.parentNode}
                                />
                              </Col>
                              <Col>
                                <TimePicker
                                  allowClear={false}
                                  format={'HH:mm'}
                                  onChange={(date) => this.updateDates( date, record, 'scheduledOccurrenceTime' )}
                                  value={moment.tz(record?.segs?.scheduledOccurrenceTime, this.state.timezone)}
                                  getPopupContainer={trigger => trigger.parentNode}
                                />
                              </Col>
                            </Row>
                          </span>
                          :record?.sub_type === 'MAN' ?

                            <span>  
                              <Row gutter={8} className='mt-2'>
                                <Col>{'arrivalOccurrenceDate:'}</Col>
                                <Col>
                                  <DatePicker
                                    allowClear={false}
                                    size='small'
                                    onChange={(date) => this.updateDates( date, record, 'arrivalOccurrenceDate' )}
                                    value={moment.tz(moment((record?.segs?.arrivalOccurrenceDate),'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                                    getPopupContainer={trigger => trigger.parentNode}
                                  />
                                </Col>
                                <Col>
                                  <TimePicker
                                    allowClear={false}
                                    format={'HH:mm'}
                                    onChange={(date) => this.updateDates( date, record, 'arrivalOccurrenceTime' )}
                                    value={moment.tz(record?.segs?.arrivalOccurrenceTime, this.state.timezone)}
                                    getPopupContainer={trigger => trigger.parentNode}
                                  />
                                </Col>
                              </Row>
                              <Row gutter={8} className='mt-2'>
                                <Col>{'departureOccurrenceDate:'}</Col>
                                <Col>
                                  <DatePicker
                                    allowClear={false}
                                    size='small'
                                    onChange={(date) => this.updateDates( date, record, 'departureOccurrenceDate' )}
                                    value={moment.tz(moment((record?.segs?.departureOccurrenceDate),'YYYY-MM-DD').format('YYYY-MM-DD'),this.state.timezone)}
                                    getPopupContainer={trigger => trigger.parentNode}
                                  />
                                </Col>
                                <Col>
                                  <TimePicker
                                    allowClear={false}
                                    format={'HH:mm'}
                                    onChange={(date) => this.updateDates( date, record, 'departureOccurrenceTime' )}
                                    value={moment.tz(record?.segs?.departureOccurrenceTime, this.state.timezone)}
                                    getPopupContainer={trigger => trigger.parentNode}
                                  />
                                </Col>
                              </Row>
                            </span>
                            : null
                        : null
              }
            </span>
          )
        }
      },
      {
        title: 'Trigger',
        dataIndex: '',
        key: 'trigger',
        render: (_,record) => (
          <>
            <Button type='link'  className='custom-button send mb-2' onClick={()=> this.sendmsg(record)} >Send to HCS</Button>
            {record.msg_type === 'ECIBOOK' ? <span>Triggered Count: {counters[record.id] || 0} </span> : 
              record.msg_type === 'MANIFEST' ? <span>Triggered Count: {countersmanifest[record.id] || 0} </span> : 
                record.msg_type === 'FLIGHT EVENT' ? <span>Triggered Count: {countersevents[record.id] || 0} </span> :
                  record.msg_type === 'FSU' ? <span>Triggered Count: {countersfsu[record.id] || 0} </span> :'' }
          </>
        ),
      }
    )
    
    return (
      <Card className='custom-card h-100' title='Airline Messages'>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 12,
          lg: 12
        }, {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12
        },]} className=''>
          <Col xs={24} className='f-spacebtw'>
            <div>
              <label className='custom-label'>Scenarios</label>
              <Select
                allowClear
                size='small'
                placeholder='Select'
                className='br-input select-input'
                value={this.state.selected_scenerio}
                onChange={(e) => { this.setState({ selected_scenerio: e },()=>{ this.fetchDemo() }) }}
              >
                <Option value='Inbound' type={true}>Inbound</Option>
                <Option value='Breakdown' type={true}>Breakdown</Option>
                <Option value='Buildup' type={false}>Buildup</Option>
                <Option value='Haulout' type={false}>Haulout</Option>
                <Option value='Fcp' type={false}>Flight Capacity Planning</Option>
              </Select>              
            </div>
            <Button type='link' className='custom-button send' onClick={()=> this.resetTables()} >Reset Table</Button>
          </Col>
          <Col xs={24} className='mb-4'>
            <label className='custom-label label-border'>Type</label>
            <Radio.Group
              onChange={this.onChangeType}
              className='f-spacebtw f-wrap'
              value={this.state.msg_type}
              label={this.state.moduleLabel}
            >
              <Radio value='ECIBOOK' label='ECIBOOK'>
                Bookings
              </Radio>
              <Radio value='MANIFEST' label='MANIFEST'>
                Manifest
              </Radio>
              <Radio value='FSU' label='FSU'>
                FSU
              </Radio>
              <Radio value='FLIGHT EVENT' label='FLIGHT EVENT'>
                Flight Events
              </Radio>
              <Radio value='FFM' label='FFM'>
                FFM
              </Radio>
            </Radio.Group>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className='table-overflow'
          >
            <div className='table-outer'>
              <Table
                className='custom-table unpr-msg-table'
                dataSource={this.state.displayData}
                columns={columns}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default AirlineMessages
