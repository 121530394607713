import React, { Component } from 'react'
import { Button, Input, DatePicker, Row, Col, Typography, Form, Card, Table, Tooltip, Popconfirm, notification, Upload, Modal, Spin } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import download from '../../../assets/images/download.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import './tabs.css'
import { Http } from '../../../utils'
import moment from 'moment'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
import Cookies from 'universal-cookie'
const { Title } = Typography
let cookies = new Cookies()
const station = cookies.get('station')

function isNotSupported(type) {
  return type !== 'image/png' && type !== 'application/pdf' && type !== 'image/jpg' && type !== 'image/jpeg'
}

class DynamicInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      tableData: [],
      is_edit: false,
      id: null,
      contry_code: '',
      ac_type: '',
      flightno: '',
      attach_conf: false,
      fileList: [],
      uld_type: '',
      prod_code: '',
      spl_code: '',
      startdate: '',
      enddate: '',
      station: '',
      remarks: '',
      duplicate: null,
      previewImg: null,
      isModalVisible: false,

      editRecord: null,
      editStation: '',
      editContry_code: '',
      editAc_type: '',
      editFlightno: '',
      editUld_type : '',
      editProd_code: '',
      editSpl_code: '',
      editAttach_conf: false,
      editFilelist: [],
      editStartdate: '',
      editEnddate: '',
      editRemarks: '',
    }
  }
  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // eslint-disable-next-line no-console
      console.log('parsedData',parsedData)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'DYNAMIC_INFO'){
          this.fetchDynamicInfo()
        }
      }
    }
    this.fetchDynamicInfo()
  }

  fetchDynamicInfo() {
    Http.get('dynamic_infos', { headers: { station: station } }).then(resp => {
      if (resp?.data && resp?.status == 200) {
        this.setState({ tableData: resp?.data?.data })
      }
    })
  }

  addDynamicInfo() {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dynamic_info,
      station: station
    }
    let formdata = new FormData()
    formdata.append('contry_code', this.state.contry_code || '')
    formdata.append('ac_type', this.state.ac_type || '')
    formdata.append('flightno', this.state.flightno || '')
    formdata.append('uld_type', this.state.uld_type || '')
    formdata.append('prod_code', this.state.prod_code || '')
    formdata.append('spl_code', this.state.spl_code || '')
    formdata.append('attach_conf', this.state.attach_conf || null)
    formdata.append('startdate', this.state.startdate? moment(this.state.startdate).format('YYYY-MM-DD') : '')
    formdata.append('enddate', this.state.enddate? moment(this.state.enddate).format('YYYY-MM-DD') : '')
    formdata.append('station', this.state.station || '')
    formdata.append('remarks', this.state.remarks || '')
    this.setState({ loading: true })
    Http.post('/dynamic_info', formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.clearForm()
          this.fetchDynamicInfo()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  editDynamicInfo(d) {
    this.setState({
      id: d.id,
      is_edit: true,
      station: d.station,
      ...d.details,
      startdate: d.details.startdate ? moment(d.details.startdate) : '',
      enddate: d.details.enddate ? moment(d.details.enddate) : '',
    })
  }

  updateDynamicInfo() {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dynamic_info,
      station: station
    }
    let formdata = new FormData()
    formdata.append('contry_code', this.state.editContry_code || '')
    formdata.append('ac_type', this.state.editAc_type || '')
    formdata.append('flightno', this.state.editFlightno || '')
    formdata.append('uld_type', this.state.editUld_type || '')
    formdata.append('prod_code', this.state.editProd_code || '')
    formdata.append('spl_code', this.state.editSpl_code || '')
    formdata.append('attach_conf', this.state.editAttach_conf || null)
    formdata.append('startdate', this.state.editStartdate? moment(this.state.editStartdate).format('YYYY-MM-DD') : '')
    formdata.append('enddate', this.state.editEnddate? moment(this.state.editEnddate).format('YYYY-MM-DD') : '')
    formdata.append('station', this.state.editStation || '')
    formdata.append('remarks', this.state.editRemarks || '')
    this.setState({ loading: true })
    Http.put(`/dynamic_info/${this.state.editRecord.id}`, formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.clearForm()
          this.clearTableForm()
          this.fetchDynamicInfo()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  deleteDynamicInfo(id) {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dynamic_info,
      station: station
    }
    Http.put(`/dynamic_info/${id}`, { is_delete: true }, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.fetchDynamicInfo()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  clearForm() {
    this.setState({
      is_edit: false,
      id: null,
      contry_code: '',
      ac_type: '',
      flightno: '',
      uld_type: '',
      prod_code: '',
      spl_code: '',
      startdate: '',
      enddate: '',
      station: '',
      remarks: '',
      attach_conf: false,
      fileList: []
    })
  }

  editTableForm = (record) => {
    this.setState({
      editRecord: record,
      editStation: record.details.station,
      editContry_code: record.details.contry_code,
      editAc_type: record.details.ac_type,
      editFlightno: record.details.flightno,
      editUld_type : record.details.uld_type,
      editProd_code: record.details.prod_code,
      editSpl_code: record.details.spl_code,
      editAttach_conf: record.details.attach_conf? JSON.stringify(record.details.attach_conf) : null,
      editFilelist: record.details?.attach_conf?.name ? [{ name: record.details?.attach_conf?.name }] : [],
      editStartdate: record.details.startdate ? moment(record.details.startdate) : '',
      editEnddate: record.details.enddate ? moment(record.details.enddate) : '',
      editRemarks: record.details.remarks ? record.details.remarks : ''
    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editStation: null,
      editContry_code: null,
      editAc_type: null,
      editFlightno: null,
      editUld_type : null,
      editProd_code: null,
      editSpl_code: null,
      editStartdate: null,
      editEnddate: null,
      editRemarks: null,
      editAttach_conf: false,
      editFilelist: [],
    })
  }

  beforeUpload = (file) => {
    if (isNotSupported(file?.type)) {
      notification.error({
        message: 'Invalid file format',
        description: 'Please upload a PDF/Image',
      })
    }
    return file.type === 'image/png'
      || file.type === 'image/jpeg'
      || file.type === 'image/jpg'
      || file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
  }

  onChangeFileHandler = (e) => {
    if (e?.fileList[0]) {
      this.setState({
        attach_conf: e?.fileList[0].originFileObj,
        fileList: e?.fileList
      })
    } else {
      this.setState({
        attach_conf: false,
        fwd_name: null,
        fileList: []
      })
    }
  }

  onChangeEditFileHandler = (e) => {
    if (e?.fileList[0]) {
      this.setState({
        editAttach_conf: e?.fileList[0].originFileObj,
        editFilelist: e?.fileList
      })
    } else {
      this.setState({
        editAttach_conf: false,
        editFwd_name: null,
        editFilelist: []
      })
    }
  }

  onRemoveFileHandler = () => {
    this.setState({
      attach_conf: false,
      fwd_name: null,
      fileList: []
    })
  }

  onRemoveEditFileHandler = () => {
    this.setState({
      editAttach_conf: false,
      editFwd_name: null,
      editFilelist: []
    })
  }

  showPreview = (record, key) => {
    this.setState({
      isModalVisible: true,
      previewImg: record.details[key].path
    })
  }

  checkType = (record) => {
    if(record?.details?.attach_conf?.name.includes('.pdf')) {
      return (
        <span>
          <Tooltip title='Preview'>
            {record?.details?.attach_conf && <a
              href={record?.details?.attach_conf?.path}
              rel='noopener noreferrer'
              target='_blank'
            ><CheckOutlined /></a>}
          </Tooltip>
          {!record?.details?.attach_conf && (<CloseOutlined />)}
        </span>
      )
    } else {
      return (
        <span>
          <Tooltip title='Preview' onClick={() => this.showPreview(record,'attach_conf' ) }>
            {record?.details?.attach_conf && <CheckOutlined />}
          </Tooltip>
          {!record?.details?.attach_conf && (<CloseOutlined />)}
        </span>
      )
    }
  }

  render() {

    const dynamiccolumns = [
      {
        title: 'Station Code',
        key: 'station',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editStation}
              onChange={(e) => this.setState({ editStation: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.station}</span> )
      },
      {
        title: 'Country Code',
        key: 'countrycode',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editContry_code}
              onChange={(e) => this.setState({ editContry_code: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.contry_code}</span> )
      },
      {
        title: 'AC Type',
        key: 'actype',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editAc_type}
              onChange={(e) => this.setState({ editAc_type: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.ac_type}</span> )
      },
      {
        title: 'Flight #',
        key: 'flight',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editFlightno}
              onChange={(e) => this.setState({ editFlightno: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.flightno}</span> )
      },
      {
        title: 'ULD Type',
        key: 'uldtype',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editUld_type}
              onChange={(e) => this.setState({ editUld_type: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.uld_type}</span> )
      },
      {
        title: 'Prod Code',
        key: 'prodcode',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editProd_code}
              onChange={(e) => this.setState({ editProd_code: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.prod_code}</span> )
      },
      {
        title: 'Special Codes',
        key: 'specialcodes',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editSpl_code}
              onChange={(e) => this.setState({ editSpl_code: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.spl_code}</span> )
      },
      {
        title: 'Start Date',
        key: 'startdate',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <DatePicker size='small' value={this.state.editStartdate} onChange={(d) => this.setState({ editStartdate: d })} />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.startdate ? moment(record?.details?.startdate).format('DD-MMM-YY').toUpperCase() : ''}</span> )
      },
      {
        title: 'End Date',
        key: 'enddate',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <DatePicker size='small' value={this.state.editEnddate} onChange={(d) => this.setState({ editEnddate: d })} />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.enddate ? moment(record?.details?.enddate).format('DD-MMM-YY').toUpperCase() : ''}</span> )
      },
      {
        title: 'Remarks',
        key: 'remarks',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editRemarks}
              onChange={(e) => this.setState({ editRemarks: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.remarks}</span> )
      },
      {
        title: 'Attachment',
        key: 'attachment',
        dataIndex: 'attachment',
        width: '150px',
        className:'fleet-tableupload pfd-upload',
        align: 'center',
        render: (_, record) => {
          return (
            this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='scaletbl-img'>
                <Upload
                  accept='image/jpg,image/jpeg,image/png,application/pdf'
                  className='scaletbl-img'
                  fileList={this.state.editFilelist}
                  maxCount={1} beforeUpload={() => false}
                  onChange={(e) => this.onChangeEditFileHandler(e)}
                  onRemove={() => this.onRemoveEditFileHandler()}>
                  <Button type='ghost' className={`image-btn ${this.state.editAttach_conf ? 'green-fliter' : ''}`} >
                    <img src={download} alt='download' />
                  </Button>
                </Upload>
              </div>
              :
              <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>
                {this.checkType(record)}
              </span>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            { this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateDynamicInfo()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteDynamicInfo(record.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    return (
      <div>
        {this.state.loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Modal
          width={400}
          closable
          destroyOnClose
          footer={null}
          visible={this.state.isModalVisible}
          onCancel={()=>{ this.setState({ previewImg: false }, () => this.setState({ isModalVisible: false })) }}
          className='preview-modal'
        >
          {this.state.previewImg && ( <img className='prev-img' src= {this.state.previewImg} alt={this.state.previewImg} />)}
        </Modal>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              },
              {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Enter Info</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24,
                        xl: 24,
                        xxl: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0,
                        xl: 0,
                        xxl: 0
                      },]} >
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='Station Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.station}
                            onChange={(e) => this.setState({ station: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='Country Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.contry_code}
                            onChange={(e) => this.setState({ contry_code: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='AC Type'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.ac_type}
                            onChange={(e) => this.setState({ ac_type: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} flex='auto' className=''>
                        <Form.Item label='Flight #'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.flightno}
                            onChange={(e) => this.setState({ flightno: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='ULD Type'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.uld_type}
                            onChange={(e) => this.setState({ uld_type: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='Prod Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.prod_code}
                            onChange={(e) => this.setState({ prod_code: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={3} className=''>
                        <Form.Item label='Special Codes'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.spl_code}
                            onChange={(e) => this.setState({ spl_code: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='Start Date'>
                          <DatePicker size='small' value={this.state.startdate} onChange={(d) => this.setState({ startdate: d })} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={4} xl={3} xxl={2} className=''>
                        <Form.Item label='End Date'>
                          <DatePicker size='small' value={this.state.enddate} onChange={(d) => this.setState({ enddate: d })} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16} lg={8} xl={6} xxl={3} className='mr-neg-12'>
                        <Form.Item label='Remarks'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.remarks}
                            onChange={(e) => this.setState({ remarks: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={'90px'} className='text-center px-0'>
                        <Form.Item label='Attachment' className='dynamic-upload text-center'>
                          <Tooltip title='Attachment'>
                            <Upload
                              accept='image/jpg,image/jpeg,image/png,application/pdf'
                              fileList={this.state.fileList}
                              maxCount={1} beforeUpload={() => false}
                              onChange={(e) => this.onChangeFileHandler(e)}
                              onRemove={() => this.onRemoveFileHandler()}>
                              <Button type='ghost' className={`image-btn ${this.state.attach_conf ? 'green-fliter' : ''}`} >
                                <img src={download} alt='download' />
                              </Button>
                            </Upload>
                          </Tooltip>
                        </Form.Item>
                      </Col>
                      <Col flex={'30px'} className='text-center ml-auto pl-0'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='image-btn' onClick={() => this.state.is_edit ? this.updateDynamicInfo() : this.addDynamicInfo()}>
                          <Tooltip title={this.state.is_edit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    {this.state.is_edit && (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn dynamic-btn-placement' onClick={() => this.clearForm()}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )}
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Current Info</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table dynamic-table'
                      dataSource={this.state.tableData}
                      columns={dynamiccolumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default DynamicInfo