import React, { Component } from 'react'
import { Button, Input, InputNumber, Row, Col, Typography, Form, Card, Table, Tooltip, Popconfirm, notification, Select } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import Cookies from 'universal-cookie'
import './tabs.css'
import Http from '../../../utils/http'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

const cookies = new Cookies()
const station = cookies.get('station')
const { Title } = Typography
const { Option } = Select

class RebookingTeamsSla extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      tableData: [],
      is_edit: false,
      id: null,
      rebook_team: '',
      q_priority: 'Standard',
      sla_time: null,
      duplicate: null,

      editRecord: null,
      editRebook_team: '',
      editQ_priority: '',
      editSla_time: null,
    }
  }
  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // eslint-disable-next-line no-console
      console.log('parsedData',parsedData)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'RECOVERY_TEAM'){
          this.fetchRecoveryTeam()
        }
      }
    }
    this.fetchRecoveryTeam()
  }

  addRecoveryTeam = async () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.rebooking_teams_sla
    }
    let formdata = {
      rebook_team: this.state.rebook_team,
      q_priority: this.state.q_priority,
      sla_time: this.state.sla_time,
      station: station || null,
    }
    await Http.post('/recovery_team', formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.clearForm()
          this.fetchRecoveryTeam()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  fetchRecoveryTeam = async () => {
    await Http.get('recovery_teams', { headers: { station: station } }).then(resp => {
      if (resp?.data && resp?.status == 200) {
        this.setState({ tableData: resp?.data?.data })
      }
    })
  }

  editRecoveryTeam = (t) => {
    this.setState({
      is_edit: true,
      id: t.id,
      rebook_team: t.details.rebook_team,
      q_priority: t.details.q_priority,
      sla_time: t.details.sla_time
    })
  }

  updateRecoveryTeam = async () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.rebooking_teams_sla
    }
    let formdata = {
      rebook_team: this.state.editRebook_team,
      q_priority: this.state.editQ_priority,
      sla_time: this.state.editSla_time,
      station: station || null,
    }
    await Http.put(`/recovery_team/${this.state?.editRecord.id}`, formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.clearTableForm()
          this.clearForm()
          this.fetchRecoveryTeam()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  deleteRecoveryTeam = async (id) => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.rebooking_teams_sla
    }
    await Http.put(`/recovery_team/${id}`, { is_delete: true }, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.fetchRecoveryTeam()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  clearForm = () =>{
    this.setState({
      is_edit: false,
      id: null,
      rebook_team: '',
      q_priority: 'Standard',
      sla_time: null,
    })
  }

  editTableForm = (record) => {
    this.setState({
      editRecord: record,
      editRebook_team: record.details.rebook_team,
      editQ_priority: record.details.q_priority,
      editSla_time: record.details.sla_time,
    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editRebook_team: '',
      editQ_priority: '',
      editSla_time: null,
    })
  }

  render() {
    const rebookingcolumns = [
      {
        title: 'Rebooking Team',
        key: 'rebookingteam',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editRebook_team}
              onChange={(e) => this.setState({ editRebook_team: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.rebook_team}</span> )
      },
      {
        title: 'Queue Priority',
        key: 'queuepriority',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select size='small' value={this.state.editQ_priority} onChange={(e) => this.setState({ editQ_priority: e })}>
              <Option value='Standard'>Standard</Option>
              <Option value='Urgent'>Urgent</Option>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.q_priority}</span> )
      },
      {
        title: 'SLA Time (Mins)',
        key: 'slatime',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              size='small'
              placeholder=''
              value={this.state.editSla_time}
              onChange={(e) => this.setState({ editSla_time: e })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.sla_time}</span> )
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            { this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateRecoveryTeam()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteRecoveryTeam(record.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} ><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]
    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card with-table'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Specify Rebooking Team</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6} className=''>
                        <Form.Item label='Name of Rebooking Team'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.rebook_team}
                            onChange={(e) => this.setState({ rebook_team: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6} className=''>
                        <Form.Item label='Queue Priority'>
                          <Select size='small' value={this.state.q_priority} onChange={(e) => this.setState({ q_priority: e })}>
                            <Option value='Standard'>Standard</Option>
                            <Option value='Urgent'>Urgent</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={6} flex='auto' xl={6} xxl={6} className=''>
                        <Form.Item label='SLA Time (Mins)'>
                          <InputNumber
                            size='small'
                            placeholder=''
                            value={this.state.sla_time}
                            onChange={(e) => this.setState({ sla_time: e })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='image-btn' onClick={() => this.state.is_edit? this.updateRecoveryTeam() : this.addRecoveryTeam()}>
                          <Tooltip  title={this.state.is_edit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    { this.state.is_edit && (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn rebooking-btn-placement' onClick={() => this.clearForm()}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )}
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>List of Rebooking Team</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table rebooking-table'
                      dataSource={this.state.tableData}
                      columns={rebookingcolumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default RebookingTeamsSla