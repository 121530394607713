import React, { Component } from 'react'
import { Button, Input, Row, Col, Typography, Select, Form, Card, Table, Checkbox, Tooltip, notification, Popconfirm, Upload, Spin, Modal  } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import download from '../../../assets/images/download.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import './tabs.css'
import Cookies from 'universal-cookie'
import Http from '../../../utils/http'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
const cookies = new Cookies()
const station = cookies.get('station')
const { Title } = Typography
const { Option } = Select
const { Search } = Input


class Fleet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isModalVisible: false,
      search: '',
      tableData: [],
      is_edit: false,
      id: null,
      ac_code: '',
      ac_type: '',
      is_uld: false,
      is_loose: false,
      pax_cap: null,
      cont_type: [],
      // alwd_uld_type: '',
      fwd_conf: false,
      aft_conf: false,
      fwd_name: null,
      aft_name: null,
      fwd_conf_data: [],
      aft_conf_data: [],
      gen_info: '',

      editRecord: null,
      editAc_code: '',
      editAc_type: '',
      editIs_uld: false,
      editIs_loose: false,
      editPax_cap: null,
      editCont_type: [],
      // editAlwd_uld_type: '',
      editFwd_conf: false,
      editAft_conf: false,
      editFwd_name: null,
      editAft_name: null,
      editFwd_conf_data: [],
      editAft_conf_data: [],
      editGen_info: '',
      previewImg: null,
    }
  }
  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData?.TYPE === 'FLEET'){
          this.fetchFleet()
        }
      }
    }
    this.fetchFleet()
  }
  addFleet = async () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.fleet
    }
    let formdata = new FormData()
    formdata.append('ac_code', this.state.ac_code)
    formdata.append('ac_type', this.state.ac_type)
    formdata.append('is_uld', this.state.is_uld)
    formdata.append('is_loose', this.state.is_loose)
    formdata.append('pax_cap', this.state.pax_cap)
    formdata.append('cont_type', this.state.cont_type.length > 0 ? this.state.cont_type.toString() : null)
    // formdata.append('alwd_uld_type', this.state.alwd_uld_type)
    formdata.append('fwd_conf', this.state.fwd_conf || null)
    formdata.append('aft_conf', this.state.aft_conf || null)
    formdata.append('gen_info', this.state.gen_info)
    formdata.append('station', station || null)
    this.setState({ loading: true })
    await Http.post('/flight', formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.clearForm()
          this.fetchFleet()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ duplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ duplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  fetchFleet = async () => {
    let headers = {
      search: this.state.search,
      // station: station
    }
    await Http.get('flights', { headers }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        this.setState({ tableData: resp?.data?.data })
      } else {
        this.setState({ tableData: [] }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        })
      }
    })
  }

  editFleet = (t) => {
    this.setState({
      ...t.details,
      cont_type: t.details.cont_type ? t.details.cont_type.split(',') : [],
      fwd_conf_data: t?.details?.fwd_name ? [{ name: t?.details?.fwd_name }] : [],
      aft_conf_data: t?.details?.aft_name ? [{ name: t?.details?.aft_name }] : [],
      id: t.id,
      is_edit: true
    })
  }

  updateFleet = async () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.fleet
    }
    let formdata = new FormData()
    formdata.append('ac_code', this.state.editAc_code)
    formdata.append('ac_type', this.state.editAc_type)
    formdata.append('is_uld', this.state.editIs_uld)
    formdata.append('is_loose', this.state.editIs_loose)
    formdata.append('pax_cap', this.state.editPax_cap)
    formdata.append('cont_type', this.state.editCont_type.length > 0 ? this.state.editCont_type.toString() : null)
    // formdata.append('alwd_uld_type', this.state.editAlwd_uld_type)
    formdata.append('fwd_conf', this.state.editFwd_conf || null)
    formdata.append('aft_conf', this.state.editAft_conf || null)
    formdata.append('fwd_name', this.state.editFwd_name || null)
    formdata.append('aft_name', this.state.editAft_name || null)
    formdata.append('gen_info', this.state.editGen_info)
    formdata.append('station', station || null)
    this.setState({ loading: true })
    await Http.put(`/flight/${this.state.editRecord.id}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.clearForm()
          this.clearTableForm()
          this.fetchFleet()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ duplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ duplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  onChangeFileHandler = (e, t) => {
    if (e?.fileList[0]) {
      if (t == 'fwd') {
        this.setState({
          fwd_conf: e?.fileList[0].originFileObj,
          fwd_conf_data: e?.fileList
        })
      } else {
        this.setState({
          aft_conf: e?.fileList[0].originFileObj,
          aft_conf_data: e?.fileList
        })
      }
    } else {
      if (t == 'fwd') {
        this.setState({
          fwd_conf: false,
          fwd_name: null,
          fwd_conf_data: []
        })
      } else {
        this.setState({
          aft_conf: false,
          aft_name: null,
          aft_conf_data: []
        })
      }
    }
  }

  onChangeEditFileHandler = (e, t) => {
    if (e?.fileList[0]) {
      if (t == 'fwd') {
        this.setState({
          editFwd_conf: e?.fileList[0].originFileObj,
          editFwd_conf_data: e?.fileList
        })
      } else {
        this.setState({
          editAft_conf: e?.fileList[0].originFileObj,
          editAft_conf_data: e?.fileList
        })
      }
    } else {
      if (t == 'fwd') {
        this.setState({
          editFwd_conf: false,
          editFwd_name: null,
          editFwd_conf_data: []
        })
      } else {
        this.setState({
          editAft_conf: false,
          editAft_name: null,
          editAft_conf_data: []
        })
      }
    }
  }

  onRemoveFileHandler = (t) => {
    if (t == 'fwd') {
      this.setState({
        fwd_conf: false,
        fwd_name: null,
        fwd_conf_data: []
      })
    } else {
      this.setState({
        aft_conf: false,
        aft_name: null,
        aft_conf_data: []
      })
    }
  }

  onRemoveEditFileHandler = (t) => {
    if (t == 'fwd') {
      this.setState({
        editFwd_conf: false,
        editFwd_name: null,
        editFwd_conf_data: []
      })
    } else {
      this.setState({
        editAft_conf: false,
        editAft_name: null,
        editAft_conf_data: []
      })
    }
  }

  looseHandler = () => {
    if (!this.state.is_loose && !this.state.is_uld) {
      this.setState({
        cont_type: [],
        // alwd_uld_type: '',
      })
    }
    this.setState({ is_loose: !this.state.is_loose })
  }

  editLooseHandler = () => {
    if (!this.state.editIs_loose && !this.state.editIs_uld) {
      this.setState({
        editCont_type: [],
        // editAlwd_uld_type: '',
      })
    }
    this.setState({ editIs_loose: !this.state.editIs_loose })
  }

  showPreview = (record, key) => {
    this.setState({
      isModalVisible: true,
      previewImg: record.details[key].path
    })
  }

  editTableForm = (record) => {
    this.setState({
      editRecord: record,
      editAc_code: record.details.ac_code,
      editAc_type: record.details.ac_type,
      editIs_uld: record.details.is_uld,
      editIs_loose: record.details.is_loose,
      editPax_cap: record.details.pax_cap,
      editCont_type: record.details.cont_type ? record.details.cont_type.split(',') : [],
      // editAlwd_uld_type: record.details.alwd_uld_type,
      editFwd_conf: record.details.fwd_conf? JSON.stringify(record.details.fwd_conf) : null,
      editAft_conf: record.details.aft_conf? JSON.stringify(record.details.aft_conf) : null,
      editFwd_conf_data: record.details?.fwd_conf?.name ? [{ name: record.details?.fwd_conf?.name }] : [],
      editAft_conf_data: record.details?.aft_conf?.name ? [{ name: record.details?.aft_conf?.name }] : [],
      editGen_info: record.details.gen_info,
    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editAc_code: '',
      editAc_type: '',
      editIs_uld: false,
      editIs_loose: false,
      editPax_cap: null,
      editCont_type: [],
      // editAlwd_uld_type: '',
      editFwd_conf: false,
      editAft_conf: false,
      editFwd_name: null,
      editAft_name: null,
      editFwd_conf_data: [],
      editAft_conf_data: [],
      editGen_info: '',
    })
  }

  clearForm = () => {
    this.setState({
      is_edit: false,
      id: null,
      ac_code: '',
      ac_type: '',
      is_uld: false,
      is_loose: false,
      pax_cap: null,
      cont_type: [],
      // alwd_uld_type: '',
      fwd_conf: false,
      fwd_conf_data: [],
      aft_conf_data: [],
      aft_conf: false,
      gen_info: '',
      duplicate: null,
    })
  }

  deleteFleet = async (id) => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.fleet
    }
    await Http.put(`/flight/${id}`, { is_delete: true }, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.clearForm()
          this.fetchFleet()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }

  setSearchValue (e){
    this.setState({ search: e.target.value },()=>{
      if( !e.target.value || e.target.value === null || e.target.value ==='' ){
        this.fetchFleet()
      }
    })
  }

  render() {
    const columns = [
      {
        title: 'AC Code',
        key: 'accode',
        width: '10%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editAc_code}
              onChange={(e) => this.setState({ editAc_code: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.ac_code}</span>)
      },
      {
        title: 'Cargo Structure',
        key: 'cargostructure',
        // width:'150px',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <span className='radio-nowrap'>
              <Checkbox value='is_uld' checked={this.state.editIs_uld} onClick={() => this.setState({ editIs_uld: !this.state.editIs_uld })} >ULD</Checkbox>
              <Checkbox value='is_loose' checked={this.state.editIs_loose} onClick={() => this.editLooseHandler()} >Loose</Checkbox>
            </span>
            :
            <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.is_uld ? `ULD${record?.details?.is_loose ? ',' : ''} ` : ''}{record?.details?.is_loose ? 'Loose' : ''}</span>
        )
      },
      {
        title: 'AC Type',
        key: 'ac_type',
        width: '10%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select size='small' placeholder='Select AC type' className='w-100' value={this.state.editAc_type} onChange={(e) => this.setState({ editAc_type: e })} >
              <Option value='WB'>WB</Option>
              <Option value='NB'>NB</Option>
              <Option value='TRK'>TRK</Option>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.ac_type}</span>
        )
      },
      {
        title: 'Pax Cap',
        key: 'paxcap',
        width: '10%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editPax_cap}
              onChange={(e) => this.setState({ editPax_cap: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.pax_cap}</span>
        )
      },
      {
        title: 'Optimal Container Type',
        key: 'conttype',
        width: '10%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select size='small' placeholder='Select Cont. type' className='w-100' mode='multiple' disabled={this.state.editIs_loose ? (this.state.editIs_uld ? false : true) : false} value={this.state.editCont_type} onChange={(e) => this.setState({ editCont_type: e })} >
              <Option value='LD3'>LD3</Option>
              <Option value='LD2'>LD2</Option>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.is_loose && !record?.details?.is_uld ? 'NA' : record?.details?.cont_type?.split(',')?.join(', ')}</span>
        )
      },
      // {
      //   title: 'Allowed ULD Types',
      //   key: 'alloweduld',
      //   className:'min-width-120',
      //   render: (_, record) => (
      //     this.state.editRecord && this.state.editRecord.id === record.id ?
      //       <Input
      //         disabled={this.state.editIs_loose ? (this.state.editIs_uld ? false : true) : false}
      //         size='small'
      //         placeholder=''
      //         value={this.state.editAlwd_uld_type}
      //         onChange={(e) => this.setState({ editAlwd_uld_type: e.target.value.toUpperCase() })}
      //       />
      //       :
      //       <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.is_loose && !record?.details?.is_uld ? 'NA' : record?.details?.alwd_uld_type?.split(',')?.join(', ')}</span>
      //   )
      // },
      {
        title: 'Aft Hold Config',
        key: 'afthold',
        dataIndex: 'details',
        // width: '150px',
        className:'fleet-tableupload',
        align: 'center',
        render: (_, record) => {
          return (
            this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='scaletbl-img'>
                <Upload fileList={this.state.editAft_conf_data} maxCount={1} beforeUpload={() => false} onChange={(e) => this.onChangeEditFileHandler(e, 'aft')} onRemove={() => this.onRemoveEditFileHandler('aft')}>
                  <Button type='ghost' className={`image-btn ${this.state.editAft_conf ? 'green-fliter' : ''}`} >
                    <img src={download} alt='download' />
                  </Button>
                </Upload>
              </div>
              :
              <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>
                <Tooltip title='Preview' onClick={() => this.showPreview(record,'aft_conf' ) }>{record?.details?.aft_conf && (<CheckOutlined />)}</Tooltip>
                {!record?.details?.aft_conf && (<CloseOutlined />)}
              </span>
          )
        }
      },
      {
        title: 'Fwd Hold Config',
        key: 'fwd hold',
        dataIndex: 'details',
        // width: '150px',
        className:'fleet-tableupload',
        align: 'center',
        render: (_, record) => {
          return (
            this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='scaletbl-img'>
                <Upload className='scaletbl-img' fileList={this.state.editFwd_conf_data} maxCount={1} beforeUpload={() => false} onChange={(e) => this.onChangeEditFileHandler(e, 'fwd')} onRemove={() => this.onRemoveEditFileHandler('fwd')}>
                  <Button type='ghost' className={`image-btn ${this.state.editFwd_conf ? 'green-fliter' : ''}`} >
                    <img src={download} alt='download' />
                  </Button>
                </Upload>
              </div>
              :
              <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>
                <Tooltip title='Preview' onClick={() => this.showPreview(record,'fwd_conf' ) }>{record?.details?.fwd_conf && (<CheckOutlined />)}</Tooltip>
                {!record?.details?.fwd_conf && (<CloseOutlined />)}
              </span>
          )
        }
      },
      {
        title: 'Gen Info',
        key: 'geninfo',
        render: (_, record) =>(
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editGen_info}
              onChange={(e) => this.setState({ editGen_info: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.details?.gen_info}</span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) =>
          <div>
            {this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.updateFleet()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteFleet(record.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },

    ]

    return (
      <div>
        <Modal
          width={400}
          closable
          //maskClosable= {false}
          destroyOnClose
          footer={null}
          visible={this.state.isModalVisible}
          onCancel={()=>{ this.setState({ previewImg: false }, () => this.setState({ isModalVisible: false })) }}
          className='preview-modal'
        >
          {this.state.previewImg && ( <img className='prev-img' src= {this.state.previewImg} alt={this.state.previewImg} />)}
        </Modal>
        {this.state.loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              },
              {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Add Aircraft</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative fleet'
                  >
                    <Row gutter={[{
                      xs: 0,
                      sm: 0,
                      md: 24,
                      lg: 24,
                      xl: 24,
                      xxl: 24
                    },
                    {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 0,
                      xl: 0,
                      xxl: 0
                    },]} >
                      <Col xs={24} sm={24} md={12} lg={6} xl={3} className=''>
                        <Form.Item label='AC Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.ac_code}
                            onChange={(e) => this.setState({ ac_code: e.target.value })}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={5} xl={3} className=''>
                        <label className='custom-label label-border'>Cargo Structure</label>
                        <>
                          <Checkbox value='is_uld' checked={this.state.is_uld} onClick={() => this.setState({ is_uld: !this.state.is_uld })} >ULD</Checkbox>
                          <Checkbox value='is_loose' checked={this.state.is_loose} onClick={() => this.looseHandler()} >Loose</Checkbox>
                        </>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={3} className=''>
                        <Form.Item label='AC Type'>
                          <Select size='small' placeholder='Select AC type' value={this.state.ac_type || null} onChange={(e) => this.setState({ ac_type: e })} >
                            <Option value='WB'>WB</Option>
                            <Option value='NB'>NB</Option>
                            <Option value='TRK'>TRK</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={7} xl={3} className=''>
                        <Form.Item label='Pax Cap'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.pax_cap}
                            onChange={(e) => this.setState({ pax_cap: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={3} className=''>
                        <Form.Item label='Optimal Container Type'>
                          <Select size='small' placeholder='Select Cont. type' mode='multiple' disabled={this.state.is_loose ? (this.state.is_uld ? false : true) : false} value={this.state.cont_type} onChange={(e) => this.setState({ cont_type: e })} >
                            <Option value='LD3'>LD3</Option>
                            <Option value='LD2'>LD2</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* <Col xs={24} sm={24} md={12} lg={12} flex='auto' xxl={4} className=''>
                        <Form.Item label='Allowed ULDs (Comma Separated)'>
                          <Input
                            disabled={this.state.is_loose ? (this.state.is_uld ? false : true) : false}
                            size='small'
                            placeholder=''
                            value={this.state.alwd_uld_type}
                            onChange={(e) => this.setState({ alwd_uld_type: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col xs={12} sm={12} md={6} lg={3} xl={2} className='text-center min-width-120'>
                        <label className='custom-label'>Aft Hold Config</label>
                        <Tooltip title='Upload Image'>
                          <Upload fileList={this.state.aft_conf_data} maxCount={1} beforeUpload={() => false} onChange={(e) => this.onChangeFileHandler(e, 'aft')} onRemove={() => this.onRemoveFileHandler('aft')}>
                            <Button type='ghost' className={`image-btn ${this.state.aft_conf ? 'green-fliter' : ''}`} >
                              <img src={download} alt='download' />
                            </Button>
                          </Upload>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={3} xl={2} className='text-center min-width-120'>
                        <label className='custom-label'>Fwd Hold Config</label>
                        <Tooltip title='Upload Image'>
                          <Upload fileList={this.state.fwd_conf_data} maxCount={1} beforeUpload={() => false} onChange={(e) => this.onChangeFileHandler(e, 'fwd')} onRemove={() => this.onRemoveFileHandler('fwd')}>
                            <Button type='ghost' className={`image-btn ${this.state.fwd_conf ? 'green-fliter' : ''}`} >
                              <img src={download} alt='download' />
                            </Button>
                          </Upload>
                        </Tooltip>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={4} className=''>
                        <Form.Item label='Gen Info'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.gen_info}
                            onChange={(e) => this.setState({ gen_info: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='image-btn' onClick={() => this.state.is_edit ? this.updateFleet() : this.addFleet()}>
                          <Tooltip title={this.state.is_edit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    {this.state.is_edit && (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn fleet-btn-placement' onClick={() => this.clearForm()}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )}
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                  <Title level={5} className='mb-0'>Fleet</Title>
                  <Search allowClear className='search-input' placeholder='Search'
                    onChange={(e) => this.setSearchValue(e)}
                    onSearch={(e) => !e || e === null || e ==='' ? null : this.fetchFleet()}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table fleet-table'
                      dataSource={this.state.tableData}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Fleet