import React, { Component } from 'react'
import { Form, Input, Button, Row, Col, Typography, Card, notification } from 'antd'
import Locked from '../../assets/images/locked.svg'
import './index.css'
//import Http from '../../utils/http'
import {
  COOKIE,
  deleteCookie,
  getCookie
} from '../../utils/cookie'
import Http from '../../utils/http'

const { Title } = Typography

class ChangePassword extends Component {
  state = {
    oldpass: '',
    newpass: '',
    confirmpass: '',
  }

  ChangedPassword = async () => {
    if(this.state.oldpass && this.state.newpass && (this.state.newpass !== this.state.confirmpass)){
      notification.destroy()
      notification.error({
        message: 'Error',
        description: 'New password and confirm password does not match!',
      })
    } else {
      let formdata= {
        username: getCookie(COOKIE.USERNAME),
        oldpassword: this.state.oldpass,
        newpassword: this.state.newpass,
      }
      Http.post('/change_password', formdata)
        .then((data) => {
          if(data && data.status === 200){
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
            deleteCookie(COOKIE.OTP)
            deleteCookie(COOKIE.STATION)
            deleteCookie(COOKIE.ROLE)
            deleteCookie(COOKIE.JOB_TITLE)
            deleteCookie(COOKIE.NAME)
            deleteCookie(COOKIE.USERNAME)
            deleteCookie(COOKIE.WEIGHT)
            deleteCookie(COOKIE.ID)
            deleteCookie(COOKIE.PPR)
            window.location.href = '/login'

          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  render() {
    return (
      <div>
        <Row gutter={[{
          xs: 12,
          sm: 12,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} className='change-password'>
          <Col
            sm={{
              span: 22,
              offset: 1
            }}
            md={{
              span: 16,
              offset: 4
            }}
            lg={{
              span: 12,
              offset: 6
            }}
            xl={{
              span: 10,
              offset: 7
            }}
            xxl={{
              span: 8,
              offset: 8
            }}
            className=''>
            <Card className='custom-card px-4 py-2'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              },
              {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className=''>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Change Password</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='p-12'>
                  <Form.Item
                    label='Old Password'
                    name='oldpassword'
                    rules={[{
                      required: true,
                      message: 'Enter old password!'
                    }]}
                  >
                    <Input.Password
                      prefix={<img src={Locked} />}
                      value={this.state.oldpass}
                      onChange={(e) => this.setState({ oldpass: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label='New Password'
                    name='newpassword'
                    rules={[{
                      required: true,
                      message: 'Enter new password!'
                    }]}
                  >
                    <Input.Password
                      prefix={<img src={Locked} />}
                      value={this.state.newpass}
                      onChange={(e) => this.setState({ newpass: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Confirm New Password'
                    name='confnewpassword'
                    rules={[{
                      required: true,
                      message: 'Confirm new password!'
                    }]}
                  >
                    <Input.Password
                      prefix={<img src={Locked} />}
                      value={this.state.confirmpass}
                      onChange={(e) => this.setState({ confirmpass: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item className='mb-0'
                  >
                    <Button
                      type='primary'
                      className='w-100 submit-btn custom-button'
                      onClick={() => this.ChangedPassword() }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
export default ChangePassword
