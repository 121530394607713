
import { notification } from 'antd'
import Http from '../utils/http'
import { COOKIE, createCookie, deleteCookie } from '../utils/cookie'
import moment from 'moment-timezone'

export async function fetchRedisDataCommon (component)  {
  let redis_url = '/parser/queues/api/queues?activeQueue=ECIBOOK'
  Promise.all([
    Http.get(redis_url, { headers: '' }),
  ]).then(([redis_data]) => {
    let edata = redis_data?.data?.queues || null
    let rediscount = 0 
    let redismaxcount = component.state.redismaxcount
    if(edata && edata.length>0){
      edata.map(async (item) => {
        rediscount = rediscount + parseInt(item.counts.active)
        rediscount = rediscount + parseInt(item.counts.delayed)
        rediscount = rediscount + parseInt(item.counts.waiting)
      })
      if(redismaxcount < rediscount || rediscount === 0 ){
        redismaxcount = rediscount
      }
      component.setState({ 
        redismaxcount: redismaxcount,
        rediscount: rediscount,
        percentage: getpercent(rediscount,redismaxcount),
      })
    }
  })
}

export async function resetTablesCommon (component) {
  component.forceUpdate()  // TO RERENDER
  deleteCookie(COOKIE.RESET_TIME)
  deleteCookie(COOKIE.DEMO_BUTTONS)
  deleteCookie('ssim')
  deleteCookie('inbound_start')
  deleteCookie('inbound_booking')
  deleteCookie('inbound_flight_events')
  deleteCookie('inbound_flight_completed')

  deleteCookie('inbound_checkin')
  deleteCookie('inbound_move_bd')
  deleteCookie('inbound_dlv')

  deleteCookie('breakdown_start')
  deleteCookie('breakdown_nfd')
  deleteCookie('breakdown')
  deleteCookie('breakdown_move_bu')

  deleteCookie('buildup_start')
  deleteCookie('buildup_build_uld')
  deleteCookie('buildup_build_cart')
  deleteCookie('buildup_asgn_flt')
  deleteCookie('buildup_pre')

  deleteCookie('haulout_start')
  deleteCookie('haulout_gt_mismatch')
  deleteCookie('haulout_depart')
    
  let reset_time = moment().tz(component.state.timezone).format('YYYY-MM-DD HH:mm') //moment()
  createCookie(COOKIE.RESET_TIME, reset_time, null,'/')

  component.setState({ 
    reset_time: reset_time,
    demo_buttons: null
  },()=>{
    Promise.all([
      Http.get('/simulator/_resetTables', { headers: '' }),
      Http.get('/simulator/_resetFlightTables', { headers: '' }),
      //Http.get('/simulator/_resetMessageLogs', { headers: '' }),
    ]).then((/*response*/) => {
      // if(response){
      //   notification.destroy()
      //   notification.success({
      //     message: 'Success',
      //     description: 'Table Data Cleared',
      //   })
      // }
      Http.get('/simulator/_resetMessageLogs', { headers: '' })
        .then((response) => {
          if(response){
            notification.destroy()
            notification.success({
              message: 'Success',
              description: 'Table Data Cleared',
            })
          }
          get_demo_msg_common('ssim', '', component)
        })
      // createCookie('ssim', true, null,'/')
    })
  })
}

export async function get_demo_msg_common (scenerio, button, component) {
  switch(`${scenerio}-${button}`) {
  case 'ssim-':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('ssim', true, null,'/')
      window.location.reload()
    }, 500)
    component.handlePercent1(50)
    break
  case 'Inbound-START':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_start', true, null,'/')
    }, 45000)
    component.handlePercent2(4500)
    break
  case 'Inbound-BOOKING':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_booking', true, null,'/')
    }, 500)
    component.handlePercent3(50)    
    break
  case 'Inbound-FLIGHT EVENT':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_flight_events', true, null,'/')
    }, 500)
    component.handlePercent4(50)
    break
  case 'Inbound-FLIGHT COMPLETED':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_flight_completed', true, null,'/')
    }, 500)
    component.handlePercent5(50)
    break
            
  case 'Inbound-CHECKIN':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_checkin', true, null,'/')
    }, 500)
    component.handlePercent6(50)
    break
  case 'Inbound-DLV':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_dlv', true, null,'/')
    }, 500)
    component.handlePercent7(50)
    break
  case 'Inbound-MOVE BD':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('inbound_move_bd', true, null,'/')
    }, 500)
    component.handlePercent8(50)
    break                
  case 'breakdown-START':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('breakdown_start', true, null,'/')
    }, 10000)
    component.handlePercent9(1000)
    break
  case 'breakdown-NFD':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('breakdown_nfd', true, null,'/')
    }, 500)
    component.handlePercent10(50)
    break
  case 'breakdown-BREARKDOWN':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('breakdown', true, null,'/')
    }, 500)
    component.handlePercent11(50)
    break
  case 'breakdown-MOVE BU':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('breakdown_move_bu', true, null,'/')
    }, 500)
    component.handlePercent12(50)
    break
                        
  case 'buildup-START':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('buildup_start', true, null,'/')
    }, 500)
    component.handlePercent13(50)
    break
  case 'buildup-BUILD ULD':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('buildup_build_uld', true, null,'/')
    }, 500)
    component.handlePercent14(50)
    break
  case 'buildup-BUILD CART':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('buildup_build_cart', true, null,'/')
    }, 500)
    component.handlePercent15(50)
    break
  case 'buildup-ASSIGNED FLT':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('buildup_asgn_flt', true, null,'/')
    }, 500)
    component.handlePercent16(50)
    break
  case 'buildup-PRE':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('buildup_pre', true, null,'/')
    }, 500)
    component.handlePercent17(50)
    break                               
  case 'haulout-START':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('haulout_start', true, null,'/')
    }, 10000)
    component.handlePercent18(1000)
    break
  case 'haulout-GATE MISMATCH':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('haulout_gt_mismatch', true, null,'/')
    }, 500)
    component.handlePercent19(50)
    break
  case 'haulout-DEPART':
    component.forceUpdate() // TO RERENDER
    setTimeout(function(){
      createCookie('haulout_depart', true, null,'/')
    }, 500)
    component.handlePercent20(50)
    break
  default:
    break
  }
  let url = 'demo_msg'
  url = `${url}?scenerio=${scenerio}&button=${button}`
  let headers = {}
  let ecibooks = []
  let manifests = []
  let fsus = []
  let flight_events = []
  let ffms = []
  let ssim = []
  await Http.get( url, { headers }).then(resp => {
    if(resp?.data?.data && resp?.data?.data.length > 0){
      resp?.data?.data.map(async (item) => {
        if(item.msg_type === 'ECIBOOK' ){
          ecibooks.push(item)
        } else if(item.msg_type === 'MANIFEST' ){
          manifests.push(item)
        } else if(item.msg_type === 'FLIGHT EVENT' ){
          flight_events.push(item)
        } else if ( item.msg_type === 'FSU') {
          fsus.push(item)
        } else if ( item.msg_type === 'FFM') {
          ffms.push(item)
        } else {
          item.flight_date = moment.tz(component.state.reset_time).add(item.time,'minutes').format('YYYY-MM-DD')
          item.arriv_date = moment.tz(component.state.reset_time).add(item.time,'minutes').format('YYYY-MM-DD HH:mm')
          //item.dept_date =  moment.tz(component.state.reset_time).add(item.itime,'minutes').format('YYYY-MM-DD')
          item.dest.scheduled_arriv_time = moment.tz(component.state.reset_time).add(item.time,'minutes').format('HHmm')
          ssim.push(item)
        }
      })
      component.setState({
        ecibooks: ecibooks,
        manifests: manifests,
        flight_events: flight_events,
        fsus: fsus,
        ffms: ffms,
        ssim: ssim,
      },()=>{
        if(scenerio === 'ssim'){
          process_SSIM_Common(component)
        }
        if(scenerio === 'Inbound'){
          process_Inbound_Start_Common(component)
          // if(button === 'START'){
          //   component.process_Inbound_Start()
          // } else if(button === 'BOOKING'){
          //   component.process_Inbound_Start()
          // } else if(button === 'FLIGHT EVENT'){
          //   component.process_Inbound_Start()
          // }
        } else if(scenerio === 'breakdown' ){
          process_Inbound_Start_Common(component)
        } else if(scenerio === 'buildup' ){
          process_Inbound_Start_Common(component)
        } else if(scenerio === 'haulout'){
          process_Inbound_Start_Common(component)
        }
      })
    }
  })

}

export async function process_SSIM_Common(component) {
  let formdata = { data: component.state.ssim }
  // eslint-disable-next-line no-console
  console.log(formdata, 'formdata')
  await Http.post('/runSSIMBulk', formdata).then((response) => {
    if(response && response.status === 200){
      component.setState({ ssim: [] })
      notification.destroy()
      notification.success({
        message: 'Success',
        description: 'sent',
      })
    }
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
  }) 
}
export async function process_Inbound_Start_Common(component) {
  let ecibooks = component.state.ecibooks
  let manifests = component.state.manifests
  let flight_events = component.state.flight_events
  let fsus = component.state.fsus
  let ffms = component.state.ffms
  let send_ecibooks = []
  let send_manifests = []
  let send_flight_events = []
  let send_fsus = []
  let send_ffms = []
  //ECIBOOK
  if(ecibooks && ecibooks.length>0){
    ecibooks.map(async (item, i) => {
      if(item.bkginfo?.segs?.seg && item.bkginfo?.segs?.seg.length > 0){
        let segarr = item.bkginfo.segs.seg
        segarr.map((item2, j) => {
          ecibooks[i].bkginfo.segs.seg[j].fltinfo.dep.time = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').format('HHmm')
          ecibooks[i].bkginfo.segs.seg[j].fltinfo.date = moment.tz(component.state.reset_time).add(item.time,'minutes').format('DDMMMYY')
          ecibooks[i].bkginfo.segs.seg[j].fltinfo.arr.time = moment.tz(component.state.reset_time, component.state.timezone).add(item.itime,'minutes').format('HHmm')
          ecibooks[i].bkginfo.segs.seg[j].fltinfo.arr.date = moment.tz(component.state.reset_time).add(item.itime,'minutes').format('DDMMMYY')
        })
      }
    })
    send_ecibooks = ecibooks
  }
  //MANIFEST
  if(manifests && manifests.length>0){
    manifests.map(async (item, i) => {
      let flight = item.businessHeaderDocument?.id?.value?.split('/')
      flight[1]= moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').format('YYYYMMDD')
      manifests[i].arrivalEvent.arrivalOccurrenceDateTime = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').subtract('4','hours').valueOf()
      //manifests[i].logisticsTransportMovement.departureEvent.departureOccurrenceDateTime = moment.tz(component.state.reset_time).subtract(4,'hours').add(item.time,'minutes').valueOf().valueOf()
      manifests[i].businessHeaderDocument.id.value = flight.join('/')
    })
    send_manifests = manifests
  }
  //FLIGHT EVENTS
  if(flight_events && flight_events.length>0){
    flight_events.map(async (item,i) => {
      if(item.c){
        flight_events[i].flightLegOrFlight.schdArrGmtTs = moment.tz(component.state.reset_time).add(item.s,'minutes').valueOf()
        flight_events[i].flightLegOrFlight.estdArrGmtTs = moment.tz(moment().tz(component.state.timezone).format('YYYY-MM-DD HH:mm')).add(item.e,'minutes').valueOf()
        flight_events[i].flightLegOrFlight.fltOrigDt = moment.tz(moment().tz(component.state.timezone).format('YYYY-MM-DD HH:mm')).add(item.e,'minutes').valueOf()
        flight_events[i].flightLegOrFlight.actlInGmtTs = moment.tz(moment().tz(component.state.timezone).format('YYYY-MM-DD HH:mm')).add(item.a,'minutes').valueOf()
      }
      else if(item.time){
        flight_events[i].flightLegOrFlight.schdDprtGmtTs = moment.tz(component.state.reset_time).add(item.time,'minutes').valueOf()
        flight_events[i].flightLegOrFlight.fltOrigDt = moment.tz(component.state.reset_time).add(item.time,'minutes').valueOf()
        flight_events[i].flightLegOrFlight.estdDprtGmtTs = null
        flight_events[i].flightLegOrFlight.actlOutGmtTs = null
      } else {
        if(item.s){
          //flight_events[i].flightLegOrFlight.schdDprtGmtTs = moment.tz(component.state.reset_time).add(item.s,'minutes').valueOf()
          flight_events[i].flightLegOrFlight.schdArrGmtTs = moment.tz(component.state.reset_time).add(item.s,'minutes').valueOf()
        }
        if(item.e){
          //flight_events[i].flightLegOrFlight.estdDprtGmtTs = moment.tz(component.state.reset_time).add(item.e,'minutes').valueOf()
          flight_events[i].flightLegOrFlight.estdArrGmtTs = moment.tz(component.state.reset_time).add(item.e,'minutes').valueOf()
        }
        if(item.a){
          //flight_events[i].flightLegOrFlight.actlOutGmtTs = moment.tz(component.state.reset_time).add(item.a,'minutes').valueOf()
          flight_events[i].flightLegOrFlight.actlInGmtTs = moment.tz(component.state.reset_time).add(item.a,'minutes').valueOf()
        }
        if(item.s){
          flight_events[i].flightLegOrFlight.fltOrigDt = moment.tz(component.state.reset_time).add(item.s,'minutes').valueOf()
        } else if(item.e){
          flight_events[i].flightLegOrFlight.fltOrigDt = moment.tz(component.state.reset_time).add(item.e,'minutes').valueOf()
        } 
      }
      // else if(item.a){
      //   flight_events[i].flightLegOrFlight.fltOrigDt = moment.tz(component.state.reset_time).add(item.a,'minutes').valueOf()
      // }
    })
    send_flight_events = flight_events
  }
  //FSU
  if(fsus && fsus.length>0){
    fsus.map(async (item, i) => {
      if(fsus[i].sub_type === 'RCF'){
        fsus[i].masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].scheduledArrivalEvent.scheduledOccurrenceDateTime = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').valueOf()
      } else if( fsus[i].sub_type === 'MAN'){
        fsus[i].masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].arrivalEvent.arrivalOccurrenceDateTime = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').valueOf()
        fsus[i].masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].departureEvent.departureOccurrenceDateTime = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').valueOf()
      } else if(fsus[i].sub_type === 'PRE'){
        fsus[i].masterConsignment.reportedStatus[0].associatedStatusConsignment[0].specifiedLogisticsTransportMovement[0].departureEvent.departureOccurrenceDateTime = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').valueOf()
      }
    })
    send_fsus = fsus
  }
  if(ffms && ffms.length>0 ){
    ffms.map(async (item, i) => {
      let flight = item.businessHeaderDocument?.id?.value?.split('/')
      flight[1]= moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').format('YYYYMMDD')
      ffms[i].logisticsTransportMovement.departureEvent.departureOccurrenceDateTime = moment.tz(component.state.reset_time, component.state.timezone).add(item.time,'minutes').subtract('4','hours').valueOf()
      ffms[i].businessHeaderDocument.id.value = flight.join('/')
    })
    send_ffms = ffms
  }
  component.setState({
    send_ecibooks: send_ecibooks,
    send_manifests: send_manifests, 
    send_flight_events:send_flight_events,
    send_fsus: send_fsus,
    send_ffms: send_ffms,
  },()=>{
    push_msg_to_queue_common(component)
  })
}
export async function sendBulkMsgCommon(parsedData)  {

  let formdata = { data: parsedData }
  await Http.post('/runDemoBulk', formdata).then((response) => {
    if(response && response.status === 200){
      // eslint-disable-next-line no-console
      console.log('response',response)
      // notification.destroy()
      // notification.success({
      //   message: 'Success',
      //   description: 'sent',
      // })
    }
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
  })
}

/**************************** Queues *********************************** */

export function push_msg_to_queue_common (component) {
  let { send_ecibooks, send_manifests, send_flight_events, send_fsus, send_ffms }= component.state
  let count = 0 

  if(send_ecibooks && send_ecibooks.length>0){
    const myFakeAsyncJob = getFakeAsyncJobCommon(send_ecibooks, 0 )
    enqueueCommon(myFakeAsyncJob, component)
    count++
  }
  if(send_manifests && send_manifests.length>0){
    if(count > 0){
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_manifests, 30000 )
      enqueueCommon(myFakeAsyncJob, component)
    } else {
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_manifests, 0 )
      enqueueCommon(myFakeAsyncJob, component)
    }
    count++
  }
  if(send_flight_events && send_flight_events.length>0){
    if(count > 0){
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_flight_events, 15000 )
      enqueueCommon(myFakeAsyncJob, component)
    } else {
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_flight_events, 0 )
      enqueueCommon(myFakeAsyncJob, component)
    }
    count++
  }
  if(send_fsus && send_fsus.length>0){
    if(count > 0){
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_fsus, 10000 )
      enqueueCommon(myFakeAsyncJob, component)
    } else {
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_fsus, 0 )
      enqueueCommon(myFakeAsyncJob, component)
    }
    count++
  }
  if(send_ffms && send_ffms.length>0){
    if(count > 0){
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_ffms, 10000 )
      enqueueCommon(myFakeAsyncJob, component)
    } else {
      const myFakeAsyncJob = getFakeAsyncJobCommon(send_ffms, 0 )
      enqueueCommon(myFakeAsyncJob, component)
    }
    count++
  }

  component.setState({
    ecibooks: [],
    manifests: [],
    flight_events: [],
    fsus: [],
    ffms: [],

    send_ecibooks: [],
    send_manifests: [],
    send_flight_events: [],
    send_fsus: [],
    send_ffms: [],
  })
}

export function enqueueCommon (job, component) {
  //console.log('Enqueing', job)
  // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
  new Promise((resolve, reject) => {
    component._queue.push({
      job,
      resolve,
      reject
    })
  })
  // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
  nextJobCommon(component)
}
export function nextJobCommon  (component) {
  if(component._queue && component._queue.length ===0 ){
    notification.destroy()
    notification.success({
      message: 'Success',
      description: 'sent',
    })
  }
  if (component._isBusy) return
  const next = component._queue.shift()
  //console.log('next ',next)
  // if the array is empty shift() will return undefined
  if (next) {
    //component.setState({ spinnerForEvents: true })
    component._isBusy = true
    next
      .job()
      .then((value) => {
        sendBulkMsgCommon(value)
        next.resolve(value)
        component._isBusy = false
        nextJobCommon(component)
      })
      .catch((error) => {
        //component.setState({ spinnerForEvents: false })
        next.reject(error)
        component._isBusy = false
        nextJobCommon(component)
      })
  } 
  // else {
  //   component.setState({ spinnerForEvents: false })
  // }
}
export function getFakeAsyncJobCommon (item, delay)  {
  return () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(item)
      }, delay)
    })
}

export function getpercent  (val, max) {
  if( max === 0 ){
    return 100
  } else {
    return Math.round(((max - val) / max) * 100 )
  }
}
