import React, { Component } from 'react'
import { Row, Col, Button, Modal, Typography, Form, Card, Table, Badge, Select, DatePicker, TimePicker, Checkbox, Space, Tooltip, notification, Spin, Statistic, Popover, Tag, Input, Popconfirm, /*Switch*/ } from 'antd'
import { InfoCircleFilled, CloseCircleFilled, CaretRightOutlined, CaretLeftOutlined, SearchOutlined, CheckCircleTwoTone, CaretDownOutlined, LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
import { weightCalculator, formatWeight, getFlightDateTime, calc, format_gate } from '../../utils/common'
import moment, { isMoment } from 'moment'
import documentIcon from '../../assets/images/document.svg'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/delete.svg'
import Update from '../../assets/images/input-tick.svg'
import thunderboltIcon from '../../assets/images/thunderbolttwo.svg'
import planeLightIcon from '../../assets/images/plane-icon-light.svg'
import planeDarkIcon from '../../assets/images/plane-icon-dark.svg'
import AftHold from '../../assets/images/conf-1.png'
import ForwardHold from '../../assets/images/conf-2.png'
import cssconstants from '../../constants/cssconstants'
import Cookies from 'universal-cookie'
import { pages as PAGE, constants, timezone } from '../../constants/constants'
import Http from '../../utils/http'
import Notes from '../notes/notes'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { cloneDeep } from 'lodash'
import HeaderSearch from '../search'
import './index.css'
import '../breakdowncontrol/index.css'
import { COOKIE, createCookie, getCookie, pageHandler } from '../../utils/cookie'

const { Text } = Typography
const { Option } = Select
const { Column, ColumnGroup } = Table
const { Countdown } = Statistic
const timeFormat = 'HH:mm:ss'
const hourFormat = 'HH:mm'
const dateFormat = 'YYYY-MM-DD'
const dateTimeFormat = 'DDMMMYY HH:mm'
const dateSFormat = 'DDMMMYY'
const country = ['USA', 'U.S. VIRGIN ISLANDS', 'PUERTO RICO']

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const legendPopover = (
  <div className='width-250 fs-10'>
    <Row gutter={[8,8]}>
      <Col span={7}>AS</Col>
      <Col span={17}>Cargo checked in at station</Col>

      <Col span={7}><img src={planeDarkIcon} width='12' height='12' alt='plane' /></Col>
      <Col span={17}>Cargo enroute to station</Col>

      <Col span={7}><Text type='success'>C</Text></Col>
      <Col span={17}>Cargo at station built up, ULD closed</Col>

      <Col span={7}><Text type='danger'>O</Text></Col>
      <Col span={17}>Cargo at station built up, ULD open</Col>

      <Col span={7}>1 | 111 | 1</Col>
      <Col span={17}>AWBS | WEIGHT | VOLUME</Col>
    </Row>
  </div>
)

//FORMAT FLIGHT NO
function formatFlightNo(flight_no, flight_date) {
  let formatted = ''
  formatted = flight_no +'/'+ moment(flight_date).format('DDMMMYY')
  return formatted
}

//FORMAT AWB NO
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}

class BuildUpControl extends Component {
  constructor() {
    super()
    this.next = this.next.bind(this),
    this.previous = this.previous.bind(this),
    this.state = {
      station: new Cookies().get('station'),
      user_id: new Cookies().get('id'),
      timezone: new Cookies().get('timezone'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      timezone_cty: timezone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York',
      servererror: false,
      firstLoad: true,
      loading: false,
      flightHistorySpinner: false,
      amber: 0,
      weight_unit: 'KG',
      cookieInterval: null,
      tileInterval: null,
      flightRow: null,
      awbRow: null,
      note: '',
      prodCode: null,
      dropdownValue: 'ALL',
      sortValue: null,
      hideDeparted: false,
      showRedFlights: false,
      pendingRadioDisabled: true,
      builtupRadioDisabled: true,
      flightHistoryVisible: false,

      cargoCapacity: false,
      flightNotes: false,
      notesTitle: false,
      shipmentVisible: false,
      ccPlanModalVisible: false,
      replacementModalVisible: false,
      replacementModalDest: '',
      wrongLocationModal: false,
      wrongGateRow: [],
      wrongGateFlightID: null,
      selectAllCheck: false,

      ccRecord: null,
      editFcst: 0,
      editAllwdUldTypes: [],
      editBagP96: 0,
      editBagP88: 0,
      editBagLD3: 0,
      editBagLD2: 0,
      editCargoMD: 0,
      editCargoP96: 0,
      editCargoP88: 0,
      editCargoLD3: 0,
      editCargoLD2: 0,
      editWgtValue: 0,
      editWgtUnit: '',
      editCont: '',
      editBuPrio: false,
      editAllocArray: [],
      editIsExceeded: false,
      previewAft: null,
      previewFwd: null,
      lastUpdatedAt: '',
      lastUpdatedBy: '',
      flightNo: '',
      flightDate: '',
      flightOrgDest: '',
      isWideBody: false,

      workAreaData: [],
      fleetData: [],
      defaultSystemParameters: [],
      systemParameters: [],
      stationsData: [],
      workAreaTiles: [],
      flightGridData: [],
      searchGridData: [],
      recoveryActions: [],
      prodPriorityData: [],
      userList: [],
      ULDGridData: [],
      filterAWBGridData: [],
      productDropdownData: [],
      locationDropdownData: [],
      currentWorkArea: '',
      search: '',
      page: 1,
      asgn_page: 1,
      start: moment(moment().tz(new Cookies().get('timezone')).format(dateFormat)),
      end: moment(moment().tz(new Cookies().get('timezone')).add(24, 'hours').format(dateFormat)),
      stime: moment().tz(new Cookies().get('timezone')).set({
        minutes: 0,
        second: 0,
      }),
      etime: moment().tz(new Cookies().get('timezone')).add(24, 'hours').set({
        minutes: 0,
        second: 0,
      }),
      priorCheck: false,
      nbCheck: false,
      wbCheck: false,
      rfsCheck: false,
      buCheck: false,
      latestTimestamp: '',
      latestTilesTimestamp: '',

      selectedAwbs: 0,
      selectedPcs: 0,
      selectedWgt: 0,
      selectedVol: 0,

      SLUSummary: [],
      PTUSummary: [],
      BuiltSummary: [],
      BLKSummary: null,
      selected: [],
      bagSummary: [],
      bagSelected: null,

      expanded: [],
      expandedPrint: [],
      isListening: false,
      replacementLoadID: null,
      replacementData: [],
      selectedReplacementData: [],

      buildupFilter: [],
      prodFilters: [],
      locationFilters: [],
      prodValue: '',
      locationValue: '',
      pauseEvent: true,

      spinnerForFlights: false,
      spinnerForEvents: false,
      departingFlightsSpinner: true,
      fetchFlightIds: [],
      printuldcon: false,
      hideMD: true,
      sliderLoad : false,
    }
    this._queue = []
    this._isBusy = false
  }

  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }

  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    const buildup_control_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=bu&stn=${this.state.station}`)

    buildup_control_events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        this.enqueue(myFakeAsyncJob)
      }
    }
    buildup_control_events.onerror = (error) => {
      if(error?.type === 'error') {
        this.setState({ servererror : true })
      }
    }
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })
    this.fetchWorkAreas()
    this.setState({ sliderLoad : true,  })
  }

  componentWillUnmount() {
    this.setState({
      cookieInterval: clearInterval(this.state.cookieInterval),
      tileInterval: clearInterval(this.state.tileInterval),
    })
  }

  eventList = (parsedData) => {
    // console.log('parsedData?.TYPE',parsedData?.TYPE)
    // console.log('parsedData?.msg',parsedData?.msg)
    // console.log('parsedData?.Action',parsedData?.Action)
    // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    if(parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'UPDATE' && parsedData?.Action === 'UPDATE'){
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'DETAILS')
    } else if(parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'PLAN' && parsedData?.Action === 'UPDATE'){
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'PLAN')
    } else if(parsedData?.TYPE === 'BOOKING_UPDATE' && parsedData?.Msg === 'Flight_out') {
      this.handleBookingUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.work_area)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('RCF')) {
      this.handleAWBUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.build_to, false)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('FPS')) {
      this.handleAWBUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.build_to, false)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('DIS')) {
      this.handleAWBUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.build_to, true)
    } else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'status') {
      this.handleULDUpdateOnStatus(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    } else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'FSU') {
      this.handleULDUpdateOnFSU(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.msg === 'add') {
      this.handleULDUpdateOnADD(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('MAN')) {
      this.handleAWBUpdateOnMAN(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('PRE')) {
      this.handleAWBUpdateOnPRE(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.msg === 'Merge') {
      this.handleAWBUpdateOnMerge(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    } else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'ADD') {
      this.addFlight(parsedData?.DETAILS, parsedData?.DETAILS?.details?.build_to)
    } else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'REMOVE') {
      this.removeFlight(parsedData?.DETAILS, parsedData?.DETAILS?.details?.build_to)
    } else if(parsedData?.TYPE === 'NOTES') {
      this.updateFlightNotesCount(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'COUNTDOWN') {
      this.handleIndicatorChange(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg === 'RECOVERY ACTION') {
      this.handleAWBUpdateOnRecovery(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'STATION_DETAILS') {
      this.fetchAmberValue('EVENT')
    } else if(parsedData?.TYPE === 'ULD MAPPING') {
      this.fetchULDMappingData('EVENT')
    } else if(parsedData?.TYPE === 'GLOBAL_DETAILS') {
      this.fetchGlobalDetails('EVENT')
    } else if(parsedData?.TYPE === 'PRODUCT_PRIORITY') {
      this.fetchProductPriority('EVENT')
    }
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    if (next) {
      this.setState({ spinnerForEvents: true })
      this._isBusy = true
      next
        .job()
        .then((value) => {
          this.eventList(value)
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          this.setState({ spinnerForEvents: false })
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    } else {
      this.setState({ spinnerForEvents: false })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 100)
      })
  }

  /*************************************************************** */
  //CHECK DATE RANGE VALIDITY, RETURN TRUE IF CONVERTED TIME IN UTC FALLS IN RANGE
  isValidFlight = (flight) => {
    let flag = false
    let flight_date = ''
    let split = flight?.org?.scheduled_dept_time?.match(/.{1,2}/g) || []
    if(split?.length > 0) {
      let timezone_date = moment(flight?.flight_date)?.set({
        hours: split[0],
        minutes: split[1],
      }).format('YYYY-MM-DD HH:mm:ss')
      flight_date = `${moment.tz(timezone_date, this.state.timezone).utc().format('YYYY-MM-DD HH:mm:ss')}`
    }
    //CHECK FLIGHT RANGE
    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc().valueOf()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc().valueOf()

    let local_tz_dept_date = 0
    if(flight_date) {
      local_tz_dept_date = moment.utc(flight_date).valueOf()
      if(local_tz_dept_date > start_date && local_tz_dept_date < end_date) {
        flag = true
      }
    }
    return flag
  }
  //FETCH INDIVIDUAL FLIGHT
  fetchIndividualFlight = async (ID) => {
    if(this.state.pauseEvent) {
      return false
    } else {
      let headers = {
        station: this.state.station,
        page: PAGE.BUILDUP
      }
      let obj = null
      //push ID to array.
      let fetchFlightIds = cloneDeep(this.state.fetchFlightIds)
      let temp_time = moment().valueOf()
      fetchFlightIds.push({
        ID : ID,
        time: temp_time
      })
      this.setState({ fetchFlightIds: fetchFlightIds })
      await Http.get(`build_up_list?id=${ID}`, { headers }).then(resp => {
        let tempdata = cloneDeep(this.state.fetchFlightIds)
        let fIFarr_index = tempdata?.findIndex((item) => parseInt(item.ID) === parseInt(ID) && parseInt(item.time) === parseInt(temp_time))
        //get list where time is greater
        let new_fIFarr = this.state.fetchFlightIds?.filter((item) => parseInt(item.ID) === parseInt(ID) && parseInt(item.time) > parseInt(temp_time))

        //no new events with same id and id is present in array.
        if(new_fIFarr.length === 0 && fIFarr_index > -1){
          // list without currect id record.
          fetchFlightIds = this.state.fetchFlightIds?.filter((item) => parseInt(item.ID) !== parseInt(ID) )
          this.setState({ fetchFlightIds : fetchFlightIds })
          if(this.state.pauseEvent) {
            return false
          } else {
            if (resp?.status === 200) {
              if (resp?.data?.data?.length > 0) {
                obj = resp?.data?.data[0]
              }
            }
          }
        }
      })
      return obj
    }
  }
  //UPDATE INDIVIDUAL FLIGHT DETAILS
  updateFlightDetails = async (DETAILS, UPDATE_TYPE) => {
    let tiles = cloneDeep(this.state.workAreaTiles)
    let work_area_index = tiles?.findIndex((p) => p.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())

    if(work_area_index > -1) {
      let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_id))
      if(flight_index > -1) {
        if(UPDATE_TYPE === 'DETAILS') {
          if(this.processIndividualFlight(this.parseFlight(tiles[work_area_index].flights[flight_index], DETAILS)) !== null)  {
            tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(this.parseFlight(tiles[work_area_index].flights[flight_index], DETAILS))
          }
        } else if(UPDATE_TYPE === 'PLAN') {
          tiles[work_area_index].flights[flight_index].details = DETAILS?.details || []
        }

        if(DETAILS?.last_updated?.length > 0) {
          let user_obj = this.state.userList?.find((item) => item?.id === parseInt(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.user_id))
          tiles[work_area_index].flights[flight_index].last_updated = DETAILS?.last_updated
          tiles[work_area_index].flights[flight_index].last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
          tiles[work_area_index].flights[flight_index].last_updated_at = moment.utc(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
        }

        //CHECK IF EDITING RECORD MATCHES FETCHED FLIGHT RECORD
        if(this.state.ccRecord && tiles[work_area_index].flights[flight_index]?.id === this.state.ccRecord?.id) {
          if(this.state.user_id !== DETAILS?.user_id) {
            let flight_no_date = `${tiles[work_area_index].flights[flight_index]?.flight_no}/${moment(tiles[work_area_index].flights[flight_index]?.flight_date).format(dateSFormat)}`
            notification.destroy()
            if(UPDATE_TYPE === 'DETAILS') {
              notification.warning({ message: `Flight ${flight_no_date} details was updated.` })
            } else if(UPDATE_TYPE === 'PLAN') {
              notification.warning({ message: 'Load plan details were updated' })
            }
          } else {
            //SET RECORD EDIT IF OPEN (MAY NOT BE NEEDED)
            this.setEdit(tiles[work_area_index].flights[flight_index], UPDATE_TYPE)
          }
        }
        if(this.state.flightRow && tiles[work_area_index].flights[flight_index]?.id === this.state.flightRow?.id) {
          this.setState({ flightRow: tiles[work_area_index].flights[flight_index] })
        }
        this.sort(tiles[work_area_index].flights, true, 'dept_time')
        this.sort(tiles[work_area_index].flights, false, 'time_to_dept')
        this.setState({
          workAreaTiles: tiles,
          flightGridData: tiles[work_area_index].flights,
        }, () => {
          if(this.state.flightRow?.id === parseInt(DETAILS?.flight_id)) {
            this.setState({
              ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
              productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
              prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
            })
          }
        })
      }
    }
  }
  //PARSE FLIGHT RECORD
  parseFlight = (flight, event_record) => {
    let flightObj = {
      bag: {},
      cargo: {},
      wgt: {},
    }

    flight.fcst = event_record?.fcst ? parseInt(event_record?.fcst) : 0
    flight.bu_prio = event_record?.bu_prio === 'true' || event_record?.bu_prio === true ? true : false
    flight.load_plan = event_record?.load_plan === 'true' || event_record?.load_plan === true ? true : false

    flightObj.bag.p96 = event_record?.bag?.p96 ? parseInt(event_record.bag.p96) : 0
    flightObj.bag.p88 = event_record?.bag?.p88 ? parseInt(event_record.bag.p88) : 0
    flightObj.bag.ld3 = event_record?.bag?.ld3 ? parseInt(event_record.bag.ld3) : 0
    flightObj.bag.ld2 = event_record?.bag?.ld2 ? parseInt(event_record.bag.ld2) : 0
    flight.bag = flightObj.bag

    flightObj.cargo.md = event_record?.cargo?.md ? parseInt(event_record.cargo.md) : 0
    flightObj.cargo.p96 = event_record?.cargo?.p96 ? parseInt(event_record.cargo.p96) : 0
    flightObj.cargo.p88 = event_record?.cargo?.p88 ? parseInt(event_record.cargo.p88) : 0
    flightObj.cargo.ld3 = event_record?.cargo?.ld3 ? parseInt(event_record.cargo.ld3) : 0
    flightObj.cargo.ld2 = event_record?.cargo?.ld2 ? parseInt(event_record.cargo.ld2) : 0
    flight.cargo = flightObj.cargo

    flightObj.wgt.value = event_record?.wgt?.value ? parseInt(event_record.wgt.value) : 0
    flightObj.wgt.unit = event_record?.wgt?.unit
    flight.wgt = flightObj.wgt
    return flight
  }
  //BOOKING UPDATE
  handleBookingUpdate = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
          tiles = cloneDeep(this.state.workAreaTiles)
          if(item) {
            let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
            if(this.isValidFlight(item)) {
              if(flight_index > -1) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)
                }
              } else {
                if(!this.state.search) {
                  if(this.processIndividualFlight(item) !== null) {
                    tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                  }
                }
              }
              if(WORK_AREA?.toUpperCase() === this.state.currentWorkArea?.toUpperCase()) {
                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            }
          }
        })
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleBookingUpdate(DETAILS, 'ALL')
      }
    }
  }
  //AWB UPDATE EVENT
  handleAWBUpdate = (DETAILS, WORK_AREA, IS_DIS) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(DETAILS?.uld_id))
          if(uld_index > -1) {
            let awb_index = tiles[work_area_index].flights[flight_index].ulds_out[uld_index]?.awbs_in?.findIndex((r) => r.id === parseInt(DETAILS?.goods_info_id))
            if(awb_index > -1) {
              tiles[work_area_index].flights[flight_index].ulds_out[uld_index].awbs_in[awb_index].location = DETAILS?.location || ''
              if(IS_DIS) {
                tiles[work_area_index].flights[flight_index].ulds_out[uld_index].awbs_in[awb_index].man = false
              }
              if(DETAILS?.inbound) {
                tiles[work_area_index].flights[flight_index].ulds_out[uld_index].awbs_in[awb_index].status = 'CHECKED IN'
              }
              this.setState({ flightGridData: [], }, () => this.setState({
                flightGridData: tiles[work_area_index].flights,
                workAreaTiles: tiles,
              }, () => {
                if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                  this.setState({
                    ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                    productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                    locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                    prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                    locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                  })
                }
                this.sort(tiles[work_area_index].flights, true, 'dept_time')
                this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
              }))
            }
          }
        }
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleAWBUpdate(DETAILS, 'ALL')
      }
    }
  }
  //ULD UPDATE ON FSU EVENT
  handleULDUpdateOnFSU = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(DETAILS?.uld_id))
          if(uld_index > -1) {
            if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
              if(!this.state.search) {
                tiles[work_area_index].flights[flight_index].ulds_out?.splice(uld_index, 1)

                if(tiles[work_area_index].flights[flight_index].ulds_out?.length === 0) {
                  tiles[work_area_index].flights?.splice(flight_index, 1)
                }
              }
            } else {
              if(!DETAILS?.old_bu_work_area_fps) {
                this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
                  tiles = cloneDeep(this.state.workAreaTiles)
                  flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
                  //UPDATE FLIGHT
                  if(flight_index > -1) {
                    if(item) {
                      if(this.processIndividualFlight(item) !== null) {
                        tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)
                      }

                      this.setState({ flightGridData: [], }, () => this.setState({
                        flightGridData: tiles[work_area_index].flights,
                        workAreaTiles: tiles,
                      }, () => {
                        if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                          this.setState({
                            ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                            productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                            locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                            prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                            locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                          })
                        }
                        this.sort(tiles[work_area_index].flights, true, 'dept_time')
                        this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                      }))
                    }
                  }
                })
              } else {
                if(DETAILS?.old_bu_work_area_fps &&
                  (this.state.currentWorkArea?.toUpperCase() === DETAILS?.old_bu_work_area_fps?.toUpperCase()) &&
                  (WORK_AREA?.toUpperCase() !== DETAILS?.old_bu_work_area_fps?.toUpperCase())) {
                  if(!this.state.search) {
                    //REMOVE ULD
                    tiles[work_area_index].flights[flight_index].ulds_out?.splice(uld_index, 1)

                    if(tiles[work_area_index].flights[flight_index].ulds_out?.length === 0) {
                      tiles[work_area_index].flights?.splice(flight_index, 1)
                    }
                  }
                } else {
                  //UPDATE FLIGHT
                  this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
                    tiles = cloneDeep(this.state.workAreaTiles)
                    //CHECK FOR FLIGHT INDEX AGAIN SINCE API CALL IS MADE
                    flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
                    if(flight_index > -1) {
                      if(item) {
                        if(this.processIndividualFlight(item) !== null) {
                          tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)
                        }

                        this.setState({ flightGridData: [], }, () => this.setState({
                          flightGridData: tiles[work_area_index].flights,
                          workAreaTiles: tiles,
                        }, () => {
                          if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                            this.setState({
                              ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                              productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                              locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                              prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                              locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                            })
                          }
                          this.sort(tiles[work_area_index].flights, true, 'dept_time')
                          this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                        }))
                      }
                    }
                  })
                }
              }
            }
          }
        } else {
          if(!this.state.search) {
            this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
              tiles = cloneDeep(this.state.workAreaTiles)
              //ADD FLIGHT
              if(item && this.isValidFlight(item)) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            })
          }
        }
      } else {
        work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === DETAILS?.old_bu_work_area_fps?.toUpperCase())
        if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === DETAILS?.old_bu_work_area_fps?.toUpperCase())) {
          if(!DETAILS?.build_to || DETAILS?.build_to !== DETAILS?.old_bu_work_area_fps?.toUpperCase()) {
            let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
            if(flight_index > -1) {
              let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(DETAILS?.uld_id))
              if(uld_index > -1) {
                if(!this.state.search) {
                  tiles[work_area_index].flights[flight_index].ulds_out?.splice(uld_index, 1)

                  if(tiles[work_area_index].flights[flight_index]?.ulds_out?.length === 0) {
                    tiles[work_area_index].flights?.splice(flight_index, 1)
                  }
                }
              }
              this.setState({ flightGridData: [], }, () => this.setState({
                flightGridData: tiles[work_area_index].flights,
                workAreaTiles: tiles,
              }, () => {
                if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                  this.setState({
                    ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                    productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                    locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                    prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                    locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                  })
                }
                this.sort(tiles[work_area_index].flights, true, 'dept_time')
                this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
              }))
            }
          }
        }
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleULDUpdateOnFSU(DETAILS, 'ALL')
      }
    }
  }
  //ULD UPDATE ON STATUS EVENT
  handleULDUpdateOnStatus = (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(DETAILS?.uld_id))
          if(uld_index > -1) {
            if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
              if(!this.state.search) {
                tiles[work_area_index].flights[flight_index].ulds_out?.splice(uld_index, 1)

                if(tiles[work_area_index].flights[flight_index].ulds_out?.length === 0) {
                  tiles[work_area_index].flights?.splice(flight_index, 1)
                }
              }
            } else if(DETAILS?.rdy) {
              tiles[work_area_index].flights[flight_index].ulds_out[uld_index].rdy = true
            }
            this.setState({ flightGridData: [], }, () => this.setState({
              flightGridData: tiles[work_area_index].flights,
              workAreaTiles: tiles,
            }, () => {
              if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                this.setState({
                  ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                  productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                  locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                  prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                  locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                })
              }
              this.sort(tiles[work_area_index].flights, true, 'dept_time')
              this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
            }))
          }
        } else {
          if(!this.state.search) {
            this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
              tiles = cloneDeep(this.state.workAreaTiles)
              //ADD FLIGHT
              if(item && this.isValidFlight(item)) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            })
          }
        }
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleULDUpdateOnStatus(DETAILS, 'ALL')
      }
    }
  }
  //ULD UPDATE ON FSU EVENT
  handleULDUpdateOnADD = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
            tiles = cloneDeep(this.state.workAreaTiles)
            //ADD FLIGHT
            flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
            if(item && this.isValidFlight(item)) {
              if(this.processIndividualFlight(item) !== null) {
                tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)
              }

              this.setState({ flightGridData: [], }, () => this.setState({
                flightGridData: tiles[work_area_index].flights,
                workAreaTiles: tiles,
              }, () => {
                if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                  this.setState({
                    ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                    productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                    locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                    prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                    locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                  })
                }
                this.sort(tiles[work_area_index].flights, true, 'dept_time')
                this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
              }))
            }
          })
        } else {
          if(!this.state.search) {
            this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
              tiles = cloneDeep(this.state.workAreaTiles)
              //ADD FLIGHT
              if(item && this.isValidFlight(item)) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            })
          }
        }
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleULDUpdateOnADD(DETAILS, 'ALL')
      }
    }
  }
  //AWB UPDATE ON MAN EVENT
  handleAWBUpdateOnMAN = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let old_flight_index = ''
      let new_flight_index = ''
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        if(DETAILS?.old_flight_out !== DETAILS?.flight_out && DETAILS?.status?.toUpperCase() !== 'COMPLETED') {
          //IF FLIGHT OUT IS THERE ADD ULD
          new_flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
          if(new_flight_index > -1) {
            this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
              tiles = cloneDeep(this.state.workAreaTiles)
              //UPDATE FLIGHT
              if(item) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights[new_flight_index] = this.processIndividualFlight(item)
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[new_flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[new_flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[new_flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[new_flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[new_flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            })
          } else {
            if(!this.state.search) {
              this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
                tiles = cloneDeep(this.state.workAreaTiles)
                //ADD FLIGHT
                if(item && this.isValidFlight(item)) {
                  if(this.processIndividualFlight(item) !== null) {
                    tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                  }

                  this.setState({ flightGridData: [], }, () => this.setState({
                    flightGridData: tiles[work_area_index].flights,
                    workAreaTiles: tiles,
                  }, () => {
                    if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                      this.setState({
                        ULDGridData: tiles[work_area_index].flights[new_flight_index]?.ulds_out,
                        productDropdownData: tiles[work_area_index].flights[new_flight_index]?.product_arr,
                        locationDropdownData: tiles[work_area_index].flights[new_flight_index]?.location_arr,
                        prodFilters: tiles[work_area_index].flights[new_flight_index]?.product_arr,
                        locationFilters: tiles[work_area_index].flights[new_flight_index]?.location_arr,
                      })
                    }
                    this.sort(tiles[work_area_index].flights, true, 'dept_time')
                    this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                  }))
                }
              })
            }
          }
          //IF OLD FLIGHT OUT IS THERE REMOVE ULD
          old_flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.old_flight_out))
          if(old_flight_index > -1) {
            this.fetchIndividualFlight(parseInt(DETAILS?.old_flight_out)).then((item) => {
              tiles = cloneDeep(this.state.workAreaTiles)
              //UPDATE FLIGHT
              if(item) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights[old_flight_index] = this.processIndividualFlight(item)
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.old_flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[old_flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[old_flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[old_flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[old_flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[old_flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            })
            // let uld_index = tiles[work_area_index].flights[old_flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(DETAILS?.uld_id))
            // if(uld_index > -1) {
            //   if(!this.state.search) {
            //     tiles[work_area_index].flights[old_flight_index]?.ulds_out?.splice(uld_index, 1)

            //     if(tiles[work_area_index].flights[old_flight_index].ulds_out?.length === 0) {
            //       tiles[work_area_index].flights.splice(old_flight_index, 1)

            //       this.setState({ flightGridData: [], }, () => this.setState({
            //         flightGridData: tiles[work_area_index].flights,
            //         workAreaTiles: tiles,
            //       }, () => {
            //         if(this.state.flightRow?.id === parseInt(DETAILS?.old_flight_out)) {
            //           this.setState({ ULDGridData: tiles[work_area_index].flights[old_flight_index]?.ulds_out })
            //         }
            //         this.sort(tiles[work_area_index].flights, true, 'dept_time')
            //         this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
            //       }))
            //     }
            //   }
            // }
          }
        } else {
          //UPDATE AWB INFO
          this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
            tiles = cloneDeep(this.state.workAreaTiles)
            //ADD/UPDATE FLIGHT
            new_flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
            if(new_flight_index > -1) {
              if(item) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights[new_flight_index] = this.processIndividualFlight(item)
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.old_flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[old_flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[old_flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[old_flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[old_flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[old_flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            } else {
              if(!this.state.search) {
                if(item && this.isValidFlight(item)) {
                  if(this.processIndividualFlight(item) !== null) {
                    tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                  }

                  this.setState({ flightGridData: [], }, () => this.setState({
                    flightGridData: tiles[work_area_index].flights,
                    workAreaTiles: tiles,
                  }, () => {
                    if(this.state.flightRow?.id === parseInt(DETAILS?.old_flight_out)) {
                      this.setState({
                        ULDGridData: tiles[work_area_index].flights[old_flight_index]?.ulds_out,
                        productDropdownData: tiles[work_area_index].flights[old_flight_index]?.product_arr,
                        locationDropdownData: tiles[work_area_index].flights[old_flight_index]?.location_arr,
                        prodFilters: tiles[work_area_index].flights[old_flight_index]?.product_arr,
                        locationFilters: tiles[work_area_index].flights[old_flight_index]?.location_arr,
                      })
                    }
                    this.sort(tiles[work_area_index].flights, true, 'dept_time')
                    this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                  }))
                }
              }
            }
          })
        }
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleAWBUpdateOnMAN(DETAILS, 'ALL')
      }
    }
  }
  //AWB UPDATE ON PRE EVENT
  handleAWBUpdateOnPRE = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(DETAILS?.uld_id))
          if(uld_index > -1) {
            let awb_index = tiles[work_area_index].flights[flight_index]?.ulds_out[uld_index]?.awbs_in?.findIndex((q) => q.id === parseInt(DETAILS?.goods_info_id))
            if(awb_index > -1) {
              tiles[work_area_index].flights[flight_index].ulds_out[uld_index].awbs_in[awb_index].rdy = DETAILS?.rdy
              tiles[work_area_index].flights[flight_index].ulds_out[uld_index].rdy = DETAILS?.rdy
              if(this.processIndividualFlight(tiles[work_area_index].flights[flight_index]) !== null) {
                tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(tiles[work_area_index].flights[flight_index])
              }
            }
          }
        }
        this.setState({ flightGridData: [], }, () => this.setState({
          flightGridData: tiles[work_area_index].flights,
          workAreaTiles: tiles,
        }, () => {
          if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
            this.setState({
              ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
              productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
              prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
            })
          }
          this.sort(tiles[work_area_index].flights, true, 'dept_time')
          this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
        }))
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleAWBUpdateOnPRE(DETAILS, 'ALL')
      }
    }
  }
  //AWB UPDATE ON Merge EVENT
  handleAWBUpdateOnMerge = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === WORK_AREA?.toUpperCase())
      if(work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
            tiles = cloneDeep(this.state.workAreaTiles)
            flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
            //UPDATE FLIGHT
            if(flight_index > -1) {
              if(item) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            }
          })
        }
      } else if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL'){
        this.handleAWBUpdateOnMerge(DETAILS, 'ALL')
      }
      if(WORK_AREA !== 'ALL' && this.state.currentWorkArea?.toUpperCase() === 'ALL') {
        this.handleAWBUpdateOnPRE(DETAILS, 'ALL')
      }
    }
  }
  //UPDATE NOTES COUNT
  updateFlightNotesCount = (DETAILS) => {
    let tiles = cloneDeep(this.state.workAreaTiles)
    let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
    if(work_area_index > -1) {
      let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flights_id))
      if(flight_index > -1) {
        tiles[work_area_index].flights[flight_index].notes = DETAILS?.notes?.length || 0
        tiles[work_area_index].flights[flight_index].notes_date = DETAILS?.timestamp ? moment.utc(DETAILS.timestamp).tz(this.state.timezone).format(dateTimeFormat) : null

        this.setState({ flightGridData: [] }, () => this.setState({ flightGridData: tiles[work_area_index].flights }))
      }
    }
  }
  //HANDLE INDICATOR CHANGE
  handleIndicatorChange = (DETAILS) => {
    let tiles = cloneDeep(this.state.workAreaTiles)
    let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
    if(work_area_index > -1) {
      let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
      if(flight_index > -1) {
        if(this.processIndividualFlight(tiles[work_area_index].flights[flight_index]) !== null) {
          tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(tiles[work_area_index].flights[flight_index])
        }
        this.setState({ flightGridData: [], }, () => this.setState({
          flightGridData: tiles[work_area_index].flights,
          workAreaTiles: tiles,
        }, () => {
          if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
            this.setState({
              ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
              productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
              prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
            })
          }
          this.sort(tiles[work_area_index].flights, true, 'dept_time')
          this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
        }))
      }
    }
  }
  //HANDLE AWB RECOVERY EVENT
  handleAWBUpdateOnRecovery = (DETAILS) => {
    if(DETAILS?.flight_out) {
      let tiles = cloneDeep(this.state.workAreaTiles)
      let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
      if(work_area_index > -1) {
        let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
        if(flight_index > -1) {
          this.fetchIndividualFlight(parseInt(DETAILS?.flight_out)).then((item) => {
            tiles = cloneDeep(this.state.workAreaTiles)
            //UPDATE FLIGHT
            flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_out))
            if(flight_index > -1 && item) {
              if(this.processIndividualFlight(item) !== null) {
                tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)

                if(this.state.wrongGateFlightID === parseInt(DETAILS?.flight_out) && this.state.wrongLocationModal) {
                  this.setState({ wrongGateRow: tiles[work_area_index].flights[flight_index].wrong_gate })
                }

                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_out)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            }
          })
        }
      }
    }
  }
  //ADD Flight on BOOKING ADD events
  addFlight = (DETAILS, WORK_AREA) =>{
    let tiles = cloneDeep(this.state.workAreaTiles)
    let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
    if((work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) || this.state.currentWorkArea?.toUpperCase() === 'ALL') {
      let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flights_id))
      if(flight_index > -1) {
        //UPDATE RECORD IN CURRENT LIST
        this.updateFlight(DETAILS, 'BOOKING')
      } else {
        //ADD RECORD IF NOT IN CURRENT LIST
        this.fetchIndividualFlight(parseInt(DETAILS?.flight_id)).then((item) => {
          tiles = cloneDeep(this.state.workAreaTiles)
          if(item) {
            let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(DETAILS?.flight_id))
            if(this.isValidFlight(item)) {
              if(flight_index > -1) {
                if(this.processIndividualFlight(item) !== null) {
                  tiles[work_area_index].flights[flight_index] = this.processIndividualFlight(item)
                }
              } else {
                if(!this.state.search) {
                  if(this.processIndividualFlight(item) !== null) {
                    tiles[work_area_index].flights.push(this.processIndividualFlight(item))
                  }
                }
              }
              if((WORK_AREA?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())|| this.state.currentWorkArea?.toUpperCase() === 'ALL') {
                this.setState({ flightGridData: [], }, () => this.setState({
                  flightGridData: tiles[work_area_index].flights,
                  workAreaTiles: tiles,
                }, () => {
                  if(this.state.flightRow?.id === parseInt(DETAILS?.flight_id)) {
                    this.setState({
                      ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                      productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                      prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                      locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                    })
                  }
                  this.sort(tiles[work_area_index].flights, true, 'dept_time')
                  this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                }))
              }
            }
          }
        })
      }
    }
  }
  //REMOVE flight on BOOKING REMOVE events
  removeFlight = (DETAILS, WORK_AREA) =>{
    let tiles = cloneDeep(this.state.workAreaTiles)
    let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
    if((work_area_index > -1 && (this.state.currentWorkArea?.toUpperCase() === WORK_AREA?.toUpperCase())) || this.state.currentWorkArea?.toUpperCase() === 'ALL') {
      let flight_index = tiles[work_area_index].flights?.findIndex((p) => parseInt(p.id) === parseInt(DETAILS?.flight_id))
      if(flight_index > -1) {
        let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => parseInt(q.id) === parseInt(DETAILS?.details?.id))
        if(uld_index > -1) {
          if(!this.state.search) {
            tiles[work_area_index].flights[flight_index].ulds_out?.splice(uld_index, 1)
            if(tiles[work_area_index].flights[flight_index].ulds_out?.length === 0) {
              tiles[work_area_index].flights?.splice(flight_index, 1)
            }
          }
          this.setState({ flightGridData: [], }, () => this.setState({
            flightGridData: tiles[work_area_index].flights,
            workAreaTiles: tiles,
          }, () => {
            if(this.state.flightRow?.id === parseInt(DETAILS?.flight_id)) {
              this.setState({
                ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
              })
            }
            this.sort(tiles[work_area_index].flights, true, 'dept_time')
            this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
          }))
        }
      }
    }
  }

  //PROCESS DATA
  processData = () => {
    let tiles = cloneDeep(this.state.workAreaTiles)
    let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())

    if(work_area_index > -1) {
      tiles[work_area_index].flights = tiles[work_area_index].flights?.map((item) => {
        return { ...this.processIndividualFlight(item) }
      })
      this.setState({ flightGridData: [], }, () => this.setState({
        flightGridData: tiles[work_area_index].flights,
        workAreaTiles: tiles,
      }, () => {
        if(this.state.flightRow?.id) {
          let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === this.state.flightRow?.id)
          if(flight_index > -1) {
            this.setState({
              ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
              productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
              prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
              locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
            })
            this.sort(tiles[work_area_index].flights, true, 'dept_time')
            this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
          }
        }
      }))
    }
  }
  //UPDATE TILES ON SET TIMEOUT
  updateTiles = async () => {
    let timestamp = moment().valueOf()
    if(!this.state.latestTilesTimestamp) {
      this.setState({ latestTilesTimestamp: timestamp })
      let headers = {
        search: '',
        station: this.state.station
      }
      let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc()
      let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc()
      let start = this.state.start ? start_date.format(dateFormat) : ''
      let end = this.state.end ? end_date.format(dateFormat) : ''
      let stime = this.state.stime ? start_date.format(timeFormat) : ''
      let etime = this.state.etime ? end_date.format(timeFormat) : ''
      await Http.get(`buildup?&start=${start}&end=${end}&stime=${stime}&etime=${etime}&is_wb=${this.state.wbCheck}&is_trk=${this.state.rfsCheck}&is_nb=${this.state.nbCheck}&is_bu=${this.state.buCheck}`, { headers }).then(resp => {
        if(timestamp === this.state.latestTilesTimestamp) {
          if (resp && resp.status === 200) {
            if (resp?.data?.data?.length > 0) {
              let flights = (this.state.workAreaTiles?.find((item) => item.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase()))?.flights || []
              let data = resp?.data?.data.map((item) => {
                let area = item?.tile === 'ALL' ? 'ALL' : item?.area_name
                if(item?.data?.flight) item.data.flight = item?.data?.flight?.filter(function( item, index, inputArray ) { return inputArray.indexOf(item) === index }) || []
                return {
                  ...item,
                  flights: area?.toUpperCase() === this.state.currentWorkArea?.toUpperCase() ? flights : [],
                  area_name: area,
                }
              })
              //SET TILES ORDER BASED ON SET COOKIES
              this.tilesSort(data)
              data = this.processWorkAreas(data)
              this.setState({
                workAreaTiles: [],
                latestTilesTimestamp: '',
              }, () => this.setState({ workAreaTiles: data }))
            }
          }
        } else {
          //DO NOTHING
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
    }
  }

  /*************************************************************** */

  //FETCH RECOVERY ACTIONS DATA
  fetchWorkAreas = async (event) => {
    let headers = {
      page: PAGE.BUILDUP,
      station: this.state.station,
      module: 'buildup'
    }
    if(!event) this.setState({ loading: true })
    await Http.get('work_areas', { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index,
              data: {
                awb: 0,
                count: 0,
                ember: 0,
                flight: [],
                stat: false,
                wgt: [],
              },
              tile: 'WORK',
              flights: [],
            }
          })
          let alltilesobj = {
            key: resp?.data && resp?.data?.data?.length,
            data: {
              awb: 0,
              count: 0,
              ember: 0,
              flight: [],
              stat: false,
              wgt: [],
            },
            flights: [],
            tile: 'ALL',
            area_name: 'ALL'
          }
          data.push(alltilesobj)
          this.tilesSort(data)
          data = this.processWorkAreas(data)
          this.setState({
            workAreaTiles: data,
            loading: false,
          }, () => {
            if(!event) this.fetchFleet()
            else {
              this.processData()
              this.setState({ loading: false })
            }
          })

        } else {
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH FLEET
  fetchFleet = async (event) => {
    if(!event) this.setState({ loading: true })
    await Http.get('flights', { headers: '' }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        this.setState({ fleetData: resp?.data?.data }, () => {
          if(!event) this.fetchProductPriority()
          else {
            this.processData()
          }
        })
      } else {
        this.setState({ loading: false })
      }
    })
  }

  //FETCH PRODUCT PRIORITY
  fetchProductPriority = async (event) => {
    Http.get(`/products_priority?station=${this.state.station}`)
      .then((data) => {
        if (data && data.status === 200) {
          if(data && data.data && data.data.data[0] && data.data.data[0].prod_prior_list.length>0){
            this.setState({ prodPriorityData : data.data.data[0].prod_prior_list })
          } else {
            this.setState({ prodPriorityData : [] })
          }
          if(!event) this.fetchAmberValue()
          else {
            this.processData()
          }
        } else {
          this.setState({ loading: false })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }
  //FETCH AMBER VALUE
  fetchAmberValue = async (event) => {
    Http.get(`/station_details?station=${this.state.station}`)
      .then((data) => {
        if(data?.data?.data?.length > 0) {
          this.setState({ amber: data.data.data[0]?.indicator_time2 || 0 }, () => {
            if(!event) this.fetchRecoveryactions()
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      })
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchRecoveryactions = async (event) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    await Http.get('recovery_actions', { headers: headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ recoveryActions: data }, () => {
            if(!event) this.fetchSystemParameters()
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH HANDLING TIMES
  fetchSystemParameters = async (event) => {
    if(!event) this.setState({ loading: true })
    await Http.get(`handling_times?station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ systemParameters: data }, () => {
            if(!event) this.fetchDefaultSystemParameters()
            else {
              this.processData()
              this.setState({ loading: false })
            }
          })
        } else {
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async (event) => {
    await Http.get(`handling_times?default=true&station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ defaultSystemParameters: data }, () => {
            if(!event) this.fetchStations()
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH STATIONS
  fetchStations = async (event) => {
    await Http.get('stations').then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ stationsData: data }, () => {
            if(!event) this.fetchReplacementLoadActions()
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      } else {
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH REPLACEMENT LOAD RECOVERY ACTIONS
  fetchReplacementLoadActions = async (event) => {
    let headers = {
      station: this.state.station,
      page: 'Replacement Load',
    }
    await Http.get('recovery_actions', { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ replacementLoadID: resp?.data?.data[0]?.id || null }, () => {
            if(!event) this.fetchULDMappingData()
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      } else {
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH ULD MAPPING DATA
  fetchULDMappingData = async (event) => {
    await Http.get('uld_mappings').then(resp => {
      if(resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ uldMappingData: resp?.data?.data }, () => {
            if(!event) this.fetchGlobalDetails()
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ loading: false })
      }
    })
  }
  //FETCH GLOBAL DETAILS
  fetchGlobalDetails = async (event) => {
    await Http.get('global_details').then(resp => {
      if(resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          let filtered = resp?.data?.data?.find((item) => item?.details?.stations?.includes(this.state.station)) || null
          if(filtered) {
            this.setState({ hideMD: filtered?.show_md ? false : true })
          } else {
            this.setState({ hideMD: false })
          }
          if(!event) this.fetchUsers()
        } else {
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ loading: false })
      }
    })
  }
  //FETCH USERS LIST
  fetchUsers = async (event) => {
    let headers = { station: this.state.station }
    await Http.get('users?sa=true', { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ userList: resp?.data?.data }, () => {
            if(!event) this.fetchFilters(false)
            else {
              this.processData()
            }
          })
        } else {
          this.setState({ loading: false })
        }
      }else {
        this.setState({ loading: false })
      }
    })
  }
  //FETCH USER FILTERS
  fetchFilters = async (user_update) => {
    await Http.get(`/page_filter/${this.state.user_id}`).then(async (data) => {
      if (data && data?.status === 200) {
        let res_data = data?.data?.data?.page_filters?.buildupcontrol_filter && data?.data?.data?.page_filters?.buildupcontrol_filter[this.state.station]
        if(res_data) {
          this.setState({
            buildupFilter: data?.data?.data?.page_filters?.buildupcontrol_filter,
            currentWorkArea: res_data?.type ? res_data?.type : 'ALL',
            // prodFilters: res_data?.prod_code || [],
            // locationFilters: res_data?.location || [],
          })
        } else {
          this.setState({
            buildupFilter: data?.data?.data?.page_filters?.buildupcontrol_filter || {},
            currentWorkArea: 'ALL',
            // prodFilters: [],
            // locationFilters: [],
          })
        }
        if(!user_update) {
          this.fetchAssignedList(res_data?.type ? res_data.type : 'ALL')
          this.fetchTiles()
        }
      } else {
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  //FETCH WORK AREA TILES
  fetchTiles = async () => {
    let headers = {
      search: '',
      station: this.state.station
    }

    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start ? start_date.format(dateFormat) : ''
    let end = this.state.end ? end_date.format(dateFormat) : ''
    let stime = this.state.stime ? start_date.format(timeFormat) : ''
    let etime = this.state.etime ? end_date.format(timeFormat) : ''
    await Http.get(`buildup?&start=${start}&end=${end}&stime=${stime}&etime=${etime}&is_wb=${this.state.wbCheck}&is_trk=${this.state.rfsCheck}&is_nb=${this.state.nbCheck}&is_bu=${this.state.buCheck}`, { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp?.data?.data?.length > 0) {
          let flights = (this.state.workAreaTiles?.find((item) => item.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase()))?.flights || []
          let data = resp?.data?.data.map((item) => {
            let area = item?.tile === 'ALL' ? 'ALL' : item?.area_name
            if(item?.data?.flight) item.data.flight = item?.data?.flight?.filter(function( item, index, inputArray ) { return inputArray.indexOf(item) === index }) || []
            return {
              ...item,
              isMaster: true,
              flights: area?.toUpperCase() === this.state.currentWorkArea?.toUpperCase() ? flights : [],
              area_name: area,
            }
          })
          //SET TILES ORDER BASED ON SET COOKIES
          this.tilesSort(data)
          data = this.processWorkAreas(data)
          this.setState({
            workAreaTiles: [],
            // loading: false,
          }, () => this.setState({ workAreaTiles: data }, () => {
            if(this.state.firstLoad) {
              this.setState({
                firstLoad: false,
                page: 1,
                asgn_page: 1,
              }/*, () => this.fetchAssignedList(this.state.currentWorkArea)*/)
            }
          }))
        } else {
          this.setState({
            workAreaTiles: [],
            loading: false
          }, () => {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //SORT ARRAY
  sortTiles = (array) => {
    array?.sort(function(a, b) {
      var keyA = a?.area_name
      var keyB = b?.area_name
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    const sorter = (a, b) => {
      if(!a['isMaster'] && b['isMaster']){
        return -1
      }
      if(a['isMaster'] && !b['isMaster']){
        return 1
      }
      return a['area_name'] - b['area_name']
    }
    array.sort(sorter)
  }
  //FETCH ASSIGNED LIST (OPTIMIZATION)
  fetchAssignedList = async (workArea) => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      // loading: this.state.page === 1 ? true : false,
      latestTimestamp: temp_timestamp,
      spinnerForFlights: true,
      pauseEvent: true,
      departingFlightsSpinner: true
    })
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }

    let work_area = this.state.currentWorkArea?.toUpperCase() === 'ALL' ? '' : this.state.currentWorkArea
    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start ? start_date.format(dateFormat) : ''
    let end = this.state.end ? end_date.format(dateFormat) : ''
    let stime = this.state.stime ? start_date.format(timeFormat) : ''
    let etime = this.state.etime ? end_date.format(timeFormat) : ''
    let page = this.state.asgn_page
    let count = 15

    let url = 'build_up_asg_list?'
    if(work_area) {
      url = url + `work_area=${work_area}&`
    }
    url = url + `start=${start}&end=${end}&stime=${stime}&etime=${etime}&page=${page}&count=${count}`

    await Http.get(url, { headers }).then(async resp => {
      if(this.state.latestTimestamp === temp_timestamp) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ departingFlightsSpinner: false })
          if(workArea !== this.state.currentWorkArea) {
            //DO NOTHING
          } else {
            let tiles = cloneDeep(this.state.workAreaTiles)
            let wa_index = tiles?.findIndex((p) => p.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
            if(wa_index > -1) {
              if(!this.state.search) {
                resp?.data?.data?.map((item) => {
                  let id = tiles[wa_index].flights?.findIndex(p => p.id === parseInt(item.id))
                  if(id > -1) {
                    //DO NOTHING
                  } else {
                    if(this.processIndividualFlight(item) !== null) {
                      tiles[wa_index].flights.push(this.processIndividualFlight(item))
                    }
                  }
                })
                this.sort(tiles[wa_index].flights, true, 'dept_time')
                this.sort(tiles[wa_index].flights, true, 'time_to_dept')
                this.setState({
                  workAreaTiles: tiles,
                  flightGridData: tiles[wa_index].flights,
                  asgn_page: this.state.asgn_page + 1,
                  loading: false
                }, () => {
                  // this.fetchWorkAreaData(this.state.currentWorkArea)
                  //this.fetchBookedList(this.state.currentWorkArea)
                  if (resp?.data?.data?.length < count) {
                    this.fetchBookedList(this.state.currentWorkArea)
                  } else {
                    this.fetchAssignedList(workArea)
                  }
                })
              }
            }
          }
        } else {
          this.setState({ loading: false }, () => {
            // this.fetchWorkAreaData(this.state.currentWorkArea)
            this.fetchBookedList(this.state.currentWorkArea)
          })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true,
        spinnerForFlights: false
      })
    })
  }
  //FETCH BOOKED LIST (OPTIMIZATION)
  fetchBookedList = async (workArea) => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      // loading: this.state.page === 1 ? true : false,
      latestTimestamp: temp_timestamp,
      // pauseEvent: true,
    })
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }

    let work_area = this.state.currentWorkArea?.toUpperCase() === 'ALL' ? '' : this.state.currentWorkArea
    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start ? start_date.format(dateFormat) : ''
    let end = this.state.end ? end_date.format(dateFormat) : ''
    let stime = this.state.stime ? start_date.format(timeFormat) : ''
    let etime = this.state.etime ? end_date.format(timeFormat) : ''
    let page = this.state.page

    let url = 'build_up_book_list?'
    if(work_area) {
      url = url + `work_area=${work_area}&`
    }
    url = url + `start=${start}&end=${end}&stime=${stime}&etime=${etime}`
    if(this.state.currentWorkArea?.toUpperCase() === 'ALL') {
      url = url + `&page=${page}&count=5`
    }

    await Http.get(url, { headers }).then(async resp => {
      if(this.state.latestTimestamp === temp_timestamp) {
        if (resp?.data?.data?.length > 0) {
          if(workArea !== this.state.currentWorkArea) {
            //DO NOTHING
          } else {
            let tiles = cloneDeep(this.state.workAreaTiles)
            let wa_index = tiles?.findIndex((p) => p.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
            if(wa_index > -1) {
              if(!this.state.search) {
                resp?.data?.data?.map((item) => {
                  let id = tiles[wa_index].flights?.findIndex(p => p.id == parseInt(item.id))
                  if(id > -1) {
                    //DO NOTHING
                    item.ulds_out = tiles[wa_index].flights[id].ulds_out
                    if(this.processIndividualFlight(item) !== null) {
                      tiles[wa_index].flights[id] = this.processIndividualFlight(item)
                    }
                  } else {
                    if(this.processIndividualFlight(item) !== null) {
                      tiles[wa_index].flights.push(this.processIndividualFlight(item))
                    }
                  }
                })
                this.sort(tiles[wa_index].flights, true, 'dept_time')
                this.sort(tiles[wa_index].flights, true, 'time_to_dept')
                this.setState({
                  workAreaTiles: tiles,
                  flightGridData: tiles[wa_index].flights,
                  page: this.state.page + 1,
                  pauseEvent: false,
                }, () => {
                  if(this.state.currentWorkArea?.toUpperCase() === 'ALL' && (temp_timestamp === this.state.latestTimestamp)) {
                    this.fetchBookedList(this.state.currentWorkArea)
                  } else {
                    this.setState({
                      tileInterval: setInterval(this.updateTiles, 5000),
                      spinnerForFlights: false,
                      pauseEvent: false,
                    })
                  }

                  if(this.state.flightRow) {
                    let flight_index = tiles[wa_index].flights?.findIndex((p) => p.id === this.state.flightRow?.id)
                    if(flight_index > -1) {
                      this.handleRowClick(tiles[wa_index].flights[flight_index])
                    }
                  }
                })
              }
            }
          }
        } else {
          if(this.state.page > 1) {
            //DO NOTHING
            this.setState({
              tileInterval: setInterval(this.updateTiles, 5000),
              pauseEvent: false,
              spinnerForFlights: false
            })
          } else {
            this.setState({
              loading: false,
              spinnerForFlights: false,
              pauseEvent: false
            }, () => {
              notification.destroy()
              notification.error({
                message: constants.NO_DATA_ERROR,
                description: resp?.data?.message,
              })
              this.setState({ departingFlightsSpinner: false })
            })
          }
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true,
        spinnerForFlights: false
      })
    })
  }
  //FETCH WORK AREA DATA
  fetchWorkAreaData = async (workArea) => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      loading: this.state.page === 1 ? true : false,
      latestTimestamp: temp_timestamp,
      pauseEvent: true,
    })
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }

    let work_area = this.state.currentWorkArea?.toUpperCase() === 'ALL' ? '' : this.state.currentWorkArea
    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start ? start_date.format(dateFormat) : ''
    let end = this.state.end ? end_date.format(dateFormat) : ''
    let stime = this.state.stime ? start_date.format(timeFormat) : ''
    let etime = this.state.etime ? end_date.format(timeFormat) : ''
    let page = this.state.page

    let url = 'build_up_list?'
    if(work_area) {
      url = url + `work_area=${work_area}&`
    }
    url = url + `start=${start}&end=${end}&stime=${stime}&etime=${etime}&page=${page}&count=25`

    await Http.get(url, { headers }).then(async resp => {
      if(this.state.latestTimestamp === temp_timestamp) {
        if (resp?.data?.data?.length > 0) {
          if(workArea !== this.state.currentWorkArea) {
            //DO NOTHING
          } else {
            let tiles = cloneDeep(this.state.workAreaTiles)
            let wa_index = tiles?.findIndex((p) => p.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
            if(wa_index > -1) {
              if(!this.state.search) {
                resp?.data?.data?.map((item) => {
                  let id = tiles[wa_index].flights?.findIndex(p => p.id == parseInt(item.id))
                  if(id > -1) {
                    //DO NOTHING
                  } else {
                    if(this.processIndividualFlight(item) !== null) {
                      tiles[wa_index].flights.push(this.processIndividualFlight(item))
                    }
                  }
                })
                this.sort(tiles[wa_index].flights, true, 'dept_time')
                this.sort(tiles[wa_index].flights, true, 'time_to_dept')
                this.setState({
                  workAreaTiles: tiles,
                  flightGridData: tiles[wa_index].flights,
                  page: this.state.page + 1
                }, () => {
                  this.fetchWorkAreaData(this.state.currentWorkArea)
                })
              }
            }
          }
        } else {
          if(this.state.page > 1) {
            //DO NOTHING
            this.setState({
              tileInterval: setInterval(this.updateTiles, 5000),
              pauseEvent: false,
            })
          } else {
            this.setState({ loading: false }, () => {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: resp?.data?.message,
              })
            })
          }
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true,
        spinnerForFlights: false,
      })
    })
  }
  clearTilesInfo = () => {
    let workAreaArray = cloneDeep(this.state.workAreaTiles)
    workAreaArray?.map((_, index) => {
      workAreaArray[index].flights = []
      workAreaArray[index].data = {
        flight: [],
        awb: 0,
        count: 0,
        ember: 0,
        wgt: [],
        stat: false,
      }
    })
    return workAreaArray
  }
  //FETCH WORK AREA DATA ON SEARCH
  fetchWorkAreaDataOnSearch = async (type) => {
    //EMPTY QUEUE LIST
    this._queue = []
    let tiles = cloneDeep(this.state.workAreaTiles)
    if(this.state.search) {
      let all_tile_index = tiles.findIndex((wa) => wa.area_name?.toUpperCase() === 'ALL')
      if(type === 'search') {
        let url = `build_up_list?search=${this.state.search}`
        let headers = {
          station: this.state.station,
          page: PAGE.BUILDUP
        }
        await Http.get(url, { headers }).then(async resp => {
          if (resp?.data?.data?.length > 0) {
            let processedData = []
            resp?.data?.data.map((item) => {
              let flight = this.processIndividualFlight(item)
              if(flight !== null) {
                processedData.push(flight)
              }
            })
            this.setState({ searchGridData: processedData })

            processedData?.map((flight) => {
              if(flight?.ulds_out?.length > 0) {
                flight.ulds_out.map((uld) => {
                  let build_to_index = tiles.findIndex((wa) => wa.area_name?.toUpperCase() === uld?.build_to?.toUpperCase())
                  //ADD TILE DATA FOR EACH TILE
                  if(build_to_index > -1) {
                    if(uld?.awbs_in?.length > 0) {
                      uld.awbs_in.map((awb) => {
                        if(awb?.wgt) {
                          tiles[build_to_index].data.wgt.push(awb?.wgt)
                        }
                        if(awb?.handling_time_object?.handling_time_color === 'red') {
                          tiles[build_to_index].data.stat = true
                          tiles[build_to_index].data.count = tiles[build_to_index].data.count + 1
                        }
                        if(awb?.is_amber === true) {
                          tiles[build_to_index].data.ember = tiles[build_to_index].data.ember + 1
                        }
                        tiles[build_to_index].data.awb = tiles[build_to_index].data.awb + 1
                      })
                    }
                    if(!tiles[build_to_index].data.flight?.includes(flight?.flight_no)) {
                      tiles[build_to_index].data.flight.push(flight?.flight_no)
                    }
                  }

                  //ADD TILE DATA FOR ALL TILE
                  if(uld?.awbs_in?.length > 0) {
                    uld.awbs_in.map((awb) => {
                      if(awb?.wgt) {
                        tiles[all_tile_index].data.wgt.push(awb?.wgt)
                      }
                      if(awb?.handling_time_object?.handling_time_color === 'red') {
                        tiles[all_tile_index].data.stat = true
                        tiles[all_tile_index].data.count = tiles[all_tile_index].data.count + 1
                      }
                      if(awb?.is_amber === true) {
                        tiles[all_tile_index].data.ember = tiles[all_tile_index].data.ember + 1
                      }
                      tiles[all_tile_index].data.awb = tiles[all_tile_index].data.awb + 1
                    })
                  }

                  if(!tiles[all_tile_index].data.flight?.includes(flight?.flight_no)) {
                    tiles[all_tile_index].data.flight.push(flight?.flight_no)
                  }
                })
              }
            })
            // this.sortTiles(tiles)

            tiles[all_tile_index].flights = processedData
            this.setState({
              pauseEvent: false,
              workAreaTiles: [],
              flightGridData: [],
              ULDGridData: [],
              awbRow: null,
              flightRow: null,
              loading: false,
            }, () => this.setState({
              workAreaTiles: tiles,
              flightGridData: tiles[all_tile_index].flights,
            }))
          } else {
            this.setState({ loading: false }, () => {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: resp?.data?.message,
              })
            })
          }
        })
      } else if(type === 'click') {
        let processedData = cloneDeep(this.state.searchGridData)
        let all_tile_index = tiles.findIndex((wa) => wa.area_name?.toUpperCase() === 'ALL')

        processedData?.map((flight) => {
          if(flight?.ulds_out?.length > 0) {
            flight.ulds_out.map((uld) => {
              let build_to_index = tiles.findIndex((wa) => wa.area_name?.toUpperCase() === uld?.build_to?.toUpperCase())
              //ADD TILE DATA FOR EACH TILE
              if(build_to_index > -1) {
                if(uld?.awbs_in?.length > 0) {
                  uld.awbs_in.map((awb) => {
                    if(awb?.wgt) {
                      tiles[build_to_index].data.wgt.push(awb?.wgt)
                    }
                    if(awb?.handling_time_object?.handling_time_color === 'red') {
                      tiles[build_to_index].data.stat = true
                      tiles[build_to_index].data.count = tiles[build_to_index].data.count + 1
                    }
                    if(awb?.is_amber === true) {
                      tiles[build_to_index].data.ember = tiles[build_to_index].data.ember + 1
                    }
                    tiles[build_to_index].data.awb = tiles[build_to_index].data.awb + 1
                  })
                }
                if(!tiles[build_to_index].data.flight?.includes(flight?.flight_no)) {
                  tiles[build_to_index].data.flight.push(flight?.flight_no)
                }
              }

              //ADD TILE DATA FOR ALL TILE
              if(uld?.awbs_in?.length > 0) {
                uld.awbs_in.map((awb) => {
                  if(awb?.wgt) {
                    tiles[all_tile_index].data.wgt.push(awb?.wgt)
                  }
                  if(awb?.handling_time_object?.handling_time_color === 'red') {
                    tiles[all_tile_index].data.stat = true
                    tiles[all_tile_index].data.count = tiles[all_tile_index].data.count + 1
                  }
                  if(awb?.is_amber === true) {
                    tiles[all_tile_index].data.ember = tiles[all_tile_index].data.ember + 1
                  }
                  tiles[all_tile_index].data.awb = tiles[all_tile_index].data.awb + 1
                })
              }

              if(!tiles[all_tile_index].data.flight?.includes(flight?.flight_no)) {
                tiles[all_tile_index].data.flight.push(flight?.flight_no)
              }
            })
          }
        })
        this.sortTiles(tiles)

        let work_area_index = tiles.findIndex((wa) => wa.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
        if(this.state.currentWorkArea?.toUpperCase() === 'ALL') {
          tiles[all_tile_index].flights = processedData
        } else {
          tiles[work_area_index].flights = processedData
          if(work_area_index > -1) {
            processedData?.map((flight, flight_index) => {
              tiles[work_area_index].flights[flight_index].ulds_out = flight?.ulds_out?.filter((uld) => uld?.build_to?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
            })
            tiles[work_area_index].flights = tiles[work_area_index].flights.filter((item) => item?.ulds_out?.length > 0) || []

            let temp_arr = []
            tiles[work_area_index].flights?.map((flight) => {
              flight = this.processIndividualFlight(flight)
              if(flight !== null) {
                temp_arr.push(flight)
              }
            })
            tiles[work_area_index].flights = temp_arr
          }
        }

        this.setState({
          pauseEvent: false,
          workAreaTiles: [],
          flightGridData: [],
          ULDGridData: [],
          awbRow: null,
          flightRow: null,
          loading: false,
        }, () => this.setState({
          workAreaTiles: tiles,
          flightGridData: this.state.currentWorkArea?.toUpperCase() === 'ALL' ? tiles[all_tile_index].flights : tiles[work_area_index].flights
        }))
      }
    } else {
      this.setState({
        pauseEvent: true,
        searchGridData: [],
      }, () => {
        this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
        this.fetchTiles()
      })
    }
  }
  //SORT FLIGHT ARRAY
  sort = (array, on_load, type) => {
    if(type === 'prod_prior') {
      let sorted = array.sort((a, b) => {
        if (a[type] === null) {
          return 1
        }
        if (b[type] === null) {
          return -1
        }
        if (a[type] === b[type]) {
          return 0
        }
        return a[type] < b[type] ? -1 : 1
      })
      return sorted
    } else {
      array && array.sort(function(a, b) {
        var keyA = type === 'time_to_dept' || type === 'flights_out_date' ? moment(a[type]).valueOf() : a[type]
        var keyB = type === 'time_to_dept' || type === 'flights_out_date' ? moment(b[type]).valueOf() : b[type]
        // Compare the fields
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })
    }

    if(!on_load) {
      this.setState({ flightGridData: [] }, () => this.setState({ flightGridData: array }))
    }
  }
  tilesSort = (array) => {
    array?.sort(function(a, b) {
      var keyA = a?.area_name
      var keyB = b?.area_name
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    const sorter = (a, b) => {
      if(!a['isMaster'] && b['isMaster']){
        return -1
      }
      if(a['isMaster'] && !b['isMaster']){
        return 1
      }
      return a['area_name'] - b['area_name']
    }
    array.sort(sorter)
  }
  //CALCULATE FLIGHT FPE
  getFPE = (flight) => {
    let fpe = 0
    let dest_type = ''

    let details = this.state.stationsData?.find((stn) => stn?.code === flight?.dest?.arriv) || ''
    if(details) {
      if(country.includes(details?.country?.toUpperCase())) {
        dest_type = 'EXPORT-D'
      } else {
        dest_type = 'EXPORT-I'
      }
      fpe = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)?.details?.fpe
    }
    return fpe
  }
  //PROCESS INDIVIDUAL FLIGHT
  processIndividualFlight = (flight) => {
    //NOTES
    flight.notes = flight?.notes_count || 0
    //CALCULATE DEPARTURE TIME
    flight.dept_time = ''
    flight.dept_prefix = 'S'
    if(flight?.dept) {
      if(flight?.dept?.act) {
        flight.dept_prefix = 'A'
        flight.dept_time = moment.utc(flight.dept.act).tz(this.state.timezone).valueOf()
      }
      else if(flight?.dept?.eta) {
        flight.dept_prefix = 'E'
        flight.dept_time = moment.utc(flight.dept.eta).tz(this.state.timezone).valueOf()
      }
      else if(flight?.dept?.schdl) {
        flight.dept_prefix = 'S'
        flight.dept_time = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
      } else if(flight?.dept_date) {
        flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
      } else {
        flight.dept_time = moment(flight.flight_date).valueOf()
      }
    } else if(flight?.dept_date) {
      flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else {
      flight.dept_time = moment(flight.flight_date).valueOf()
    }
    //CALC FLIGHT WEIGHT
    let unit = flight?.wgt?.unit
    let value = flight?.wgt?.value
    flight.weight_lbs = 0
    flight.weight_kg = 0
    if(unit === 'LBS' || unit === 'L' || unit === 'LB' || unit === 'LBR') {
      flight.weight_lbs = value //ROUNDED TO NEAREST INTEGER
      flight.weight_kg = weightCalculator(value, 'LBS')
    } else if(unit === 'K' || unit === 'KG' || unit === 'KGM') {
      flight.weight_kg = value //ROUNDED TO NEAREST INTEGER
      flight.weight_lbs = weightCalculator(value, 'KGM')
    }

    flight.booked_kg = 0
    flight.booked_lbs = 0
    flight.ready_kg = 0
    flight.ready_lbs = 0
    flight.gate_kg = 0
    flight.gate_lbs = 0
    flight.product_arr = []
    flight.location_arr = []
    flight.wrong_gate = []
    flight.handling_time_amber = null
    flight.handling_time = null
    flight.handling_time_color = 'grey'

    let all_ulds_out = []
    if(!flight?.ulds_out) {
      flight.ulds_out = []
    }
    if(flight?.ulds_out?.length > 0) {
      //CONCAT AWB SEGMENTS WITH ULDS OUT
      flight.ulds_out.map((uld) => {
        all_ulds_out.push(uld?.uld_no)
        uld?.awbs_in?.map((awb) => {
          all_ulds_out.push(formatAWBNo(awb?.awb_no))
        })
      })
      flight = this.processUlds(flight, all_ulds_out)
    } else {
      flight = this.processUlds(flight, all_ulds_out)
    }

    let uldPositionsObj = this.calcULDPositions(flight)
    flight.actual = {
      MD: uldPositionsObj?.MD,
      P96: uldPositionsObj?.P96,
      P88: uldPositionsObj?.P88,
      LD3: uldPositionsObj?.LD3,
      LD2: uldPositionsObj?.LD2,
    }
    if(flight?.cargo?.p96 || flight?.cargo?.p88 || flight?.cargo?.ld3 || flight?.cargo?.ld2 || flight?.actual?.MD || flight?.actual?.P96 || flight?.actual?.P88 || flight?.actual?.LD3 || flight?.actual?.LD2){
      flight.showULDpos = true
    } else {
      flight.showULDpos = false
    }

    //GET LAST UPDATED INFO
    flight.last_updated_by = ''
    flight.last_updated_at = ''
    if(flight?.last_updated?.length > 0) {
      let user_obj = this.state.userList?.find((item) => item?.id === parseInt(flight.last_updated[flight.last_updated.length - 1]?.user_id))
      flight.last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
      flight.last_updated_at = moment.utc(flight.last_updated[flight.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
    }

    //CALCULATE COUNTDOWN TIMER
    flight.fpe = this.getFPE(flight)
    //CALCULATE COUNTDOWN TIMER
    let time_left = moment(flight.dept_time).diff(moment().tz(this.state.timezone), 'minutes')
    //SUBTRACT FPE
    time_left = time_left - (1*(flight.fpe || 0))
    flight.time_to_dept = moment().add(time_left, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    //CALCULATE PAX CAPACITY, CONT TYPE, AFT AND FWS CONFIG.
    flight.pax_cap = 0
    flight.aft_conf = null
    flight.fwd_conf = null
    flight.cont_type = null
    flight.body_type = null
    flight.acpt_body = null
    if(this.state.fleetData?.length > 0) {
      let filter = this.state.fleetData.filter((item) => item?.details?.ac_code === flight?.ac_type)
      if(filter?.length > 0) {
        flight.pax_cap = filter[0]?.details?.pax_cap
        flight.aft_conf = filter[0]?.details?.aft_conf
        flight.fwd_conf = filter[0]?.details?.fwd_conf
        flight.cont_type = filter[0]?.details?.cont_type
        flight.acpt_body = filter[0]?.details?.ac_type === 'WB' ? true : false
        flight.body_type = filter[0]?.details?.ac_type || null
      }
    }
    //CHECK FOR SHOW RED FLIGHTS
    if(this.state.showRedFlights) {
      //CHECK IF CURRENT SELECTED FLIGHT HAS AWBS OR NO
      if(this.state.flightRow && this.state.flightRow?.id === flight?.id && this.state.flightRow?.handling_time_color !== 'red') {
        this.setState({
          ccRecord: null,
          flightRow: null,
          awbRow: null,
          prodCode: null,
          filterAWBGridData: [],
          ULDGridData: [],
          expanded: [],
          productDropdownData: [],
          locationDropdownData: [],
          prodFilters: [],
          locationFilters: [],
          pendingRadioDisabled: true,
          builtupRadioDisabled: true,
        })
      } else if(this.state.flightRow && this.state.flightRow?.id === flight?.id) {
        this.setState({ flightRow: flight })
      }
    } else if(this.state.flightRow && this.state.flightRow?.id === flight?.id) {
      this.setState({ flightRow: flight })
    }
    return flight?.ulds_out?.length > 0 ? flight : null
  }
  //PROCESS ULDS
  processUlds = (flight, all_ulds_out) => {
    if(flight?.awb_segments?.length > 0 && this.concatSegments(flight, all_ulds_out)?.length > 0 && !flight.is_segments_added) {
      flight.ulds_out.push(...this.concatSegments(flight, all_ulds_out))
      flight.is_segments_added = true
    }

    if(this.state.currentWorkArea?.toUpperCase() !== 'ALL') {
      // flight.ulds_out = flight.ulds_out?.filter((item) => item?.build_to?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
      flight.ulds_out = flight.ulds_out?.filter((item) => item?.master && !item?.info?.fps ?
        item?.dash_build_to?.toUpperCase() === this.state.currentWorkArea?.toUpperCase() :
        item?.build_to?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
    }
    flight.ulds_out.map((uld) => {
      uld.type = this.checkULDType(uld)
      uld.sort_blk = !uld?.blk || uld?.type === 'CART' ? 'A' : !uld?.is_segment ? 'B' : 'C'
      uld.sort_oc = uld?.rdy ? 'A' : 'B'
      uld.awb_prod_code = []
      uld.awb_location = []
      uld.avl_pcs = 0
      uld.awb_wgt_kgs = 0
      uld.awb_wgt_lbs = 0
      uld.awb_vol_value = ''
      uld.awb_vol_unit = ''
      uld.handling_time_color = 'grey'
      uld.position_obj = this.state.uldMappingData?.find((item) => item?.uld_prefix === uld?.uld_no?.slice(0, 3) && item?.ac_types?.includes(flight?.ac_type)) || null

      //CALC ULD TARE WEIGHT
      uld.tare_wgt_kg = 0
      uld.tare_wgt_lbs = 0
      if(uld?.type !== 'CART' && uld?.position_obj?.weight) {
        let unit = uld?.position_obj?.weight?.unit
        let value = uld?.position_obj?.weight?.value
        if(unit === 'L' || unit === 'LBS' || unit === 'LBR') {
          uld.tare_wgt_lbs = parseFloat(value)
          uld.tare_wgt_kg = weightCalculator(parseFloat(value), 'LBS')
        } else if(unit === 'KG' || unit === 'KGM') {
          uld.tare_wgt_kg = parseFloat(value)
          uld.tare_wgt_lbs = weightCalculator(parseFloat(value), 'KGM')
        }
      }

      if(uld?.awbs_in?.length > 0) {
        uld.awbs_in.map((awb, index) => {
          if( this.state.prodPriorityData.length >0){
            let pp_index = this.state.prodPriorityData.findIndex((p) => p.p_code?.toUpperCase() === awb?.awb?.prod_code?.toUpperCase())
            if(pp_index > -1) {
              uld.awbs_in[index].prod_prior = this.state.prodPriorityData[pp_index].priority
            } else {
              uld.awbs_in[index].prod_prior = null
            }
          } else {
            uld.awbs_in[index].prod_prior = null
          }
          let recAct_id = this.state.recoveryActions.findIndex((p) => parseInt(p.id) === parseInt(awb?.recovery_actions_id))
          if(recAct_id < 0) {
            awb.recovery_actions_id = null
          }
          awb.flight_out = flight.id
          awb.uld_in = uld.id
          awb.uld_no = uld?.uld_no
          if(uld.awb_prod_code && !uld.awb_prod_code?.includes(awb?.awb?.prod_code) && awb?.awb?.prod_code) {
            uld.awb_prod_code.push(awb?.awb?.prod_code)
          }
          //STORE PROD_CODE IN ARR
          if(awb?.awb?.prod_code && !flight.product_arr?.includes(awb?.awb?.prod_code)) {
            flight.product_arr.push(awb?.awb?.prod_code)
          }
          //AWB STAGE
          let page = ''
          if(!awb?.inbound || (awb?.inbound && !awb?.location)) page = 'INBOUND'
          else page = 'BUILDUP'

          awb.handling_time_object = this.calcHandlingTime(flight, awb,
            this.state.systemParameters?.length > 0 && this.state.systemParameters.filter((item) => item.station === this.state.station) || [],
            this.state.defaultSystemParameters?.length > 0 && this.state.defaultSystemParameters.filter((item) => item.station === this.state.station) || [], this.state.stationsData, page) //HANDLING TIME CALCULATOR

          if(awb?.is_segment) {
            awb.handling_time_object.handling_time_color = 'grey'
            awb.handling_time_object.handling_time = moment.utc().tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm')
          }

          //SET HT OUTSIDE FOR SORTING PURPOSES
          awb.ht_sort = awb.handling_time_object.handling_time

          //SET AMBER INDICATOR ON LOAD
          awb.is_amber = false
          awb.handling_time_amber = moment(awb?.handling_time_object?.handling_time).subtract(this.state.amber, 'minutes').format('YYYY-MM-DD HH:mm')
          if(awb?.recovery_actions_id) {
            awb.is_amber = false
            awb.handling_time_object.handling_time_color = awb?.is_segment ? 'grey' : 'green'
          }

          if(moment(awb?.handling_time_object?.handling_time).valueOf() < moment().valueOf()) {
            awb.is_amber = false
            awb.handling_time_object.handling_time_color = awb?.is_segment ? 'grey' : awb?.recovery_actions_id || awb?.dept ? 'green' : 'red'
          } else {
            if(moment(awb.handling_time_amber).valueOf() < moment().valueOf()) {
              awb.is_amber = true
            } else {
              awb.handling_time_object.handling_time_color = awb?.is_segment ? 'grey' : 'green'
            }
          }

          //SET LEAST AWB HANDLING TIME TO FLIGHT
          if(!flight.handling_time && moment(awb?.handling_time_object?.handling_time).valueOf() > moment().valueOf()) {
            flight.handling_time = awb?.handling_time_object?.handling_time
            flight.handling_time_amber = awb.handling_time_amber
          } else if(moment(flight.handling_time).valueOf() > moment(awb?.handling_time_object?.handling_time).valueOf() &&
              moment(awb?.handling_time_object?.handling_time).valueOf() > moment().valueOf()) {
            flight.handling_time = awb?.handling_time_object?.handling_time
            flight.handling_time_amber = awb.handling_time_amber
          }

          //SET AWB COLOR GREEN IF IN CART/ULD
          if(uld?.type !== 'BLK' || awb?.recovery_actions_id) {
            awb.is_amber = false
            awb.handling_time_object.handling_time_color = awb?.is_segment ? 'grey' : 'green'
          }

          //CHECK LOCAL/TRANSFER
          awb.shipment_type = 'Local'
          if(awb?.dest !== this.state.station || awb?.org !== this.state.station) {
            awb.shipment_type = 'Transfer'
          }
          //AWB LOCATION LOGIC
          if(!awb?.inbound) {
            let flight_no = awb?.flights_in?.flight_no
            let flight_date = moment(awb?.flights_in?.flight_date).format(dateSFormat)
            awb.location = flight_no +'/'+ flight_date
          }
          //CARGO AT WRONG GATE LOGIC
          awb.flight_gate = ''
          awb.location2 = format_gate(awb?.location)

          //PUSH LOCATIONS
          if(uld.awb_location && !uld.awb_location?.includes(awb?.location2) && awb?.location2) {
            uld.awb_location.push(awb?.location2)
          }
          if(!flight.location_arr?.includes(awb.location2)) {
            flight.location_arr.push(awb.location2)
          }
          if(awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null || awb?.prev_location !== 'UNKNOWN')) {
            let temp_awb_gate = ''
            if(flight?.dept?.gate) {
              awb.flight_gate = '0'+flight?.dept?.gate
              if(awb?.location?.length === 3 && awb?.location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}$/g)) {
                temp_awb_gate = awb?.location?.slice(1,2) + awb?.location?.slice(0,1) + awb?.location?.slice(2)
              } else if(awb?.location.length === 4 && awb?.location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{2}$/g)) {
                temp_awb_gate = awb?.location?.slice(1)
              } else if(awb?.location.length === 4 && awb?.location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}$/g)) {
                temp_awb_gate = awb?.location?.slice(1)
              }

              if(awb.flight_gate?.length === 3 && awb.flight_gate?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}$/g)) {
                awb.flight_gate = awb.flight_gate?.slice(1,2) + awb.flight_gate?.slice(0,1) + awb.flight_gate?.slice(2)
              } else if(awb.flight_gate.length === 4 && awb.flight_gate?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{2}$/g)) {
                awb.flight_gate = awb.flight_gate?.slice(1)
              } else if(awb.flight_gate.length === 4 && awb.flight_gate?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}$/g)) {
                awb.flight_gate = awb.flight_gate?.slice(1)
              }

              if(temp_awb_gate !== awb.flight_gate) {
                flight.wrong_gate.push(awb)
              }
            }
          }
          //AWB HISTORY
          // awb.history = []
          // if(awb?.goods_infos_histories?.length > 0) {
          //   awb.history = this.processAWBHistory(awb?.goods_infos_histories)
          // }
          //CHECK BUILDUP/PENDING STATUS
          awb.is_completed = false
          if(!uld?.blk && (awb?.man && awb?.rdy)) {
            awb.is_completed = true
          }
          if(uld?.blk && awb?.man) {
            awb.is_completed = true
          }
          //ADD AWB WEIGHT FOR FLIGHTS BOOKED/READY/AT_GATE
          let unit = awb?.wgt?.unit
          let value = awb?.wgt?.value
          if(unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
            flight.booked_lbs += parseFloat(value)
            flight.booked_kg += weightCalculator(parseFloat(value), 'LBS')
            if(!uld?.blk) {
              flight.ready_lbs += parseFloat(value)
              flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
            } else {
              if((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                flight.ready_lbs += parseFloat(value)
                flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              }
            }

            // if(!uld?.blk) {
            //   if(uld?.rdy) {
            //     flight.ready_lbs += parseFloat(value)
            //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
            //   }
            // } else {
            //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
            //     flight.ready_lbs += parseFloat(value)
            //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
            //   }
            // }

            if(awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
              flight.gate_lbs += parseFloat(value)
              flight.gate_kg += weightCalculator(parseFloat(value), 'LBS')
            }
          } else if(unit === 'K' || unit === 'KG' || unit === 'KGM') {
            flight.booked_kg += parseFloat(value)
            flight.booked_lbs += weightCalculator(parseFloat(value), 'KGM')

            if(!uld?.blk) {
              flight.ready_kg += parseFloat(value)
              flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
            } else {
              if((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                flight.ready_kg += parseFloat(value)
                flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              }
            }

            // if(!uld?.blk) {
            //   if(uld?.rdy) {
            //     flight.ready_kg += parseFloat(value)
            //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
            //   }
            // } else {
            //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
            //     flight.ready_kg += parseFloat(value)
            //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
            //   }
            // }

            if(awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
              flight.gate_kg += parseFloat(value)
              flight.gate_lbs += weightCalculator(parseFloat(value), 'KGM')
            }
          }
          //AIRWAYBILL COLUMN LOGIC
          awb.format_awb = ''
          if(awb?.awb_no) {
            awb.format_awb = formatAWBNo(awb?.awb_no)
          }

          //ADD AWB WEIGHTS
          let wgt = awb?.wgt?.value
          awb.wgt_kg = 0, awb.wgt_lbs = 0
          if(unit === 'L' || unit === 'LBS' || unit === 'LBR') {
            awb.wgt_lbs = parseFloat(wgt)
            awb.wgt_kg = weightCalculator(parseFloat(wgt), 'LBS')
          } else if(unit === 'KG' || unit === 'KGM') {
            awb.wgt_kg = parseFloat(wgt)
            awb.wgt_lbs = weightCalculator(parseFloat(wgt), 'KGM')
          }

          //IF NO INBOUND OBJECT, ADD AWB PCS TO AVL_PCS
          if(!awb.inbound) {
            awb.avl_pcs = awb?.pcs || 0
          }
          //ADD AWB WEIGHTS TO ULD
          uld.awb_wgt_kgs+=(awb.wgt_kg || 0)
          uld.awb_wgt_lbs+=(awb.wgt_lbs || 0)
          uld.avl_pcs+=(awb?.avl_pcs || 0)
          uld.awb_vol_value+=(awb?.vol?.value || 0)
          uld.awb_vol_unit = awb?.vol?.unit || ''

          //FORMAT AWB COLUMN STRING
          // awb.format_awb = awb.format_awb +
          // `${awb?.avl_pcs || 0} |
          // ${this.state.weight_unit === 'KG' ? formatWeight(awb.wgt_kg) + 'KG' : this.state.weight_unit === 'LB' ? formatWeight(awb.wgt_lbs) + 'LB' : ''} |
          // ${awb?.vol?.value ? parseFloat(awb?.vol?.value)?.toFixed(2) : 0}${awb?.vol?.unit || ''} |
          // ${awb?.org + '-' + awb?.dest}`

          //AWB STATUS LOGIC
          let fltno = flight?.flight_no || null
          let seg_fltno = awb?.segs_out?.flight_no || null
          if(fltno && (fltno.charAt(fltno.length-1) == 'L' || fltno.charAt(fltno.length-1) == 'S') ) {
            fltno = fltno.slice(0, -1)
          }
          if(seg_fltno && (seg_fltno.charAt(seg_fltno.length-1) == 'L' || seg_fltno.charAt(seg_fltno.length-1) == 'S') ) {
            seg_fltno = seg_fltno.slice(0, -1)
          }
          awb.status = ''
          if(seg_fltno &&
            `${seg_fltno}/${moment(awb?.segs_out?.flight_date).format(dateSFormat)}` !==
            `${fltno}/${moment(flight?.flight_date).format(dateSFormat)}`) {
            awb.status = `BOOKED FOR ${awb?.segs_out?.flight_no}/${moment(awb?.segs_out?.flight_date).format(dateSFormat)?.toUpperCase()}`
          } else if(awb?.dept) {
            awb.status = 'OUT FFM RCVD'
          } else {
            if(awb?.man && uld?.type !== 'BLK' && awb?.rdy) {
              awb.status = 'BUILT UP'
            } else if(awb?.man/* && (uld?.type === 'BLK' || uld?.blk)*/) {
              awb.status = 'ASSIGNED'
            } else {
              if(!awb?.inbound) {
                let eta = ''
                let difference = ''
                if(awb?.flights_in?.arr) {
                  eta = awb?.flights_in?.arr ? getFlightDateTime(awb?.flights_in, awb?.flights_in?.arriv_date) : ''
                } else {
                  let split = awb?.flights_in?.dest?.scheduled_arriv_time?.match(/.{1,2}/g) || []
                  if(split?.length > 0) {
                    eta = `${moment.tz(awb?.flights_in?.arriv_date, this.state.timezone)?.set({
                      hours: split[0],
                      minutes: split[1],
                    }).format('YYYY-MM-DD HH:mm:ss')}`
                  }
                }
                difference = moment.tz(eta, this.state.timezone).diff(moment.tz(this.state.timezone), 'minutes')
                awb.status = 'ETA'
                awb.status_countdown = eta ? moment.tz(this.state.timezone).add(difference, 'minutes') : ''
              }
              else if(awb?.inbound) {
                awb.status = 'CHECKED IN'
              }
              else if(uld?.master && uld?.uld_dispo?.toUpperCase() === 'BREAKDOWN') {
                awb.status = 'BROKEN DOWN'
              }
            }
          }

          if(awb?.is_segment) {
            awb.blk = true
            awb.status = awb?.segs_out?.flights_out ?
              awb?.segs_out?.flights_out?.flight_no !== flight?.flight_no ?
                `ASSIGNED TO ${awb?.segs_out?.flights_out?.flight_no}/${moment(awb?.segs_out?.flight_date).format(dateSFormat)?.toUpperCase()}` :
                '' :
              'NOT RECEIVED'
            uld.avl_pcs = awb?.space?.pcs
            if(uld.awb_location && !uld.awb_location?.includes(awb?.location2) && awb?.location2) {
              uld.awb_location.push('UNK')
            }
            if(uld.awb_prod_code && !uld.awb_prod_code?.includes(awb?.awb?.prod_code) && awb?.awb?.prod_code) {
              uld.awb_prod_code.push(awb?.awb?.prod_code)
            }
            uld.awb_vol_value = awb?.space?.vol
            uld.awb_vol_unit = awb?.vol_unit
            if(awb?.wgt_unit === 'L' || awb?.wgt_unit === 'LB' || awb?.wgt_unit === 'LBS' || awb?.wgt_unit === 'LBR') {
              uld.awb_wgt_kgs = weightCalculator(parseFloat(awb?.space?.wgt), 'LBS')
              uld.awb_wgt_lbs = parseFloat(awb?.space?.wgt)

              flight.booked_kg += weightCalculator(parseFloat(awb?.space?.wgt), 'LBS')
              flight.booked_lbs += parseFloat(awb?.space?.wgt)
            } else if(awb?.wgt_unit === 'K' || awb?.wgt_unit === 'KG' || awb?.wgt_unit === 'KGM') {
              uld.awb_wgt_kgs = parseFloat(awb?.space?.wgt)
              uld.awb_wgt_lbs = weightCalculator(parseFloat(awb?.space?.wgt), 'KGM')

              flight.booked_kg += parseFloat(awb?.space?.wgt)
              flight.booked_lbs += weightCalculator(parseFloat(awb?.space?.wgt), 'KGM')
            }
          }
        })
        this.sort(uld.awbs_in, true, 'ht_sort')
        //ULD INDICATOR NEEDED TO GET FLIGHT INDICATOR
        uld.handling_time_color = uld.awbs_in.filter((item) => item?.handling_time_object?.handling_time_color === 'red')?.length > 0 ? 'red' :
          uld.awbs_in.filter((item) => item?.handling_time_object?.handling_time_color === 'green')?.length > 0 ?'green' : 'grey'
        uld.is_amber = uld.awbs_in.filter((item) => item?.is_amber === true)?.length > 0 ? true : false
        if(uld.handling_time_color === 'red') {
          uld.is_amber = false
        }
        uld.prod_prior = uld?.type === 'BLK' && uld.awbs_in[0]?.awb?.prod_code && uld.awbs_in[0]?.status !== 'NOT RECEIVED' ? uld.awbs_in[0]?.prod_prior : null
      }
    })
    flight.tare_wgt_kg = this.addTareWgt(flight)?.weight_kg || 0
    flight.tare_wgt_lbs = this.addTareWgt(flight)?.weight_lbs || 0
    flight.ready_kg += parseFloat(flight.tare_wgt_kg)
    flight.ready_lbs += parseFloat(flight.tare_wgt_lbs)

    this.sort(flight.ulds_out, true, 'uld_no')
    this.sort(flight.ulds_out, true, 'sort_oc')
    this.sort(flight.ulds_out, true, 'sort_blk')
    //FLIGHT INDICATOR
    flight.handling_time_color = flight?.ulds_out?.filter((item) => item?.handling_time_color === 'red')?.length > 0 ? 'red' :
      flight?.ulds_out?.filter((item) => item?.handling_time_color === 'green')?.length > 0 ? 'green' : 'grey'
    flight.is_amber = flight?.ulds_out?.filter((item) => item?.is_amber === true)?.length > 0 ? true : false
    if(flight.handling_time_color === 'red') {
      flight.is_amber = false
    }
    return flight
  }
  //ADDING TARE WEIGHT TO FLIGHT
  addTareWgt = (flight) => {
    let weight_kg = 0
    let weight_lbs = 0
    if(flight?.ulds_out?.length > 0) {
      flight?.ulds_out?.map((item) => {
        if(item?.uld_type !== 'CART' && item?.position_obj?.ac_types?.includes(flight?.ac_type)) {
          let unit = item?.position_obj?.weight?.unit
          let value = item?.position_obj?.weight?.value
          if(unit === 'LBS' || unit === 'L' || unit === 'LB' || unit === 'LBR') {
            weight_lbs += value //ROUNDED TO NEAREST INTEGER
            weight_kg += weightCalculator(value, 'LBS')
          } else if(unit === 'K' || unit === 'KG' || unit === 'KGM') {
            weight_kg += value //ROUNDED TO NEAREST INTEGER
            weight_lbs += weightCalculator(value, 'KGM')
          }
        }
      })
    }
    return {
      weight_kg: weight_kg,
      weight_lbs: weight_lbs,
    }
  }
  //CONCAT SEGMENTS WITH ULDS OUT
  concatSegments = (flight, ulds) => {
    let uld_arr = []
    let unique_awbs = []
    flight?.awb_segments?.map((awb) => {
      if(!unique_awbs?.includes(awb?.awb_no)) {
      // if(flight?.flight_no !== awb?.segs_out?.flights_out?.flight_no && !unique_awbs?.includes(awb?.awb_no)) {
        unique_awbs.push(awb?.awb_no)
        let awb_no = formatAWBNo(awb?.awb_no)
        if(!ulds?.includes(awb_no)) {
          awb.awbs_in = []
          awb.blk = true
          awb.uld_no = awb_no
          awb.is_segment = true
          let awbObj = { awb: {} }
          awbObj.is_segment = true
          awbObj.inbound = {}
          awbObj.location = 'UNK'
          awbObj.segs_out = awb?.segs_out || null
          awbObj.awb = awb?.awb
          awbObj.awb_no = awb_no
          awbObj.space = awb?.space
          awbObj.org = awb?.awb?.org
          awbObj.dest = awb?.awb?.dest
          awbObj.vol_unit = awb?.awb?.vol_unit
          awbObj.wgt_unit = awb?.awb?.weight_unit
          awb.awbs_in.push(awbObj)
          uld_arr.push(awb)
        }
      }
    })
    return uld_arr
  }
  //HANDLING TIMES FUNCTION FOR BUILD UP
  calcHandlingTime = (flight, awb, system_params, default_system_params, stations, page) => {
    let awb_handling_time = null
    let awb_handling_time_color = null
    let awb_booking_confirmed = true
    //GET ESTIMATED TIME OF ARRIVAL VALUE
    let eta = true
    if(awb?.flights_in?.arr?.act || awb?.flights_in?.arr?.eta || awb?.flights_in?.arr?.schdl) {
      eta = awb?.flights_in?.arr?.act ? parseInt(awb.flights_in.arr.act) :
        awb?.flights_in?.arr?.eta ? parseInt(awb.flights_in.arr.eta) :
          awb?.flights_in?.arr?.schdl ? parseInt(awb.flights_in.arr.schdl) : false
    } else {
      eta = awb?.flights_in?.arriv_date ? moment.utc(awb?.flights_in?.arriv_date).tz(this.state.timezone).valueOf() : false
    }
    //GET FLIGHT OUT VALUE
    let flights_out_date = false
    if(flight?.flight_date) {
    //ADD FLIGHTS OUT DATE
      flights_out_date = moment.tz(`${moment(flight?.flight_date).format('YYYY-MM-DD')} ${flight?.org?.scheduled_dept_time?.substr(0, 2)}:${flight?.org?.scheduled_dept_time?.substr(2)}:00`, this.state.timezone_cty).utc().format('YYYY-MM-DD HH:mm')
    } else if(awb?.segs_out?.flight_date) {
    //ADD SEG OUT DATE
      flights_out_date = awb?.segs_out ? moment.tz(`${moment(awb?.segs_out?.flight_date).format('YYYY-MM-DD')} ${awb?.segs_out?.dept_time?.substr(0, 2)}:${awb?.segs_out?.dept_time?.substr(2)}:00`, this.state.timezone_cty).utc().format('YYYY-MM-DD HH:mm') : false
    }

    //CALCULATE HANDLING TIME
    let handling_times_object = calc(awb, system_params, default_system_params, stations, page)
    let handling_time = handling_times_object?.handling_time || 0
    let fpe = handling_times_object?.fpe || 0

    //GET CONNECTION TIME
    let conn = false
    if(eta) {
      conn = moment(eta).utc().add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
    } else {
      conn = moment.tz(awb?.flights_in?.arr?.utc, this.state.timezone_cty).utc().add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
    }

    if(awb?.org === this.state.station && awb?.info?.start) {
      conn = moment.utc(awb?.info?.start).add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
    } else {
      conn = false
    }

    //GET DIFFERENCE BETWEEN FLIGHT OUT DATE AND CONNECTION TIME TO GET ACTUAL HANDLING TIME
    if(awb?.recovery_actions_id || awb?.dept) {
      awb_handling_time_color = 'green'
      if(flights_out_date) {
        flights_out_date = moment(flights_out_date).add(-1*(fpe || 0), 'minutes').format('YYYY-MM-DD HH:mm')
        awb_handling_time = moment.utc(flights_out_date).tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm')
      } else {
        awb_handling_time = moment.utc(conn).local().format('YYYY-MM-DD HH:mm')
        awb_handling_time_color = awb?.recovery_actions_id || awb?.dept ? 'green' : 'red'
      }
    } else {
      if (flights_out_date) {
        flights_out_date = moment(flights_out_date).add(-1*(fpe || 0), 'minutes').format('YYYY-MM-DD HH:mm')
        awb_handling_time = moment.utc(flights_out_date).tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm')
        if(awb?.man && awb?.rdy) {
          awb_handling_time_color = 'green'
        } else if(awb?.recovery_actions_id) {
          // awb_handling_time = moment.utc(flights_out_date).tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm')
          awb_handling_time_color = 'green'
        } else if (moment(conn).valueOf() > moment(flights_out_date).valueOf()) {
          // awb_handling_time = moment.utc(flights_out_date).tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm')
          awb_handling_time_color = awb?.recovery_actions_id ? 'green' : 'red'
        } else {
          // awb_handling_time = moment.utc(flights_out_date).tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm')
          if(moment(moment.utc().format('YYYY-MM-DD HH:mm')).valueOf() < moment(flights_out_date).valueOf()) {
            awb_handling_time_color = 'green'
          } else {
            awb_handling_time_color =  'red'
          }
        }
      } else {
        awb_handling_time = moment.utc(conn).local().format('YYYY-MM-DD HH:mm')
        awb_handling_time_color = awb?.recovery_actions_id ? 'green' : 'red'
      }
    }

    return {
      handling_time_color: awb_handling_time_color || 'red',
      handling_time: awb_handling_time || moment.utc().tz(this.state.timezone).local().format('YYYY-MM-DD HH:mm'),
      booking_confirmed: awb_booking_confirmed || false,
    }
  }
  //CALCULATE ULD POSITIONS
  calcULDPositions = (flight) => {
    let a_md = 0
    let a_p96 = 0
    let a_p88 = 0
    let a_ld3 = 0
    let a_ld2 = 0

    if(flight?.ulds_out?.length > 0) {
      flight?.ulds_out?.map((item) => {
        if((!item?.uld_no?.startsWith('C') && !item?.uld_no?.endsWith('#')) && item?.position_obj?.ac_types?.includes(flight?.ac_type)) {
          switch (item?.position_obj?.uldtype) {
          case 'MD':
            a_md+=item?.position_obj?.position
            break
          case 'P96':
            a_p96+=item?.position_obj?.position
            break
          case 'P88':
            a_p88+=item?.position_obj?.position
            break
          case 'LD3':
            a_ld3+=item?.position_obj?.position
            break
          case 'LD2':
            a_ld2+=item?.position_obj?.position
            break
          default:
            break
          }
        }
      })
    }

    return {
      MD: a_md,
      P96: a_p96,
      P88: a_p88,
      LD3: a_ld3,
      LD2: a_ld2,
    }
  }
  //PROCESS AWB HISTORY
  processAWBHistory = (history) => {
    let historyArray = []

    //OMIT MANIFEST HISTORY
    history = history?.filter((item) => item?.event_name?.toUpperCase() !== 'MANIFEST RECEIVED')
    history?.map((item) => {
      let obj = {}
      switch (item?.event_name?.toUpperCase()) {
      case 'FOH':
        obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
        obj.status = 'FSU FOH RCVD'
        break
      case 'RCF':
        obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
        obj.status = 'Checked In - FSU RCF RCVD'
        break
      case 'RCS':
        obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
        obj.status = 'FSU RCS RCVD'
        break
      case 'FPS':
        obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
        obj.status = 'FSU FPS RCVD'
        break
      case 'MAN':
        obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
        obj.status = 'Built Up - FSU MAN RCVD'
        break
      case 'FLIGHT EVENT RECEIVED':
        if(item?.status?.status?.arr?.act) {
          obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
          obj.status = `Flight arrived ${moment.utc(item?.status?.status?.arr?.act).format(dateTimeFormat)} ${this.state.ext}`
        } else {
          let eta = item?.status?.status?.arr?.act ? item?.status?.status?.arr?.act :
            item?.status?.status?.arr?.eta ? item?.status?.status?.arr?.eta :
              item?.status?.status?.arr?.schdl ? item?.status?.status?.arr?.schdl :
                item?.status?.status?.arr?.utc ? moment.utc(item?.status?.status?.arr?.utc).valueOf() : ''

          let time_left = eta ? moment(eta).diff(moment().format('YYYY-MM-DD HH:mm:ss'), 'minutes') : ''
          obj.createdAt = moment.utc(item?.createdAt).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext
          obj.status = time_left ? `ETA at ${moment().tz(this.state.timezone).add(time_left, 'minutes').format(hourFormat)} ${this.state.ext}` : 'ETA Not Available'
        }
        break
      default:
        break
      }
      historyArray.push(obj)
    })
    return historyArray
  }
  //PROCESS INDIVIDUAL AWB
  processIndividualAWB = (awb) => {
    //ASSIGN FLIGHT OUT DATE TO OUTER AWB FOR SORT
    awb.flights_out_date = awb?.flights_out?.flight_date
    //AIRWAYBILL COLUMN LOGIC
    awb.format_awb = ''
    if(awb?.awb_no) {
      awb.format_awb = formatAWBNo(awb?.awb_no)
    }

    let unit = awb?.wgt?.unit
    let wgt = awb?.wgt?.value
    awb.wgt_kg = 0, awb.wgt_lbs = 0
    if(unit === 'L' || unit === 'LBS' || unit === 'LBR') {
      awb.wgt_lbs = parseFloat(wgt)
      awb.wgt_kg = weightCalculator(parseFloat(wgt), 'LBS')
    } else if(unit === 'KG' || unit === 'KGM') {
      awb.wgt_kg = parseFloat(wgt)
      awb.wgt_lbs = weightCalculator(parseFloat(wgt), 'KGM')
    }

    // awb.format_awb = awb.format_awb +
    //     `${awb?.pcs || 0} |
    //     ${this.state.weight_unit === 'KG' ? formatWeight(awb.wgt_kg) + 'KG' : this.state.weight_unit === 'LB' ? formatWeight(awb.wgt_lbs) + 'LB' : ''} |
    //     ${awb?.vol?.value ? parseFloat(awb?.vol?.value)?.toFixed(2) : 0}${awb?.vol?.unit || ''} |
    //     ${awb?.org + '-' + awb?.dest}`

    //AWB LOCATION LOGIC
    if(!awb?.inbound) {
      let flight_no = awb?.flights_in?.flight_no
      let flight_date = moment(awb?.flights_in?.flight_date).format(dateSFormat)
      awb.location = flight_no +'/'+ flight_date
    } else {
      awb.location = format_gate(awb?.location)
    }

    return awb
  }
  //SHOW NOTES
  showFlightNotes = (record) => {
    let note = record?.awb_no ? record.awb_no : record?.flight_no ? record.flight_no : null
    if(record.flight_no && record.flight_date){
      note = note+'/'+moment(record.flight_date).format('DDMMMYY')
    }
    let title  = record?.flight_no && record?.flight_date ? 'Flight Notes - '+ formatFlightNo(record.flight_no, record.flight_date) : record?.awb_no ? 'AWB Notes - '+ formatAWBNo(record.awb_no) : ''
    this.setState({
      note: note,
      flightNotes: true,
      notesTitle: title
    })
  }
  //HANDLE WORK AREA CLICK
  handleWorkAreaClick = (record) => {
    if(this.state.currentWorkArea?.toUpperCase() !== record?.area_name?.toUpperCase()) {
      //EMPTY QUEUE LIST
      this._queue = []
      this.setState({
        latestTimestamp: null,
        nbCheck: false,
        wbCheck: false,
        rfsCheck: false,
        buCheck: false,
        showRedFlights: false,
        hideDeparted: false,
        fetchFlightIds: [],
        servererror: false,
        currentWorkArea: record.area_name,
        loading: true,
        page: 1,
        asgn_page: 1,
        flightGridData: [],
        ULDGridData: [],
        flightRow: null,
        awbRow: null,
        tileInterval: clearInterval(this.state.tileInterval),
        pauseEvent: true,
        latestTilesTimestamp: '',
      },()=> {
        if(!this.state.search) {
          this.applyFilters()
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.updateTiles()
        } else {
          this.setState({
            latestTimestamp: null,
            loading: true,
            flightGridData: [],
            ULDGridData: [],
            flightRow: null,
            awbRow: null,
            workAreaTiles: this.clearTilesInfo(),
            fetchFlightIds: [],
            latestTilesTimestamp: '',
          }, () => this.fetchWorkAreaDataOnSearch('click'))
        }
      })
    }
  }
  //RENDER TILE INFO
  renderTileInfo = (item) => {
    //GREEN BADGE
    let tile_name = item?.area_name
    if(item?.data?.stat === false && item?.data?.awb > 0) {
      return (
        <>
          <h1>{tile_name}</h1>
          <span className='d-flex gap-10'>
            {item?.data?.ember > 0 && <span className='badge-circle badge-yellow'>
              {item?.data?.ember}
            </span>}
            <span className='badge-circle badge-green'></span>
          </span>
        </>
      )
    }
    //RED BADGE
    else if(item?.data?.stat === true) {
      return (
        <>
          <h1>{tile_name}</h1>
          <span className='d-flex gap-10'>
            {item?.data?.ember > 0 && <span className='badge-circle badge-yellow'>
              {item?.data?.ember}
            </span>}
            <span className={item?.data?.count > 0 && item?.data?.count < 10 ? 'badge-circle badge-red' : 'badge-capsule badge-red'}>
              {item?.data?.count || 0}
            </span>
          </span>
        </>
      )
    }
    //GREY BADGE
    else {
      return (
        <>
          <h1>{tile_name}</h1>
          <span className='badge-circle badge-grey'></span>
        </>
      )
    }
  }
  //CALC WEIGHT IN TONS
  calcTons = (weight_arr) => {
    let weight_lbs = 0, weight_kg = 0
    weight_arr?.map((item) => {
      if(item?.unit === 'L' || item?.unit === 'LBS' || item?.unit === 'LBR' || item?.unit === 'LB') {
        weight_lbs += parseFloat(item.value) //ROUNDED TO NEAREST INTEGER
        weight_kg += weightCalculator(parseFloat(item?.value), 'LBS')
      } else if(item?.unit === 'K' || item?.unit === 'KG' ||item?.unit === 'KGM') {
        weight_kg += parseFloat(item?.value) //ROUNDED TO NEAREST INTEGER
        weight_lbs += weightCalculator(parseFloat(item?.value), 'KGM')
      }
    })
    return `${(this.state.weight_unit === 'KG' ? weight_kg/1000 : weight_lbs/1000).toFixed(2)}`
  }
  //SET AWB DATA BASED ON ULD ROW CLICKED
  handleRowClick = async (record) => {
    if(record?.ulds_out?.length > 0) {
      this.setState({
        ccRecord: null,
        awbRow: null,
        prodCode: null,
        filterAWBGridData: [],
        ULDGridData: [],
        expanded: this.state.flightRow && this.state.flightRow?.id === record?.id ? this.state.expanded : [],
        productDropdownData: [],
        locationDropdownData: [],
        prodFilters: [],
        locationFilters: [],
        pendingRadioDisabled: true,
        builtupRadioDisabled: true,
      }, () => {
        this.setState({
          pendingRadioDisabled: record?.ulds_out?.filter((seg) => seg?.is_completed === false)?.length > 0 ? false : true,
          builtupRadioDisabled: record?.ulds_out?.filter((seg) => seg?.is_completed === true)?.length > 0 ? false : true,
          ULDGridData: record.ulds_out,
          productDropdownData: record?.product_arr,
          locationDropdownData: record?.location_arr,
          prodFilters: record?.product_arr,
          locationFilters: record?.location_arr,
        })
      })
    }
  }
  //SET ROW CLASSNAME ON ROW CLICK
  setRowClassName = (record, type) => record.id === this.state[type]?.id ? 'selected pointer' : 'pointer'
  //CC PLAN MODAL
  ccPlanModal = () => {
    const CCcolumns = [
      {
        title: 'DOD Team Comments',
        dataIndex: 'plan_comm',
        key: 'plan_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              disabled
              size='small'
              placeholder=''
              value={this.state.editTComm1 || ''}
              onChange={(e) => this.setState({ editTComm1: e.target.value })}
            />
            : <span>{record?.comm1}</span>)
      },
      {
        title: 'Warehouse Team Comments',
        dataIndex: 'dep_comm',
        key: 'dep_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm2 || ''}
              onChange={(e) => this.setState({ editTComm2: e.target.value })}
            />
            : <span>{record?.comm2}</span>)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.submit('edit')} ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearAllocTableForm()} ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=> this.editAllocTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.setState({ deleteAllocID: record.id }, () => this.submit('delete'))} okText='Yes' cancelText='No'>
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
        ),
      },
    ]
    return (
      <Modal
        title={`Cargo Capacity - ${this.state.flightNo}/${this.state.flightDate} - ${this.state.flightOrgDest}`}
        visible={this.state.ccPlanModalVisible}
        onOk={() => this.setState({
          ccRecord: null,
          ccPlanModalVisible: false,
          SLUSummary: [],
          PTUSummary: [],
          BuiltSummary: [],
          BLKSummary: null,
          selected: [],
          bagSummary: [],
          remainingP96: 0,
          remainingP88: 0,
          remainingLD3: 0,
          remainingLD2: 0,
          remainingMD: 0,
        })}
        onCancel={() => this.setState({
          ccRecord: null,
          ccPlanModalVisible: false,
          SLUSummary: [],
          PTUSummary: [],
          BuiltSummary: [],
          BLKSummary: null,
          selected: [],
          bagSummary: [],
        })}
        closable={false}
        footer={[
          <>
            {this.state.showSuccess && <div className='mr-4'><b><CheckCircleTwoTone twoToneColor='#52c41a' /> Load Plan updated successfully</b></div>}
            <Button
              onClick={() => this.setState({
                ccRecord: null,
                ccPlanModalVisible: false,
                showSuccess: false,
              })}
              type='primary'
              className='custom-button mr-4'
            >Close
            </Button>
          </>
        ]}
        width={'70%'}
        centered
        className='custom-modal pb-0 load-plan'
      >
        {this.state.modalLoading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} className='with-table'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <Card className='cargo-cap-card'>
              <div>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='buildup-cc'
                >
                  <Col xs={12} sm={6} md={4} lg={2} xl={2} xxl={2}>
                    <small className='info-title mb-1'>AC</small>
                    <p className='info-data'>{this.state.ccRecord?.ac_type || 'N/A'}</p>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={5} xl={5} xxl={4}>
                    <small className='info-title mb-1'>DEPT (E)</small>
                    <p className='info-data'>{this.state.ccRecord?.dept_time && `${this.state.ccRecord?.dept_prefix} ${moment.tz(this.state.ccRecord.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` || 'N/A'}</p>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
                    <small className='info-title mb-1'>P CAP/FCST</small>
                    <p className='info-data'>{this.state.ccRecord?.pax_cap || 0} / {this.state.ccRecord?.fcst || 0}</p>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={5} xl={3} xxl={3}>
                    <small className='info-title mb-1'>BAG POSNS</small>
                    <p className='info-data'>{/*this.state.editBagP96} / {this.state.editBagP88} / {*/this.state.editBagLD3} / {this.state.editBagLD2}</p>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={4} xl={3} xxl={4}>
                    <small className='info-title mb-1'>CONT TYPE</small>
                    <p className='info-data'>{this.state.editCont || 'N/A'}</p>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={5} xl={5} xxl={4}>
                    <small className='info-title mb-1'>OPT BUILD</small>
                    <Space className='h-auto'>
                      {this.state.hideMD ? <p className='info-data'>{this.state.editCargoP96} / {this.state.editCargoP88} / {this.state.editCargoLD3} / {this.state.editCargoLD2}</p> :
                        <p className='info-data'>{this.state.editCargoMD} / {this.state.editCargoP96} / {this.state.editCargoP88} / {this.state.editCargoLD3} / {this.state.editCargoLD2}</p>}
                      {/* <Button
                        className='custom-icon-button disabled-bg-none h-auto'
                        ghost
                        icon={<SearchOutlined />}
                        onClick={() => this.fetchFlightSummary()}
                        size='small'
                      /> */}
                    </Space>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
                    <div>
                      <small className='info-title mb-1'>Cargo pay load</small>
                      <p className='info-data'>{this.state.editWgtValue}{this.state.editWgtUnit}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
            <Row gutter={[24,0]} className=''>
              <Col xs={24} md={12} className='text-center'>
                <label className='custom-label'>Aft Hold Configuration</label>
                {this.state.previewAft ? <img width='400' height='200' className='border-dark p-1' src={this.state.previewAft || AftHold} alt={this.state.previewAft || AftHold} /> : <div className='config-placeholder'> No image available </div>}
              </Col>
              <Col xs={24} md={12} className='text-center'>
                <label className='custom-label'>Forward Hold Configuration</label>
                {this.state.previewFwd ? <img width='400' height='200' className='border-dark p-1' src={this.state.previewFwd || ForwardHold} alt={this.state.previewFwd || ForwardHold} /> : <div className='config-placeholder'> No image available </div>}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card
              className='cargo-cap-card'
            >
              <span className='text-uppercase fs-10 info-title'>Allowed ULD types</span>
              <div className='text-uppercase fs-14'>
                <Space size={[16, 0]} wrap>
                  {
                    this.state.editAllwdUldTypes?.map((item) => (
                      <span key={item} className='info-data'>{item}</span>
                    ))
                  }
                </Space>
              </div>
            </Card>

            <div className='my-3'>
              <Row>
                <Col xs={24} md={24} lg={6}>
                  <Text strong={true}>Load Planning Notes</Text>
                </Col>
                <Col xs={24} md={24} lg={18} className='text-right'>
                  <Space wrap>
                    <Text type='secondary' strong>Last Update:</Text>
                    <Space size={[48, 0]} direction='horizontal'>
                      <Text>{this.state.lastUpdatedAt ? this.state.lastUpdatedAt + ' ' + this.state.ext : 'N/A'}</Text>
                      <Text>{this.state.lastUpdatedBy ? this.state.lastUpdatedBy : 'N/A'}</Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </div>
            <div className='table-outer'>
              <Table
                className='custom-table w-100'
                dataSource={this.state.editAllocArray} columns={CCcolumns}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }
  //SAVE CHANGES
  submit = async (action) => {
    if(this.state.ccRecord?.id) {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {}
      let url = ''

      if(action == 'add' || action === 'edit') {
        url = `/flight_plan/${action}/${parseInt(this.state.ccRecord.id)}`
        if(this.state.editAllocRecord) {
          formdata.id = this.state.editAllocRecord?.id
        }
        formdata.comm1 = this.state.editAllocRecord ? this.state.editTComm1 || '' : this.state.editComm1 || ''
        formdata.comm2 = this.state.editAllocRecord ? this.state.editTComm2 || '' : this.state.editComm2 || ''
      } else if(action == 'delete') {
        url = `/flight_plan/${action}/${parseInt(this.state.ccRecord?.id)}/${parseInt(this.state.deleteAllocID)}`
      }

      let awaitMehod = action == 'add' || action === 'edit' ? Http.post(url, formdata, { headers }) : Http.delete(url, { headers })
      await awaitMehod.then((data) => {
        if (data && data?.status === 200) {
          //CLEAR LOAD PLAN FORM
          if(action == 'add') this.clearEditAllocForm()
          //CLEAR LOAD PLAN TABLE FORM
          if(this.state.editAllocRecord) this.clearAllocTableForm()

          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: 'Invalid Input',
      })
    }
  }
  //CLEAR ALLOCATIONS TABLE FORM
  clearAllocTableForm = () => {
    this.setState({
      editAllocRecord: null,
      deleteAllocID: null,
      editTComm1: '',
      editTComm2: '',
    })
  }
  //SET ALLOCATIONS VALUES
  editAllocTableForm = (record) => {
    this.setState({
      editAllocRecord: record,
      editTComm1: record?.comm1,
      editTComm2: record?.comm2,
    })
  }
  //FETCH FLIGHT SUMMARY
  fetchFlightSummary = async () => {
    await Http.get(this.summaryURL()).then((resp) => {
      if(resp?.data?.data) {
        this.calcSummary(resp?.data?.data)
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message,
        })
      }
    }).catch(() => {
      this.setState({ servererror : true }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
    })
  }
  //GENERATE SUMMARY URL
  summaryURL = () => {
    let contPresent = this.state.ccRecord?.cont_type?.length > 0 ? this.state.ccRecord?.cont_type : ''
    let selected = ''
    if(contPresent) {
      if(contPresent?.includes('LD2') && this.state.editBagLD2 > 0) {
        selected = 'LD2'
      } else if(contPresent?.includes('LD3') && this.state.editBagLD3 > 0) {
        selected = 'LD3'
      }
    }

    if(!selected) {
      if(this.state.editBagLD2 > 0) {
        selected = 'LD2'
      } else if(this.state.editBagLD3 > 0) {
        selected = 'LD3'
      }
    }

    let url = `summery?id=${this.state.ccRecord?.id}&fleet=${this.state.ccRecord?.ac_type}`
    //if(selected === 'LD2') {
    url += `&ld2=${this.state.editBagLD2 || 0}`
    //}
    //else if(selected === 'LD3') {
    url += `&ld3=${this.state.editBagLD3 || 0}`
    //}
    return url
  }
  //CALULATE SUMMARY DATA
  calcSummary = (data) => {
    let SLU3CharArr = []
    let SLUAll = []
    let PTU3CharArr = []
    let PTUAll = []
    let Built3CharArr = []
    let BuiltAll = []

    let blkObj = {}
    let blk_count_IT = 0
    let blk_count_CI = 0
    let weight_lbs_IT = 0
    let weight_lbs_CI = 0
    let weight_kg_IT = 0
    let weight_kg_CI = 0
    let pcs_IT = 0
    let pcs_CI = 0

    data?.summery?.map((item) => {
      if(item?.ulds_in?.blk) {
        if(item?.location) {
          blk_count_CI += 1
          pcs_CI += parseFloat(item?.vol?.value || 0) || 0
          if(item.wgt.unit === 'L' || item.wgt.unit === 'LB' || item.wgt.unit === 'LBR' || item.wgt.unit === 'LBS') {
            weight_lbs_CI += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_kg_CI += weightCalculator(parseFloat(item.wgt.value), 'LBS')
          } else if(item.wgt.unit === 'K' || item.wgt.unit === 'KG' || item.wgt.unit === 'KGM') {
            weight_kg_CI += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_lbs_CI += weightCalculator(parseFloat(item.wgt.value), 'KGM')
          }
        } else {
          blk_count_IT += 1
          pcs_IT += parseFloat(item?.vol?.value || 0) || 0
          if(item.wgt.unit === 'L' || item.wgt.unit === 'LB' || item.wgt.unit === 'LBR' || item.wgt.unit === 'LBS') {
            weight_lbs_IT += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_kg_IT += weightCalculator(item.wgt.value, 'LBS')
          } else if(item.wgt.unit === 'K' || item.wgt.unit === 'KG' || item.wgt.unit === 'KGM') {
            weight_kg_IT += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_lbs_IT += weightCalculator(item.wgt.value, 'KGM')
          }
        }
      } else if(item?.ulds_in?.uld_dispo && item?.ulds_in?.uld_dispo?.toUpperCase() !== 'BREAKDOWN'){
        let uldType = this.checkULDType(item?.ulds_in)
        if(uldType === 'PTU') {
          if(!PTUAll?.includes(item?.ulds_in?.uld_no)) {
            PTUAll.push(item?.ulds_in?.uld_no)
            PTU3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              location: item?.ulds_in?.location,
            })
          }
        } else if(uldType === 'SLU') {
          if(!SLUAll?.includes(item?.ulds_in?.uld_no)) {
            SLUAll.push(item?.ulds_in?.uld_no)
            SLU3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              location: item?.ulds_in?.location,
            })
          }
        }
      } else if(item?.ulds_in) {
        if(!BuiltAll.includes(item?.ulds_in?.uld_no)) {
          BuiltAll.push(item?.ulds_in?.uld_no)
          Built3CharArr.push({
            uld_no: item?.ulds_in?.uld_no,
            ready: item?.ulds_in?.rdy || true,
          })
        }
      }
    })

    blkObj.blk_count_CI = blk_count_CI
    blkObj.blk_count_IT = blk_count_IT
    blkObj.weight_lbs_CI = weight_lbs_CI
    blkObj.weight_lbs_IT = weight_lbs_IT
    blkObj.weight_kg_CI = weight_kg_CI
    blkObj.weight_kg_IT = weight_kg_IT
    blkObj.pcs_CI = pcs_CI?.toFixed(2) || 0
    blkObj.pcs_IT = pcs_IT?.toFixed(2) || 0

    this.setState({
      SLUSummary: SLU3CharArr || [],
      PTUSummary: PTU3CharArr || [],
      BuiltSummary: Built3CharArr || [],
      BLKSummary: blkObj || null,
      bagSummary: data?.bag?.length > 0 ? data.bag : []
    }, () => this.setState({ optimalBuild: true }))
  }
  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if(uld?.blk && (!uld?.uld_no?.startsWith('C') && !uld?.uld_no?.endsWith('#'))) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN' && uld?.master) {
        type = 'PTU'
      } else if(uld?.uld_no?.startsWith('C') || uld?.uld_no?.endsWith('#')) {
        type = 'CART'
      }
    }
    return type
  }
  //SEARCH MODAL
  searchModal = () => {
    let SLU = this.state.SLUSummary
    let PTU = this.state.PTUSummary
    let BUILT = this.state.BuiltSummary
    let BLK = this.state.BLKSummary
    let badSummary = this.state.bagSummary
    const obcolumns = [
      {
        title: 'MD',
        dataIndex: 'md',
        key: 'md',
        className: 'space-nowrap',
        render: (_, record) => <span>{record?.md || 0}</span>
      },
      {
        // title: 'P96',
        title: 'PMC',
        dataIndex: 'pmc',
        key: 'pmc',
        render: (_, record) => <span>{record?.pmc - this.state.editBagP96}</span>
      },
      {
        // title: 'P88',
        title: 'PAG',
        dataIndex: 'pag',
        key: 'pag',
        render: (_, record) => <span>{record?.pag - this.state.editBagP88}</span>
      },
      {
        title: 'LD3',
        dataIndex: 'ld3',
        key: 'ld3',
        render: (_, record) => <span>{record?.ld3 - this.state.editBagLD3}</span>
      },
      {
        title: 'LD2',
        dataIndex: 'ld2',
        key: 'ld2',
        render: (_, record) => <span>{record?.ld2 - this.state.editBagLD2}</span>
      },
    ]
    return (
      <Modal
        title= {`Optimal Build - ${this.state.ccRecord?.flight_no}/${moment(this.state.ccRecord?.flight_date).format(dateSFormat)} - ${this.state.ccRecord?.ac_type}`}
        visible={this.state.optimalBuild}
        onCancel={() => this.setState({ optimalBuild: false })}
        footer={false}
        width={1160}
        centered
        className='curved-modal pb-0'
        destroyOnClose={true}
        closeIcon={<Text type='secondary'><CloseCircleFilled /></Text>}
      >
        <Card size='small' className='custom-card custom-padding mb-4'
          title={
            <Space>
              <span>Cargo Summary</span>
              <Popover content={legendPopover} title='Legend'><InfoCircleFilled /></Popover>
            </Space>}>
          <Row gutter={[{
            xs: 8,
            sm: 16,
            lg: 24
          }, {
            xs: 8,
            sm: 16,
            lg: 24
          }]}>
            <Col xs={24} lg={16}>
              <Card title='On Hand' size='small' bordered={false} className='curved-card custom-padding'>
                <Row gutter={[8, 8]} className='p-2'>
                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>SLU</p>
                      {SLU?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {!key?.location ?
                              <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' /> :
                              <span className='float-right'>AS</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Potential TUs</p>
                      {PTU?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {!key?.location ?
                              <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' /> :
                              <span className='float-right'>AS</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Built Up</p>
                      {BUILT?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {key?.ready ?
                              <span style={{ color: '#D9001B' }} className='float-right'>O</span> :
                              <span style={{ color: '#95F204' }} className='float-right'>C</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Loose</p>
                      {/* IN TRANSIT */}
                      {BLK?.blk_count_IT > 0 && <p>
                        <span className='dib-w90'>
                          {`${BLK?.blk_count_IT} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            BLK?.weight_kg_IT : this.state.weight_unit === 'LB' ? BLK?.weight_lbs_IT : 0) || 0} | ${BLK?.pcs_IT}`}
                        </span>
                        <span>
                          <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' />
                        </span>
                      </p>}
                      {/* CHECKED IN */}
                      {BLK?.blk_count_CI > 0 && <p>
                        <span className='dib-w90'>
                          {`${BLK?.blk_count_CI} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            BLK?.weight_kg_CI : this.state.weight_unit === 'LB' ? BLK?.weight_lbs_CI : 0) || 0} | ${BLK?.pcs_CI}`}
                        </span>
                        <span>
                          <span className='float-right'>AS</span>
                        </span>
                      </p>}
                    </div>
                  </Col>

                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card title='Booked, Not Tendered' size='small' bordered={false} className='curved-card custom-padding'>
                <Row gutter={[8, 8]} className='p-2'>
                  <Col xs={24} sm={12} xl={12}>
                    <div className='cargo-summary'>
                      <p>SLU</p>
                      <p><span className='dib-w90'>MD</span><span>0</span></p>
                      <p><span className='dib-w90'>P96/P88</span><span>{this.state.ccRecord?.not_tendered?.udp || 0}</span></p>
                      <p><span className='dib-w90'>LD3</span><span>{this.state.ccRecord?.not_tendered?.ldc || 0}</span></p>
                      <p><span className='dib-w90'>LD2</span><span>{this.state.ccRecord?.not_tendered?.ldp || 0}</span></p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} xl={12}>
                    <div className='cargo-summary'>
                      <p>Loose</p>
                      <p>
                        <span className='dib-w90'>
                          {`${this.state.ccRecord?.not_tendered?.blk_count || 0} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            this.state.ccRecord?.not_tendered?.weight_kg || 0 :
                            this.state.weight_unit === 'LB' ? this.state.ccRecord?.not_tendered?.weight_lbs || 0 :
                              0) || 0} | ${this.state.ccRecord?.not_tendered?.pcs || 0}`}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card size='small' className='custom-card custom-padding' title='Available Configurations'>
          <Row gutter={[{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          },]} className='p-12'>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100'
                  dataSource={badSummary}
                  columns={obcolumns}
                  pagination={false}
                  rowKey='id'
                  rowSelection={{
                    type: 'checkbox',
                    onChange: (_, rows) => this.handleCheckboxChange(rows),
                    hideSelectAll: true,
                    selectedRowKeys: this.state.selected
                  }}
                />
              </div>
            </Col>
            <Col className='ml-auto'>
              <Button
                disabled={!this.state.bagSelected}
                type='primary'
                className='custom-button mr-4'
                onClick={this.updateBaggage}
              >Update
              </Button>
            </Col>
          </Row>
        </Card>
      </Modal>
    )
  }
  //UPDATE BAGGAGE POSITIONS
  updateBaggage = () => {
    this.setState({
      editCargoMD: this.state.bagSelected?.md || 0,
      editCargoP96: this.state.editBagP96 > 0 ? this.state.bagSelected?.pmc - this.state.editBagP96 : this.state.bagSelected?.pmc,
      editCargoP88: this.state.editBagP88 > 0 ? this.state.bagSelected?.pag - this.state.editBagP88 : this.state.bagSelected?.pag,
      editCargoLD3: this.state.editBagLD3 > 0 ? this.state.bagSelected?.ld3 - this.state.editBagLD3 : this.state.bagSelected?.ld3,
      editCargoLD2: this.state.editBagLD2 > 0 ? this.state.bagSelected?.ld2 - this.state.editBagLD2 : this.state.bagSelected?.ld2,
      // remainingP96: 0,
      // remainingP88: 0,
      // remainingLD3: 0,
      // remainingLD2: 0,
      // remainingMD: 0,
    }, () => this.setState({
      selected: [],
      bagSelected: null,
      optimalBuild: false,
      //showAlloc: true,
    }))
  }
  //HANDLE CHECKBOX CHANGE
  handleCheckboxChange = (selectedRow) => {
    this.setState({
      selected: [selectedRow[selectedRow?.length - 1]?.id] || [],
      bagSelected: selectedRow[selectedRow?.length - 1] || null
    })
  }
  //SET FILTERS
  setFilters = (type) => {
    let current_date = moment().tz(this.state.timezone).format(dateFormat)
    let selected_date_time = ''
    if(type === 'start') {
      selected_date_time = moment(this.state.start).format(dateFormat)

      if(selected_date_time < current_date) {
        this.setState({
          latestTimestamp: null,
          fetchFlightIds: [],
          tileInterval: clearInterval(this.state.tileInterval),
          workAreaTiles: this.clearTilesInfo(),
          page: 1,
          asgn_page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          end: moment(this.state.start).add(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          firstLoad: true,
          pauseEvent: true,
          latestTilesTimestamp: '',
        }, () => {
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.fetchTiles()
        })
      } else if(selected_date_time === current_date) {
        this.setState({
          latestTimestamp: null,
          fetchFlightIds: [],
          tileInterval: clearInterval(this.state.tileInterval),
          workAreaTiles: this.clearTilesInfo(),
          page: 1,
          asgn_page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
          firstLoad: true,
          pauseEvent: true,
          latestTilesTimestamp: '',
        }, () => {
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.fetchTiles()
        })
      } else if(selected_date_time > current_date) {
        this.setState({
          latestTimestamp: null,
          fetchFlightIds: [],
          tileInterval: clearInterval(this.state.tileInterval),
          workAreaTiles: this.clearTilesInfo(),
          page: 1,
          asgn_page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          firstLoad: true,
          pauseEvent: true,
          latestTilesTimestamp: '',
        }, () => {
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.fetchTiles()
        })
      }
    } else if(type === 'end') {
      selected_date_time = moment(this.state.end).format(dateFormat)

      if(selected_date_time < current_date) {
        this.setState({
          latestTimestamp: null,
          fetchFlightIds: [],
          tileInterval: clearInterval(this.state.tileInterval),
          workAreaTiles: this.clearTilesInfo(),
          page: 1,
          asgn_page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            second: 59,
          }),
          firstLoad: true,
          pauseEvent: true,
          latestTilesTimestamp: '',
        }, () => {
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.fetchTiles()
        })
      } else if(selected_date_time === current_date) {
        this.setState({
          latestTimestamp: null,
          fetchFlightIds: [],
          tileInterval: clearInterval(this.state.tileInterval),
          workAreaTiles: this.clearTilesInfo(),
          page: 1,
          asgn_page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
          firstLoad: true,
          pauseEvent: true,
          latestTilesTimestamp: '',
        }, () => {
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.fetchTiles()
        })
      } else if(selected_date_time > current_date) {
        this.setState({
          latestTimestamp: null,
          fetchFlightIds: [],
          tileInterval: clearInterval(this.state.tileInterval),
          workAreaTiles: this.clearTilesInfo(),
          page: 1,
          asgn_page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          start: moment(this.state.end).subtract(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          firstLoad: true,
          pauseEvent: true,
          latestTilesTimestamp: '',
        }, () => {
          this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
          this.fetchTiles()
        })
      }
    } else if(type === 'stime' || type === 'etime') {
      this.setState({
        latestTimestamp: null,
        fetchFlightIds: [],
        tileInterval: clearInterval(this.state.tileInterval),
        workAreaTiles: this.clearTilesInfo(),
        page: 1,
        asgn_page: 1,
        flightGridData: [],
        ULDGridData: [],
        flightRow: null,
        awbRow: null,
        loading: true,
        firstLoad: true,
        pauseEvent: true,
        latestTilesTimestamp: '',
      }, () => {
        this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
        this.fetchTiles()
      })
    }
  }
  //SET DATES DISABLED ON CONDITION
  disabledDate = (current) => {
    let prev = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
    let next = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
    return current && (current < moment(prev, dateFormat) || current > moment(next, dateFormat))
  }
  //UPDATE RECOVERY ACTION
  handleRecoveryActionChange = async (record, value, rebook) => {
    if(value) {
      let formdata = {}
      formdata.recovery_actions_id = value
      formdata.is_gen_rebook = rebook
      formdata.awb_stat = record?.is_amber? 'amber' : record?.handling_time_color || record?.stat || null
      formdata.reason = record?.is_amber? 'Withing Amber scope' : record?.handling_time_color || record?.stat ? 'FPE Elapsed' : null
      formdata.connex_flt = record?.flights_out ? record.flights_out.flight_no : record?.segs_out ? record.segs_out.flight_no :  null
      formdata.connex_date = record?.flights_out ? record.flights_out.flight_date : record?.segs_out ? record.segs_out.flight_date : null
      formdata.rb_trigger = 'Buildup'
      let headers = {
        station: this.state.station,
        page: PAGE.BUILDUP,
      }

      await Http.put(`/awb/${parseInt(record.id)}`, formdata, { headers: headers })
        .then((data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        })
    }
  }
  //UPDATE WRONG GATE RECOVERY
  handleWrongGateChange = async(value, count = 0, record = null, rebook) => {
    let formdata = {}
    formdata.recovery_actions_id = value
    formdata.is_gen_rebook = rebook
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP,
    }
    if(this.state.selectAllCheck) {
      if(count < this.state.wrongGateRow?.length) {
        await Http.put(`/awb/${parseInt(this.state.wrongGateRow[count].id)}`, formdata, { headers: headers })
          .then((data) => {
            if(data && data?.status === 200) {
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data?.data?.message,
              })
              count+=1
              this.handleWrongGateChange(value, count, null)
            } else {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: data?.data?.message,
              })
            }
            this.setState({ loading: false })
          })
      } else {
        this.setState({ selectAllCheck: false })
      }
    } else {
      await Http.put(`/awb/${parseInt(record.id)}`, formdata, { headers: headers })
        .then((data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        })
    }
  }
  //FETCH REPLACEMENT LOAD DATA
  fetchReplacementLoad = async () => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let flight_index = this.state.flightGridData?.findIndex((p) => p.id === this.state.flightRow?.id)
    let flight_destination = ''
    let dest_stn = ''
    if(flight_index > -1) {
      flight_destination = this.state.flightGridData[flight_index]?.dest?.arriv
      dest_stn = this.state.flightGridData[flight_index].dest_stn
      let url = `replacement_list?dest=${dest_stn}&flight_id=${this.state.flightRow?.id}`
      if(this.state.flightRow?.body_type) {
        url+=`&body_type=${this.state.flightRow?.body_type}`
      }
      await Http.get(url, { headers }).then(resp => {
        if (resp && resp.status === 200) {
          if (resp && resp?.data && resp?.data?.data?.length > 0) {
            let data = resp?.data?.data?.map((item, index) => {
              return {
                ...this.processIndividualAWB(item),
                key: index + 1,
                checked: false,
              }
            })
            this.sort(data, true, 'flights_out_date')
            this.setState({
              replacementData: data,
              replacementModalDest: flight_destination,
            }, () => this.setState({ replacementModalVisible: true }))
          } else {
            this.setState({
              replacementData: [],
              replacementModalDest: ''
            }, () => this.setState({ replacementModalVisible: true }))
          }
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp.data.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
    }
  }
  replacementModal = () => {
    const shipmentColumns = [
      {
        title: '',
        dataIndex: 'checkbox',
        key: 'checkbox',
        align: 'center',
        className: 'status-column',
        render: (_, record) =>
          <div>
            <Checkbox checked={record?.checked} onChange={(e) => this.checkAWb(record, e.target.checked)} />
          </div>
      },
      {
        title: 'Airwaybill',
        dataIndex: 'airwaybill',
        key: 'airwaybill',
        className: 'space-nowrap',
        render: (_, record) => (
          // <span>{record?.format_awb ? record.format_awb : 'N/A'}</span>
          <span>
            {`${record?.format_awb || ''} | `}
            {`${record?.avl_pcs || 0} | `}
            {`${this.state.weight_unit === 'KG' ? formatWeight(record.wgt_kg) + 'KG' : this.state.weight_unit === 'LB' ? formatWeight(record.wgt_lbs) + 'LB' : ''} | `}
            {`${record?.vol?.value ? parseFloat(record?.vol?.value)?.toFixed(2) : 0}${record?.vol?.unit || ''} | `}
            {`${record?.org + '-' + record?.dest}`}
          </span>
        ),
      },
      {
        title: 'Commodity',
        dataIndex: 'commodity',
        key: 'commodity',
        render: (_, record) => (
          <span>{record?.shipment_type === 'Local' ? record?.awb?.book_rmks?.rmk1 || 'N/A' : record?.info?.loading_remark || 'N/A'}</span>
        ),
      },
      {
        title: 'Current Flt',
        dataIndex: 'current_flt',
        key: 'current_flt',
        render: (_, record) => (
          <span>{record?.flights_out?.flight_no && record?.flights_out?.flight_date ? `${record?.flights_out?.flight_no}/${moment(record?.flights_out?.flight_date).format(dateSFormat)}` : 'N/A'}</span>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render: (_, record) => (
          <span>{record?.location ? record.location : 'N/A'}</span>
        ),
      },
      {
        title: 'Prod',
        dataIndex: 'prod',
        key: 'prod',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record?.awb?.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'Specials',
        dataIndex: 'specials',
        key: 'specials',
        render: (_, record) => (
          <span>{record?.spl_code ? record.spl_code : 'N/A'}</span>
        ),
      },
    ]

    return (
      <Modal
        title={`Shipments available to ${this.state.replacementModalDest}`}
        destroyOnClose
        visible={this.state.replacementModalVisible}
        onOk={this.hideReplacementModal}
        onCancel={this.hideReplacementModal}
        footer={false}
        width={'80%'}
        centered
        className='custom-modal pb-0'
      >
        <Row gutter={[{
          xs: 0,
          sm: 12,
          md: 12,
          lg: 12
        }, {
          xs: 24,
          sm: 12,
          md: 12,
          lg: 12
        },]} className='with-table'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className='table-overflow'
          >
            <div className='table-outer'>
              <Table
                className='custom-table w-100'
                dataSource={this.state.replacementData}
                columns={shipmentColumns}
                pagination={false}
              />
            </div>
          </Col>
          <Col className='ml-auto'>
            <p>Total Selected: {this.state.selectedAwbs} Pcs {this.state.selectedPcs} {formatWeight(this.state.selectedWgt) || 0}K.G {formatWeight(this.state.selectedVol) || 0}MC</p>
            <Button
              disabled={!this.state.selectedReplacementData?.length > 0}
              onClick={() => { this.setState({ loading: true } ,() => this.requestRebooking()) }}
              className='custom-button float-right'>Request Rebooking</Button>
          </Col>
        </Row>
      </Modal>
    )
  }
  checkAWb = (awb, flag) => {
    let replacementData = cloneDeep(this.state.replacementData)
    let selectedReplacementData = cloneDeep(this.state.selectedReplacementData)
    let awb_index = replacementData?.findIndex((p) => p.id === awb?.id)

    if(awb_index > -1) {
      if(flag === true) {
        selectedReplacementData.push(replacementData[awb_index])
      } else {
        let sel_awb_index = selectedReplacementData?.findIndex((p) => p.id === awb?.id)
        if(sel_awb_index > -1) {
          selectedReplacementData.splice(sel_awb_index, 1)
        }
      }

      replacementData[awb_index].checked = flag
      //ADD AWB COUNT, PCS, WGT, VOL
      let count = this.state.selectedAwbs
      let pcs = this.state.selectedPcs
      let wgt = this.state.selectedWgt
      let vol = this.state.selectedVol

      this.setState({
        replacementData: [],
        selectedAwbs: flag === true ? count + 1 : count - 1,
        selectedPcs: flag === true ? pcs + awb?.pcs || 0 : pcs - awb?.pcs || 0,
        selectedWgt: flag === true ? wgt + awb?.wgt_kg || 0 : wgt - awb?.wgt_kg || 0,
        selectedVol: flag === true ? vol + awb?.vol?.value || 0 : vol - awb?.vol?.value || 0,
      }, () => this.setState({
        replacementData: replacementData,
        selectedReplacementData: selectedReplacementData,
      }))
    }
  }
  requestRebooking = async (count = 0) => {
    let flight = `${this.state.flightRow?.flight_no}/${moment(this.state.flightRow.flight_date).format(dateSFormat)}`
    let formdata = {}
    formdata.recovery_actions_id = this.state.replacementLoadID
    formdata.replacement_remark = `Rebook on ${flight}`
    formdata.rb_trigger = 'Buildup - Replacement Load'
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP,
    }
    if(count < this.state.selectedReplacementData?.length) {
      await Http.put(`/awb/${parseInt(this.state.selectedReplacementData[count].id)}`, formdata, { headers: headers })
        .then((data) => {
          if(data && data?.status === 200) {
            count+=1
            this.requestRebooking(count)
          }
        })
    } else {
      this.setState({ loading: false } ,() => {
        this.hideReplacementModal()
        notification.destroy()
        notification.success({
          message: 'Success',
          description: 'Request submitted successfully',
        })
      })
    }
  }
  hideReplacementModal = () => {
    this.setState({
      replacementData: [],
      selectedReplacementData: [],
      replacementModalDest: '',
      selectedAwbs: 0,
      selectedPcs: 0,
      selectedWgt: 0,
      selectedVol: 0,
    }, () => this.setState({ replacementModalVisible: false }))
  }
  //FETCH FLIGHT HISTORY
  fetchFlightHistory = async (awb) => {
    this.setState({ flightHistorySpinner: false })
    if(awb?.history && awb?.history?.length > 0) {
      //DO NOTHING
    } else {
      this.setState({ flightHistorySpinner: true })
      await Http.get(`goods_history?id=${awb?.id}`, { headers: '' }).then(resp => {
        this.setState({ flightHistorySpinner: false })
        if (resp && resp?.status === 200) {
          if (resp?.data?.data?.length > 0) {
            let tiles = cloneDeep(this.state.workAreaTiles)
            let work_area_index = tiles?.findIndex((q) => q.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
            if(work_area_index > -1) {
              let flight_index = tiles[work_area_index].flights?.findIndex((p) => p.id === parseInt(awb?.flight_out))
              if(flight_index > -1) {
                let uld_index = tiles[work_area_index].flights[flight_index]?.ulds_out?.findIndex((q) => q.id === parseInt(awb?.uld_in))
                if(uld_index > -1) {
                  let awb_index = tiles[work_area_index].flights[flight_index]?.ulds_out[uld_index]?.awbs_in?.findIndex((q) => q.id === parseInt(awb?.id))
                  if(awb_index > -1) {
                    tiles[work_area_index].flights[flight_index].ulds_out[uld_index].awbs_in[awb_index].history = []
                    tiles[work_area_index].flights[flight_index].ulds_out[uld_index].awbs_in[awb_index].history = this.processAWBHistory(resp?.data?.data)

                    this.setState({ flightGridData: [], }, () => this.setState({
                      workAreaTiles: tiles,
                      flightGridData: tiles[work_area_index].flights,
                    }, () => {
                      if(this.state.flightRow?.id === parseInt(awb?.flight_out)) {
                        this.setState({
                          ULDGridData: tiles[work_area_index].flights[flight_index]?.ulds_out,
                          productDropdownData: tiles[work_area_index].flights[flight_index]?.product_arr,
                          locationDropdownData: tiles[work_area_index].flights[flight_index]?.location_arr,
                          prodFilters: tiles[work_area_index].flights[flight_index]?.product_arr,
                          locationFilters: tiles[work_area_index].flights[flight_index]?.location_arr,
                        })
                      }
                      this.sort(tiles[work_area_index].flights, true, 'dept_time')
                      this.sort(tiles[work_area_index].flights, true, 'time_to_dept')
                    }))
                  }
                }
              }
            }
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: 'No Data Found',
            })
          }
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          servererror : true,
          flightHistorySpinner: false
        })
      })
    }
  }
  //SHOW FLIGHT HISTORY
  showFlightHistory = (history) => {
    if(this.state.flightHistoryVisible && history?.length > 0) {
      return (
        <div>
          {history.map((item, index) => (
            item?.status && item?.createdAt ?
              <p
                key={index}>
                {item?.status}<br />{item?.createdAt ? `${item?.createdAt}` : ''}
              </p> :
              <p
                key={index}>
                {item?.status}
              </p>
          ))}
        </div>
      )
    } else if(this.state.flightHistorySpinner) {
      return <LoadingOutlined />
    } else {
      return <div>No data found</div>
    }
  }
  generateRemainingULDHud = () => {
    return (
      <>
        {this.state.hideMD ? null : <div className='capsule'>
          <span>MD</span>
          <span className={((this.state.flightRow?.cargo?.md || 0) - (this.state.flightRow?.actual?.MD|| 0)) > -1 ? '' : 'text-danger'}>
            {(this.state.flightRow?.cargo?.md || 0) - (this.state.flightRow?.actual?.MD || 0)}
          </span>
        </div>}
        <div className='capsule'>
          <span>PMC</span>
          <span className={((this.state.flightRow?.cargo?.p96 || 0) - (this.state.flightRow?.actual?.P96|| 0)) > -1 ? '' : 'text-danger'}>
            {(this.state.flightRow?.cargo?.p96 || 0) - (this.state.flightRow?.actual?.P96 || 0)}
          </span>
        </div>
        <div className='capsule'>
          <span>PAG</span>
          <span className={((this.state.flightRow?.cargo?.p88 || 0) - (this.state.flightRow?.actual?.P88|| 0)) > -1 ? '' : 'text-danger'}>
            {(this.state.flightRow?.cargo?.p88 || 0) - (this.state.flightRow?.actual?.P88 || 0)}
          </span>
        </div>
        <div className='capsule'>
          <span>LD3</span>
          <span className={((this.state.flightRow?.cargo?.ld3 || 0) - (this.state.flightRow?.actual?.LD3|| 0)) > -1 ? '' : 'text-danger'}>
            {(this.state.flightRow?.cargo?.ld3 || 0) - (this.state.flightRow?.actual?.LD3 || 0)}
          </span>
        </div>
        <div className='capsule'>
          <span>LD2</span>
          <span className={((this.state.flightRow?.cargo?.ld2 || 0) - (this.state.flightRow?.actual?.LD2|| 0)) > -1 ? '' : 'text-danger'}>
            {(this.state.flightRow?.cargo?.ld2 || 0) - (this.state.flightRow?.actual?.LD2 || 0)}
          </span>
        </div>
      </>
    )
  }
  generateULDPositions = (row, type) => {
    return (
      <>
        {this.state.hideMD ? null : <div className='capsule'>
          <span>MD</span>
          <span>
            {row?.[type]?.md || row?.[type]?.MD || 0}
          </span>
        </div>}
        <div className='capsule'>
          <span>PMC</span>
          <span>
            {row?.[type]?.p96 || row?.[type]?.P96 || 0}
          </span>
        </div>
        <div className='capsule'>
          <span>PAG</span>
          <span>
            {row?.[type]?.p88 || row?.[type]?.P88 || 0}
          </span>
        </div>
        <div className='capsule'>
          <span>LD3</span>
          <span>
            {row?.[type]?.ld3 || row?.[type]?.LD3 || 0}
          </span>
        </div>
        <div className='capsule'>
          <span>LD2</span>
          <span>
            {row?.[type]?.ld2 || row?.[type]?.LD2 || 0}
          </span>
        </div>
      </>
    )
  }
  //RENDER CC CLASS
  renderCCClass = (record) => {
    let weight_assigned = this.state.weight_unit === 'KG' ? record?.booked_kg : record?.booked_lbs
    let weight_cargo = this.state.weight_unit === 'KG' ? record?.weight_kg : record?.weight_lbs
    if(!record?.bag) {
      if(weight_cargo > 0) {
        if(weight_assigned > weight_cargo) {
          return 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
        } else {
          return 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
        }
      } else {
        return 'p-icon cc-icon fs-0' //SHOW BLUE
      }
    } else if(record?.bag && record?.cargo && record?.cargo?.md === 0 && record?.cargo?.p96 === 0 && record?.cargo?.p88 === 0 && record?.cargo?.ld3 === 0 && record?.cargo?.ld2 === 0){
      if(weight_cargo > 0) {
        if(weight_assigned > weight_cargo) {
          return 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
        } else {
          return 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
        }
      } else {
        return 'p-icon cc-icon fs-0' //SHOW BLUE
      }
    } else {
      if(((record?.cargo?.md || 0) - (record?.actual?.MD || 0)) < 0 ||
      ((record?.cargo?.p96 || 0) - (record?.actual?.P96 || 0)) < 0 ||
      ((record?.cargo?.p88 || 0) - (record?.actual?.P88 || 0)) < 0 ||
      ((record?.cargo?.ld3 || 0) - (record?.actual?.LD3 || 0)) < 0 ||
      ((record?.cargo?.ld2 || 0) - (record?.actual?.LD2 || 0)) < 0) {
        return 'p-icon cc-icon fs-0 red-cc' //SHOW RED
      } else {
        if(weight_cargo > 0 && (weight_assigned > weight_cargo)) {
          return 'p-icon cc-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
        } else if(((record?.cargo?.md || 0) - (record?.actual?.MD || 0)) > 0 ||
        ((record?.cargo?.p96 || 0) - (record?.actual?.P96 || 0)) > 0 ||
        ((record?.cargo?.p88 || 0) - (record?.actual?.P88 || 0)) > 0 ||
        ((record?.cargo?.ld3 || 0) - (record?.actual?.LD3 || 0)) > 0 ||
        ((record?.cargo?.ld2 || 0) - (record?.actual?.LD2 || 0)) > 0) {
          return 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
        } else return 'p-icon cc-icon fs-0' //SHOW BLUE
      }
    }
  }
  //FETCH ALLOWED ULD MAPPING DATA
  fetchAllowedULDs = async (record) => {
    if(!record?.allwd_types?.length > 0 && record?.ac_type) {
      await Http.get(`uld_mappings_prefix?ac_types=${record.ac_type}`, { headers: { page: PAGE.BUILDUP } }).then(resp => {
        if (resp?.status === 200) {
          if (resp?.data?.data?.length > 0) {
            let allowedUlds = resp?.data?.data?.map((item) => {
              return item?.uld_prefix
            })

            let tiles = cloneDeep(this.state.workAreaTiles)
            let work_area_index = tiles?.findIndex((p) => p.area_name?.toUpperCase() === this.state.currentWorkArea?.toUpperCase())
            if(work_area_index > -1) {
              let flight_index = tiles[work_area_index]?.flights?.findIndex((p) => p.id === record?.id)
              if(flight_index > -1) {
                tiles[work_area_index].flights[flight_index].allwd_types = allowedUlds || []
                this.setState({
                  workAreaTiles: tiles,
                  flightGridData: tiles[work_area_index].flights,
                }, () => this.setEdit(tiles[work_area_index].flights[flight_index]))
              }
            }
          } else {
            this.setEdit(record)
          }
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Could not find data',
        })
      })
    } else {
      this.setEdit(record)
    }
  }
  //SET RECORD EDITABLE
  setEdit = (record, type = '') => {
    let calcRemainingPos = record?.details?.length > 0 ? this.calcPos(record?.details) : ''
    if(type) {
      if(type === 'DETAILS') {
        this.setState({
          ccRecord: record,
          flightNo: record?.flight_no || '',
          flightDate: moment(record?.flight_date).format(dateSFormat)?.toUpperCase(),
          flightOrgDest: record?.org?.origin + record?.dest?.arriv,
          editFcst: record?.fcst || 0,
          editAllwdUldTypes: record?.allwd_types || [],
          editBagP96: record?.bag?.p96 || 0,
          editBagP88: record?.bag?.p88 || 0,
          editBagLD3: record?.bag?.ld3 || 0,
          editBagLD2: record?.bag?.ld2 || 0,
          editCargoMD: record?.cargo?.md || 0,
          editCargoP96: record?.cargo?.p96 || 0,
          editCargoP88: record?.cargo?.p88 || 0,
          editCargoLD3: record?.cargo?.ld3 || 0,
          editCargoLD2: record?.cargo?.ld2 || 0,
          editWgtValue: this.state.weight_unit === 'KG' ? record?.weight_kg || 0 : record?.weight_lbs || 0,
          editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
          editCont: record?.cont_type || '',
          editBuPrio: record?.bu_prio || false,
          editIsExceeded: record?.load_plan || false,
          previewFwd: record?.fwd_conf?.path,
          previewAft: record?.aft_conf?.path,
          lastUpdatedBy: record?.last_updated_by,
          lastUpdatedAt: record?.last_updated_at,
          isWideBody: record?.acpt_body,
          remainingP96: calcRemainingPos?.P96 ? (record?.cargo?.p96 || 0) - calcRemainingPos.P96 : (record?.cargo?.p96 || 0),
          remainingP88: calcRemainingPos?.P88 ? (record?.cargo?.p88 || 0) - calcRemainingPos.P88 : (record?.cargo?.p88 || 0),
          remainingLD3: calcRemainingPos?.LD3 ? (record?.cargo?.ld3 || 0) - calcRemainingPos.LD3 : (record?.cargo?.ld3 || 0),
          remainingLD2: calcRemainingPos?.LD2 ? (record?.cargo?.ld2 || 0) - calcRemainingPos.LD2 : (record?.cargo?.ld2 || 0),
          remainingMD: calcRemainingPos?.MD ? (record?.cargo?.md || 0) - calcRemainingPos.MD : (record?.cargo?.md || 0),
        }, () => this.setState({ ccPlanModalVisible: true }))
      } else {
        this.setState({
          ccRecord: record,
          flightNo: record?.flight_no || '',
          flightDate: moment(record?.flight_date).format(dateSFormat)?.toUpperCase(),
          flightOrgDest: record?.org?.origin + record?.dest?.arriv,
          lastUpdatedBy: record?.last_updated_by,
          lastUpdatedAt: record?.last_updated_at,
          isWideBody: record?.acpt_body,
          editAllocArray: record?.details || [],
        }, () => this.setState({ ccPlanModalVisible: true }))
      }
    } else {
      this.setState({
        ccRecord: record,
        flightNo: record?.flight_no || '',
        flightDate: moment(record?.flight_date).format(dateSFormat)?.toUpperCase(),
        flightOrgDest: record?.org?.origin + record?.dest?.arriv,
        editFcst: record?.fcst || 0,
        editAllwdUldTypes: record?.allwd_types || [],
        editBagP96: record?.bag?.p96 || 0,
        editBagP88: record?.bag?.p88 || 0,
        editBagLD3: record?.bag?.ld3 || 0,
        editBagLD2: record?.bag?.ld2 || 0,
        editCargoMD: record?.cargo?.md || 0,
        editCargoP96: record?.cargo?.p96 || 0,
        editCargoP88: record?.cargo?.p88 || 0,
        editCargoLD3: record?.cargo?.ld3 || 0,
        editCargoLD2: record?.cargo?.ld2 || 0,
        editWgtValue: this.state.weight_unit === 'KG' ? record?.weight_kg || 0 : record?.weight_lbs || 0,
        editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
        editCont: record?.cont_type || '',
        editBuPrio: record?.bu_prio || false,
        editIsExceeded: record?.load_plan || false,
        previewFwd: record?.fwd_conf?.path,
        previewAft: record?.aft_conf?.path,
        lastUpdatedBy: record?.last_updated_by,
        lastUpdatedAt: record?.last_updated_at,
        isWideBody: record?.acpt_body,
        editAllocArray: record?.details || [],
        remainingP96: calcRemainingPos?.P96 ? (record?.cargo?.p96 || 0) - calcRemainingPos.P96 : (record?.cargo?.p96 || 0),
        remainingP88: calcRemainingPos?.P88 ? (record?.cargo?.p88 || 0) - calcRemainingPos.P88 : (record?.cargo?.p88 || 0),
        remainingLD3: calcRemainingPos?.LD3 ? (record?.cargo?.ld3 || 0) - calcRemainingPos.LD3 : (record?.cargo?.ld3 || 0),
        remainingLD2: calcRemainingPos?.LD2 ? (record?.cargo?.ld2 || 0) - calcRemainingPos.LD2 : (record?.cargo?.ld2 || 0),
        remainingMD: calcRemainingPos?.MD ? (record?.cargo?.md || 0) - calcRemainingPos.MD : (record?.cargo?.md || 0),
      }, () => this.setState({ ccPlanModalVisible: true }))
    }

  }
  //CALCULATE REMAINING POSITIONS
  calcPos = (details) => {
    let countp96 = 0
    let countp88 = 0
    let countld3 = 0
    let countld2 = 0
    let countmd = 0

    details?.map((item) => {
      switch (item?.type) {
      case 'P96':
        countp96+=parseInt(item?.pos || 0)
        break
      case 'P88':
        countp88+=parseInt(item?.pos || 0)
        break
      case 'LD3':
        countld3+=parseInt(item?.pos || 0)
        break
      case 'LD2':
        countld2+=parseInt(item?.pos || 0)
        break
      case 'MD':
        countmd+=parseInt(item?.pos || 0)
        break
      default:
        break
      }
    })

    return {
      P96: countp96,
      P88: countp88,
      LD3: countld3,
      LD2: countld2,
      MD: countmd,
    }
  }
  //DISABLE PRE DEPARTURE NOTES IF FLIGHT HAS DEPARTED
  disableNote = (dept_time) => {
    return moment.tz(dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'minutes') < 0
  }
  //UPDATE WEIGHT ON TOGGLE
  updateWeightVal = (unit, value) => {
    if(value > 0) {
      if(unit === 'LB') {
        return weightCalculator(parseFloat(value), 'KGM')
      } else if(unit === 'KG') {
        return weightCalculator(parseFloat(value), 'LBS')
      }
    }
  }
  //APPLY TO ALL COMPONENT
  locationColumnSearch = () => ({
    filterDropdown: () => (
      <div />
    ),
    filterIcon: () =>
      <Form.Item label='' labelCol={{
        lg: { span: 24 },
        xl: { span: 0 },
      }} wrapperCol={{
        lg: { span: 24 },
        xl: { span: 24 },
      }}
      className='w-100 mb-0'
      >
        <Checkbox
          label =''
          onChange={(e) => this.setState({ selectAllCheck: e.target.checked })}
          checked={this.state.selectAllCheck}
          disabled={this.state.wrongGateRow?.length < 2}
          className='br-spac-ini'
        > Apply To All</Checkbox>
      </Form.Item>
  })
  //CHECKBOXES FILTERS
  filterByCheckboxes = () => {
    let flightData = cloneDeep(this.state.flightGridData)
    let filters = {}
    if(this.state.buCheck) {
      if(this.state.wbCheck && this.state.buCheck) {
        filters.wbEquals = item => item?.body_type === 'WB' && item?.bu_prio === true
      }
      if(this.state.nbCheck && this.state.buCheck) {
        filters.nbEquals = item => item?.body_type === 'NB' && item?.bu_prio === true
      }
      if(this.state.rfsCheck && this.state.buCheck) {
        filters.rfsEquals = item => (item?.flight_no?.match(/^YY7[0-9]{3,5}[A-Z]{1}/g) || item?.body_type === 'TRK') && item?.bu_prio === true
      }
      if(this.state.buCheck && !this.state.wbCheck && !this.state.nbCheck && !this.state.rfsCheck) {
        filters.buEquals = item => item?.bu_prio === true
      }
    } else {
      if(this.state.wbCheck) {
        filters.wbEquals = item => item?.body_type === 'WB'
      }
      if(this.state.nbCheck) {
        filters.nbEquals = item => item?.body_type === 'NB'
      }
      if(this.state.rfsCheck) {
        filters.rfsEquals = item => (item?.flight_no?.match(/^YY7[0-9]{3,5}[A-Z]{1}/g) || item?.body_type === 'TRK')
      }
    }
    if(Object.keys(filters)?.length > 0) {
      flightData = flightData?.filter(item => Object.values(filters)?.some(f => f(item)))
    }
    if(this.state.hideDeparted) {
      flightData = flightData?.filter(item => moment(item?.time_to_dept).valueOf() > moment().valueOf())
    }
    if(this.state.showRedFlights) {
      flightData = flightData?.filter(item => item?.handling_time_color === 'red')
    }
    return flightData || []
  }
  //DROPDOWN FILTERS FOR AWB
  filterAWBByDropdown = () => {
    let uldData = cloneDeep(this.state.ULDGridData)
    let radio = this.state.dropdownValue
    let product_dropdown = this.state.prodCode
    let sort_dropdown = this.state.sortValue
    if(radio === 'ALL') {
      if(this.state.priorCheck){
        this.sort(uldData, true, 'uld_no')
        this.sort(uldData, true, 'sort_oc')
        this.sort(uldData, true, 'prod_prior')
        this.sort(uldData, true, 'sort_blk')
      } else {
        this.sort(uldData, true, 'uld_no')
        this.sort(uldData, true, 'sort_oc')
        this.sort(uldData, true, 'sort_blk')
      }
      return uldData
    } else {
      let filters = {}
      if(radio) {
        let flag = radio === 'PENDING' ? false : true
        filters.radioEquals = item => item.awbs_in?.some((awb) => awb.is_completed === flag)
      }
      if(product_dropdown) {
        filters.productEquals = item => item.awb?.prod_code === product_dropdown
      }
      if(sort_dropdown) {
        filters.sortEquals = item => item.awb?.prod_code === product_dropdown
      }
      let filteredData = uldData?.filter(item => Object.values(filters)?.every(f => f(item)))
      //record?.awbs_in && this.state.priorCheck )? this.sort(record.awbs_in, true, 'prod_prior')
      if(this.state.priorCheck){
        this.sort(filteredData, true, 'uld_no')
        this.sort(filteredData, true, 'sort_oc')
        this.sort(filteredData, true, 'prod_prior')
        this.sort(filteredData, true, 'sort_blk')
      } else {
        this.sort(filteredData, true, 'uld_no')
        this.sort(filteredData, true, 'sort_oc')
        this.sort(filteredData, true, 'sort_blk')
      }
      return filteredData || []
    }
  }
  //GENERATE REMAINING CARGO PAYLOAD
  remainingCargoPayload = () => {
    let unit = this.state.weight_unit === 'KG' ? 'Kgs' : this.state.weight_unit === 'LB' ? 'Lbs' : ''
    if(this.state.flightRow) {
      let cargoWgt = this.state.weight_unit === 'KG' ?
        formatWeight(this.state.flightRow?.weight_kg) : this.state.weight_unit === 'LB' ?
          formatWeight(this.state.flightRow?.weight_lbs) : 0
      let readyWgt = this.state.weight_unit === 'KG' &&  this.state.flightRow?.ready_kg ?
        formatWeight(this.state.flightRow?.ready_kg) :
        this.state.weight_unit === 'LB' && this.state.flightRow?.ready_lbs ?
          formatWeight(this.state.flightRow?.ready_lbs) : 0
      return (cargoWgt - readyWgt)?.toFixed(2) + unit
    } else return 'N/A'
  }
  //PARSE COUNTDOWN DATA AND SEND TO QUEUE
  createCountdownPromise = (record, flag) => {
    if(moment(record?.handling_time).add(5, 'seconds').valueOf() > moment().valueOf()) {
      let parsedData = {
        TYPE: 'COUNTDOWN',
        DETAILS: {
          flight_out: record?.id,
          flag: flag,
        }
      }
      const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
      this.enqueue(myFakeAsyncJob)
    }
  }
  //CHECKBOX CHANGE
  handleFilterCheckboxChange =(e, type) => {
    let filters = cloneDeep(this.state[type])
    let index = filters?.findIndex((p) => p === e.target.value)
    if(!e.target.checked && index > -1) {
      filters.splice(index, 1)
    } else if(e.target.checked && index === -1) {
      filters.push(e.target.value)
    }
    this.setState({ [type]: [] }, () => this.setState({ [type]: filters }, () => {
      // this.applyFilters()
    }))
  }
  //FILTER BY PROD VALUE
  filterByProdValue = () => {
    if(this.state.prodValue) {
      let filteredData = this.state.productDropdownData?.filter((code) => code?.includes(this.state.prodValue))
      if(filteredData?.length > 0) {
        return (
          filteredData?.map((code) => (
            <Checkbox key={code} checked={this.state.prodFilters?.includes(code)} value={code} onChange={(e) => this.handleFilterCheckboxChange(e, 'prodFilters')}>{code}</Checkbox>
          ))
        )
      }
    } else {
      return (
        this.state.productDropdownData?.map((code) => (
          <Checkbox key={code} checked={this.state.prodFilters?.includes(code)} value={code} onChange={(e) => this.handleFilterCheckboxChange(e, 'prodFilters')}>{code}</Checkbox>
        ))
      )
    }
  }
  //FILTER BY LOCATION VALUE
  filterByLocationValue = () => {
    if(this.state.locationValue) {
      let filteredData = this.state.locationDropdownData?.filter((code) => code?.includes(this.state.locationValue))
      if(filteredData?.length > 0) {
        return (
          filteredData?.map((code) => (
            <Checkbox key={code} checked={this.state.locationFilters?.includes(code)} value={code} onChange={(e) => this.handleFilterCheckboxChange(e, 'locationFilters')}>{code}</Checkbox>
          ))
        )
      }
    } else {
      return (
        this.state.locationDropdownData?.map((code) => (
          <Checkbox key={code} checked={this.state.locationFilters?.includes(code)} value={code} onChange={(e) => this.handleFilterCheckboxChange(e, 'locationFilters')}>{code}</Checkbox>
        ))
      )
    }
  }
  //APPLY FILTER
  applyFilters = async () => {
    let buildupcontrol_filter = cloneDeep(this.state.buildupFilter)
    buildupcontrol_filter[this.state.station] = {
      type: this.state.currentWorkArea,
      // prod_code: this.state.prodFilters,
      // location: this.state.locationFilters,
    }
    let formdata = { buildupcontrol_filter: buildupcontrol_filter }
    await Http.put(`/page_filter/${this.state.user_id}`, formdata).then(async (data) => {
      if (data && data?.status === 200) {
        //DO NOTHING
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  //SHIFT TILE
  moveTile = (work_area, from, to) => {
    let tiles = this.state.workAreaTiles
    let work_area_index = tiles?.findIndex((p) => p.area_name === work_area)

    let cookieArr = []
    if(work_area_index > -1) {
      tiles?.splice(from, 1, tiles?.splice(to, 1, tiles[from])[0])
      tiles?.map((wa) => cookieArr.push(wa?.area_name))
      createCookie(COOKIE.TILES_BU, cookieArr, '', '/')
      this.setState({ workAreaTiles: [] }, () => this.setState({ workAreaTiles: tiles }))
    }
  }
  //PROCESS WORK AREA TILES
  processWorkAreas = (work_area_list) => {
    let work_areas_cookies = new Cookies().get('tiles_bu')
    let cookieArr = []
    if(work_areas_cookies && work_areas_cookies?.length > 0) {
      work_area_list.sort((a, b) => work_areas_cookies.indexOf(a?.area_name) - work_areas_cookies.indexOf(b?.area_name))
      work_area_list?.map((wa) => cookieArr.push(wa?.area_name))
    } else {
      work_area_list?.map((wa) => cookieArr.push(wa?.area_name))
    }
    createCookie(COOKIE.TILES_BU, cookieArr, '', '/')
    return work_area_list
  }

  print = () =>{
    //if printuldcon expand all ULDs
    //else collapse all ULDs
    let data = cloneDeep(this.state.ULDGridData)
    let expanded = []
    if(this.state.printuldcon) {
      data?.map((item) => {
        if(item?.type !== 'BLK') {
          expanded.push(item.id)
        }
      })
    }
    this.setState({ expandedPrint: [] }, () => this.setState({ expandedPrint: expanded }, () => {
      window.print()
    }))
  }

  showCountdown = (date) =>{
    let difference = moment(date).tz(this.state.timezone).diff(moment().tz(this.state.timezone), 'seconds')
    if (difference >0){
      let hours = Math.floor(difference / 3600)
      let mins = Math.floor(difference % 3600 / 60)
      return  ((hours.toString().length === 1 ? ('0'+hours) : hours)+':'+( mins.toString().length === 1 ? ('0'+mins) :mins))
    } else {
      return '00:00'
    }
  }

  next(props) {
    this.slider.slickNext()
    this.setState({ sliderLoad : false,  })
    const { className, style, onClick } = props
    return (
      <div
        className={`opacity ${ className }`}
        style={{
          ...style,
          display: 'block',
        }}
        onClick={onClick}
      />
    )
  }

  previous(props) {
    this.slider.slickPrev()
    this.setState({ sliderLoad : false,  })
    const { className, style, onClick } = props
    return (
      <div
        className={`opacity ${ className }`}
        style={{
          ...style,
          display: 'block',
        }}
        onClick={onClick}
      />
    )
  }

  render() {
    const uldColumns = [
      {
        title: 'FPE',
        className: 'fpe-col',
        width: 45,
        render: (_, record) => (
          record?.type === 'BLK' ? <span className='countdown black'><Countdown value={record?.awbs_in[0]?.handling_time_object?.handling_time} format='HH:mm' /></span> : ''
        )
      },
      {
        title: (<><span>ULD</span> <span className='awb-rec'>AWB</span></>),
        dataIndex: 'uld_no',
        key: 'uld_no',
        className: 'min-width-320 space-nowrap',
        width: 320,
        render: (_, record) => (
          <>
            {`${record?.uld_no} | `}
            {record?.type === 'BLK' ? '' : `${(record?.awbs_in?.length || 0)} AWBS | `}
            {`${(record?.avl_pcs || 0)} | `}
            {this.state.weight_unit === 'KG' && record?.awb_wgt_kgs ? `${formatWeight((record?.awb_wgt_kgs + (record?.tare_wgt_kg || 0)))}KG` :
              this.state.weight_unit === 'LB' && record?.awb_wgt_lbs ? `${formatWeight((record?.awb_wgt_lbs + (record?.tare_wgt_lbs || 0)))}LB` : 'N/A'}
            {record?.type !== 'BLK' ? record?.rdy ?
              <>
                <span>{' | '}</span><span style={{ color: '#629d03' }}>CLOSED</span>
              </> : <>
                <span>{' | '}</span><span style={{ color: '#D9001B' }}>OPEN</span>
              </> : ''}
            {record?.type === 'BLK' ? ` | ${record?.awb_vol_value ? parseFloat(record?.awb_vol_value)?.toFixed(2) : 0}${record?.awb_vol_unit || ''} | ${record?.awbs_in[0]?.org}-${record?.awbs_in[0]?.dest}` : ''}
            {/* {this.state.weight_unit === 'KG' && record?.awb_wgt_kgs ? ` | ${formatWeight((record?.awb_wgt_kgs + (record?.tare_wgt_kg || 0)))}KG` :
              this.state.weight_unit === 'LB' && record?.awb_wgt_lbs ? ` | ${formatWeight((record?.awb_wgt_lbs + (record?.tare_wgt_lbs || 0)))}LB` : 'N/A'} */}
          </>
        )
      },
      {
        title: 'Commodity',
        dataIndex: '',
        key: '',
        className: 'min-width-140',
        width: 140,
        render: (_, record) => (
          <span>{record?.type === 'BLK' ?
            record?.awbs_in[0]?.shipment_type === 'Local' ?
              record?.awbs_in[0]?.awb?.book_rmks?.rmk1 || 'N/A' :
              record?.awbs_in[0]?.info?.loading_remark || 'N/A' :
            record?.type === 'CART' ? 'BLK' :record?.type}</span>
        ),
      },
      {
        title: 'Location',
        dataIndex: '',
        key: '',
        className: 'min-width-109',
        filteredValue: this.state.locationFilters,
        filterDropdown: () => (
          <div className='fliter-dd'>
            <Input
              prefix={<SearchOutlined />}
              value={this.state.locationValue}
              className='filter-search'
              size='small'
              onChange={(e) => this.setState({ locationValue: e.target.value?.toUpperCase() })}
            />
            {
              this.filterByLocationValue()
            }
            <div className='filter-reset f-spacebtw p-2'>
              <Button className='link-btn' size='small' onClick={() => this.setState({ locationFilters: [] })} type='link'>Reset</Button>
              <Button className='custom-button' size='small' onClick={() => this.setState({ locationFilters: this.state.flightRow?.location_arr || [] })} type='primary'>Select All</Button>
            </div>
          </div>
        ),
        onFilter: (value, record) => record?.awb_location?.includes(value),
        render: (_, record) => (
          <span>{record?.type === 'BLK' ? record?.awbs_in[0]?.location2 ? record?.awbs_in[0].location2 : 'N/A' : ''}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: '',
        key: '',
        className: 'min-width-109',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e } , () => this.fetchFlightHistory(record?.type === 'BLK' ? record?.awbs_in[0] : record))}
            placement='rightTop'
            content={() => this.showFlightHistory(record?.type === 'BLK' ? record?.awbs_in[0]?.history : [])}
            title='AWB History'
            trigger='click'
            overlayClassName='status-popover'>
            <Space>
              <span>{record?.type === 'BLK' ? record?.awbs_in[0]?.status ? record?.awbs_in[0]?.status?.includes('ETA') ? 'ETA in ' : record?.awbs_in[0]?.status : 'N/A' : ''}</span>
              <span className='countdown black'>{record?.type === 'BLK' ? record?.awbs_in[0]?.status?.includes('ETA') ? <Countdown value={record?.awbs_in[0]?.status_countdown} format='HH:mm' /> : '' : ''}</span>
            </Space>
          </Popover>
        ),
      },
      {
        title: 'Prod',
        dataIndex: '',
        key: '',
        className: 'min-width-60',
        filteredValue: this.state.prodFilters,
        filterDropdown: () => (
          <div className='fliter-dd'>
            <Input
              prefix={<SearchOutlined />}
              value={this.state.prodValue}
              className='filter-search'
              size='small'
              onChange={(e) => this.setState({ prodValue: e.target.value?.toUpperCase() })}
            />
            {
              this.filterByProdValue()
            }
            <div className='filter-reset f-spacebtw p-2'>
              <Button className='link-btn' size='small' onClick={() => this.setState({ prodFilters: [] })} type='link'>Reset</Button>
              <Button className='custom-button' size='small' onClick={() => this.setState({ prodFilters: this.state.flightRow?.product_arr || [] })} type='primary'>Select All</Button>
            </div>
          </div>
        ),
        onFilter: (value, record) => record?.awb_prod_code?.includes(value),
        render: (_, record) => (
          <span>{record?.type === 'BLK' ? record?.awbs_in[0]?.awb?.prod_code ? record?.awbs_in[0].awb.prod_code : 'N/A' : ''}</span>
        ),
      },
      {
        title: 'Recovery Action',
        dataIndex: '',
        key: '',
        width: 120,
        render: (_, record) => (
          record?.type === 'BLK' && !record?.awbs_in[0]?.is_segment ? <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 100 }}
              size='small'
              value={record?.type === 'BLK' ? record?.awbs_in[0]?.recovery_actions_id ? record?.awbs_in[0]?.recovery_actions_id : null : null}
              onChange={(e, { rebook }) => this.handleRecoveryActionChange(record?.type === 'BLK' ? record?.awbs_in[0] : null, e, rebook)}
              dropdownClassName='bc-dd'
              placement='bottomRight'
            >
              {this.state.recoveryActions && this.state.recoveryActions.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div> : ''
        )
      },
    ]
    const awbColumns = [
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        className: 'status-column icon-col',
        render: (_, record) => (
          <div className='table-badge'>
            {/* <Countdown value={record.handling_time_amber} format='HH:mm' />
            <Countdown value={record?.handling_time_object?.handling_time} format='HH:mm' /> */}
            {record?.is_segment && record?.is_amber ? <Badge status='warning' /> :
              record?.handling_time_object?.handling_time_color === 'green' ? <Badge status='success' /> :
                record?.handling_time_object?.handling_time_color === 'red' ? <Badge status='error' /> :
                  <Badge status='default' />}
          </div>
        )
      },
      {
        title: 'FPE',
        dataIndex: 'fpe',
        key: 'fpe',
        className: 'fpe-col',
        width: 45,
        render: (_, record) => (
          <span className='countdown black'><Countdown value={record?.handling_time_object?.handling_time} format='HH:mm' /></span>
        )
      },
      {
        title: 'Airwaybill',
        dataIndex: 'airwaybill',
        key: 'airwaybill',
        className: 'min-width-320 space-nowrap',
        width: 320,
        render: (_, record) => (
          // <span>{record?.format_awb ? record.format_awb : 'N/A'}</span>
          <span>
            {`${record?.format_awb || ''} | `}
            {`${record?.avl_pcs || 0} | `}
            {`${this.state.weight_unit === 'KG' ? formatWeight(record.wgt_kg) + 'KG' : this.state.weight_unit === 'LB' ? formatWeight(record.wgt_lbs) + 'LB' : ''} | `}
            {`${record?.vol?.value ? parseFloat(record?.vol?.value)?.toFixed(2) : 0}${record?.vol?.unit || ''} | `}
            {`${record?.org + '-' + record?.dest}`}
          </span>
        ),
      },
      {
        title: 'Commodity',
        dataIndex: 'commodity',
        key: 'commodity',
        className: 'min-width-140',
        width: 140,
        render: (_, record) => (
          <span>{record?.shipment_type === 'Local' ? record?.awb?.book_rmks?.rmk1 || 'N/A' : record?.info?.loading_remark || 'N/A'}</span>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        className: 'min-width-109',
        render: (_, record) => (
          <span>{record?.location2 ? record.location2 : 'N/A'}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: '',
        key: 'status',
        className: 'min-width-109',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e } ,() => this.fetchFlightHistory(record))}
            placement='rightTop'
            content={() => this.showFlightHistory(record?.history)}
            title='AWB History'
            trigger='click'
            overlayClassName='status-popover'>
            <Space>
              <span>{record?.status ? record?.status?.includes('ETA') ? 'ETA in ' : record?.status : 'N/A'}</span>
              <span className='countdown black'>{record?.status?.includes('ETA') ? <Countdown value={record?.status_countdown} format='HH:mm' /> : ''}</span>
            </Space>
          </Popover>
        ),
      },
      {
        title: 'Prod',
        dataIndex: 'prod',
        key: 'prod',
        className: 'min-width-60',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'Recovery Action',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        width: 120,
        render: (_, record) =>
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 100 }}
              size='small'
              value={record?.recovery_actions_id ? record?.recovery_actions_id : null}
              onChange={(e, { rebook }) => this.handleRecoveryActionChange(record, e, rebook)}
              dropdownClassName='bc-dd'
              placement='bottomRight'
            >
              {this.state.recoveryActions && this.state.recoveryActions.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
      },
    ]

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          }
        },

        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 654,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    }

    const wrongLocationColumns = [
      {
        title: 'ULD/Cart',
        dataIndex: 'uld_no',
        key: 'uld_no',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record?.uld_no ? record?.uld_no: 'N/A'}</span>
        ),
      },

      {
        title: 'AWB',
        dataIndex: 'awb_no',
        key: 'awb_no',
        render: (_, record) => (
          <span>{record?.awb_no ? record.awb_no : 'N/A'}</span>
        ),
      },
      {
        title: 'Flight Gate',
        dataIndex: 'gate',
        key: 'gate',
        render: (_, record) => (
          <span>{record?.flight_gate ? record?.flight_gate : 'N/A'}</span>
        ),
      },
      {
        title: 'Cargo At',
        dataIndex: 'cargo',
        key: 'cargo',
        render: (_, record) => (
          <span>{record?.location2 ? record.location2 : 'N/A'}</span>
        ),
      },
      {
        title: 'Recovery',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        render: (_, record) => (
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 140 }}
              size='small'
              value={record?.recovery_actions_id ? record?.recovery_actions_id : null}
              onChange={(e, { rebook }) => this.handleWrongGateChange(e, 0, record, rebook)}
              dropdownClassName='bc-dd'
            >
              {/* <Option key='1' value='Move To Correct Gate' title='Move To Correct Gate'>Move To Correct Gate</Option>
              <Option key='2' value='No Action' title='No Action'>No Action</Option>
              <Option key='3' value='Rebook - Cargo set up at wrong gate' title='Rebook - Cargo set up at wrong gate'>Rebook - Cargo set up at wrong gate</Option> */}
              {this.state.recoveryActions && this.state.recoveryActions.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
        ),
        ...this.locationColumnSearch(),
      },
    ]

    return (
      <div>
        {this.state.ccPlanModalVisible && this.ccPlanModal()}
        {this.state.replacementModalVisible && this.replacementModal()}
        {this.state.optimalBuild && this.searchModal()}
        {/* {
          this.state.servererror ?
            <p className='dis-server'>Disconnected from the server, Please refresh the page.</p> : null
        } */}
        {/* {this.state.loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)} */}
        <HeaderSearch
          placeholder='Eg: YY123/01NOV22 or 001-12455675'
          searchResults={(e) => this.setState({
            latestTimestamp: null,
            currentWorkArea: 'ALL',
            search: e ? e?.split('=')[1] : '',
            pauseEvent: true,
            wbCheck: false,
            nbCheck: false,
            rfsCheck: false,
            buCheck: false,
            firstLoad: true,
            loading: true,
            flightGridData: [],
            ULDGridData: [],
            flightRow: null,
            awbRow: null,
            page: 1,
            asgn_page: 1,
            servererror: false,
            workAreaTiles: this.clearTilesInfo(),
            tileInterval: clearInterval(this.state.tileInterval),
            fetchFlightIds: [],
          }, () => this.fetchWorkAreaDataOnSearch('search'))} page='GATEKEEPER'
        />
        <Row gutter={[{
          xs: 12,
          sm: 12,
          md: 24,
          lg: 24,
          xxl: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xxl: 24
        },]} className=''>

          <Col span={24} className='no-print'>
            <Row gutter={[{
              xs: 12,
              sm: 12,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 24
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 12
            },]}  className='slider-row'>
              <Slider  ref={c => (this.slider = c)} {...settings} className={'custom-slider bc-slider ' + (this.state.sliderLoad ? 'slider-transition ' : ' ')}>
                {this.state.workAreaTiles && this.state.workAreaTiles.map((item, index) => (
                  <Col xs className='position-relative' key={index}>
                    {index > 0 && <div className={this.state.currentWorkArea === item.area_name ? 'sitem-selected sitem-left' : 'sitem-left'}><CaretLeftOutlined onClick={() => this.moveTile(item.area_name, index, index - 1)} /></div>}
                    <Card
                      hoverable
                      size='small'
                      title={<span className='title-wrapper'>{this.renderTileInfo(item)}</span>}
                      className={this.state.currentWorkArea === item.area_name ? 'info-card' : 'info-card disabled-info-card'}
                      onClick={() => this.handleWorkAreaClick(item)}
                    >
                      <div className='text-center'>
                        <p className='info-data'>{item?.data?.awb ? item.data.awb : 0}</p>
                        <small className='info-title'>AWBS</small>
                      </div>
                      <div className='text-center'>
                        <p className='info-data'>{item?.data?.flight?.length > 0 ? item?.data?.flight?.length : 0}</p>
                        <small className='info-title'>FLIGHTS</small>
                      </div>
                      <div className='text-center'>
                        <p className='info-data'>{this.calcTons(item?.data?.wgt)}</p>
                        <small className='info-title'>TONS</small>
                      </div>
                    </Card>
                    {index < this.state.workAreaTiles?.length - 1 && <div className={this.state.currentWorkArea === item.area_name ? 'sitem-selected sitem-right' : 'sitem-right'}><CaretRightOutlined onClick={() => this.moveTile(item.area_name, index, index + 1)} /></div>}
                  </Col>))}
              </Slider>
              <div>
                <button className='prev-button bc-slick-prev slick-prev' onClick={this.previous}>
                  Previous
                </button>
                <button className='next-button bc-slick-next slick-next' onClick={this.next}>
                  Next
                </button>
              </div>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11} className='no-print'>
            <Spin spinning={this.filterByCheckboxes().length > 0 ? false : this.state.departingFlightsSpinner } >
              <Card className='custom-card h-100'
                title={
                  <div className='f-spacebtw'>
                    <span>Departing Flights</span>
                    <span>
                      <Checkbox
                        checked={this.state.showRedFlights}
                        onChange={(e) => this.setState({
                          showRedFlights: e.target.checked,
                          flightRow: null,
                          awbRow: null,
                          ULDGridData: [],
                          locationFilters: [],
                          locationValue: '',
                          prodFilters: [],
                          prodValue: '',
                          productDropdownData: [],
                          locationDropdownData: [],
                        })}
                        className='mr-2'
                      >
                        Show Red Flights
                      </Checkbox>
                      <Checkbox
                        checked={this.state.hideDeparted}
                        onChange={(e) => this.setState({
                          hideDeparted: e.target.checked,
                          flightRow: null,
                          awbRow: null,
                          ULDGridData: [],
                        })}
                      >Hide Departed Flights</Checkbox>
                      <Spin spinning={this.state.spinnerForFlights} indicator={antIcon} />
                    </span>
                  </div>
                }>
                <Row gutter={[{
                  xs: 0,
                  sm: 12,
                  md: 24,
                  lg: 24
                }, {
                  xs: 24,
                  sm: 24,
                  md: 24,
                  lg: 24
                },]} className='with-table'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                    <Form
                      layout='vertical'
                    >
                      <Row
                        gutter={[{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12
                        }, {
                          xs: 0,
                          sm: 0,
                          md: 0,
                          lg: 0
                        },]}  className=''>
                        <Col xs={24} sm={12} flex='auto' lg={24} xl={12} xxl={8} className=''>
                          <Form.Item label='From' className='inbound-label mb-0'>
                            <Row
                              gutter={[{
                                xs: 0,
                                sm: 12,
                                md: 12,
                                lg: 12
                              }, {
                                xs: 24,
                                sm: 24  ,
                                md: 24,
                                lg: 24
                              },]} >
                              <Col xs={24} sm={12} lg={12} xl={12} className='' >
                                <DatePicker
                                  allowClear={false}
                                  className=''
                                  onChange={(moment) => this.setState({ start: moment }, () => this.setFilters('start'))}
                                  value={moment(this.state.start, dateFormat)}
                                  disabledDate={(current) => this.disabledDate(current)}
                                  disabled={this.state.search}
                                />
                              </Col>
                              <Col xs={24} sm={12} lg={12} xl={12}className=''>
                                <TimePicker
                                  allowClear={false}
                                  onChange={(moment) => this.setState({ stime: moment }, () => this.setFilters('stime'))}
                                  value={moment(this.state.stime, timeFormat)}
                                  disabledHours={()=>{
                                    var hours = []
                                    let start = this.state.start ? moment(this.state.start, dateFormat).format(dateFormat) : null
                                    let atlstart = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
                                    if(start && atlstart && start === atlstart ){
                                      for(var i = 0; i < moment().tz(this.state.timezone).hour(); i++){
                                        hours.push(i)
                                      }
                                    }
                                    return hours
                                  }}
                                  disabled={this.state.search}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} flex='auto' lg={24} xl={12} xxl={8} className=''>
                          <Form.Item label='To' className='inbound-label mb-0'>
                            <Row
                              gutter={[{
                                xs: 12,
                                sm: 12,
                                md: 12,
                                lg: 12
                              }, {
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 24
                              },]} >
                              <Col xs={24} sm={12} lg={12} xl={12} className=''>
                                <DatePicker
                                  allowClear={false}
                                  className=''
                                  onChange={(moment) => this.setState({ end: moment }, () => this.setFilters('end'))}
                                  value={moment(this.state.end, dateFormat)}
                                  disabledDate={(current) => this.disabledDate(current)}
                                  disabled={this.state.search}
                                />
                              </Col>
                              <Col xs={24} sm={12} lg={12} xl={12} className=''>
                                <TimePicker
                                  allowClear={false}
                                  onChange={(moment) => this.setState({ etime: moment }, () => this.setFilters('etime'))}
                                  value={moment(this.state.etime, timeFormat)}
                                  disabledHours={()=>{
                                    var hours = []
                                    let end = this.state.end ? moment(this.state.end, dateFormat).format(dateFormat) : null
                                    let atlend = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
                                    if(end && atlend && end === atlend){
                                      for(var i = 23; i > moment().tz(this.state.timezone).hour(); i--){
                                        hours.push(i)
                                      }
                                    }
                                    return hours
                                  }}
                                  disabled={this.state.search}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={18} xxl={8} flex='260px' className=''>
                          <Form.Item label=' ' className='mt-1 mb-0'>
                            <div className='space-nowrap'>
                              <Checkbox
                                disabled={this.state.search}
                                checked={this.state.wbCheck}
                                onChange={(e) => this.setState({
                                  wbCheck: e.target.checked,
                                  ULDGridData: [],
                                  expanded: [],
                                  flightRow: null,
                                  awbRow: null,
                                  productDropdownData: [],
                                  locationDropdownData: [],
                                  prodFilters: [],
                                  locationFilters: [],
                                  tileInterval: clearInterval(this.state.tileInterval),
                                  pauseEvent: true,
                                }, () => {
                                  this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
                                  this.fetchTiles()
                                })}>
                                  WB
                              </Checkbox>
                              <Checkbox
                                disabled={this.state.search}
                                checked={this.state.nbCheck}
                                onChange={(e) => this.setState({
                                  nbCheck: e.target.checked,
                                  ULDGridData: [],
                                  expanded: [],
                                  flightRow: null,
                                  awbRow: null,
                                  productDropdownData: [],
                                  locationDropdownData: [],
                                  prodFilters: [],
                                  locationFilters: [],
                                  tileInterval: clearInterval(this.state.tileInterval),
                                  pauseEvent: true,
                                }, () => {
                                  this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
                                  this.fetchTiles()
                                })}>
                                  NB
                              </Checkbox>
                              <Checkbox
                                disabled={this.state.search}
                                checked={this.state.rfsCheck}
                                onChange={(e) => this.setState({
                                  rfsCheck: e.target.checked,
                                  ULDGridData: [],
                                  expanded: [],
                                  flightRow: null,
                                  awbRow: null,
                                  productDropdownData: [],
                                  locationDropdownData: [],
                                  prodFilters: [],
                                  locationFilters: [],
                                  tileInterval: clearInterval(this.state.tileInterval),
                                  pauseEvent: true,
                                }, () => {
                                  this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
                                  this.fetchTiles()
                                })}>
                                RFS
                              </Checkbox>
                              <Checkbox
                                disabled={this.state.search}
                                checked={this.state.buCheck}
                                onChange={(e) => this.setState({
                                  buCheck: e.target.checked,
                                  ULDGridData: [],
                                  expanded: [],
                                  flightRow: null,
                                  awbRow: null,
                                  productDropdownData: [],
                                  locationDropdownData: [],
                                  prodFilters: [],
                                  locationFilters: [],
                                  tileInterval: clearInterval(this.state.tileInterval),
                                  pauseEvent: true,
                                }, () => {
                                  this.fetchAssignedList(this.state.currentWorkArea || 'ALL')
                                  this.fetchTiles()
                                })}>
                                BU PRIO
                              </Checkbox>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='table-overflow'
                  >
                    <div className='table-outer'>
                      <Table
                        className='custom-table w-100 multi-head buildup-cont buildup-table'
                        dataSource={this.filterByCheckboxes()}
                        pagination={false}
                        rowClassName={(record) => this.setRowClassName(record, 'flightRow')}
                      >
                        <Column
                          title= ''
                          dataIndex= 'status'
                          key= 'status'
                          align= 'center'
                          className= 'status-column'
                          render={(_, record) =>(
                            <div className='table-badge'>
                              <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, true)} value={record?.handling_time_amber} format='HH:mm' />
                              <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, false)} value={record?.handling_time} format='HH:mm' />
                              {record?.is_amber ? <Badge status='warning' /> :
                                record?.handling_time_color === 'green' ? <Badge status='success' /> :
                                  record?.handling_time_color === 'red' ? <Badge status='error' /> :
                                    <Badge status='default' />}
                            </div>
                          )}
                        />
                        <Column
                          title= 'Flight #'
                          dataIndex= ''
                          key= 'flight_no'
                          className= 'space-nowrap'
                          render={ (_, record) => (
                            <span>{record?.flight_no ? `${record.flight_no}` : 'N/A'}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <Column
                          title= 'Date'
                          dataIndex= 'date'
                          key= 'date'
                          className= 'space-nowrap'
                          render={ (_, record) => (
                            <span>{record.flight_date ? `${moment(record.flight_date).format('DDMMMYY')?.toUpperCase()}` : record.dept_date ? `${moment(record.dept_date).format('DDMMMYY')?.toUpperCase()}` : record?.dept_time ? `${moment(record.dept_time).format('DDMMMYY')?.toUpperCase()}` : 'N/A'}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <Column
                          title= 'A/C'
                          dataIndex= ''
                          key= 'ac_type'
                          className= 'min-width-40'
                          render={ (_, record) => (
                            <span>{record?.ac_type ? record.ac_type : 'N/A'}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <Column
                          title= 'Dest'
                          dataIndex= ''
                          key= 'dest'
                          className= 'min-width-40'
                          render={ (_, record) => (
                            <span>{record?.dest?.arriv ? record.dest?.arriv : 'N/A'}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <ColumnGroup title={<span className='udl-pl'>ULD Positions</span>} align='center'>
                          <Column
                            title='' dataIndex='PA' key='PA' align='right'
                            className='uld-table'
                            render={ (_, record) => (
                              <Row gutter={[0, 8]}>
                                <Col span={24}><span className={record?.showULDpos || record.body_type === 'WB'? 'px-1 fs-10 pa-badge': ''}>{record?.showULDpos || record.body_type === 'WB'? 'P' : ''}</span></Col>
                                <Col span={24}><span className={record?.showULDpos || record.body_type === 'WB'? 'px-1 fs-10 pa-badge': ''}>{record?.showULDpos || record.body_type === 'WB'? 'A' : ''}</span></Col>
                              </Row>
                            )}
                            onCell={(record) => { return {
                              onClick: () => { this.setState({
                                flightRow: record,
                                expanded: [],
                              }, () => this.handleRowClick(record)) }
                            }} }
                          />
                          {this.state.hideMD ? null : <Column
                            title='MD' dataIndex='MD' key='MD'
                            className='uld-table'
                            render={ (_, record) => (
                              <Row gutter={[0, 8]}>
                                <Col span={24}><span>{record?.cargo?.md || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                                <Col span={24}><span>{record?.actual?.MD || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                              </Row>
                            )}
                            onCell={(record) => { return {
                              onClick: () => { this.setState({
                                flightRow: record,
                                expanded: [],
                              }, () => this.handleRowClick(record)) }
                            }} }
                          />}
                          <Column
                            title='PMC' dataIndex='P96' key='P96'
                            className='uld-table'
                            render={ (_, record) => (
                              <Row gutter={[0, 8]}>
                                <Col span={24}><span>{record?.cargo?.p96 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                                <Col span={24}><span>{record?.actual?.P96 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                              </Row>
                            )}
                            onCell={(record) => { return {
                              onClick: () => { this.setState({
                                flightRow: record,
                                expanded: [],
                              }, () => this.handleRowClick(record)) }
                            }} }
                          />
                          <Column
                            title='PAG' dataIndex='P88' key='P88'
                            className='uld-table'
                            render={ (_, record) => (
                              <Row gutter={[0, 8]}>
                                <Col span={24}><span>{record?.cargo?.p88 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                                <Col span={24}><span>{record?.actual?.P88 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                              </Row>
                            )}
                            onCell={(record) => { return {
                              onClick: () => { this.setState({
                                flightRow: record,
                                expanded: [],
                              }, () => this.handleRowClick(record)) }
                            }} }
                          />
                          <Column
                            title='LD3' dataIndex='LD3' key='LD3'
                            className='uld-table'
                            render={ (_, record) => (
                              <Row gutter={[0, 8]}>
                                <Col span={24}><span>{record?.cargo?.ld3 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                                <Col span={24}><span>{record?.actual?.LD3 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                              </Row>
                            )}
                            onCell={(record) => { return {
                              onClick: () => { this.setState({
                                flightRow: record,
                                expanded: [],
                              }, () => this.handleRowClick(record)) }
                            }} }
                          />
                          <Column
                            title='LD2' dataIndex='LD2' key='LD2'
                            className='uld-table'
                            render={ (_, record) => (
                              <Row gutter={[0, 8]}>
                                <Col span={24}><span>{record?.cargo?.ld2 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                                <Col span={24}><span>{record?.actual?.LD2 || (record?.showULDpos || record.body_type === 'WB'? '0' : '')}</span></Col>
                              </Row>
                            )}
                            onCell={(record) => { return {
                              onClick: () => { this.setState({
                                flightRow: record,
                                expanded: [],
                              }, () => this.handleRowClick(record)) }
                            }} }
                          />
                        </ColumnGroup>
                        <Column
                          title= 'Bkd + Asd'
                          dataIndex= 'booked'
                          key= 'booked'
                          className= 'space-nowrap'
                          render={ (_, record) => (
                            <span>{this.state.weight_unit === 'KG' && record?.booked_kg ? `${formatWeight(record?.booked_kg)}KG` :
                              this.state.weight_unit === 'LB' && record?.booked_lbs ? `${formatWeight(record?.booked_lbs)}LB` :
                                ''}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <Column
                          title= 'Ready'
                          dataIndex= 'ready'
                          key= 'ready'
                          render={ (_, record) => (
                            <span>{this.state.weight_unit === 'KG' && record?.ready_kg ? `${formatWeight(record?.ready_kg)}KG` :
                              this.state.weight_unit === 'LB' && record?.ready_lbs ? `${formatWeight(record?.ready_lbs)}LB` :
                                ''}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <Column
                          title= 'At Gate'
                          dataIndex= 'at_gate'
                          key= 'at_gate'
                          render={ (_, record) => (
                            <span className={record?.wrong_gate?.length > 0 ? 'link-color' : ''}>{this.state.weight_unit === 'KG' && record?.gate_kg ? `${formatWeight(record?.gate_kg)}KG` :
                              this.state.weight_unit === 'LB' && record?.gate_lbs ? `${formatWeight(record?.gate_lbs)}LB` :
                                <span className='color-initial'></span>}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => {
                              if(record?.wrong_gate?.length > 0) {
                                this.setState({
                                  wrongGateRow: record?.wrong_gate,
                                  wrongGateFlightID: record?.id,
                                }, () => {
                                  this.setState({ wrongLocationModal: true })
                                })
                              } else {
                                this.setState({
                                  flightRow: record,
                                  expanded: [],
                                }, () => this.handleRowClick(record))
                              }
                            }
                          }} }
                        />
                        <Column
                          title= 'FPE-'
                          dataIndex= 'ttd'
                          key= 'ttd'
                          render={ (_, record) => (
                            <span className='countdown'>{record?.time_to_dept ? <Countdown value={moment(record.time_to_dept)} format='HH:mm' /> : 'N/A'}</span>
                          )}
                          onCell={(record) => { return {
                            onClick: () => { this.setState({
                              flightRow: record,
                              expanded: [],
                            }, () => this.handleRowClick(record)) }
                          }} }
                        />
                        <Column
                          title= 'Action'
                          dataIndex= 'action'
                          key= 'action'
                          align= 'center'
                          render={ (_, record) =>(
                            <div className='action-column inbound-action-col'>
                              {record?.bu_prio && <Tooltip
                                title=''
                                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                              >
                                <img src={thunderboltIcon} alt='document' />
                              </Tooltip>}
                              <Button className={this.renderCCClass(record)} onClick={() => this.fetchAllowedULDs(record)}>CC</Button>
                              <Badge size='small' count={record?.notes || 0} color={'#0a1227'}>
                                <Tooltip
                                  title='Notes'
                                  overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                                  onClick={() => this.showFlightNotes(record)}
                                >
                                  <img src={documentIcon} alt='document' />
                                </Tooltip>
                              </Badge>
                            </div>
                          )}
                        />
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={13} className=''>
            <Card
              className='custom-card h-100 uld-print'
              title={<div className='f-spacebtw no-print'>
                {this.state.flightRow ?
                  `Booked Load ${this.state.flightRow?.flight_no}/${moment(this.state.flightRow?.flight_date).format('DDMMMYY')}` :
                  'Booked Load'}
                <Popconfirm
                  placement='topLeft'
                  title={'Show ULD content'}
                  okText='Yes'
                  cancelText='No'
                  onConfirm={()=> this.setState({ printuldcon: true }, () => this.print())}
                  onCancel={()=> this.setState({ printuldcon: false }, () => this.print())}
                  className='no-print'
                  disabled={this.state.ULDGridData?.length === 0}
                >
                  <Button className='custom-icon-button round-button' type='primary' shape='circle' icon={<PrinterOutlined />} size='small' disabled={this.state.ULDGridData?.length === 0} />
                </Popconfirm>
              </div>
              }
            >
              <Row gutter={[{
                xs: 12,
                sm: 12,
                md: 24,
                lg: 24
              }, {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='on-print'>
                  <Form
                    layout='vertical'
                    className=''
                  >
                    <Row wrap
                      gutter={[{
                        xs: 0,
                        sm: 12,
                        md: 12,
                        lg: 12
                      }, {
                        xs: 24,
                        sm: 24,
                        md: 24,
                        lg: 0
                      },]}>
                      <h2 className='hidden-print-table flight-no w-100'>{
                        <div className='header-wrapper'>
                          <h3>{this.state.flightRow?.flight_no ? ('Flight # '+this.state.flightRow.flight_no+ (this.state.flightRow?.flight_date ? `/${moment(this.state.flightRow.flight_date).format('DDMMMYY')?.toUpperCase()}` : this.state.flightRow?.dept_date ? `/${moment(this.state.flightRow.dept_date).format('DDMMMYY')?.toUpperCase()}` : this.state.flightRow?.dept_time ? `/${moment(this.state.flightRow.dept_time).format('DDMMMYY')?.toUpperCase()}` : '') ) : ''}</h3>
                          <div className='d-flex'>
                            <span>{this.state.flightRow?.ac_type ? ('  AC Type: '+this.state.flightRow?.ac_type) : ''}</span>
                            <span>{this.state.flightRow?.time_to_dept ? ('  FPE: '+this.showCountdown(this.state.flightRow?.time_to_dept)) : ''}</span>
                          </div>
                        </div>
                      }</h2>
                      <Col span={12} className='bl-width hidden-print-table uld-pos-print'>
                        <Form.Item label='Planned ULD Positions' className='mb-0'>
                          <Space size={[8, 8]} wrap>
                            {this.generateULDPositions(this.state.flightRow || '', 'cargo')}
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col className='bl-width print-w50'>
                        <Form.Item label='Remaining ULD Positions' className='mb-0'>
                          <Space size={[8, 8]} wrap>
                            {this.generateRemainingULDHud()}
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col span={12} className='bl-width hidden-print-table uld-pos-print'>
                        <Form.Item label='Actual ULD Positions' className='mb-0'>
                          <Space size={[8, 8]} wrap>
                            {this.generateULDPositions(this.state.flightRow || '', 'actual')}
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col xs className='bl-width print-w25'>
                        <Form.Item label='' className='mb-0'>
                          <label className='space-nowrap'>Remaining Cargo Payload</label>
                          <Tag className='text-center primary-tag mr-0'>
                            {this.remainingCargoPayload()}
                          </Tag>
                        </Form.Item>
                      </Col>
                      <Col flex='auto' className='mt-neg-1 bl-width print-w25'>
                        <Form.Item label='Build Up Status' className='mb-0'>
                          <Select
                            className='w-100'
                            size='small'
                            defaultValue='ALL'
                            onChange={(e) => this.setState({ dropdownValue: e }, () => this.filterAWBByDropdown())} value={this.state.dropdownValue}
                          >
                            <Option value={'ALL'}>All</Option>
                            <Option value={'PENDING'}>Pending Build Up</Option>
                            <Option value={'COMPLETED'}>Build Up</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='auto' className='mt-neg-1 bl-width rankby-label no-print'>
                        <Form.Item label=' ' className='mt-1 mb-0'>
                          <Checkbox
                            checked={this.state.priorCheck}
                            onChange={(e) => this.setState({ priorCheck: e.target.checked })}>
                              Rank By Product priority
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={6} md={5} lg={8} xl={7} xxl={5} className='text-right ml-auto no-print'>
                        <Form.Item className='mb-0'>
                          <label className='custom-label transparent'>-</label>
                          <Button
                            type='primary'
                            className='custom-button fs-11 repl-btn f-middle w-100 mb-24px'
                            size='small'
                            onClick={this.fetchReplacementLoad}
                            disabled={!this.state.flightRow?.id}
                          >Find replacement Load
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow no-print'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table w-100 ex-tabl buildup-table'
                      dataSource={this.filterAWBByDropdown()}
                      columns={uldColumns}
                      pagination={false}
                      expandable={{
                        expandedRowRender: (record) => (
                          <Table
                            className='custom-table no-header-table ex-inner-tbl pl-0'
                            columns={awbColumns}
                            // dataSource={(record?.awbs_in && this.state.priorCheck )? this.sort(record.awbs_in, true, 'prod_prior') : record?.awbs_in || []}
                            dataSource={record?.awbs_in || []}
                            pagination={false}
                            rowClassName={(record) => this.setRowClassName(record, 'awbRow')}
                            onRow={(record) => { return { onClick: () => this.setState({ awbRow: record }) }}}
                          />
                        ),
                        rowExpandable: (record) => record?.type !== 'BLK',
                        expandedRowKeys: this.state.expanded,
                        onExpandedRowsChange: (rows) => this.setState({ expanded: [] }, () => this.setState({ expanded: rows })),
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded?
                            record?.type !== 'BLK' ? (
                              <CaretDownOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                              <div className='table-badge'>
                                {record?.type === 'BLK' ? record?.is_amber ? <Badge status='warning' /> :
                                  record?.handling_time_color === 'green' ? <Badge status='success' /> :
                                    record?.handling_time_color === 'red' ? <Badge status='error' /> :
                                      <Badge status='default' /> : ''}
                              </div>
                            ): record?.type === 'BLK' ?
                              <div className='table-badge'>
                                {record?.type === 'BLK' ? record?.is_amber ? <Badge status='warning' /> :
                                  record?.handling_time_color === 'green' ? <Badge status='success' /> :
                                    record?.handling_time_color === 'red' ? <Badge status='error' /> :
                                      <Badge status='default' /> : ''}
                              </div>
                              : <CaretRightOutlined onClick={e => onExpand(record, e)} />
                      }}
                      rowKey='id'
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow hidden-print-table'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table w-100 ex-tabl buildup-table'
                      dataSource={this.filterAWBByDropdown()}
                      columns={uldColumns}
                      pagination={false}
                      expandable={{
                        expandedRowRender: (record) => (
                          <Table
                            className='custom-table no-header-table ex-inner-tbl pl-0'
                            columns={awbColumns}
                            dataSource={record?.awbs_in || []}
                            pagination={false}
                            rowClassName={(record) => this.setRowClassName(record, 'awbRow')}
                            onRow={(record) => { return { onClick: () => this.setState({ awbRow: record }) }}}
                          />
                        ),
                        rowExpandable: (record) => record?.type !== 'BLK',
                        expandedRowKeys: this.state.expandedPrint,
                        onExpandedRowsChange: (rows) => this.setState({ expandedPrint: [] }, () => this.setState({ expandedPrint: rows })),
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded?
                            record?.type !== 'BLK' ? (
                              <CaretDownOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                              <div className='table-badge'>
                                {record?.type === 'BLK' ? record?.is_amber ? <Badge status='warning' /> :
                                  record?.handling_time_color === 'green' ? <Badge status='success' /> :
                                    record?.handling_time_color === 'red' ? <Badge status='error' /> :
                                      <Badge status='default' /> : ''}
                              </div>
                            ): record?.type === 'BLK' ?
                              <div className='table-badge'>
                                {record?.type === 'BLK' ? record?.is_amber ? <Badge status='warning' /> :
                                  record?.handling_time_color === 'green' ? <Badge status='success' /> :
                                    record?.handling_time_color === 'red' ? <Badge status='error' /> :
                                      <Badge status='default' /> : ''}
                              </div>
                              : <CaretRightOutlined onClick={e => onExpand(record, e)} />
                      }}
                      rowKey='id'
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Modal
          title= {this.state.notesTitle}
          visible={this.state.flightNotes}
          onCancel={() => this.setState({
            flightNotes: false,
            notesTitle: ''
          })}
          footer={false}
          width={'80%'}
          centered
          className='custom-modal pb-0'
          destroyOnClose={true}
        >
          <Notes search={this.state.note} />
        </Modal>

        <Modal
          title='Cargo at wrong gate'
          destroyOnClose
          visible={this.state.wrongLocationModal}
          onOk={() => this.setState({
            wrongLocationModal: false,
            wrongGateRow: [],
            wrongGateFlightID: null,
          })}
          onCancel={() => this.setState({
            wrongLocationModal: false,
            wrongGateRow: [],
            wrongGateFlightID: null,
          })}
          footer={false}
          width={'80%'}
          centered
          className='custom-modal pb-0'
        >
          <Row gutter={[{
            xs: 0,
            sm: 12,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 12,
            md: 12,
            lg: 12
          },]} className='with-table'>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100'
                  dataSource={this.state.wrongGateRow || []}
                  columns={wrongLocationColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default BuildUpControl
