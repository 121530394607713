import React, { Component } from 'react'
import { Button, Input, Row, Col, Typography, Select, Space, Form, Card, Table, Radio, Checkbox, Tooltip, Popconfirm, notification } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants, { timezone, systemParameterTabs as titles } from '../../../constants/constants'
import './tabs.css'
import Http from '../../../utils/http'
import Cookies from 'universal-cookie'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

const { Title } = Typography
class RecoveryAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      station: new Cookies().get('station'),
      user_id: new Cookies().get('id'),
      loading: false,
      tableData: [],
      recoveryTeamsData: [],
      team: [],
      teamOption: [],
      teamPriority: {},
      is_edit: false,
      id: null,
      action: '',
      station_limit: new Cookies().get('station'),
      is_inbound: false,
      is_breakdown: false,
      is_buildup: false,
      is_gen_rebook: true,
      q_to: '',
      q_priority: '',
      is_notify: false,
      email: '',
      duplicate: null,

      editRecord: null,
      editAction: null,
      editStation_limit: null,
      editIs_inbound: false,
      editIs_breakdown: false,
      editIs_buildup: false,
      editIs_gen_rebook: true,

      editQ_to: '',
      editQ_priority: '',
      editTeamOption: [],
      editIs_notify: false,
      editEmail: null,

    }
  }

  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${this.state.station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // eslint-disable-next-line no-console
      console.log('parsedData',parsedData)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData?.TYPE === 'RECOVERY_TEAM') {
          this.fetchTeam()
        }
        if(parsedData?.TYPE === 'RECOVERY_ACTION') {
          this.fetchAction()
        }
      }
    }
    this.fetchTeam()
    this.fetchAction()
  }

  fetchTeam() {
    let headers = { station: this.state.station }
    Http.get('recovery_teams', { headers }).then(resp => {
      if (resp?.data && resp?.status == 200) {
        let teamData = []
        let teamPriority = []
        resp.data?.data?.map(d => {
          teamData.push({
            label: d.details.rebook_team,
            value: d.details.rebook_team
          })
          teamPriority.push({
            rebook_team: d.details.rebook_team,
            label: d.details.q_priority,
            value: d.details.q_priority
          })
        })
        teamData = teamData.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.value === thing.value
          ))
        )
        this.setState({
          team: teamData,
          recoveryTeamsData: resp.data?.data
        })
        const groupBYName = this.groupBy('rebook_team')
        this.setState({ teamPriority: groupBYName(teamPriority) })
      }
    })
  }

  teamHandlere(e) {
    if(this.state.teamPriority[e]?.length === 1) {
      this.setState({
        q_to: e,
        teamOption: this.state.teamPriority[e] || [],
        q_priority: this.state.teamPriority[e][0].value
      })
    } else {
      this.setState({
        q_priority: '',
        q_to: e,
        teamOption: this.state.teamPriority[e] || []
      })
    }
  }
  editTeamHandlere(e) {
    if(this.state.teamPriority[e]?.length === 1) {
      this.setState({
        editQ_to: e,
        editTeamOption: this.state.teamPriority[e] || [],
        editQ_priority: this.state.teamPriority[e][0].value
      })
    } else {
      this.setState({
        editQ_priority: '',
        editQ_to: e,
        editTeamOption: this.state.teamPriority[e] || []
      })
    }
  }

  rebookingQueHandler(v) {
    this.setState({ is_gen_rebook: v })
    if (!v) {
      this.setState({
        q_to: '',
        q_priority: '',
        teamOption: [],
        is_notify: true
      })
    }
  }
  editRebookingQueHandler(v) {
    this.setState({ editIs_gen_rebook: v })
    if (!v) {
      this.setState({
        editQ_to: '',
        editQ_priority: '',
        editTeamOption: [],
        editIs_notify: true
      })
    }
  }

  groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})

  fetchAction() {
    let headers = { station: this.state.station }
    Http.get('recovery_actions', { headers }).then(resp => {
      if (resp?.data && resp?.status == 200) {
        this.setState({ tableData: resp?.data?.data })
      }
    })
  }

  addAction() {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.recovery_action
    }
    let formdata = {
      recovery_teams_id: this.state.recoveryTeamsData?.filter((item) => item?.details?.rebook_team === this.state.q_to && item?.details?.q_priority === this.state.q_priority)[0]?.id || null,
      action: this.state.action,
      station_limit: this.state.station_limit,
      is_inbound: this.state.is_inbound,
      is_breakdown: this.state.is_breakdown,
      is_buildup: this.state.is_buildup,
      is_gen_rebook: this.state.is_gen_rebook,
      q_to: this.state.q_to,
      q_priority: this.state.q_priority,
      is_notify: this.state.is_notify,
      email: this.state.email,
      station: this.state.station || null,
    }
    Http.post('/recovery_action', formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.clearForm()
          this.fetchAction()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  editAction(a) {
    this.setState({
      is_edit: true,
      id: a.id,
      action: a.details.action,
      station_limit: a.details.station_limit,
      is_inbound: a.details.is_inbound,
      is_breakdown: a.details.is_breakdown,
      is_buildup: a.details.is_buildup,
      is_gen_rebook: a.details.is_gen_rebook,
      q_to: a.details.q_to,
      q_priority: a.details.q_priority,
      is_notify: a.details.is_notify,
      email: a.details.email
    })
  }
  updateAction() {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.recovery_action
    }
    let formdata = {
      recovery_teams_id: this.state.recoveryTeamsData?.filter((item) => item?.details?.rebook_team === this.state.editQ_to && item?.details?.q_priority === this.state.editQ_priority)[0]?.id || null,
      action: this.state.editAction,
      station_limit: this.state.editStation_limit,
      is_inbound: this.state.editIs_inbound,
      is_breakdown: this.state.editIs_breakdown,
      is_buildup: this.state.editIs_buildup,
      is_gen_rebook: this.state.editIs_gen_rebook,
      q_to: this.state.editQ_to,
      q_priority: this.state.editQ_priority,
      is_notify: this.state.editIs_notify,
      email: this.state.editEmail,
      station: this.state.station || null,
    }
    Http.put(`/recovery_action/${this.state?.editRecord.id}`, formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.clearTableForm()
          this.clearForm()
          this.fetchAction()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  clearForm = () => {
    this.setState({
      is_edit: false,
      id: null,
      action: '',
      station_limit: this.state.station,
      is_inbound: false,
      is_breakdown: false,
      is_buildup: false,
      is_gen_rebook: true,
      q_to: '',
      q_priority: '',
      is_notify: false,
      email: ''
    })
  }

  deleteAction = async (id) => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.recovery_action
    }
    await Http.put(`/recovery_action/${id}`, { is_delete: true }, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.fetchAction()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  editTableForm = (record) => {
    this.setState({
      editRecord: record,
      editAction: record.details.action,
      editStation_limit: record.details.station_limit,
      editIs_inbound: record.details.is_inbound,
      editIs_breakdown: record.details.is_breakdown,
      editIs_buildup: record.details.is_buildup,
      editIs_gen_rebook: record.details.is_gen_rebook,

      editQ_to: record.details.q_to,
      editQ_priority: record.details.q_priority,
      editTeamOption: this.state.teamPriority[record.details.q_to] || [],
      editIs_notify: record.details.is_notify,
      editEmail: record.details.email

    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editAction: null,
      editStation_limit: this.state.station,
      editIs_inbound: false,
      editIs_breakdown: false,
      editIs_buildup: false,
      editIs_gen_rebook: true,

      editQ_to: '',
      editQ_priority: '',
      editTeamOption: [],
      editIs_notify: false,
      editEmail: null,

    })
  }

  render() {

    const recoverycolumns = [
      {
        title: 'Recovery Action',
        key: 'recoveryaction',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editAction}
              onChange={(e) => this.setState({ editAction: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>
              {record?.details?.action}
            </span>
        )
      },
      {
        title: 'For Station',
        key: 'station',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select 
              className='w-100' 
              size='small' 
              placeholder='' 
              options={timezone} 
              value={this.state.editStation_limit} 
              onChange={(e) => this.setState({ editStation_limit: e })}
              disabled
            >
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.station_limit}</span> )
      },
      {
        title: 'Inbound Flight Prep',
        key: 'inflight',
        align: 'center',
        className: 'radio-nowrap',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Checkbox checked={this.state.editIs_inbound} onClick={() => this.setState({ editIs_inbound: !this.state.editIs_inbound })} >Inbound Flight Prep</Checkbox>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_inbound? (<CheckOutlined />) : (<CloseOutlined />)}</span> )
      },
      {
        title: 'Breakdown Control',
        key: 'breakdowncontrol',
        align: 'center',
        className: 'radio-nowrap',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Checkbox checked={this.state.editIs_breakdown} onClick={() => this.setState({ editIs_breakdown: !this.state.editIs_breakdown })} >Breakdown Control</Checkbox>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_breakdown ? (<CheckOutlined />) : (<CloseOutlined />)}</span> )
      },
      {
        title: 'Build Up Control',
        key: 'buildupcontrol',
        align: 'center',
        className: 'radio-nowrap',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Checkbox checked={this.state.editIs_buildup} onClick={() => this.setState({ editIs_buildup: !this.state.editIs_buildup })} >Build Up Control</Checkbox>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_buildup? (<CheckOutlined />) : (<CloseOutlined />)}</span> )
      },
      {
        title: 'Rebooking Queue',
        key: 'rebookingqueue',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Radio.Group onChange={(e) => this.editRebookingQueHandler(e.target.value)} value={this.state.editIs_gen_rebook} className='radio-nowrap'>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_gen_rebook ? 'Yes' : 'No'}</span> )
      },
      {
        title: 'Queue To',
        key: 'queueto',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select className='w-100' disabled={!this.state.editIs_gen_rebook} size='small' placeholder='' options={this.state.team} value={this.state.editQ_to} onChange={(e) => this.editTeamHandlere(e)}>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_gen_rebook? record?.details?.q_to : 'NA'}</span> )
      },
      {
        title: 'Queue Priority',
        key: 'queuepriority',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select className='w-100' disabled={!this.state.editIs_gen_rebook} size='small' options={this.state.editTeamOption} value={this.state.editQ_priority} onChange={(e) => this.setState({ editQ_priority: e })}>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_gen_rebook? record?.details?.q_priority : 'NA'}</span> )
      },
      {
        title: 'Email Notifications To',
        key: 'emailto',
        width: '30%',
        className: 'wordbreak',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <div className='radio-nowrap'>
              <Space>
                <Checkbox disabled={!this.state.editIs_gen_rebook} checked={this.state.editIs_notify} onClick={() => { this.state.editIs_notify ? this.setState({ editEmail: '' }) : {} ;this.setState({ editIs_notify: !this.state.editIs_notify }) } }></Checkbox>
                <Input
                  size='small'
                  placeholder=''
                  disabled={!this.state.editIs_notify}
                  value={this.state.editEmail}
                  onChange={(e) => this.setState({ editEmail: e.target.value })}
                />
              </Space>
            </div>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.is_notify? record?.details?.email : '-'}</span> )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            { this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateAction()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteAction(record.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card with-table'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Specify Recovery Action</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={3} className=''>
                        <Form.Item label='Enter Recovery Action'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.action}
                            onChange={(e) => this.setState({ action: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={2} className=''>
                        <Form.Item label='Limit to Station'>
                          <Select 
                            size='small' 
                            placeholder='' 
                            options={timezone} 
                            value={this.state.station_limit} 
                            onChange={(e) => this.setState({ station_limit: e })}
                            disabled
                          >
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={9} flex='440px' className=''>
                        <Form.Item>
                          <label className='custom-label label-border'>Relevant for modules</label>
                          <>
                            <Checkbox checked={this.state.is_inbound} onClick={() => this.setState({ is_inbound: !this.state.is_inbound })} >Inbound Flight Prep</Checkbox>
                            <Checkbox checked={this.state.is_breakdown} onClick={() => this.setState({ is_breakdown: !this.state.is_breakdown })} >Breakdown Control</Checkbox>
                            <Checkbox checked={this.state.is_buildup} onClick={() => this.setState({ is_buildup: !this.state.is_buildup })} >Build Up Control</Checkbox>
                          </>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item>
                          <label className='custom-label label-border'>Rebooking Queue</label>
                          {/* <Divider/> */}
                          <Radio.Group onChange={(e) => this.rebookingQueHandler(e.target.value)} value={this.state.is_gen_rebook} className='radio-nowrap'>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={2} className=''>
                        <Form.Item label='Send Queue to'>
                          <Select disabled={!this.state.is_gen_rebook} size='small' placeholder='' options={this.state.team} value={this.state.q_to} onChange={(e) => this.teamHandlere(e)}>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={2} className=''>
                        <Form.Item label='Queue Priority'>
                          <Select disabled={!this.state.is_gen_rebook} size='small' options={this.state.teamOption} value={this.state.q_priority} onChange={(e) => this.setState({ q_priority: e })}>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6} flex='auto' className=''>
                        <Form.Item label=''>
                          <Checkbox disabled={!this.state.is_gen_rebook} checked={this.state.is_notify} onClick={() => { this.state.is_notify ? this.setState({ email: '' }) : {} ;this.setState({ is_notify: !this.state.is_notify }) } }><label className='custom-label'>E-mail notifications to</label></Checkbox>
                          <Input
                            size='small'
                            placeholder=''
                            disabled={!this.state.is_notify}
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                          />
                        </Form.Item>
                      </Col>

                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='image-btn' onClick={() => this.state.is_edit ? this.updateAction() : this.addAction()}>
                          <Tooltip title={this.state.is_edit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    {this.state.is_edit && (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn recovery-btn-placement' onClick={() => this.clearForm()}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )}
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} >
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>List of Recovery Actions</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table rec-act-table'
                      dataSource={this.state.tableData}
                      columns={recoverycolumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default RecoveryAction