import React, { useState, useEffect } from 'react'
import { 
  AirlineMessages, 
  Steps 
} from './tabs'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { messageInterfaceTabs } from '../../constants/constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const MessageInterface = ({ match }) => {

  const [tab, setTab] = useState(false)

  useEffect(() => {
    if (match?.params.tab) {
      if (messageInterfaceTabs[match.params.tab]) {
        setTab(messageInterfaceTabs[match.params.tab])
      } else {
        setTab(messageInterfaceTabs.steps)
      }
    } else {
      setTab(messageInterfaceTabs.steps)
    }
  }, [])


  const tabHnadler = (t) => {
    setTab(t)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>
      <Slider {...settings} className='custom-slider mb-24px'>
        <div className={`tabs ${tab == messageInterfaceTabs.steps? 'active-tab' : ''}`} onClick={() => tabHnadler(messageInterfaceTabs.steps)}>
          <span>Demo Steps</span>
        </div>
        <div className={`tabs ${tab == messageInterfaceTabs.airline_msg? 'active-tab' : ''}`} onClick={() => tabHnadler(messageInterfaceTabs.airline_msg)}>
          <span>Airline Messages</span>
        </div>

      </Slider>

      {tab == messageInterfaceTabs.airline_msg &&
        <AirlineMessages />
      }
      {tab == messageInterfaceTabs.steps &&
        <Steps />
      }
    </div>
  )
}
MessageInterface.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default MessageInterface