import React, { Component } from 'react'
import { Input } from 'antd'
import './search.css'

const { Search } = Input

class HeaderSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    //console.log('props ',this.props)
  }

  onSearch = (value) => {
    if(this.props.page && this.props.page !== 'GATEKEEPER' && value.includes('-')) {
      value = value.replace('-', '')
    }
    let trimmedValue = value ? value.trim() : ''
    let url = trimmedValue ? `?search=${trimmedValue}` : ''
    this.props.searchResults(url)
  }

  handleKeyDown = e => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  render() {
    return (
      <div>
        <Search
          allowClear
          className='search-input header-search no-print'
          placeholder={this.props.placeholder || 'Eg: YY123/01NOV22 or 001-12455675 or XXX12345YY'}
          onKeyDown={this.handleKeyDown}
          onSearch={this.onSearch}
        />
      </div>
    )
  }
}

export default HeaderSearch