import React, { Component } from 'react'
import { Image, Button } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import dataIcon from '../../assets/images/presentation.svg'
import Logo from '../../assets/images/logo.png'
import './index.css'
import Cookies from 'universal-cookie'

class LandingPage extends Component {
  //REDIRECT LOGIC
  handleRedirect = (value) => {
    if(value === 'Recovery'){
      window.location.href = '/recovery-dashboard'
    } else if (value === 'Buildup'){
      window.location.href = '/buildup-dashboard'
    }else if (value === 'Breakdown'){
      window.location.href = '/breakdown-dashboard'
    }else if (value === 'Haulout'){
      window.location.href = '/haulout-dashboard'
    }
  }

  render() {
    return (
      <div className='landing-main'>
        <div className='top-block'>
          <Image preview={false} src={Logo} className='logo-img'></Image>
          <SettingOutlined size='large' className='head-icon' />
          <SettingOutlined size='large' className='head-icon2' />
          <div className='dash-head'>
            <p className='landing-loc'>{new Cookies().get('station')}</p>
            <p className=''>Dashboard</p>
          </div>
        </div>
        <div className='inner-block'>
          <Button className='dash-tile'  onClick={()=>this.handleRedirect('Recovery')}>
            <span>
              Recovery <br /> Dashboard
            </span>
            <img src={dataIcon} alt='data-icon' className='dash-tile-icon' />
          </Button>
          <Button className='dash-tile' onClick={()=>this.handleRedirect('Buildup')}>
            <span>
              Buildup <br /> Dashboard
            </span>
            <img src={dataIcon} alt='data-icon' className='dash-tile-icon' />
          </Button>
          <Button className='dash-tile' onClick={()=>this.handleRedirect('Haulout')}>
            <span>
              Haulout <br /> Dashboard
            </span>
            <img src={dataIcon} alt='data-icon' className='dash-tile-icon' />
          </Button>
          <Button className='dash-tile' onClick={()=>this.handleRedirect('Breakdown')}>
            <span>
              Breakdown <br /> Dashboard
            </span>
            <img src={dataIcon} alt='data-icon' className='dash-tile-icon' />
          </Button>
        </div>
      </div>
    )
  }
}

export default LandingPage
