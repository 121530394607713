import React, { Component } from 'react'
import { Row, Col, Card, Collapse, Button, Table, Progress } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import Tick from '../../../assets/images/tick.png'
import '../index.css'
import { pages as title } from '../../../constants/constants'
import { COOKIE, getCookie } from '../../../utils/cookie'
import { 
  get_demo_msg_common, resetTablesCommon, fetchRedisDataCommon,
  getFakeAsyncJobCommon,
  enqueueCommon,
  nextJobCommon,
  push_msg_to_queue_common,
  sendBulkMsgCommon,
  process_Inbound_Start_Common,
  process_SSIM_Common,
} from '../../../utils/comsteps'
const { Panel } = Collapse

const panelStyle = {
  marginBottom: 24,
  background: 'white',
  borderRadius: '15pxpx',
  border: 'none',
  fontSize: '13px'
}

class Steps extends Component {
  _queue = []
  _isBusy = false

  constructor() {
    super()
    this.state = {
      timezone: getCookie(COOKIE.TIMEZONE),
      station: getCookie(COOKIE.STATION),
      reset_time: getCookie(COOKIE.RESET_TIME) || null,
      demo_buttons: getCookie(COOKIE.DEMO_BUTTONS) || null,
      ecibooks: [],
      manifests: [],
      flight_events: [],
      fsus: [],
      ffms: [],
      ssim: [],

      send_ecibooks: [],
      send_manifests: [],
      send_flight_events: [],
      send_fsus: [],
      send_ffms: [],
      percent1: 0,
      percent2: 0,
      percent3: 0,
      percent4: 0,
      percent5: 0,
      percent6: 0,
      percent7: 0,
      percent8: 0,
      percent9: 0,
      percent10: 0,
      percent11: 0,
      percent12: 0,
      percent13: 0,
      percent14: 0,
      percent15: 0,
      percent16: 0,
      percent17: 0,
      percent18: 0,
      percent19: 0,
      percent20: 0,

      fetchInterval: null,
      rediscount: 0,
      redismaxcount: 0,
      percentage: 0,
    }
    // this.resetTables = this.resetTables.bind(this)
    // this.get_demo_msg = this.get_demo_msg.bind(this)
    // this.fetchRedisData = this.fetchRedisData.bind(this)
    // this.getFakeAsyncJob = this.getFakeAsyncJob.bind(this)
    // this.enqueue = this.enqueue.bind(this)
    // this.push_msg_to_queue = this.push_msg_to_queue.bind(this)
    // this.sendBulkMsg = this.sendBulkMsg.bind(this)
    // this.process_Inbound_Start = this.process_Inbound_Start.bind(this)+
    // this.process_SSIM = this.process_SSIM.bind(this)
    // this.nextJob = this.nextJob.bind(this)

    this._queue = []
    this._isBusy = false
    const bars = [
      {
        handlePercent: 'handlePercent1',
        percent: 'percent1' 
      },
      {
        handlePercent: 'handlePercent2',
        percent: 'percent2' 
      },
      {
        handlePercent: 'handlePercent3',
        percent: 'percent3' 
      },
      {
        handlePercent: 'handlePercent4',
        percent: 'percent4' 
      },
      {
        handlePercent: 'handlePercent5',
        percent: 'percent5' 
      },
      {
        handlePercent: 'handlePercent6',
        percent: 'percent6' 
      },
      {
        handlePercent: 'handlePercent7',
        percent: 'percent7' 
      },
      {
        handlePercent: 'handlePercent8',
        percent: 'percent8' 
      },
      {
        handlePercent: 'handlePercent9',
        percent: 'percent9' 
      },
      {
        handlePercent: 'handlePercent10',
        percent: 'percent10' 
      },
      {
        handlePercent: 'handlePercent11',
        percent: 'percent11' 
      },
      {
        handlePercent: 'handlePercent12',
        percent: 'percent12' 
      },
      {
        handlePercent: 'handlePercent13',
        percent: 'percent13' 
      },
      {
        handlePercent: 'handlePercent14',
        percent: 'percent14' 
      },
      {
        handlePercent: 'handlePercent15',
        percent: 'percent15' 
      },
      {
        handlePercent: 'handlePercent16',
        percent: 'percent16' 
      },
      {
        handlePercent: 'handlePercent17',
        percent: 'percent17' 
      },
      {
        handlePercent: 'handlePercent18',
        percent: 'percent18' 
      },
      {
        handlePercent: 'handlePercent19',
        percent: 'percent19' 
      },
      {
        handlePercent: 'handlePercent20',
        percent: 'percent20' 
      },
      
    ]
    bars.forEach((bar) => {
      this[bar.handlePercent] = (time) => {
        let percent = 0
        const interval = setInterval(() => {
          percent += 10
          if (percent >= 100) {
            clearInterval(interval)
            this.setState({ [bar.percent]: 0, })
          } else {
            this.setState({ [bar.percent]: percent })
          }
        }, time)
      }
    })
  }

  componentDidMount() {
    this.fetchRedisData()
    var interval = setInterval(() => {
      this.fetchRedisData()
    }, 1000)
    this.setState({ fetchInterval: interval })
  }

  fetchRedisData = async () => {
    fetchRedisDataCommon(this)
  }

  resetTables = async () =>{
    resetTablesCommon(this)
  }
  
  get_demo_msg = async (scenerio, button) =>{ 
    get_demo_msg_common(scenerio, button, this)  

  }
  process_SSIM = async () =>{
    process_SSIM_Common(this)
  }
  process_Inbound_Start = async () =>{
    process_Inbound_Start_Common(this)
  }
  sendBulkMsg = async (parsedData) => {
    sendBulkMsgCommon(parsedData, this)
  }

  /**************************** Queues *********************************** */

  push_msg_to_queue = () => {
    push_msg_to_queue_common(this)
  }

  enqueue = (job) => {
    enqueueCommon(job, this)

  }
  nextJob = () => {
    nextJobCommon(this)
  }
  getFakeAsyncJob = (item, delay) => {
    getFakeAsyncJobCommon(item, delay, this)
  }

  /*************************************************************** */

  render() {
    
    const stepsColumn = [
      {
        title: 'Sr No',
        dataIndex: 'srno',
        width: '100px'
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        align: 'center',
        width: '250px'
      },
    ]

    const data = [
      // {
      //   key: '1',
      //   srno: '1',
      //   description: <>SSIM for Incoming Flights.</>,
      //   action: <>
      //     <Button className='custom-button send mr-0' onClick={()=> this.get_demo_msg('ssim','', this)}>SSIM
      //       <Progress percent={this.state.percent1} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
      //     </Button>
      //     {getCookie('ssim') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
      //   </>

      // },
      {
        key: '2',
        srno: '1',
        description: <>Flight Manifest : YY2541, YY7000M, YY2035, YY980, YY726
          <ul className='mt-1'><li>YY2541 (111-71051680,111-71063451,111-71023120)</li>
            <li>YY1263 (111-71028425,111-71028042,111-71027946,111-71026852,111-71026362)</li>
            <li>YY2035 (111-71028705,111-71027003,111-71036324,111-71036232)</li></ul></>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound', 'START', this)}>Start           
            <Progress percent={this.state.percent2} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('inbound_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </>

      },
      {
        key: '3',
        srno: '2',
        description: <>Booking for 111-71028705 moved from <span style={{ color: 'red' }}>Unconfirmed</span> to <span style={{ color: 'green' }}>Confirmed</span>.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound','BOOKING', this)}>Booking Confirmed
            <Progress percent={this.state.percent3} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('inbound_booking') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </>
      },
      {
        key: '4',
        srno: '3',
        description: <>Flight event for YY980</>,
        action:
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound','FLIGHT EVENT', this)}>Flight Events
            <Progress percent={this.state.percent4} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('inbound_flight_events') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '5',
        srno: '4',
        description: <>Awbs in YY7000M have been Checked In</>,
        action:
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound','FLIGHT COMPLETED', this)}>Flight Completed
            <Progress percent={this.state.percent5} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('inbound_flight_completed') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
    ]

    const recoveryData = [
      {
        key: '1',
        srno: '1',
        description: <>RCF message for 111-71051680, 111-71063451 & DQL16123YY.</>,
        action: 
         <>
           <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound','CHECKIN', this)}>Check In
             <Progress percent={this.state.percent6} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
           </Button>
           {getCookie('inbound_checkin') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
         </> 

      },
      {
        key: '2',
        srno: '2',
        description: <>DLV message to take 111-71063451 off the recovery dashboard.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound','DLV', this)}>DLV
            <Progress percent={this.state.percent7} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('inbound_dlv') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '3',
        srno: '3',
        description: <>FPS message to move 111-71051680 & DQL16123YY to a breakdown location and removes from the recovery dashboard.</>,
        action:
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('Inbound','MOVE BD', this)}>Move to Breakdown
            <Progress percent={this.state.percent8} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('inbound_move_bd') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
    ]

    const breakdownData = [
      {
        key: '1',
        srno: '1',
        description: <>All inbound flights get arrived status, all awbs receive an RCF message which moves them to the breakdown screen.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('breakdown','START')} >Start
            <Progress percent={this.state.percent9} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('breakdown_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 

      },
      {
        key: '2',
        srno: '2',
        description: <><span>NFD processed for the below awbs and the same are taken off the breakdown control screen and dashboard.</span>
          <ul className='mt-1'>
            <li>111-71028425 </li>
            <li>111-71028042</li>
            <li>111-71027946</li>
            <li>111-71026852</li> 
            <li>111-71026362</li>
            <li>111-71036324</li>
            <li>111-71036232</li>
            <li>111-71029280</li></ul>
        </>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('breakdown','NFD')} >NFD
            <Progress percent={this.state.percent10} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('breakdown_nfd') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '3',
        srno: '3',
        description: <><span>Breakdown message sent which acts as a partial breakdown for the container DPL54418YY & also moves the below AWBs to the completed list.</span>
          <ul className='mt-1'>
            <li>DQL16123YY</li>
            <li>DPL53939YY</li>
            <li>DPL53552YY</li>
          </ul>
        </>,        
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('breakdown','BREARKDOWN', this)}>Breakdown
            <Progress percent={this.state.percent11} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('breakdown') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '4',
        srno: '4',
        description: <>Sends an FPS message which moves the AWB (111-71023120) to the BUILDUP location 3BUILDUP.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('breakdown','MOVE BU', this)}>Move to Buildup
            <Progress percent={this.state.percent12} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('breakdown_move_bu') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
    ]

    const fcpData = [
      {
        key: '1',
        srno: '1',
        description: <>All transfer and export shipments are moved to the buildup screen.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','START', this)}>Start Buildup
            <Progress percent={this.state.percent13} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('buildup_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '2',
        srno: '2',
        description: <>Builds 2 new ULDs (AKY14994YY & PMC95370YY) for the flight YY032 & Builds 2 new ULDs (AKE30837YY & AKY14569YY) for flight YY014.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','BUILD ULD', this)}>Build ULD
            <Progress percent={this.state.percent14} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('buildup_build_uld') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '3',
        srno: '3',
        description: <>Builds a cart DPL53140# (111-71021215) for the flight YY1332L.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','BUILD CART', this)}>Build Cart
            <Progress percent={this.state.percent15} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('buildup_build_cart') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '4',
        srno: '4',
        description: <>Assigns the AWB 111-61141640 from its booked flight YY014 to flight YY032.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','ASSIGNED FLT', this)}>Assigned Flight
            <Progress percent={this.state.percent16} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('buildup_asgn_flt') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '5',
        srno: '5',
        description: <>PRE received for ULDs AKY14994YY (111-72269422) & PMC95370YY (111-72246996) for flight YY032.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','PRE', this)}>PRE
            <Progress percent={this.state.percent17} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('buildup_pre') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
    ]
    const hauloutData = [
      {
        key: '1',
        srno: '1',
        description: <><span>Moves the below AWBs to the Haulout dashboard.</span>
          <ul className='mt-1'>
            <li>111-71028705</li>
            <li>PMC95370YY (111-72246996)</li>
            <li>AKY14994YY (111-72269422)</li>
            <li>DPL53140# (111-71021215)</li>
            <li>111-71051680</li>
            <li>111-71023120</li>
            <li>DPL54417YY</li>
            <li>DPL54921YY</li>
          </ul>
        </>,        
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('haulout','START', this)}>Start
            <Progress percent={this.state.percent18} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('haulout_start') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 

      },
      {
        key: '2',
        srno: '2',
        description: <><span>Moves the below AWBs to a gate location which does not match the outgoing flight gate.</span>
          <ul className='mt-1'>
            <li>PMC95370YY (111-72246996)</li>
            <li>AKY14994YY (111-72269422)</li>
            <li>111-71023120</li>
          </ul>
        </>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('haulout','GATE MISMATCH', this)}>Gate Mismatch
            <Progress percent={this.state.percent19} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('haulout_gt_mismatch') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
      {
        key: '3',
        srno: '3',
        description: <>Departs all flights and clears all dashboards.</>,
        action: 
        <>
          <Button className='custom-button send' onClick={()=> this.get_demo_msg('haulout','DEPART', this)}>Depart
            <Progress percent={this.state.percent20} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
          </Button>
          {getCookie('haulout_depart') ? <img src={Tick} alt='tick' className='tick-icon' /> : ''}
        </> 
      },
    ]

    
    return (
      <Card className='custom-card h-100' 
        title={
          <div className='f-spacebtw'>
            <span>Demo Steps</span>
            <div>
              <small className='est'>{getCookie(COOKIE.RESET_TIME) ? getCookie(COOKIE.RESET_TIME) + 'EST' : ''} </small>
              <Button type='link' className='custom-button send ml-3' onClick={()=> this.resetTables()} >Reset Steps</Button>
            </div>
          </div>
        }>       

        <div className='progress-bar'>
          <Progress percent={this.state.percentage} status='active' strokeColor='fff' strokeWidth={3} />
        </div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 12,
          lg: 12
        }, {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12
        },]} className=''>
          <Col span={24}>
            <Collapse
              defaultActiveKey={['1','2','3','4','5','6','7']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className='step-collapse'
            >
              <Panel 
                header={<div className='f-spacebtw'>
                  <span>{title.INBOUND}</span>
                </div>} key='1' style={panelStyle}>
                <div className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table td-top no-vscroll'
                      dataSource={data}
                      columns={stepsColumn}
                      pagination={false}
                    />
                  </div>
                </div>

              </Panel>
              <Panel header={title.recovery_dashboard} key='2' style={panelStyle}>
                <div className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table td-top no-vscroll'
                      dataSource={recoveryData}
                      columns={stepsColumn}
                      pagination={false}
                    />
                  </div>
                </div>
              </Panel>
              <Panel header={title.BREAKDOWN} key='3' style={panelStyle}>
                <div className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table td-top no-vscroll'
                      dataSource={breakdownData}
                      columns={stepsColumn}
                      pagination={false}
                    />
                  </div>
                </div>
              </Panel>
              <Panel header={title.flight_capacity_planning + ' / ' + title.buildup_control} key='4' style={panelStyle}>
                {/* <div className='f-spacebtw'>
                  <p>FCP</p>
                  <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','START')} >Start Buildup</Button>
                </div>
                <div className='f-spacebtw'>
                  <p>FCP</p>
                  <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','BUILD ULD')} >Build ULD</Button>
                </div>
                <div className='f-spacebtw'>
                  <p>FCP</p>
                  <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','BUILD CART')} >Build Cart</Button>
                </div>
                <div className='f-spacebtw'>
                  <p>FCP</p>
                  <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','ASSIGNED FLT')} >Assigned Flight</Button>
                </div>
                <div className='f-spacebtw'>
                  <p>FCP</p>
                  <Button className='custom-button send' onClick={()=> this.get_demo_msg('buildup','PRE')} >PRE</Button>
                </div>
                <div className='f-spacebtw'>
                  <p>FCP</p>
                  <Button className='custom-button send' >Over Build</Button>
                </div> */}
                <div className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table td-top no-vscroll'
                      dataSource={fcpData}
                      columns={stepsColumn}
                      pagination={false}
                    />
                  </div>
                </div>
              </Panel>
              <Panel header={title.haulout_dashboard} key='7' style={panelStyle}>
                <div className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table td-top no-vscroll demo-nested-tbl'
                      dataSource={hauloutData}
                      columns={stepsColumn}
                      pagination={false}
                    />
                  </div>
                </div>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default Steps
