import React, { Component } from 'react'
import { Row, Col, Typography, Form, Card, Table, Modal, Select, Tooltip, Input, InputNumber, Button, Checkbox, DatePicker, TimePicker, Space, notification, Statistic, Spin, Badge, Popconfirm, Switch, Popover /*Divider, message*/ } from 'antd'
import { SearchOutlined, CloseCircleFilled, /*SaveOutlined, PlusOutlined,*/ CheckOutlined, CloseOutlined, CheckCircleTwoTone, InfoCircleFilled /*PaperClipOutlined*/ } from '@ant-design/icons'
import addIcon from '../../assets/images/add.svg'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/delete.svg'
import Update from '../../assets/images/input-tick.svg'
import documentIcon from '../../assets/images/document.svg'
import planeDarkIcon from '../../assets/images/plane-icon-dark.svg'
import planeLightIcon from '../../assets/images/plane-icon-light.svg'
import cssconstants from '../../constants/cssconstants'
import Http from '../../utils/http'
import Notes from '../notes/notes'
import Cookies from 'universal-cookie'
import AftHold from '../../assets/images/conf-1.png'
import ForwardHold from '../../assets/images/conf-2.png'
import { cloneDeep } from 'lodash'
import moment, { isMoment } from 'moment'
import { constants, timezone } from '../../constants/constants'
import { weightCalculator, formatWeight } from '../../utils/common'
import './index.css'

const { Title, Text } = Typography
const { Option } = Select
const { Countdown } = Statistic
const dateTimeFormat = 'DDMMMYY HH:mm'
const dateOnly = 'DDMMMYY'
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'
const completeDateTime = 'YYYY-MM-DD HH:mm:ss'
const { Column, ColumnGroup } = Table
const country = ['USA', 'U.S. VIRGIN ISLANDS', 'PUERTO RICO']
const legendPopover = (
  <div className='width-250 fs-10'>
    <Row gutter={[8,8]}>
      <Col span={7}>AS</Col>
      <Col span={17}>Cargo checked in at station</Col>

      <Col span={7}><img src={planeDarkIcon} width='12' height='12' alt='plane' /></Col>
      <Col span={17}>Cargo enroute to station</Col>

      <Col span={7}><Text type='success'>C</Text></Col>
      <Col span={17}>Cargo at station built up, ULD closed</Col>

      <Col span={7}><Text type='danger'>O</Text></Col>
      <Col span={17}>Cargo at station built up, ULD open</Col>

      <Col span={7}>1 | 111 | 1</Col>
      <Col span={17}>AWBS | WEIGHT | VOLUME</Col>
    </Row>
  </div>
)

// const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)]

//FORMAT FLIGHT NO
function formatFlightNo(flight_no, flight_date) {
  let formatted = ''
  formatted = flight_no +'/'+ moment(flight_date).format(dateOnly)
  return formatted
}

//FORMAT AWB NO
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    let b = '-'
    let position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}

class FlightControl extends Component {
  constructor() {
    super()
    this.state = {
      servererror: false,
      loading: false,
      modalLoading: false,
      loadplanvisible: false,
      station: new Cookies().get('station'),
      user_id: new Cookies().get('id'),
      timezone: new Cookies().get('timezone'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      start: moment().tz(new Cookies().get('timezone')).format(dateFormat),
      end: moment().tz(new Cookies().get('timezone')).add(24, 'hours').format(dateFormat),
      stime: moment().tz(new Cookies().get('timezone')).set({
        minutes: 0,
        second: 0,
      }),
      etime: moment().tz(new Cookies().get('timezone')).add(24, 'hours').set({
        minutes: 0,
        second: 0,
      }),
      page: 1,
      weight_unit: 'KG',
      cookieInterval: null,
      search: '',
      flightData: [],
      displayData: [],
      fleetData: [],
      userList: [],
      defaultSystemParameters: [],
      uldMappingData: [],
      stationsData: [],
      sort: 'dept_time',
      departedFlightsCheck: false,
      amberFlightsCheck: false,
      redFlightsCheck: false,
      searchString: '',
      flightNotes: false,
      optimalBuild: false,
      showAlloc: false,
      notesTitle: '',
      isListening: false,
      searchFlightNo: '',
      latestTimestamp: '',
      searchDate: '',
      ccView: false,
      showSuccess: false,

      editRecord: null,
      editAllocRecord: null,
      deleteAllocID: null,

      editFcst: 0,
      editBagP96: 0,
      editBagP88: 0,
      editBagLD3: 0,
      editBagLD2: 0,
      editCargoMD: 0,
      editCargoP96: 0,
      editCargoP88: 0,
      editCargoLD3: 0,
      editCargoLD2: 0,
      editWgtValue: 0,
      editWgtUnit: '',
      editBuPrio: false,
      editAllocArray: [],
      editIsExceeded: false,
      previewAft: null,
      previewFwd: null,
      lastUpdatedAt: '',
      lastUpdatedBy: '',

      editPos: '',
      editType: '',
      editWorkArea: '',
      editULDAWB: '',
      editProd: '',
      editComm1: '',
      editComm2: '',

      editTPos: '',
      editTType: '',
      editTWorkArea: '',
      editTULDAWB: '',
      editTProd: '',
      editTComm1: '',
      editTComm2: '',

      remainingP96: 0,
      remainingP88: 0,
      remainingLD3: 0,
      remainingLD2: 0,
      remainingMD: 0,

      SLUSummary: [],
      PTUSummary: [],
      BuiltSummary: [],
      BLKSummary: null,
      selected: [],
      bagSummary: [],
      bagSelected: null,
      isWideBody: false,
      hideMD: true,
    }
    this._queue = []
    this._isBusy = false
  }

  componentDidMount() {
    // const system_params_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    const flight_control_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=fc&stn=${this.state.station}`)

    flight_control_events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        this.enqueue(myFakeAsyncJob)
      }
    }
    flight_control_events.onerror = (error) => {
      if(error?.type === 'error') {
        this.setState({ servererror : true })
      }
    }

    // system_params_events.onmessage = (event) => {
    //   const parsedData = JSON.parse(event.data)
    //   if(!this.state.isListening) this.setState({ isListening: true })
    //   else {
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   }
    // }
    // system_params_events.onerror = (error) => {
    //   if(error?.type === 'error') {
    //     this.setState({ servererror : true })
    //   }
    // }

    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })
    this.fetchFleet()
  }

  componentWillUnmount() {
    this.setState({ cookieInterval: clearInterval(this.state.cookieInterval) })
  }

  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'UPDATE' && parsedData?.Action === 'UPDATE'){
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'DETAILS')
    } else if(parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'PLAN' && parsedData?.Action === 'UPDATE'){
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'PLAN')
    } else if(parsedData?.TYPE === 'NOTES') {
      this.updateFlightNotesCount(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'FLIGHT_EVENT') {
      this.updateFlight(parsedData?.DETAILS, 'DEPARTURE')
    } else if(parsedData?.TYPE === 'FSU' && parsedData?.msg === 'MAN') {
      this.updateFlight(parsedData?.DETAILS?.data, 'BUILDUP')
    } else if(parsedData?.TYPE === 'FSU' && parsedData?.msg === 'GATE') {
      this.updateFlight(parsedData?.DETAILS?.data, 'GATE')
    } else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'UPDATE') {
      this.updateFlight(parsedData?.DETAILS, 'BOOKING')
    } else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'ADD') {
      this.addAWB_segment(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'REMOVE') {
      this.removeAWB_segment(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'FLEET') {
      this.fetchFleet('EVENT')
    } else if(parsedData?.TYPE === 'STATIONS') {
      this.fetchStations('EVENT')
    } else if(parsedData?.TYPE === 'DEFAULT_HANDLING_TIMES') {
      this.fetchDefaultSystemParameters('EVENT')
    } else if(parsedData?.TYPE === 'GLOBAL_DETAILS') {
      this.fetchGlobalDetails('EVENT')
    }
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this._isBusy = true
      next
        .job()
        .then((value) => {
          this.eventList(value)
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */

  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }
  //PROCESS FLIGHT DATA
  processData = () => {
    let flight_array = this.state.flightData?.map((flight) => {
      return { ...this.processIndividualFlight(flight) }
    })
    this.sort(flight_array, false)
  }
  //FETCH FLEET
  fetchFleet = async (event) => {
    if(!event) this.setState({ loading: true })
    await Http.get('flights', { headers: '' }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        this.setState({ fleetData: resp?.data?.data })
      } else {
        this.setState({ fleetData: [] })
      }
      if(!event) this.fetchULDMappingData()
      else {
        this.processData()
      }
    })
  }
  //FETCH ULD MAPPING DATA
  fetchULDMappingData = async (event) => {
    await Http.get('uld_mappings').then(resp => {
      if(resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ uldMappingData: resp?.data?.data })
        }
        if(!event) this.fetchDefaultSystemParameters()
        else {
          this.processData()
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ loading: false })
      }
    })
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async (event) => {
    await Http.get(`handling_times?default=true&station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ defaultSystemParameters: resp?.data?.data })
        } else {
          this.setState({ defaultSystemParameters: [] })
        }
        if(!event) this.fetchStations()
        else {
          this.processData()
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH STATIONS
  fetchStations = async (event) => {
    await Http.get('stations').then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ stationsData: resp?.data?.data })
        } else {
          this.setState({ stationsData: [] })
        }
        if(!event) this.fetchUsers()
        else {
          this.processData()
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  fetchUsers = async (event) => {
    let headers = {
      search: this.state.search,
      station: this.state.station,
    }
    await Http.get('users?sa=true', { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          this.setState({ userList: resp?.data?.data })
        } else {
          this.setState({ userList: [] }, () => {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          })
        }
        if(!event) this.fetchGlobalDetails()
        else {
          this.processData()
        }
      }else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    })
  }
  //FETCH GLOBAL DETAILS
  fetchGlobalDetails = async (event) => {
    await Http.get('global_details').then(resp => {
      if(resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          let filtered = resp?.data?.data?.find((item) => item?.details?.stations?.includes(this.state.station)) || null
          if(filtered) {
            this.setState({ hideMD: filtered?.show_md ? false : true })
          } else {
            this.setState({ hideMD: false })
          }
          if(!event) this.fetchFlights()
        } else {
          this.setState({ loading: false })
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ loading: false })
      }
    })
  }
  //UPDATE FLIGHT
  updateFlight = (DETAILS, TYPE) => {
    let ID = parseInt(DETAILS?.flight_id)
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex((p) => p.id === ID)

    if(flight_index > -1) {
      if(TYPE === 'DEPARTURE') {
        let dept_obj = {}
        dept_obj.act = DETAILS?.flight?.dept?.act ? parseInt(DETAILS.flight.dept.act) : null
        dept_obj.eta = DETAILS?.flight?.dept?.eta ? parseInt(DETAILS.flight.dept.eta) : null
        dept_obj.schdl = DETAILS?.flight?.dept?.schdl ? parseInt(DETAILS.flight.dept.schdl) : null
        DATA[flight_index].dept_date = DETAILS?.flight?.dept_date ? DETAILS?.flight?.dept_date : DATA[flight_index].dept_date
        DATA[flight_index].dept = dept_obj
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if(TYPE === 'BUILDUP') {
        let buildUpArray = DETAILS?.build_up
        DATA[flight_index].build_up = buildUpArray
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if(TYPE === 'BOOKING') {
        let bookingArray = DETAILS?.details
        DATA[flight_index].awb_segments = bookingArray
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if(TYPE === 'GATE') {
        let gateArray = DETAILS?.gate
        DATA[flight_index].awb_segments = gateArray
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      }

      this.setState({ flightData: [] }, () => this.setState({ flightData: DATA }, () => {
        if(this.state.departedFlightsCheck) {
          this.departedFlightsFilter()
        }
      }))
    }
  }
  addAWB_segment = (DETAILS) =>{
    let ID = parseInt(DETAILS?.flight_id)
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex((p) => p.id === ID)
    if(flight_index > -1) {
      let awb_seg_index = DATA[flight_index].awb_segments.findIndex((p) => p.id === DETAILS?.details?.id)
      if(awb_seg_index > -1){
        //do nothing
      } else {
        let obj = {
          id: DETAILS?.details?.id,
          awb_no: DETAILS?.details?.awb_no,
          space: DETAILS?.details?.space,
          awb: {
            weight_unit: DETAILS?.details?.weight_unit,
            vol_unit: DETAILS?.details?.vol_unit
          }
        }
        DATA[flight_index].awb_segments.push(obj)
        DATA[flight_index] = this.processIndividualFlight(cloneDeep(DATA[flight_index]))
        this.setState({ flightData: [] }, () => this.setState({ flightData: DATA }))
      }

    }
  }
  removeAWB_segment = (DETAILS) =>{
    let ID = parseInt(DETAILS?.flight_id)
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex((p) => p.id === ID)
    if(flight_index > -1) {
      let awb_seg_index = DATA[flight_index].awb_segments.findIndex((p) => p.id === DETAILS?.details?.id)
      if(awb_seg_index > -1){
        DATA[flight_index].awb_segments.splice(awb_seg_index, 1)
        DATA[flight_index] = this.processIndividualFlight(cloneDeep(DATA[flight_index]))
        this.setState({ flightData: [] }, () => this.setState({ flightData: DATA }))
      }
    }
  }
  //FETCH FLIGHT CONTROL DATA
  fetchFlights = async () => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      loading: this.state.search || this.state.page === 1 ? true : false,
      latestTimestamp: temp_timestamp
    })
    let headers = { station: this.state.station }

    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start ? start_date.format(dateFormat) : ''
    let end = this.state.end ? end_date.format(dateFormat) : ''
    let stime = this.state.stime ? start_date.format(timeFormat) : ''
    let etime = this.state.etime ? end_date.format(timeFormat) : ''

    await Http.get(`flight_control_list?&start=${start}&end=${end}&stime=${stime}&etime=${etime}&page=${this.state.page}&count=50`, { headers }).then(resp => {
      if(this.state.latestTimestamp === temp_timestamp) {
        if (resp && resp.status === 200) {
          if (resp?.data?.data?.length > 0) {
            let data = []
            if(this.state.search === '' || this.state.search === null) {
              data = this.state.flightData?.concat(resp.data.data)
            }

            //DUPLICATE DATA CHECK
            let newData = []
            data?.map((item, index) => {
              let id = newData.findIndex(p => p.id == parseInt(item.id))
              if(id > -1) {
                //DO NOTHING
              } else {
                item.key = index
                newData.push(item)
              }
            })

            newData = newData.map((flight) => {
              return { ...this.processIndividualFlight(flight) }
            })

            this.sort(newData, true)
            this.setState({
              loading: false,
              flightData: newData,
              page: this.state.page + 1,
            }, () => {
              if(this.state.departedFlightsCheck) {
                this.departedFlightsFilter()
              }
              this.fetchFlights()
            })
          } else {
            if(this.state.page > 1) {
              //DO NOTHING
            } else {
              this.setState({
                flightData: [],
                loading: false
              }, () => {
                notification.destroy()
                notification.error({
                  message: constants.NO_DATA_ERROR,
                  description: resp?.data?.message,
                })
              })
            } 
          }
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //UPDATE INDIVIDUAL FLIGHT DETAILS
  updateFlightDetails = async (DETAILS, UPDATE_TYPE) => {
    let DATA = cloneDeep(this.state.flightData)

    //CHECK FLIGHT RANGE
    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) +' '+ this.state.stime.format(timeFormat), this.state.timezone).utc().valueOf()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) +' '+ this.state.etime.format(timeFormat), this.state.timezone).utc().valueOf()

    let local_tz_dept_date = 0
    if(DETAILS?.flight_date) {
      local_tz_dept_date = moment.utc(DETAILS?.flight_date).valueOf()
      if(local_tz_dept_date > start_date && local_tz_dept_date < end_date) {
        let flight_index = DATA?.findIndex(p => p.id == parseInt(DETAILS?.flight_id))
        //UPDATE FLIGHT DATA
        if(flight_index > -1) {
          if(UPDATE_TYPE === 'DETAILS') {
            DATA[flight_index] = this.processIndividualFlight(this.parseFlight(DATA[flight_index], DETAILS))
          } else if(UPDATE_TYPE === 'PLAN') {
            DATA[flight_index].details = DETAILS?.details || []
          }
          if(DETAILS?.last_updated?.length > 0) {
            let user_obj = this.state.userList?.find((item) => item?.id === parseInt(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.user_id))
            DATA[flight_index].last_updated = DETAILS?.last_updated
            DATA[flight_index].last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
            DATA[flight_index].last_updated_at = moment.utc(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
          }
          //CHECK IF EDITING RECORD MATCHES FETCHED FLIGHT RECORD
          if(this.state.editRecord && DATA[flight_index]?.id === this.state.editRecord?.id) {
            if(this.state.user_id !== DETAILS?.user_id) {
              let flight_no_date = ''
              if(DATA[flight_index]?.dept_time)
                flight_no_date = `${DATA[flight_index]?.flight_no}/${moment(DATA[flight_index]?.dept_time).format(dateOnly)}`
              else
                flight_no_date = `${DATA[flight_index]?.flight_no}/${moment(DATA[flight_index]?.flight_date).format(dateOnly)}`
              notification.destroy()
              if(UPDATE_TYPE === 'DETAILS') {
                notification.warning({ message: `Flight ${flight_no_date} details was updated.` })
              } else if(UPDATE_TYPE === 'PLAN') {
                notification.warning({ message: 'Load plan details were updated' })
              }
            } else {
              //SET RECORD EDIT IF OPEN (MAY NOT BE NEEDED)
              this.setEdit(DATA[flight_index], UPDATE_TYPE)
            }
          }

          this.sort(DATA, false)
        } else {
          //UPDATE RECORD IF NOT IN CURRENT LIST
          this.fetchIndividualFlight(parseInt(DETAILS?.flight_id)).then((item) => {
            if(item) {
              DATA = cloneDeep(this.state.flightData)
              DATA.push(this.processIndividualFlight(item))
              this.setState({ flightData: DATA }, () => this.updateFlightDetails(DETAILS, UPDATE_TYPE))
            }
          })
        }
      } else {
        //IF FLIGHT DOES NOT EXIST IN CURRENT LIST AND IS BEING EDITED ON SEARCH, THEN UPDATE DETAILS/PLAN
        let editingRecord = this.state.editRecord
        if(editingRecord?.id) {
          if(UPDATE_TYPE === 'DETAILS') {
            editingRecord = this.processIndividualFlight(this.parseFlight(this.state.editRecord, DETAILS))
          } else if(UPDATE_TYPE === 'PLAN') {
            editingRecord.details = DETAILS?.details || []
            //GET LAST UPDATED INFO
          }
          if(DETAILS?.last_updated?.length > 0) {
            let user_obj = this.state.userList?.find((item) => item?.id === parseInt(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.user_id))
            editingRecord.last_updated = DETAILS?.last_updated
            editingRecord.last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
            editingRecord.last_updated_at = moment.utc(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
          }

          //CHECK IF EDITING RECORD MATCHES FETCHED FLIGHT RECORD
          if(editingRecord?.id === parseInt(DETAILS?.flight_id)) {
            if(this.state.user_id !== DETAILS?.user_id) {
              // let flight_no_date = `${DATA[flight_index]?.flight_no}/${moment(DATA[flight_index]?.flight_date).format(dateOnly)}`
              notification.destroy()
              if(UPDATE_TYPE === 'DETAILS') {
                // notification.warning({ message: `Flight ${flight_no_date} details was updated.` })
                notification.warning({ message: 'Flight details was updated.' })
              } else if(UPDATE_TYPE === 'PLAN') {
                notification.warning({ message: 'Load plan details were updated' })
              }
            } else {
              //SET RECORD EDIT IF OPEN (MAY NOT BE NEEDED)
              this.setEdit(editingRecord, UPDATE_TYPE)
            }
          }
        }
      }
    }
  }
  //FETCH INDIVIDUAL FLIGHT
  fetchIndividualFlight = async (ID) => {
    let headers = { station: this.state.station }
    let obj = null
    await Http.get(`flight_control_list?id=${ID}`, { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          obj = resp?.data?.data[0]
        }
      }
    })
    return obj
  }
  //PARSE FLIGHT RECORD
  parseFlight = (flight, event_record) => {
    let flightObj = {
      bag: {},
      cargo: {},
      wgt: {},
    }

    flight.fcst = event_record?.fcst ? parseInt(event_record?.fcst) : 0
    flight.bu_prio = event_record?.bu_prio === 'true' || event_record?.bu_prio === true ? true : false
    flight.load_plan = event_record?.load_plan === 'true' || event_record?.load_plan === true ? true : false

    flightObj.bag.p96 = event_record?.bag?.p96 ? parseInt(event_record.bag.p96) : 0
    flightObj.bag.p88 = event_record?.bag?.p88 ? parseInt(event_record.bag.p88) : 0
    flightObj.bag.ld3 = event_record?.bag?.ld3 ? parseInt(event_record.bag.ld3) : 0
    flightObj.bag.ld2 = event_record?.bag?.ld2 ? parseInt(event_record.bag.ld2) : 0
    flight.bag = flightObj.bag

    flightObj.cargo.md = event_record?.cargo?.md ? parseInt(event_record.cargo.md) : 0
    flightObj.cargo.p96 = event_record?.cargo?.p96 ? parseInt(event_record.cargo.p96) : 0
    flightObj.cargo.p88 = event_record?.cargo?.p88 ? parseInt(event_record.cargo.p88) : 0
    flightObj.cargo.ld3 = event_record?.cargo?.ld3 ? parseInt(event_record.cargo.ld3) : 0
    flightObj.cargo.ld2 = event_record?.cargo?.ld2 ? parseInt(event_record.cargo.ld2) : 0
    flight.cargo = flightObj.cargo

    flightObj.wgt.value = event_record?.wgt?.value ? parseFloat(event_record.wgt.value) : 0
    flightObj.wgt.unit = event_record?.wgt?.unit
    flight.wgt = flightObj.wgt
    return flight
  }
  //FETCH FLIGHT DATA ON SEARCH
  fetchFlightOnSearch = async () => {
    let headers = { station: this.state.station }
    if(this.state.searchFlightNo && this.state.searchDate) {
      let date = moment(this.state.searchDate).format(dateFormat)
      await Http.get(`flight_control_list?flight_no=${this.state.searchFlightNo}&flight_date=${date}`, { headers }).then((resp) => {
        this.setState({ modalLoading: false })
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          this.setEdit(this.processIndividualFlight(resp?.data?.data[resp?.data?.data?.length - 1]))
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        }
      }).catch(() => {
        this.setState({
          servererror : true,
          modalLoading: false,
        }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
        })
      })
    } else {
      this.setState({ modalLoading: false }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid Input',
        })
      })
    }
  }
  //PRODCESS INDIVDUAL FLIGHT
  processIndividualFlight = (flight) => {
    //NOTES
    flight.notes = flight?.notes_count || 0
    flight.notes_date = flight.notes_date ? moment.utc(flight.notes_date).tz(this.state.timezone).format(completeDateTime) : null
    //CALCULATE DEPARTURE TIME
    flight.dept_time = ''
    flight.schdl = ''
    flight.dept_prefix = 'S'
    flight.fpe = this.getFPE(flight)
    if(flight?.dept) {
      if(flight?.dept?.act) {
        flight.dept_prefix = 'A'
        flight.dept_time = moment.utc(flight.dept.act).tz(this.state.timezone).valueOf()
      }
      else if(flight?.dept?.eta) {
        flight.dept_prefix = 'E'
        flight.dept_time = moment.utc(flight.dept.eta).tz(this.state.timezone).valueOf()
      }
      else if(flight?.dept?.schdl) {
        flight.dept_prefix = 'S'
        flight.dept_time = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
      } else if(flight?.dept_date) {
        flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
      } else {
        flight.dept_time = moment(flight.flight_date).valueOf()
      }
    } else if(flight?.dept_date) {
      flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else {
      flight.dept_time = moment(flight.flight_date).valueOf()
    }

    if(flight?.dept_date) {
      flight.schdl = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else if(flight?.dept?.schdl) {
      flight.schdl = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
    }
    //CALCULATE BOOKED WEIGHT
    flight.booked_kg = 0
    flight.booked_lbs = 0

    let uniqueULDNo = []
    if(flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld) => {
        if(uld?.awbs_in?.length > 0) {
          uld.awbs_in.map((item) => {
            uniqueULDNo.push(item?.awb_no)
            let unit = item?.wgt?.unit
            if(unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
              flight.booked_lbs += parseFloat(item?.wgt?.value)
              flight.booked_kg += weightCalculator(parseFloat(item?.wgt?.value), 'LBS')
            } else if(unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
              flight.booked_kg += parseFloat(item?.wgt?.value)
              flight.booked_lbs += weightCalculator(parseFloat(item?.wgt?.value), 'KGM')
            }
          })
        }
      })
    }

    if(flight?.awb_segments?.length > 0) {
      flight.awb_segments.map((seg) => {
        if(!uniqueULDNo?.includes(seg?.awb_no)) {
          uniqueULDNo.push(seg?.awb_no)
          let unit = seg?.awb?.weight_unit
          if(unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
            flight.booked_lbs += parseFloat(seg?.space?.wgt)
            flight.booked_kg += weightCalculator(parseFloat(seg?.space?.wgt), 'LBS')
          } else if(unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
            flight.booked_kg += parseFloat(seg?.space?.wgt)
            flight.booked_lbs += weightCalculator(parseFloat(seg?.space?.wgt), 'KGM')
          }
        }
      })
    }
    //CALCULATE BUILD UP WEIGHT
    flight.ready_kg = 0
    flight.ready_lbs = 0
    flight.gate_kg = 0
    flight.gate_lbs = 0
    if(flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld) => {
        uld.type = this.checkULDType(uld)
        if(uld?.awbs_in?.length > 0) {
          uld.awbs_in.map((awb) => {
            let unit = awb?.wgt?.unit
            let value = awb?.wgt?.value
            if(unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
              if(!uld?.blk) {
                flight.ready_lbs += parseFloat(value)
                flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              } else {
                if((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                  flight.ready_lbs += parseFloat(value)
                  flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
                }
              }
              // if(!uld?.blk) {
              //   if(uld?.rdy) {
              //     flight.ready_lbs += parseFloat(value)
              //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              //   }
              // } else {
              //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
              //     flight.ready_lbs += parseFloat(value)
              //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              //   }
              // }

              if(awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
                flight.gate_lbs += parseFloat(value)
                flight.gate_kg += weightCalculator(parseFloat(value), 'LBS')
              }
            } else if(unit === 'K' || unit === 'KG' || unit === 'KGM') {
              if(!uld?.blk) {
                flight.ready_kg += parseFloat(value)
                flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              } else {
                if((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                  flight.ready_kg += parseFloat(value)
                  flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
                }
              }
              // if(!uld?.blk) {
              //   if(uld?.rdy) {
              //     flight.ready_kg += parseFloat(value)
              //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              //   }
              // } else {
              //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
              //     flight.ready_kg += parseFloat(value)
              //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              //   }
              // }

              if(awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
                flight.gate_kg += parseFloat(value)
                flight.gate_lbs += weightCalculator(parseFloat(value), 'KGM')
              }
            }
          })
        }
      })
    }
    //CALCULATE COUNTDOWN TIMER
    let time_left = moment(flight.dept_time).diff(moment().tz(this.state.timezone), 'minutes')
    //SUBTRACT FPE
    time_left = time_left - (1*(flight.fpe || 0))
    flight.time_to_dept = moment().add(time_left, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    //CALCULATE PAX CAPACITY, CONT TYPE, AFT AND FWS CONFIG.
    flight.pax_cap = 0
    flight.aft_conf = null
    flight.fwd_conf = null
    flight.cont_type = []
    flight.acpt_body = true
    if(this.state.fleetData?.length > 0) {
      let filter = this.state.fleetData.filter((item) => item?.details?.ac_code === flight?.ac_type)
      if(filter?.length > 0) {
        flight.pax_cap = filter[0]?.details?.pax_cap
        flight.aft_conf = filter[0]?.details?.aft_conf
        flight.fwd_conf = filter[0]?.details?.fwd_conf
        flight.cont_type = filter[0]?.details?.cont_type?.split(',') || []
        flight.acpt_body = filter[0]?.details?.ac_type === 'WB' ? true : false
      }
    }
    //CALC FLIGHT WEIGHT
    let unit = flight?.wgt?.unit
    let value = flight?.wgt?.value
    if(unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
      flight.weight_lbs = parseFloat(value) //ROUNDED TO NEAREST INTEGER
      flight.weight_kg = weightCalculator(parseFloat(value), 'LBS')
    } else if(unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
      flight.weight_kg = parseFloat(value) //ROUNDED TO NEAREST INTEGER
      flight.weight_lbs = weightCalculator(parseFloat(value), 'KGM')
    }
    //GET LAST UPDATED INFO
    flight.last_updated_by = ''
    flight.last_updated_at = ''
    if(flight?.last_updated?.length > 0) {
      let user_obj = this.state.userList?.find((item) => item?.id === parseInt(flight.last_updated[flight.last_updated.length - 1]?.user_id))
      flight.last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
      flight.last_updated_at = moment.utc(flight.last_updated[flight.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
    }
    //STORE ULDS FOR NOT TENDERED SUMMARY
    flight.not_tendered = {
      blk_count: 0,
      weight_lbs: 0,
      weight_kg: 0,
      pcs: 0,
      md: 0,
      udp: 0,
      ldc: 0,
      ldp: 0,
    }
    let notTenderedArr = flight?.awb_segments?.filter(ar => !flight?.summery?.find(rm => rm.awb_no === ar.awb_no)) || []
    if(notTenderedArr?.length > 0) {
      let blk_count = 0
      let weight_lbs = 0
      let weight_kg = 0
      let pcs = 0

      notTenderedArr?.map((item) => {
        if(item?.space?.udp || item?.space?.ldc || item?.space?.ldp) {
          flight.not_tendered.udp = flight.not_tendered.udp + parseInt(item?.space?.udp || 0)
          flight.not_tendered.ldc = flight.not_tendered.ldc + parseInt(item?.space?.ldc || 0)
          flight.not_tendered.ldp = flight.not_tendered.ldp + parseInt(item?.space?.ldp || 0)
        } else {
          blk_count += 1
          pcs += parseFloat(item?.space?.vol || 0) || 0
          // if(item?.vol?.unit === 'FTQ') {
          //   pcs += parseFloat(item?.space?.vol || 0)
          // } else if(item?.vol?.unit === 'MTQ') {
          //   pcs += parseFloat((item?.space?.vol || 0) * 35.314667)
          // } else if(item?.vol?.unit === 'CF') {
          //   pcs += parseFloat(item?.space?.vol || 0)
          // }
          if(item.awb.weight_unit === 'L' || item.awb.weight_unit === 'LB' || item.awb.weight_unit === 'LBS' || item.awb.weight_unit === 'LBR') {
            weight_lbs += item.space.wgt //ROUNDED TO NEAREST INTEGER
            weight_kg += weightCalculator(item.space.wgt, 'LBS')
          } else if(item.awb.weight_unit === 'K' || item.awb.weight_unit === 'KG' || item.awb.weight_unit === 'KGS' || item.awb.weight_unit === 'KGM') {
            weight_kg += item.space.wgt //ROUNDED TO NEAREST INTEGER
            weight_lbs += weightCalculator(item.space.wgt, 'KGM')
          }
        }
      })
      flight.not_tendered.blk_count = blk_count
      flight.not_tendered.weight_lbs = weight_lbs
      flight.not_tendered.weight_kg = weight_kg
      flight.not_tendered.pcs = pcs?.toFixed(2) || 0
    }

    //GET ACTUAL ULD POSITIONS
    let ULDs = []
    flight.unique_ulds = []
    if(flight?.summery?.length > 0) {
      flight.summery.map((awb) => {
        if(awb?.ulds_in) {
          awb.ulds_in.position_obj = this.state.uldMappingData?.find((item) => item?.uld_prefix === awb?.ulds_in?.uld_no?.slice(0, 3) && item?.ac_types?.includes(flight?.ac_type)) || null
          ULDs.push(awb?.ulds_in)
        }
      })
    }
    //GET UNIQUE ULDS AND CALC ULD POSITIONS LOGIC
    flight.unique_ulds = [...new Map(ULDs.map(item => [item?.uld_no, item])).values()]
    let uldPositionsObj = this.calcULDPositions(flight)
    flight.actual = {
      MD: uldPositionsObj?.MD,
      P96: uldPositionsObj?.P96,
      P88: uldPositionsObj?.P88,
      LD3: uldPositionsObj?.LD3,
      LD2: uldPositionsObj?.LD2,
    }

    //FLIGHT TARE WEIGHT AND ADD TO READY WEIGHT
    flight.tare_wgt_kg = this.addTareWgt(flight)?.weight_kg || 0
    flight.tare_wgt_lbs = this.addTareWgt(flight)?.weight_lbs || 0
    flight.ready_kg += parseFloat(flight.tare_wgt_kg)
    flight.ready_lbs += parseFloat(flight.tare_wgt_lbs)
    flight.cccolour = ''
    flight.cccolour_css = ''
    let weight_assigned = this.state.weight_unit === 'KG' ? flight?.booked_kg : flight?.booked_lbs
    let weight_cargo = this.state.weight_unit === 'KG' ? flight?.weight_kg : flight?.weight_lbs
    if(!flight?.bag) {
      if(weight_cargo > 0) {
        if(weight_assigned > weight_cargo) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
          flight.cccolour = 'green'
        }
      } else {
        flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW BLUE
        flight.cccolour = 'blue'
      }
    } else if(flight?.bag && flight?.cargo && flight?.cargo?.md === 0 && flight?.cargo?.p96 === 0 && flight?.cargo?.p88 === 0 && flight?.cargo?.ld3 === 0 && flight?.cargo?.ld2 === 0){
      if(weight_cargo > 0) {
        if(weight_assigned > weight_cargo) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
          flight.cccolour = 'green'
        }
      } else {
        flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW BLUE
        flight.cccolour = 'blue'
      }
    } else {
      if(((flight?.cargo?.md || 0) - (flight?.actual?.MD || 0)) < 0 ||
      ((flight?.cargo?.p96 || 0) - (flight?.actual?.P96 || 0)) < 0 ||
      ((flight?.cargo?.p88 || 0) - (flight?.actual?.P88 || 0)) < 0 ||
      ((flight?.cargo?.ld3 || 0) - (flight?.actual?.LD3 || 0)) < 0 ||
      ((flight?.cargo?.ld2 || 0) - (flight?.actual?.LD2 || 0)) < 0) {
        flight.cccolour_css = 'p-icon cc-icon fs-0 red-cc' //SHOW RED
        flight.cccolour = 'red'
      } else {
        if(weight_cargo > 0 && (weight_assigned > weight_cargo)) {
          flight.cccolour_css = 'p-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else if(((flight?.cargo?.md || 0) - (flight?.actual?.MD || 0)) > 0 ||
        ((flight?.cargo?.p96 || 0) - (flight?.actual?.P96 || 0)) > 0 ||
        ((flight?.cargo?.p88 || 0) - (flight?.actual?.P88 || 0)) > 0 ||
        ((flight?.cargo?.ld3 || 0) - (flight?.actual?.LD3 || 0)) > 0 ||
        ((flight?.cargo?.ld2 || 0) - (flight?.actual?.LD2 || 0)) > 0) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc'
          flight.cccolour = 'green'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW Blue
          flight.cccolour = 'blue'
        }
      }
    }
    return flight
  }
  //ADDING TARE WEIGHT TO FLIGHT
  addTareWgt = (flight) => {
    let weight_kg = 0
    let weight_lbs = 0
    if(flight?.unique_ulds?.length > 0) {
      flight?.unique_ulds?.map((item) => {
        if((item?.uld_type !== 'CART') && item?.position_obj?.ac_types?.includes(flight?.ac_type)) {
          let unit = item?.position_obj?.weight?.unit
          let value = item?.position_obj?.weight?.value
          if(unit === 'LBS' || unit === 'L' || unit === 'LB' || unit === 'LBR') {
            weight_lbs += value //ROUNDED TO NEAREST INTEGER
            weight_kg += weightCalculator(value, 'LBS')
          } else if(unit === 'K' || unit === 'KG' || unit === 'KGM') {
            weight_kg += value //ROUNDED TO NEAREST INTEGER
            weight_lbs += weightCalculator(value, 'KGM')
          }
        }
      })
    }
    return {
      weight_kg: weight_kg,
      weight_lbs: weight_lbs,
    }
  }
  //CALCULATE ULD POSITIONS
  calcULDPositions = (flight) => {
    let a_md = 0
    let a_p96 = 0
    let a_p88 = 0
    let a_ld3 = 0
    let a_ld2 = 0

    if(flight?.unique_ulds?.length > 0) {
      flight?.unique_ulds?.map((item) => {
        if((!item?.uld_no?.startsWith('C') && !item?.uld_no?.endsWith('#')) && item?.position_obj?.ac_types?.includes(flight?.ac_type)) {
          switch (item?.position_obj?.uldtype) {
          case 'MD':
            a_md+=item?.position_obj?.position
            break
          case 'P96':
            a_p96+=item?.position_obj?.position
            break
          case 'P88':
            a_p88+=item?.position_obj?.position
            break
          case 'LD3':
            a_ld3+=item?.position_obj?.position
            break
          case 'LD2':
            a_ld2+=item?.position_obj?.position
            break
          default:
            break
          }
        }
      })
    }
    return {
      MD: a_md,
      P96: a_p96,
      P88: a_p88,
      LD3: a_ld3,
      LD2: a_ld2,
    }
  }
  //CALCULATE FLIGHT FPE
  getFPE = (flight) => {
    let fpe = 0
    let dest_type = ''

    let details = this.state.stationsData?.find((stn) => stn?.code === flight?.dest?.arriv) || ''
    if(details) {
      if(country.includes(details?.country?.toUpperCase())) {
        dest_type = 'EXPORT-D'
      } else {
        dest_type = 'EXPORT-I'
      }
      fpe = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)?.details?.fpe
    }

    return fpe
  }
  //UPDATE NOTES COUNT
  updateFlightNotesCount = (DETAILS) => {
    let DATA = cloneDeep(this.state.flightData)

    let flight_index = DATA?.findIndex(p => p.id == parseInt(DETAILS?.flights_id))
    if(flight_index > -1) {
      if(DETAILS?.type?.toUpperCase() === 'FLIGHT') {
        DATA[flight_index].notes = DETAILS?.notes?.length || 0
        DATA[flight_index].notes_date = DETAILS?.timestamp ? moment.utc(DETAILS.timestamp).tz(this.state.timezone).format(completeDateTime) : null
        this.sort(DATA, false)
      }
    }
  }
  //DISABLE PRE DEPARTURE NOTES IF FLIGHT HAS DEPARTED
  disableNote = (dept_time) => {
    return moment.tz(dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'minutes') < 0
  }
  //UPDATE WEIGHT ON TOGGLE
  updateWeightVal = (unit, value) => {
    if(value > 0) {
      if(unit === 'LB') {
        return weightCalculator(parseFloat(value || 0), 'KGM')?.toFixed(2)
      } else if(unit === 'KG') {
        return weightCalculator(parseFloat(value || 0), 'LBS')?.toFixed(2)
      }
    }
  }
  //LOAD PLAN MODAL JSX
  loadPlanModal = () => {
    const planLoadColumns = [
      {
        title: '',
        dataIndex: 'pos',
        key: 'pos',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              controls={false}
              size='small'
              placeholder=''
              value={this.state.editTPos || ''}
              onChange={(e) => this.setState({ editTPos: e })}
            />
            : <span>{record?.pos}</span>)
      },
      {
        title: '',
        dataIndex: 'type',
        key: 'type',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Select size='small'
              placeholder='Select process'
              className='w-100'
              value={this.state.editTType || ''}
              onChange={(e) => this.setState({ editTType: e })}
            >
              <Option value='P96'>PMC</Option>
              <Option value='P88'>PAG</Option>
              <Option value='LD3'>LD3</Option>
              <Option value='LD2'>LD2</Option>
              <Option value='MD'>MD</Option>
            </Select>
            : <span>{record?.type}</span>)
      },
      {
        title: '',
        dataIndex: 'work_area',
        key: 'work_area',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Select size='small'
              placeholder='Select process'
              className='w-100'
              value={this.state.editTWorkArea || ''}
              onChange={(e) => this.setState({ editTWorkArea: e })}
            >
              <Option value='International'>International</Option>
              <Option value='Dash'>Dash</Option>
              <Option value='Breezeway'>Breezeway</Option>
              <Option value='T-Point'>T-Point</Option>
            </Select>
            : <span>{record?.work_area}</span>)
      },
      {
        title: '',
        dataIndex: 'uld_awb_no',
        key: 'uld_awb_no',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTULDAWB || ''}
              onChange={(e) => this.setState({ editTULDAWB: e.target.value?.toUpperCase() })}
            />
            : <span>{record?.uld_awb_no}</span>)
      },
      {
        title: '',
        dataIndex: 'prod_code',
        key: 'prod_code',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTProd || ''}
              onChange={(e) => this.setState({ editTProd: e.target.value?.toUpperCase() })}
            />
            : <span>{record?.prod_code}</span>)
      },
      {
        title: '',
        dataIndex: 'plan_comm',
        key: 'plan_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm1 || ''}
              onChange={(e) => this.setState({ editTComm1: e.target.value })}
            />
            : <span>{record?.comm1}</span>)
      },
      {
        title: '',
        dataIndex: 'dep_comm',
        key: 'dep_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm2 || ''}
              onChange={(e) => this.setState({ editTComm2: e.target.value })}
            />
            : <span>{record?.comm2}</span>)
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '10%',
        render: (_, record) =>
          <div>
            {this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.submit('edit')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearAllocTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=> this.editAllocTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.setState({ deleteAllocID: record.id }, () => this.submit('delete'))} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    const CCcolumns = [
      {
        title: 'DOD Team Comments',
        dataIndex: 'plan_comm',
        key: 'plan_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              disabled={this.disableNote(this.state.editRecord?.dept_time)}
              size='small'
              placeholder=''
              value={this.state.editTComm1 || ''}
              onChange={(e) => this.setState({ editTComm1: e.target.value })}
            />
            : <span>{record?.comm1}</span>)
      },
      {
        title: 'Warehouse Team Comments',
        dataIndex: 'dep_comm',
        key: 'dep_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm2 || ''}
              onChange={(e) => this.setState({ editTComm2: e.target.value })}
              disabled
            />
            : <span>{record?.comm2}</span>)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.submit('edit')} ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearAllocTableForm()} ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              {/* <Tooltip title='Attachment'>
                <Badge size='small' count={2}>
                  <Button type='text' shape='circle' className='clip' icon={<PaperClipOutlined />} />
                </Badge>
              </Tooltip> */}
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=> this.editAllocTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.setState({ deleteAllocID: record.id }, () => this.submit('delete'))} okText='Yes' cancelText='No'>
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
        ),
      },
    ]

    return (
      <Modal
        title={
          <Row>
            <Col xs={24} md={6} className='my-auto'>
              <span>Load Plan</span>
            </Col>
            <Col xs={24} md={18} className='text-center'>
              <Form layout='inline'>
                <Space>
                  <Form.Item label='Enter Flight Number'>
                    <Input
                      size='small'
                      placeholder='Eg:YY123'
                      onChange={(e) => this.setState({ searchFlightNo: e.target.value?.toUpperCase() })}
                      value={this.state.searchFlightNo}
                    />
                  </Form.Item>
                  <Form.Item label='Flight Date'>
                    <DatePicker
                      size='small'
                      onChange={(mom) => this.setState({ searchDate: mom })}
                      value={this.state.searchDate}
                      placeholder='Select Date'
                    />
                  </Form.Item>
                  <Button
                    className='custom-icon-button mb-2'
                    size='small'
                    icon={<SearchOutlined />}
                    onClick={() => this.setState({ modalLoading: true }, () => this.fetchFlightOnSearch())}
                  />
                </Space>
              </Form>
            </Col>
          </Row>
        }
        visible={this.state.loadplanvisible}
        onOk={() => this.setState({
          loadplanvisible: false,
          showAlloc: false,
          ccView: false,
          SLUSummary: [],
          PTUSummary: [],
          BuiltSummary: [],
          BLKSummary: null,
          selected: [],
          bagSummary: [],
          remainingP96: 0,
          remainingP88: 0,
          remainingLD3: 0,
          remainingLD2: 0,
          remainingMD: 0,
        }, () => {
          this.clearEditAllocForm()
          this.clearEditFlightForm()
          this.clearAllocTableForm()
        })}
        onCancel={() => this.setState({
          loadplanvisible: false,
          showAlloc: false,
          ccView: false,
          SLUSummary: [],
          PTUSummary: [],
          BuiltSummary: [],
          BLKSummary: null,
          selected: [],
          bagSummary: [],
        }, () => {
          this.clearEditAllocForm()
          this.clearEditFlightForm()
          this.clearAllocTableForm()
        })}
        closable={false}
        footer={[
          <>
            {this.state.showSuccess && <div className='mr-4'><b><CheckCircleTwoTone twoToneColor='#52c41a' /> Load Plan updated successfully</b></div>}
            <Button
              disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.editRecord}
              onClick={this.submitData}
              type='primary'
              className='custom-button mr-4'
            >Update Load Plan
            </Button>
            <Button
              onClick={() => this.setState({
                loadplanvisible: false,
                showSuccess: false,
              }, () => {
                //CLEAR FLIGHT FORM
                this.clearEditFlightForm()
                //CLEAR ALLOCATION FORM
                this.clearEditAllocForm()
                //CLEAR ALLOCATION TABLE FORM
                this.clearAllocTableForm()
              })}
              type='primary'
              className='custom-button mr-4'
            >Close
            </Button>
          </>
        ]}
        width={'70%'}
        centered
        className='custom-modal pb-0 load-plan'
      >
        {this.state.modalLoading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} className='with-table'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <Card className='cargo-cap-card'>
              <div>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                >
                  {/* <Col xs={24} xl={8} xxl={8}>
                    <Row> */}
                  <Col xs={24} sm={12} md={3} lg={3} xl={3} xxl={2}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>AC</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.ac_type || 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={4} lg={4} xl={3} xxl={2}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Seg</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.org && this.state.editRecord?.dest ? `${this.state.editRecord.org.origin}-${this.state.editRecord.dest.arriv}` : 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xxs={24} sm={12} md={3} lg={3} xl={3} xxl={2}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Gate</small>
                      <p className='info-data mb-2 space-nowrap'>{this.state.editRecord?.dept?.gate ? this.state.editRecord.dept.gate : 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={7} lg={5} xl={5} xxl={3}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Dept Time</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.dept_time && `${this.state.editRecord?.dept_prefix} ${moment.tz(this.state.editRecord.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` || 'N/A'}</p>
                    </div>
                  </Col>
                  {/* </Row>
                  </Col> */}
                  <Col xs={24} sm={12} md={6} lg={4} xl={4} xxl={3}>
                    <div className='recd-ht'><small className='info-title'>P CAP / FCST</small>
                      <Space direction='horizontal'>
                        <p className='info-data text-right'>{this.state.editRecord?.pax_cap || 0} </p> <span className='color-white'>/</span>
                        {<InputNumber
                          min={0}
                          precision={0}
                          controls={false}
                          size='small'
                          placeholder=''
                          value={this.state.editFcst || 0}
                          onChange={(e) => this.setState({ editFcst: e })}
                          disabled={this.disableNote(this.state.editRecord?.dept_time)}
                          className='w-40'
                        />}
                      </Space>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={7} lg={5} xl={5} xxl={3}>
                    <div className='sub-head'>
                      <div><small className='info-title'>Bag Posns</small></div>
                      <Space size='small'>
                        {/* <div className='w-40'>
                          <small className='info-title'>PMC</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editBagP96 || 0}
                            onChange={(e) => this.setState({ editBagP96: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <div className='w-40'>
                          <small className='info-title'>PAG</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editBagP88 || 0}
                            onChange={(e) => this.setState({ editBagP88: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div> */}
                        <div className='w-40'>
                          <small className='info-title'>LD3</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editBagLD3 || 0}
                            onChange={(e) => this.setState({ editBagLD3: e })}
                            disabled={!this.state.editRecord?.cont_type.includes('LD3') || this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <div className='w-40'>
                          <small className='info-title'>LD2</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editBagLD2 || 0}
                            onChange={(e) => this.setState({ editBagLD2: e })}
                            disabled={!this.state.editRecord?.cont_type.includes('LD2') || this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <Button
                          className='custom-icon-button mt-4 disabled-bg-none'
                          ghost
                          icon={<SearchOutlined />}
                          onClick={() => this.fetchFlightSummary()} 
                          disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                        />
                      </Space>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={6}>
                    <div className='sub-head'>
                      <div><small className='info-title'>Optimal Cargo Build</small></div>
                      <Space size='small'>
                        {this.state.hideMD ? null : <div className='w-40'>
                          <small className='info-title'>MD</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editCargoMD || 0}
                            onChange={(e) => this.setState({ editCargoMD: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>}
                        <div className='w-40'>
                          <small className='info-title'>PMC</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editCargoP96 || 0}
                            onChange={(e) => this.setState({ editCargoP96: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <div className='w-40'>
                          <small className='info-title'>PAG</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editCargoP88 || 0}
                            onChange={(e) => this.setState({ editCargoP88: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <div className='w-40'>
                          <small className='info-title'>LD3</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editCargoLD3 || 0}
                            onChange={(e) => this.setState({ editCargoLD3: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <div className='w-40'>
                          <small className='info-title'>LD2</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editCargoLD2 || 0}
                            onChange={(e) => this.setState({ editCargoLD2: e })}
                            disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                      </Space>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={4} xl={4} xxl={2} className='sub-head text-center'>
                    <small className='info-title space-nowrap'>Cargo Payload</small>
                    <div className=''>
                      <div className='weight-toggle'>
                        <Switch
                          checkedChildren='LB'
                          unCheckedChildren='KG'
                          size='small'
                          className='mx-1'
                          checked={this.state.editWgtUnit === 'KG' ? false : true}
                          onChange={((e) => this.setState({
                            editWgtUnit: e === false ? 'KG' : 'LB',
                            editWgtValue: this.updateWeightVal(e === false ? 'KG' : 'LB', this.state.editWgtValue)
                          }))}
                        />
                      </div>
                      <div className=''>
                        <InputNumber
                          min={0}
                          controls={false}
                          size='small'
                          placeholder=''
                          value={this.state.editWgtValue || 0}
                          onChange={(e) => this.setState({ editWgtValue: e })}
                          disabled={this.disableNote(this.state.editRecord?.dept_time)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={4} lg={2} xl={2} xxl={1} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title '>BU PRIO</small>
                      <Checkbox
                        className='light-checkbox mx-auto'
                        checked={this.state.editBuPrio}
                        onChange={(e) => this.setState({ editBuPrio: e.target.checked })}
                        disabled={this.disableNote(this.state.editRecord?.dept_time)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
            <Row gutter={[24,0]} className=''>
              <Col sm={24} lg={12} className='text-center'>
                <label className='custom-label'>Aft Hold Configuration</label>
                {this.state.previewAft ? <img width='400' height='200' className='border-dark p-1 conf-img' src={this.state.previewAft || AftHold} alt={this.state.previewAft || AftHold} /> : <div className='config-placeholder'> No image available </div>}
              </Col>
              <Col sm={24} lg={12} className='text-center'>
                <label className='custom-label'>Forward Hold Configuration</label>
                {this.state.previewFwd ? <img width='400' height='200' className='border-dark p-1 conf-img' src={this.state.previewFwd || ForwardHold} alt={this.state.previewFwd || ForwardHold} /> : <div className='config-placeholder'> No image available </div>}
              </Col>
            </Row>
          </Col>
          {this.state.showAlloc && <>
            <Col xs={24} sm={24} md={24} lg={8} xl={12} className=''>
              <Title level={5} className='mb-0'>Load Plan</Title>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={12} className=''>
              <span className='rem-pos-title'>Remaining Positions: </span>
              <div className='d-flex f-wrap gap-10 rem_posn'>
                <p className='fv-middle gap-10'>P96:
                  <InputNumber
                    size='small'
                    className={this.state.remainingP96 > 0 ? 'w-35px dis-blue' : this.state.remainingP96 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                    value={this.state.remainingP96 < 0 ? this.state.remainingP96?.toString()?.split('-')[1] : this.state.remainingP96 || 0}
                    disabled
                  />
                </p>
                <p className='fv-middle gap-10'>P88:
                  <InputNumber
                    size='small'
                    className={this.state.remainingP88 > 0 ? 'w-35px dis-blue' : this.state.remainingP88 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                    value={this.state.remainingP88 < 0 ? this.state.remainingP88?.toString()?.split('-')[1] : this.state.remainingP88 || 0}
                    disabled
                  />
                </p>
                <p className='fv-middle gap-10'>LD3:
                  <InputNumber
                    size='small'
                    className={this.state.remainingLD3 > 0 ? 'w-35px dis-blue' : this.state.remainingLD3 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                    value={this.state.remainingLD3 < 0 ? this.state.remainingLD3?.toString()?.split('-')[1] : this.state.remainingLD3 || 0}
                    disabled
                  />
                </p>
                <p className='fv-middle gap-10'>LD2:
                  <InputNumber
                    size='small'
                    className={this.state.remainingLD2 > 0 ? 'w-35px dis-blue' : this.state.remainingLD2 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                    value={this.state.remainingLD2 < 0 ? this.state.remainingLD2?.toString()?.split('-')[1] : this.state.remainingLD2 || 0}
                    disabled
                  />
                </p>
                <p className='fv-middle gap-10'>MD:
                  <InputNumber
                    size='small'
                    className={this.state.remainingMD > 0 ? 'w-35px dis-blue' : this.state.remainingMD < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                    value={this.state.remainingMD < 0 ? this.state.remainingMD?.toString()?.split('-')[1] : this.state.remainingMD || 0}
                    disabled
                  />
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='px-0 p-12'>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[{
                    xs: 0,
                    sm: 0,
                    md: 24,
                    lg: 24
                  }, {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0
                  },]}
                  className='product-ht'>

                  <Col xs={24} sm={24} md={8} lg={6} xl={2} xxl={2} className=''>
                    <Form.Item label='# Of Posns'>
                      <InputNumber
                        min={0}
                        precision={0}
                        controls={false}
                        size='small'
                        placeholder=''
                        value={this.state.editPos || ''}
                        onChange={(e) => this.setState({ editPos: e })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={2} xxl={2} className=''>
                    <Form.Item label='Posn Type'>
                      <Select size='small'
                        placeholder='Select process'
                        className='w-100'
                        value={this.state.editType || []}
                        onChange={(e) => this.setState({ editType: e })}
                      >
                        <Option value='P96'>PMC</Option>
                        <Option value='P88'>PAG</Option>
                        <Option value='LD3'>LD3</Option>
                        <Option value='LD2'>LD2</Option>
                        <Option value='MD'>MD</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={3} className=''>
                    <Form.Item label='For Work Area'>
                      <Select size='small'
                        placeholder='Select process'
                        className='w-100'
                        value={this.state.editWorkArea || []}
                        onChange={(e) => this.setState({ editWorkArea: e })}
                      >
                        <Option value='International'>International</Option>
                        <Option value='Dash'>Dash</Option>
                        <Option value='Breezeway'>Breezeway</Option>
                        <Option value='T-Point'>T-Point</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={3} className=''>
                    <Form.Item label='ULD ID / AWB'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.editULDAWB || ''}
                        onChange={(e) => this.setState({ editULDAWB: e.target.value?.toUpperCase() })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={3} className=''>
                    <Form.Item label='Prod'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.editProd || []}
                        onChange={(e) => this.setState({ editProd: e.target.value?.toUpperCase() })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={5} className=''>
                    <Form.Item label='Planning Comments'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.editComm1 || ''}
                        onChange={(e) => this.setState({ editComm1: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={5} className=''>
                    <Form.Item label='Post Departure Comments'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.editComm2 || ''}
                        onChange={(e) => this.setState({ editComm2: e.target.value })}
                      />
                    </Form.Item>
                  </Col>

                  <Col flex='44px' className='text-center ml-auto p-0'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={() => this.submit('add')}>
                      <Tooltip title={this.state.isEdit ? 'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <Table
                className='custom-table w-100 no-header-table'
                dataSource={this.state.editAllocArray}
                columns={planLoadColumns}
                pagination={false}
              />
            </Col>
          </>}
          <Col span={24}>
            {/* <Card
              size='small'
              className='info-card-2'
            >
              <span className='text-uppercase fs-10'>Allowed ULD types</span>
              <Divider className='mt-1 mb-2' />
              <div className='text-uppercase fs-14'>
                <Space size={[16, 0]} wrap>
                  <span>PMC</span>
                  <span>PAJ</span>
                  <span>P6P</span>
                  <span>P1P</span>
                  <span>AKE</span>
                  <span>AVA</span>
                  <span>AKN</span>
                  <span>RKN</span>
                  <span>RAP</span>
                  <span>AAN</span>
                </Space>
              </div>
            </Card> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='px-0 p-12'>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[{
                    xs: 0,
                    sm: 0,
                    md: 24,
                    lg: 24
                  }, {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0
                  },]}
                  className='product-ht'>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className=''>
                    <Form.Item label='Planning Comments' className='mb-2'>
                      <Input
                        disabled={this.disableNote(this.state.editRecord?.dept_time)}
                        size='small'
                        placeholder=''
                        value={this.state.editComm1 || ''}
                        onChange={(e) => this.setState({ editComm1: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className=''>
                    <Form.Item label='Post Departure Notes'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.editComm2 || ''}
                        onChange={(e) => this.setState({ editComm2: e.target.value })}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={() => this.submit('add')}>
                      <Tooltip title={this.state.isEdit ? 'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <div className='my-3'>
              <Row>
                <Col xs={24} md={24} lg={6}>
                  <Text strong={true}>Load Planning Notes</Text>
                </Col>
                <Col xs={24} md={24} lg={18} className='text-right'>
                  <Space wrap>
                    <Text type='secondary' strong>Last Update:</Text>
                    <Space size={[48, 0]} direction='horizontal'>
                      <Text strong>{this.state.lastUpdatedAt ? this.state.lastUpdatedAt + ' ' + this.state.ext : 'N/A'}</Text>
                      <Text strong>{this.state.lastUpdatedBy ? this.state.lastUpdatedBy : 'N/A'}</Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </div>
            <div className='table-outer'>
              <Table
                className='custom-table w-100'
                dataSource={this.state.editAllocArray} columns={CCcolumns}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }
  //GENERATE SUMMARY URL
  summaryURL = () => {
    let contPresent = this.state.editRecord?.cont_type?.length > 0 ? this.state.editRecord?.cont_type : ''
    let selected = ''
    if(contPresent) {
      if(contPresent?.includes('LD2') && this.state.editBagLD2 > 0) {
        selected = 'LD2'
      } else if(contPresent?.includes('LD3') && this.state.editBagLD3 > 0) {
        selected = 'LD3'
      }
    }

    if(!selected) {
      if(this.state.editBagLD2 > 0) {
        selected = 'LD2'
      } else if(this.state.editBagLD3 > 0) {
        selected = 'LD3'
      }
    }

    let url = `summery?id=${this.state.editRecord?.id}&fleet=${this.state.editRecord?.ac_type}`
    //if(selected === 'LD2') {
    url += `&ld2=${this.state.editBagLD2 || 0}`
    //}
    //else if(selected === 'LD3') {
    url += `&ld3=${this.state.editBagLD3 || 0}`
    //}
    return url
  }
  //FETCH FLIGHT SUMMARY
  fetchFlightSummary = async () => {
    await Http.get(this.summaryURL()).then((resp) => {
      if(resp?.data?.data) {
        this.calcSummary(resp?.data?.data)
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message,
        })
      }
    }).catch(() => {
      this.setState({ servererror : true }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
    })
  }
  //CALULATE SUMMARY DATA
  calcSummary = (data) => {
    let SLU3CharArr = []
    let SLUAll = []
    let PTU3CharArr = []
    let PTUAll = []
    let Built3CharArr = []
    let BuiltAll = []

    let blkObj = {}
    let blk_count_IT = 0
    let blk_count_CI = 0
    let weight_lbs_IT = 0
    let weight_lbs_CI = 0
    let weight_kg_IT = 0
    let weight_kg_CI = 0

    let vol_IT = 0
    let vol_CI = 0

    data?.summery?.map((item) => {
      if(item?.ulds_in?.blk) {
        if(item?.location) {
          blk_count_CI += 1
          // vol_CI += parseFloat(item?.vol?.value || 0) || 0
          if(item?.vol?.unit === 'FTQ') {
            vol_CI += parseFloat(item?.vol?.value || 0)
          } else if(item?.vol?.unit === 'MTQ') {
            vol_CI += parseFloat((item?.vol?.value || 0) * 35.314667)
          } else if(item?.vol?.unit === 'CF') {
            vol_CI += parseFloat(item?.vol?.value || 0)
          }

          if(item.wgt.unit === 'L' || item.wgt.unit === 'LB' || item.wgt.unit === 'LBR' || item.wgt.unit === 'LBS') {
            weight_lbs_CI += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_kg_CI += weightCalculator(parseFloat(item.wgt.value), 'LBS')
          } else if(item.wgt.unit === 'K' || item.wgt.unit === 'KG' || item.wgt.unit === 'KGM') {
            weight_kg_CI += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_lbs_CI += weightCalculator(parseFloat(item.wgt.value), 'KGM')
          }
        } else {
          blk_count_IT += 1
          // vol_IT += parseFloat(item?.vol?.value || 0) || 0
          if(vol_IT?.vol?.unit === 'FTQ') {
            vol_CI += parseFloat(item?.vol?.value || 0)
          } else if(item?.vol?.unit === 'MTQ') {
            vol_IT += parseFloat((item?.vol?.value || 0) * 35.314667)
          } else if(item?.vol?.unit === 'CF') {
            vol_IT += parseFloat(item?.vol?.value || 0)
          }

          if(item.wgt.unit === 'L' || item.wgt.unit === 'LB' || item.wgt.unit === 'LBR' || item.wgt.unit === 'LBS') {
            weight_lbs_IT += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_kg_IT += weightCalculator(item.wgt.value, 'LBS')
          } else if(item.wgt.unit === 'K' || item.wgt.unit === 'KG' || item.wgt.unit === 'KGM') {
            weight_kg_IT += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_lbs_IT += weightCalculator(item.wgt.value, 'KGM')
          }
        }
        //ADD CART IN BUILD UP SUMMARY
        if(!item?.ulds_in?.master && this.checkULDType(item?.ulds_in) === 'CART') {
          if(!BuiltAll.includes(item?.ulds_in?.uld_no)) {
            BuiltAll.push(item?.ulds_in?.uld_no)
            Built3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              ready: item?.ulds_in?.rdy === true ? true : false,
            })
          }
        }
      } else {
        let uldType = this.checkULDType(item?.ulds_in)
        if(uldType === 'SLU') {
          if(!SLUAll?.includes(item?.ulds_in?.uld_no)) {
            SLUAll.push(item?.ulds_in?.uld_no)
            SLU3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              location: item?.ulds_in?.location,
            })
          }
        } else if(item?.ulds_in?.uld_dispo && item?.ulds_in?.master){
          if(uldType === 'PTU') {
            if(!PTUAll?.includes(item?.ulds_in?.uld_no)) {
              PTUAll.push(item?.ulds_in?.uld_no)
              PTU3CharArr.push({
                uld_no: item?.ulds_in?.uld_no,
                location: item?.ulds_in?.location,
              })
            }
          }
        } else if(item?.ulds_in && !item?.ulds_in?.master) {
          if(!BuiltAll.includes(item?.ulds_in?.uld_no)) {
            BuiltAll.push(item?.ulds_in?.uld_no)
            Built3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              ready: item?.ulds_in?.rdy === true ? true : false,
            })
          }
        }
      }
    })

    blkObj.blk_count_CI = blk_count_CI
    blkObj.blk_count_IT = blk_count_IT
    blkObj.weight_lbs_CI = weight_lbs_CI
    blkObj.weight_lbs_IT = weight_lbs_IT
    blkObj.weight_kg_CI = weight_kg_CI
    blkObj.weight_kg_IT = weight_kg_IT
    blkObj.vol_CI = vol_CI?.toFixed(2) || 0
    blkObj.vol_IT = vol_IT?.toFixed(2) || 0
    this.sortSummary(data.bag)

    this.setState({
      SLUSummary: SLU3CharArr || [],
      PTUSummary: PTU3CharArr || [],
      BuiltSummary: Built3CharArr || [],
      BLKSummary: blkObj || null,
      bagSummary: data?.bag?.length > 0 ? data.bag : []
    }, () => this.setState({ optimalBuild: true }))
  }
  //SORT
  sortSummary = (array) => {
    array && array.sort(function(a, b) {
      // Compare the fields
      if (a?.pmc > b?.pmc) return -1
      if (a?.pmc < b?.pmc) return 1
      return 0
    })
  }
  //SEARCH MODAL
  searchModal = () => {
    let SLU = this.state.SLUSummary
    let PTU = this.state.PTUSummary
    let BUILT = this.state.BuiltSummary
    let BLK = this.state.BLKSummary
    let bagSummary = this.state.bagSummary
    const obcolumns = [
      {
        title: 'MD',
        dataIndex: 'md',
        key: 'md',
        className: 'space-nowrap',
        render: (_, record) => <span>{record?.md || 0}</span>
      },
      {
        // title: 'P96',
        title: 'PMC',
        dataIndex: 'pmc',
        key: 'pmc',
        render: (_, record) => <span>{record?.pmc - this.state.editBagP96}</span>
      },
      {
        // title: 'P88',
        title: 'PAG',
        dataIndex: 'pag',
        key: 'pag',
        render: (_, record) => <span>{record?.pag - this.state.editBagP88}</span>
      },
      {
        title: 'LD3',
        dataIndex: 'ld3',
        key: 'ld3',
        render: (_, record) => <span>{record?.ld3 - this.state.editBagLD3}</span>
      },
      {
        title: 'LD2',
        dataIndex: 'ld2',
        key: 'ld2',
        render: (_, record) => <span>{record?.ld2 - this.state.editBagLD2}</span>
      },
    ]
    return (
      <Modal
        title= {`Optimal Build - ${this.state.editRecord?.flight_no}/${moment(this.state.editRecord?.flight_date).format(dateOnly)} - ${this.state.editRecord?.ac_type}`}
        visible={this.state.optimalBuild}
        onCancel={() => this.setState({ optimalBuild: false })}
        footer={false}
        width={1160}
        centered
        className='curved-modal pb-0'
        destroyOnClose={true}
        closeIcon={<Text type='secondary'><CloseCircleFilled /></Text>}
      >
        <Card size='small' className='custom-card custom-padding mb-4'
          title={
            <Space>
              <span>Cargo Summary</span>
              <Popover content={legendPopover} title='Legend'><InfoCircleFilled /></Popover>
            </Space>}>
          <Row gutter={[{
            xs: 8,
            sm: 16,
            lg: 24
          }, {
            xs: 8,
            sm: 16,
            lg: 24
          }]}>
            <Col xs={24} lg={16}>
              <Card title='On Hand' size='small' bordered={false} className='curved-card custom-padding'>
                <Row gutter={[8, 8]} className='p-2'>
                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>SLU</p>
                      {SLU?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {!key?.location ?
                              <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' /> :
                              <span className='float-right'>AS</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Potential TUs</p>
                      {PTU?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {!key?.location ?
                              <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' /> :
                              <span className='float-right'>AS</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Built Up</p>
                      {BUILT?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {key?.ready === true ?
                              <span style={{ color: '#629d03' }} className='float-right'>C</span> :
                              <span style={{ color: '#D9001B' }} className='float-right'>O</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Loose</p>
                      {/* IN TRANSIT */}
                      {BLK?.blk_count_IT > 0 && <p>
                        <span className='dib-w90'>
                          {`${BLK?.blk_count_IT} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            BLK?.weight_kg_IT : this.state.weight_unit === 'LB' ? BLK?.weight_lbs_IT : 0) || 0}${this.state.weight_unit} | ${BLK?.vol_IT}CF`}
                        </span>
                        <span>
                          <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' />
                        </span>
                      </p>}
                      {/* CHECKED IN */}
                      {BLK?.blk_count_CI > 0 && <p>
                        <span className='dib-w90'>
                          {`${BLK?.blk_count_CI} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            BLK?.weight_kg_CI : this.state.weight_unit === 'LB' ? BLK?.weight_lbs_CI : 0) || 0} | ${BLK?.vol_CI}CF`}
                        </span>
                        <span>
                          <span className='float-right'>AS</span>
                        </span>
                      </p>}
                    </div>
                  </Col>

                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card title='Booked, Not Tendered' size='small' bordered={false} className='curved-card custom-padding'>
                <Row gutter={[8, 8]} className='p-2'>
                  <Col xs={24} sm={12} xl={12}>
                    <div className='cargo-summary'>
                      <p>SLU</p>
                      <p><span className='dib-w90'>MD</span><span>0</span></p>
                      <p><span className='dib-w90'>PMC/PAG</span><span>{this.state.editRecord?.not_tendered?.udp || 0}</span></p>
                      <p><span className='dib-w90'>LD3</span><span>{this.state.editRecord?.not_tendered?.ldc || 0}</span></p>
                      <p><span className='dib-w90'>LD2</span><span>{this.state.editRecord?.not_tendered?.ldp || 0}</span></p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} xl={12}>
                    <div className='cargo-summary'>
                      <p>Loose</p>
                      <p>
                        <span className='dib-w90'>
                          {`${this.state.editRecord?.not_tendered?.blk_count || 0} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            this.state.editRecord?.not_tendered?.weight_kg || 0 :
                            this.state.weight_unit === 'LB' ? this.state.editRecord?.not_tendered?.weight_lbs || 0 :
                              0) || 0}${this.state.weight_unit} | ${this.state.editRecord?.not_tendered?.pcs || 0}CF`}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card size='small' className='custom-card custom-padding' title='Available Configurations'>
          <Row gutter={[{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          },]} className='p-12'>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <Table
                className='custom-table w-100'
                dataSource={bagSummary}
                columns={obcolumns}
                pagination={false}
                rowKey='id'
                rowSelection={{
                  type: 'checkbox',
                  onChange: (_, rows) => this.handleCheckboxChange(rows),
                  hideSelectAll: true,
                  selectedRowKeys: this.state.selected
                }}
              />
            </Col>
            <Col className='ml-auto'>
              <Button
                disabled={!this.state.bagSelected}
                type='primary'
                className='custom-button mr-4'
                onClick={this.updateBaggage}
              >Update
              </Button>
            </Col>
          </Row>
        </Card>
      </Modal>
    )
  }
  //UPDATE BAGGAGE POSITIONS
  updateBaggage = () => {
    this.setState({
      editCargoMD: this.state.bagSelected?.md,
      editCargoP96: this.state.editBagP96 > 0 ? this.state.bagSelected?.pmc - this.state.editBagP96 : this.state.bagSelected?.pmc,
      editCargoP88: this.state.editBagP88 > 0 ? this.state.bagSelected?.pag - this.state.editBagP88 : this.state.bagSelected?.pag,
      editCargoLD3: this.state.editBagLD3 > 0 ? this.state.bagSelected?.ld3 - this.state.editBagLD3 : this.state.bagSelected?.ld3,
      editCargoLD2: this.state.editBagLD2 > 0 ? this.state.bagSelected?.ld2 - this.state.editBagLD2 : this.state.bagSelected?.ld2,
      // remainingP96: 0,
      // remainingP88: 0,
      // remainingLD3: 0,
      // remainingLD2: 0,
      // remainingMD: 0,
    }, () => this.setState({
      selected: [],
      bagSelected: null,
      optimalBuild: false,
      //showAlloc: true,
    }))
  }
  //HANDLE CHECKBOX CHANGE
  handleCheckboxChange = (selectedRow) => {
    this.setState({
      selected: [selectedRow[selectedRow?.length - 1]?.id] || [],
      bagSelected: selectedRow[selectedRow?.length - 1] || null
    })
  }
  //SAVE CHANGES
  submit = async (action) => {
    if(this.state.editRecord?.id) {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {}
      let url = ''

      if(action == 'add' || action === 'edit') {
        url = `/flight_plan/${action}/${parseInt(this.state.editRecord.id)}`
        if(this.state.editAllocRecord) {
          formdata.id = this.state.editAllocRecord?.id
        }
        formdata.comm1 = this.state.editAllocRecord ? this.state.editTComm1 || '' : this.state.editComm1 || ''
        formdata.comm2 = this.state.editAllocRecord ? this.state.editTComm2 || '' : this.state.editComm2 || ''
      } else if(action == 'delete') {
        url = `/flight_plan/${action}/${parseInt(this.state.editRecord?.id)}/${parseInt(this.state.deleteAllocID)}`
      }

      let awaitMehod = action == 'add' || action === 'edit' ? Http.post(url, formdata, { headers }) : Http.delete(url, { headers })
      await awaitMehod.then((data) => {
        if (data && data?.status === 200) {
          //CLEAR LOAD PLAN FORM
          if(action == 'add') this.clearEditAllocForm()
          //CLEAR LOAD PLAN TABLE FORM
          if(this.state.editAllocRecord) this.clearAllocTableForm()

          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: 'Invalid Input',
      })
    }
  }
  //SET RECORD EDITABLE
  setEdit = (record, type = '') => {
    let calcRemainingPos = record?.details?.length > 0 ? this.calcPos(record?.details) : ''

    if(type) {
      if(type === 'DETAILS') {
        this.setState({
          editRecord: record,
          editFcst: record?.fcst || 0,
          editBagP96: record?.bag?.p96 || 0,
          editBagP88: record?.bag?.p88 || 0,
          editBagLD3: record?.bag?.ld3 || 0,
          editBagLD2: record?.bag?.ld2 || 0,
          editCargoMD: record?.cargo?.md || 0,
          editCargoP96: record?.cargo?.p96 || 0,
          editCargoP88: record?.cargo?.p88 || 0,
          editCargoLD3: record?.cargo?.ld3 || 0,
          editCargoLD2: record?.cargo?.ld2 || 0,
          editWgtValue: this.state.weight_unit === 'KG' ? parseFloat(record?.weight_kg || 0)?.toFixed(2) : parseFloat(record?.weight_lbs || 0)?.toFixed(2),
          editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
          editBuPrio: record?.bu_prio || false,
          editIsExceeded: record?.load_plan || false,
          previewFwd: record?.fwd_conf?.path,
          previewAft: record?.aft_conf?.path,
          lastUpdatedBy: record?.last_updated_by,
          lastUpdatedAt: record?.last_updated_at,
          isWideBody: record?.acpt_body,
          remainingP96: calcRemainingPos?.P96 ? (record?.cargo?.p96 || 0) - calcRemainingPos.P96 : (record?.cargo?.p96 || 0),
          remainingP88: calcRemainingPos?.P88 ? (record?.cargo?.p88 || 0) - calcRemainingPos.P88 : (record?.cargo?.p88 || 0),
          remainingLD3: calcRemainingPos?.LD3 ? (record?.cargo?.ld3 || 0) - calcRemainingPos.LD3 : (record?.cargo?.ld3 || 0),
          remainingLD2: calcRemainingPos?.LD2 ? (record?.cargo?.ld2 || 0) - calcRemainingPos.LD2 : (record?.cargo?.ld2 || 0),
          remainingMD: calcRemainingPos?.MD ? (record?.cargo?.md || 0) - calcRemainingPos.MD : (record?.cargo?.md || 0),
          searchFlightNo: record?.flight_no?.toUpperCase() || '',
          searchDate: record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone) :
            record?.schdl ? moment.tz(record.schdl, this.state.timezone) :
              record?.flight_date ? moment(record.flight_date) : '',
        }, () => this.setState({ loadplanvisible: true }))
      } else {
        this.setState({
          editRecord: record,
          lastUpdatedBy: record?.last_updated_by,
          lastUpdatedAt: record?.last_updated_at,
          isWideBody: record?.acpt_body,
          editAllocArray: record?.details || [],
          searchFlightNo: record?.flight_no?.toUpperCase() || '',
          searchDate: record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone) :
            record?.schdl ? moment.tz(record.schdl, this.state.timezone) :
              record?.flight_date ? moment(record.flight_date) : '',
        }, () => this.setState({ loadplanvisible: true }))
      }
    } else {
      this.setState({
        editRecord: record,
        editFcst: record?.fcst || 0,
        editBagP96: record?.bag?.p96 || 0,
        editBagP88: record?.bag?.p88 || 0,
        editBagLD3: record?.bag?.ld3 || 0,
        editBagLD2: record?.bag?.ld2 || 0,
        editCargoMD: record?.cargo?.md || 0,
        editCargoP96: record?.cargo?.p96 || 0,
        editCargoP88: record?.cargo?.p88 || 0,
        editCargoLD3: record?.cargo?.ld3 || 0,
        editCargoLD2: record?.cargo?.ld2 || 0,
        editWgtValue: this.state.weight_unit === 'KG' ? parseFloat(record?.weight_kg || 0)?.toFixed(2) : parseFloat(record?.weight_lbs || 0)?.toFixed(2),
        editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
        editBuPrio: record?.bu_prio || false,
        editIsExceeded: record?.load_plan || false,
        previewFwd: record?.fwd_conf?.path,
        previewAft: record?.aft_conf?.path,
        lastUpdatedBy: record?.last_updated_by,
        lastUpdatedAt: record?.last_updated_at,
        isWideBody: record?.acpt_body,
        editAllocArray: record?.details || [],
        remainingP96: calcRemainingPos?.P96 ? (record?.cargo?.p96 || 0) - calcRemainingPos.P96 : (record?.cargo?.p96 || 0),
        remainingP88: calcRemainingPos?.P88 ? (record?.cargo?.p88 || 0) - calcRemainingPos.P88 : (record?.cargo?.p88 || 0),
        remainingLD3: calcRemainingPos?.LD3 ? (record?.cargo?.ld3 || 0) - calcRemainingPos.LD3 : (record?.cargo?.ld3 || 0),
        remainingLD2: calcRemainingPos?.LD2 ? (record?.cargo?.ld2 || 0) - calcRemainingPos.LD2 : (record?.cargo?.ld2 || 0),
        remainingMD: calcRemainingPos?.MD ? (record?.cargo?.md || 0) - calcRemainingPos.MD : (record?.cargo?.md || 0),
        searchFlightNo: record?.flight_no?.toUpperCase() || '',
        searchDate: record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone) :
          record?.schdl ? moment.tz(record.schdl, this.state.timezone) :
            record?.flight_date ? moment(record.flight_date) : '',
      }, () => this.setState({ loadplanvisible: true }))
    }

  }
  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if(uld?.blk && (!uld?.uld_no?.startsWith('C') && !uld?.uld_no?.endsWith('#'))) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.dest && uld?.dest !== this.state.station && uld?.master) {
        type = 'PTU'
      } else if(uld?.uld_no?.startsWith('C') || uld?.uld_no?.endsWith('#')) {
        type = 'CART'
      }
    }
    return type
  }
  //CALCULATE REMAINING POSITIONS
  calcPos = (details) => {
    let countp96 = 0
    let countp88 = 0
    let countld3 = 0
    let countld2 = 0
    let countmd = 0

    details?.map((item) => {
      switch (item?.type) {
      case 'P96':
        countp96+=parseInt(item?.pos || 0)
        break
      case 'P88':
        countp88+=parseInt(item?.pos || 0)
        break
      case 'LD3':
        countld3+=parseInt(item?.pos || 0)
        break
      case 'LD2':
        countld2+=parseInt(item?.pos || 0)
        break
      case 'MD':
        countmd+=parseInt(item?.pos || 0)
        break
      default:
        break
      }
    })

    return {
      P96: countp96,
      P88: countp88,
      LD3: countld3,
      LD2: countld2,
      MD: countmd,
    }
  }
  //SET ALLOCATIONS VALUES
  editAllocTableForm = (record) => {
    this.setState({
      editAllocRecord: record,
      editTPos: record?.pos,
      editTType: record?.type,
      editTWorkArea: record?.work_area,
      editTULDAWB: record?.uld_awb_no,
      editTProd: record?.prod_code,
      editTComm1: record?.comm1,
      editTComm2: record?.comm2,
    })
  }
  //CLEAR FLIGHT FORM
  clearEditFlightForm = () => {
    this.setState({
      editRecord: null,
      editFcst: 0,
      editBagP96: 0,
      editBagP88: 0,
      editBagLD3: 0,
      editBagLD2: 0,
      editCargoMD: 0,
      editCargoP96: 0,
      editCargoP88: 0,
      editCargoLD3: 0,
      editCargoLD2: 0,
      editWgtValue: 0,
      editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
      editBuPrio: false,
      editIsExceeded: false,
      editAllocArray: [],
      previewFwd: null,
      previewAft: null,
      lastUpdatedAt: '',
      lastUpdatedBy: '',
      isWideBody: false,
      searchDate: '',
      searchFlightNo: '',
    })
  }
  //CLEAR ALLOCATIONS FORM
  clearEditAllocForm = () => {
    this.setState({
      editPos: '',
      editType: '',
      editWorkArea: '',
      editULDAWB: '',
      editProd: '',
      editComm1: '',
      editComm2: '',
    })
  }
  //CLEAR ALLOCATIONS TABLE FORM
  clearAllocTableForm = () => {
    this.setState({
      editAllocRecord: null,
      deleteAllocID: null,
      editTPos: '',
      editTType: '',
      editTWorkArea: '',
      editTULDAWB: '',
      editTProd: '',
      editTComm1: '',
      editTComm2: '',
    })
  }
  //SORT
  sort = (array, on_load) => {
    let sort = this.state.sort
    array && array.sort(function(keyA, keyB) {
      let a = sort === 'ac_type' ? keyA[sort] : keyA[sort] ? moment(keyA[sort]) : null
      let b = sort === 'ac_type' ? keyB[sort] : keyB[sort] ? moment(keyB[sort]) : null

      let va = (a === null) ? '' : '' + a
      let vb = (b === null) ? '' :  ''+ b

      if(sort === 'notes_date') {
        return va > vb ? -1 : (va === vb ? 0 : 1)
      } else {
        return va > vb ? 1 : (va === vb ? 0 : -1)
      }
    })

    if(!on_load) {
      this.setState({ flightData: [] }, () => this.setState({ flightData: array }, () => {
        if(this.state.departedFlightsCheck) {
          this.departedFlightsFilter()
        }
      }))
    }
  }
  //FILTER DEPARTED FLIGHTS
  departedFlightsFilter = () => {
    if(this.state.departedFlightsCheck || this.state.amberFlightsCheck || this.state.redFlightsCheck){
      let filtered_data = cloneDeep(this.state.flightData)
      let filters = {}
      if(this.state.departedFlightsCheck) {
        filtered_data = filtered_data?.filter((flight) => moment(flight?.dept_time).valueOf() > moment().tz(this.state.timezone).valueOf())
      }

      if(this.state.amberFlightsCheck) {
        filters.amberEquals = item => item.cccolour === 'amber'
      }
      if(this.state.redFlightsCheck) {
        filters.redEquals = item => item.cccolour === 'red'
      }
      if(this.state.amberFlightsCheck || this.state.redFlightsCheck){
        filtered_data = filtered_data?.filter(item => Object.values(filters)?.some(f => f(item)))
      }

      this.setState({ displayData: filtered_data })
    }
    else {
      this.setState({ displayData: [] })
    }
  }
  //SHOW NOTES
  showFlightNotes = (record) => {
    let note = record?.awb_no ? record.awb_no : record?.flight_no ? record.flight_no : null
    if(record.flight_no && record.flight_date){
      note = note+'/'+moment(record.flight_date).format(dateOnly)
    }
    let title  = record?.flight_no && record?.dept_time ? 'Flight Notes - '+ formatFlightNo(record.flight_no, record.dept_time) : record?.awb_no ? 'AWB Notes - '+ formatAWBNo(record.awb_no) :
      record?.flight_no && record?.flight_date ? 'Flight Notes - '+ formatFlightNo(record.flight_no, record.flight_date) : record?.awb_no ? 'AWB Notes - '+ formatAWBNo(record.awb_no) : ''
    this.setState({
      searchString: note,
      flightNotes: true,
      notesTitle: title
    })
  }
  //SET FILTERS
  setFilters = (type) => {
    let current_date = moment().tz(this.state.timezone).format(dateFormat)
    let selected_date_time = ''
    if(type === 'start') {
      selected_date_time = moment(this.state.start).format(dateFormat)

      if(selected_date_time < current_date) {
        this.setState({
          flightData: [],
          page: 1,
          end: moment(this.state.start).add(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
        }, () => this.fetchFlights())
      } else if(selected_date_time === current_date) {
        this.setState({
          flightData: [],
          page: 1,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
        }, () => this.fetchFlights())
      } else if(selected_date_time > current_date) {
        this.setState({
          flightData: [],
          page: 1,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
        }, () => this.fetchFlights())
      }
    } else if(type === 'end') {
      selected_date_time = moment(this.state.end).format(dateFormat)

      if(selected_date_time < current_date) {
        this.setState({
          flightData: [],
          page: 1,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            second: 59,
          }),
        }, () => this.fetchFlights())
      } else if(selected_date_time === current_date) {
        this.setState({
          flightData: [],
          page: 1,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
        }, () => this.fetchFlights())
      } else if(selected_date_time > current_date) {
        this.setState({
          flightData: [],
          page: 1,
          start: moment(this.state.end).subtract(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
        }, () => this.fetchFlights())
      }
    } else if(type === 'stime' || type === 'etime') {
      this.setState({
        flightData: [],
        page: 1,
      }, () => this.fetchFlights())
    }
  }
  //SET DATES DISABLED ON CONDITION
  disabledDate = (current) => {
    let prev = moment().tz(this.state.timezone).subtract(1, 'month').format(dateFormat)
    let next = moment().tz(this.state.timezone).add(1, 'week').format(dateFormat)
    return current && (current < moment(prev, dateFormat) || current > moment(next, dateFormat))
  }

  submitData = async () => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let formdata = {
      bag: {},
      cargo: {},
      wgt: {},
    }
    formdata.fcst = this.state.editFcst || 0
    formdata.bag.p96 = this.state.editBagP96 || 0
    formdata.bag.p88 = this.state.editBagP88 || 0
    formdata.bag.ld3 = this.state.editBagLD3 || 0
    formdata.bag.ld2 = this.state.editBagLD2 || 0
    formdata.cargo.md = this.state.editCargoMD || 0
    formdata.cargo.p96 = this.state.editCargoP96 || 0
    formdata.cargo.p88 = this.state.editCargoP88 || 0
    formdata.cargo.ld3 = this.state.editCargoLD3 || 0
    formdata.cargo.ld2 = this.state.editCargoLD2 || 0
    formdata.wgt.value = this.state.editWgtValue || 0
    formdata.wgt.unit = this.state.editWgtUnit
    formdata.bu_prio = this.state.editBuPrio
    formdata.load_plan = this.state.editIsExceeded
    // formdata.details = this.state.editAllocArray

    await Http.put(`/flight_control_update/${parseInt(this.state.editRecord.id)}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({ showSuccess: true })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }
  checkFlightDate = (record) => {
    let hours = moment.utc(record.flight_date).format('HH')
    let mins = moment.utc(record.flight_date).format('mm')  
    if(hours === '00' && mins === '00'){
      return moment(record.flight_date).format(dateOnly)
    } else {
      moment.utc(record.flight_date).tz(this.state.timezone).format(dateOnly)
    }
  }

  render() {
    return (
      <div>
        {
          this.state.servererror ?
            <p className='dis-server'>Disconnected from the server, Please refresh the page.</p> : null
        }
        {this.state.loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        {this.state.loadplanvisible && this.loadPlanModal()}
        {this.state.optimalBuild && this.searchModal()}
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100 e'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 12,
                lg: 12
              }, {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
                  <Title level={5} className='mb-0'>
                    Flights
                  </Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=' mb-4'>
                  <Row gutter={[{
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  },]} className=''>
                    <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                      <div>
                        <label className='custom-label'>From</label>
                        <DatePicker
                          allowClear={false}
                          className=''
                          onChange={(moment) => this.setState({
                            start: moment,
                            flightData: [],
                            page: 1,
                          }, () => this.fetchFlights())}
                          // onChange={(moment) => this.setState({ start: moment }, () => this.setFilters('start'))}
                          value={moment(this.state.start, dateFormat)}
                          disabledDate={(current) => this.disabledDate(current)}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                      <div>
                        <label className='custom-label transparent'>-</label>
                        <TimePicker
                          allowClear={false}
                          onChange={(moment) => this.setState({
                            stime: moment,
                            flightData: [],
                            page: 1,
                          }, () => this.fetchFlights())}
                          // onChange={(moment) => this.setState({ stime: moment }, () => this.setFilters('stime'))}
                          value={moment(this.state.stime, timeFormat)}
                          disabledHours={()=>{
                            var hours = []
                            let start = this.state.start ? moment(this.state.start, dateFormat).format(dateFormat) : null
                            let atlstart = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
                            if(start && atlstart && start === atlstart ){
                              for(var i = 0; i < moment().tz(this.state.timezone).hour(); i++){
                                hours.push(i)
                              }
                            }
                            return hours
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                      <div>
                        <label className='custom-label'>To</label>
                        <DatePicker
                          allowClear={false}
                          className=''
                          onChange={(moment) => this.setState({
                            end: moment,
                            flightData: [],
                            page: 1,
                          }, () => this.fetchFlights())}
                          // onChange={(moment) => this.setState({ end: moment }, () => this.setFilters('end'))}
                          value={moment(this.state.end, dateFormat)}
                          disabledDate={(current) => this.disabledDate(current)}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                      <div>
                        <label className='custom-label transparent'>-</label>
                        <TimePicker
                          allowClear={false}
                          onChange={(moment) => this.setState({
                            etime: moment,
                            flightData: [],
                            page: 1,
                          }, () => this.fetchFlights())}
                          // onChange={(moment) => this.setState({ etime: moment }, () => this.setFilters('etime'))}
                          value={moment(this.state.etime, timeFormat)}
                          disabledHours={()=>{
                            var hours = []
                            let end = this.state.end ? moment(this.state.end, dateFormat).format(dateFormat) : null
                            let atlend = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
                            if(end && atlend && end === atlend){
                              for(var i = 23; i > moment().tz(this.state.timezone).hour(); i--){
                                hours.push(i)
                              }
                            }
                            return hours
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                      <div>
                        <Form.Item className='mb-0'>
                          <label className='custom-label'>Sort By</label>
                          <Select
                            value={this.state.sort}
                            size='small'
                            placeholder='Sort By'
                            className='text-center'
                            onChange={(e) => this.setState({ sort: e }, () => this.sort(this.state.flightData, false))}
                          >
                            <Option value='dept_time'>Dept Time</Option>
                            <Option value='ac_type'>AC Type</Option>
                            <Option value='notes_date'>New Notes</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={17} lg={17} flex={'480px'} className='pr-0'>
                      <div className='mt-1'>
                        <label className='custom-label transparent'>-</label>
                        <Checkbox
                          checked={this.state.amberFlightsCheck}
                          onChange={(e) => this.setState({ amberFlightsCheck: e.target.checked }, () => this.departedFlightsFilter())}
                        >
                            Show Payload Critical Flights
                        </Checkbox>
                        <Checkbox
                          checked={this.state.redFlightsCheck}
                          onChange={(e) => this.setState({ redFlightsCheck: e.target.checked }, () => this.departedFlightsFilter())}
                        >
                            Show Build Conflicts
                        </Checkbox>
                        <Checkbox
                          checked={this.state.departedFlightsCheck}
                          onChange={(e) => this.setState({ departedFlightsCheck: e.target.checked }, () => this.departedFlightsFilter())}
                        >
                            Hide Departed Flights
                        </Checkbox>
                      </div>
                    </Col>
                    <Col flex={'30px'} className='text-center ml-auto'>
                      <label className='custom-label transparent'>-</label>
                      <Button
                        type='ghost'
                        className='image-btn'
                        onClick={() => {
                          this.clearEditFlightForm()
                          this.clearEditAllocForm()
                          this.clearAllocTableForm()
                          this.setState({ loadplanvisible: true })
                        }}
                      >
                        <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                      </Button>
                      {/* <Switch
                        checked={this.state.hideMD}
                        checkedChildren='Hide MD'
                        unCheckedChildren='Show MD'
                        onChange={(e) => this.setState({ hideMD: e }) }
                      /> */}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='table-overflow'
                >
                  <div className='table-outer'>
                    <Table
                      className='custom-table w-100 multi-head fc-table'
                      dataSource={this.state.departedFlightsCheck ||this.state.amberFlightsCheck ||this.state.redFlightsCheck? this.state.displayData : this.state.flightData}
                      pagination={false}
                    >
                      <Column
                        title= 'Flight #'
                        dataIndex=''
                        key= 'flight_no'
                        className='space-nowrap'
                        render= {(_, record) => (
                          <span>{record?.flight_no ? record.flight_no : 'N/A'}</span>
                        )}
                      />
                      <Column
                        title= 'Flight Date'
                        dataIndex=''
                        key= 'flight_date'
                        className='space-nowrap'
                        render= {(_, record) => (
                          <span>{record?.flight_date ?this.checkFlightDate(record) :
                            record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone).format(dateOnly)?.toUpperCase() :
                              record?.schdl ? moment.tz(record.schdl, this.state.timezone).format(dateOnly)?.toUpperCase() :
                                'N/A'}</span>
                        )}
                      />
                      <Column
                        title= 'AC'
                        dataIndex= ''
                        key= 'ac_type'
                        render= {(_, record) => (
                          <span>{record?.ac_type ? record.ac_type : 'N/A'}</span>
                        )}
                      />
                      <Column
                        title= 'Routing'
                        dataIndex= ''
                        key= 'routing'
                        render= {(_, record) => (
                          <span>{record?.org && record?.dest ? `${record.org.origin}-${record.dest.arriv}` : 'N/A'}</span>
                        )}
                      />
                      <Column
                        title= {`Dept Time (in ${this.state.ext})`}
                        dataIndex= ''
                        key= 'dept_time'
                        render= {(_, record) => (
                          <Space className=''>
                            <span className='px-1 fs-10 pa-badge'>{record?.dept_prefix ? record?.dept_prefix : ''}</span>
                            <span>{record?.dept_time ? `${moment.tz(record.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` : 'N/A'}</span>
                          </Space>
                        )}
                      />
                      <Column
                        title= 'P CAP/FCST'
                        dataIndex= 'cap_fcst'
                        key= 'cap_fcst'
                        render= {(_, record) => (
                          <span>{`${record?.pax_cap}/${record?.fcst || 0}`}</span>
                        )}
                      />
                      <ColumnGroup title='Bag Posns' align='center'>
                        {/* <Column
                          title= 'PMC'
                          dataIndex= 'bag_posns'
                          key= 'bag_posns'
                          className='psn'
                          render= {(_, record) => (
                            <span>{record?.bag?.p96 || 0}</span>
                          )}
                        />
                        <Column
                          title= 'PAG'
                          dataIndex= 'bag_posns'
                          key= 'bag_posns'
                          className='psn'
                          render= {(_, record) => (
                            <span>{record?.bag?.p88 || 0}</span>
                          )}
                        /> */}
                        <Column
                          title= 'LD3'
                          dataIndex= 'bag_posns'
                          key= 'bag_posns'
                          className='psn'
                          render= {(_, record) => (
                            <span>{record?.bag?.ld3 || 0}</span>
                          )}
                        />
                        <Column
                          title= 'LD2'
                          dataIndex= 'bag_posns'
                          key= 'bag_posns'
                          // width='70px'
                          align= 'left'
                          className='psn'
                          render= {(_, record) => (
                            <span>{record?.bag?.ld2 || 0}</span>
                          )}
                        />
                      </ColumnGroup>
                      <ColumnGroup title='Optimal Cargo Build' align='center' className=''>
                        <Column width={20}></Column>
                        {this.state.hideMD ? null : <Column
                          title= 'MD'
                          dataIndex= ''
                          key= ''
                          className='psn op-build'
                          render= {(_, record) => (
                            <span>{record?.cargo?.md || 0}</span>
                          )}
                        />}
                        <Column
                          title= 'PMC'
                          dataIndex= ''
                          key= ''
                          className='psn op-build'
                          render= {(_, record) => (
                            <span>{record?.cargo?.p96 || 0}</span>
                          )}
                        />
                        <Column
                          title= 'PAG'
                          dataIndex= ''
                          key= ''
                          className='psn op-build'
                          render= {(_, record) => (
                            <span>{record?.cargo?.p88 || 0}</span>
                          )}
                        />
                        <Column
                          title= 'LD3'
                          dataIndex= ''
                          key= ''
                          className='psn op-build'
                          render= {(_, record) => (
                            <span>{record?.cargo?.ld3 || 0}</span>
                          )}
                        />
                        <Column
                          title= 'LD2'
                          dataIndex= ''
                          key= ''
                          className='psn op-build'
                          render= {(_, record) => (
                            <span>{record?.cargo?.ld2 || 0}</span>
                          )}
                        />
                      </ColumnGroup>
                      <Column
                        title= 'Bkd + Asd'
                        dataIndex= 'booked'
                        key= 'booked'
                        render= {(_, record) => (
                          <span>{this.state.weight_unit === 'KG' && record?.booked_kg ? `${formatWeight(record?.booked_kg)}KG` :
                            this.state.weight_unit === 'LB' && record?.booked_lbs ? `${formatWeight(record?.booked_lbs)}LB` :
                              'N/A'}</span>
                        )}
                      />
                      <Column
                        title= 'Ready'
                        dataIndex= 'built_up'
                        key= 'built_up'
                        align= 'center'
                        render= {(_, record) => (
                          <span>{this.state.weight_unit === 'KG' && record?.ready_kg ? `${formatWeight(record?.ready_kg)}KG` :
                            this.state.weight_unit === 'LB' && record?.ready_lbs ? `${formatWeight(record?.ready_lbs)}LB` :
                              '-'}</span>
                        )}
                      />
                      <Column
                        title= 'At Gate'
                        dataIndex= 'at_gate'
                        key= 'at_gate'
                        render= {(_, record) => (
                          <span>{this.state.weight_unit === 'KG' && record?.gate_kg ? `${formatWeight(record?.gate_kg)}KG` :
                            this.state.weight_unit === 'LB' && record?.gate_lbs ? `${formatWeight(record?.gate_lbs)}LB` :
                              '-'}</span>
                        )}
                      />
                      <Column
                        title= 'FPE'
                        dataIndex= 'dept_time'
                        key= 'dept_time'
                        render= {(_, record) => (
                          <span className='countdown'>{record?.time_to_dept ? <Countdown value={moment(record.time_to_dept)} format='HH:mm' /> : 'N/A'}</span>
                        )}
                      />
                      <Column
                        title= 'BU PRIO'
                        dataIndex= ''
                        key= 'priority'
                        align= 'center'
                        render= {(_, record) => (
                          record?.bu_prio ? <CheckOutlined /> : <CloseOutlined />
                        )}
                      />
                      <Column
                        title= 'Actions'
                        dataIndex= 'action'
                        key= 'action'
                        render= {(_, record) =>
                          <div className='action-column'>
                            <Tooltip title='Cargo Capacity' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                              <Button
                                className={record.cccolour_css}
                                onClick={() => this.setEdit(record)}
                              >
                                CC
                              </Button>
                            </Tooltip>
                            <Badge size='small' count={record?.notes || 0} color={'#0a1227'}>
                              <Tooltip
                                title='Notes'
                                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                                onClick={() => this.showFlightNotes(record)}
                              >
                                <img src={documentIcon} alt='document' />
                              </Tooltip>
                            </Badge>
                          </div>
                        }
                      />
                    </Table>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Modal
          title= {this.state.notesTitle}
          visible={this.state.flightNotes}
          onCancel={() => this.setState({
            flightNotes: false,
            notesTitle: ''
          })}
          footer={false}
          width={'80%'}
          centered
          className='custom-modal pb-0'
          destroyOnClose={true}
        >
          <Notes search={this.state.searchString} />
        </Modal>
      </div>
    )
  }
}

export default FlightControl