import React, { Component } from 'react'
import { Button, Input, Row, Col, Typography, Form, Card, Table, Tooltip,/* Radio,*/ Popconfirm, notification } from 'antd'
// import { SaveOutlined } from '@ant-design/icons'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants, { errormsg, systemParameterTabs as titles }  from '../../../constants/constants'
import Http from '../../../utils/http'
import { cloneDeep } from 'lodash'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
import './tabs.css'

const { Title } = Typography
const { Search } = Input

class HauloutAreaMapping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      station: getCookie(COOKIE.STATION),
      user_id: getCookie(COOKIE.ID),
      timezone: getCookie(COOKIE.TIMEZONE),
      loading: false,
      workAreasList: [],
      areaList: [],
      workAreasProductsList: [],
      workAreasLocationsList: [],
      search: '',
      exceptionRow: null,
      only_booked: false,
      red_locations: '',
      amber_locations: '',
      system_details: [],

      selworkArea: null,
      filterWorkArea: null,
      area_id: null,

      area_name: '',
      id_wa: null,
      isWAEdit: false,
      waEditRecord: null,
      editArea_name: null,

      p_code: '',
      transfer_type: 'Local',
      dom_intl: 'DOM',
      id_wap: null,
      isWAPEdit: false,
      prodWorkArea: '',
      wapEditRecord :null,
      editProdWorkArea: null,
      editP_code: null,
      editTransfer_type: '',
      editDom_intl: '',
      editprodArea_id: null,

      locations: '',
      id_wal: null,
      isWALEdit: false,
      locWorkArea: '',
      walEditRecord :null,
      editLocations: null,
      editLocWorkArea: null,
      editlocArea_id: null,
      editlocArea_name: null,
      editlocArea_location_group: null,

      duplicate: null,
      pduplicate: null,
      lduplicate: null,
      eduplicate: null,

      selectedArea: '',
      isEdit: false,

      showExceptionsModal: false,
      exceptionList: [],
      exceptionName: null,
      exceptionValue: null,
      exceptionType: 'Local',
      exceptionDomIntl: 'DOM',
      exceptionWorkArea: null,
      exceptionProductID: null,

      editExceptionRecord: null,
      editExceptionName: null,
      editExceptionValue: null,
      editExceptionType: null,
      editExceptionDomIntl: null,
      editExceptionWorkArea: null,
    }
  }

  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${this.state.station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'HAULOUT_WORK_AREA'){
          this.fetchWorkAreas()
        }
        if(parsedData.TYPE === 'HAULOUT_WORK_AREA_LOCATION'){
          this.fetchWorkAreaLocations()
        }
        // if(parsedData.TYPE === 'HAULOUT_WORK_AREA_EXCEPTIONS'){
        //   this.fetchExceptionList()
        // }
      }
    }
    this.fetchWorkAreas()
    this.fetchWorkAreaLocations()
    this.fetchAmberValue()
  }
  //FETCH HANDLING DATA
  fetchAmberValue = () => {
    Http.get(`/station_details?station=${this.state.station}`)
      .then((data) => {
        if(data?.data?.data?.length>0) {
          this.setState({
            system_details: data.data.data,
            only_booked: data.data.data[0]?.details?.onlybooked_haulout, //boolean
            red_locations: data.data.data[0]?.details?.red_locations_haulout || '', //comma seperated string
            amber_locations: data.data.data[0]?.details?.amber_locations_haulout || '', //comma seperated string
          })
        }
      })
  }

  fetchWorkAreas = () => {
    let headers = {
      station: this.state.station,
      module: 'haulout'
    }
    Http.get('/work_areas', { headers: headers })
      .then((data) => {
        if(data && data?.data && data?.data?.data?.length>0){
          let areaList = []
          data.data?.data?.map(d => {
            d.id = d?.ids?.[0]
            areaList.push({
              label: d.area_name,
              value: d.id,
            })
          })
          areaList = areaList.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.value === thing.value
            ))
          )
          this.setState({
            workAreasList : data.data.data,
            areaList: areaList,
          },()=>{
            let found = false
            if(this.state.selworkArea){
              found = this.state.workAreasList.some(obj=> obj.id === this.state.selworkArea.id)
              if(!found){
                this.setState({ selworkArea: null },()=>{
                  this.clearForm('work_areas')
                })
              }
              else {
                this.clearForm('work_areas')
              }
            } else {
              this.clearForm('work_areas')
            }
          })
        } else {
          this.setState({
            workAreasList : [],
            areaList: [],
            selworkArea: null
          },()=>{
            this.clearForm('work_areas')
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: 'Work Areas '+data.data.message,
            })})
        }
      })
  }
  fetchWorkAreaLocations = () => {
    let url = '/work_arealocations'
    // if(this.state.filterWorkArea)
    //   url= url+'?area_id='+this.state.filterWorkArea
    if(this.state.selworkArea?.id)
      url= url+'?area_name='+this.state.selworkArea.area_name

    let headers = {
      search: this.state.search,
      station: this.state.station,
      module: 'haulout'
    }
    Http.get(url,{ headers })
      .then((data) => {
        if(data && data.data && data.data.data && data.data.data.length>0){
          data.data?.data?.map(d => { d.id = d?.ids?.[0] })
          this.setState({ workAreasLocationsList : data.data.data })
        } else {
          if(this.state.search){
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
          this.setState({ workAreasLocationsList : [] })
        }
      })
  }
  changeFilter = (e, type) => {
    if(type === 'location_area'){
      this.setState({ filterWorkArea: e || [], },()=>this.fetchWorkAreaLocations())
    }
  }
  editHandler = (record, type) =>{
    if(type === 'work_areas'){
      this.setState({
        isWAEdit: true,
        area_name: record.area_name,
        id_wa: record.id
      })
    }
    else if(type === 'work_area_locations'){
      this.setState({
        isWALEdit: true,
        locations: record.details.location,
        id_wal: record.id,
        locWorkArea: record.station+' / '+record.work_area.area_name,
        area_id: record.area_id
      })
    }
  }

  addWorkAreas = () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.haulout_area_mapping + '_workarea',
    }
    //eslint-disable-next-line
    let formdata= {
      station: this.state.station,
      area_name: this.state.area_name,
      module: 'haulout'
    }
    Http.post('/work_area', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            area_name: '',
            duplicate: null,
          },()=>{
            this.fetchWorkAreas()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  updateWorkAreas = () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.haulout_area_mapping + '_workarea'
    }
    let formdata= {
      area_name: this.state.editArea_name,
      station: this.state.station,
      module: 'haulout'
    }
    Http.put('/work_area/'+this.state.waEditRecord.id, formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearTableForm()
          this.fetchWorkAreas()
          this.fetchWorkAreaLocations()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          var tempData = data && data.data && data.data.data ? data.data.data : null
          this.setState({ duplicate: tempData },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  addWorkAreaLocations  = () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.haulout_area_mapping + '_workarealocations'
    }
    if(!this.state.selworkArea || !this.state.selworkArea.id){
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: errormsg.sel_work_area,
      })
    }
    if(this.state.selworkArea && this.state.selworkArea.id){
      let formdata= {
        area_name: this.state.selworkArea.area_name,
        station: this.state.station,
        location: this.state.locations,
        module: 'haulout'
      }
      Http.post('/work_arealocation', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              location: '',
              lduplicate: null,
            },()=>{
              this.clearForm('work_area_locations')
              if(this.state?.selworkArea?.area_name)
                this.setState({ locWorkArea: this.state.station+' / '+this.state.selworkArea.area_name })
              this.fetchWorkAreaLocations()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            this.setState({ lduplicate:data?.data?.data ? data.data.data[0] : null },()=>setTimeout(() => {this.setState({ lduplicate: null })}, 4500))
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  updateWorkAreaLocations = () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.haulout_area_mapping + '_workarealocations'
    }
    let formdata= {
      location: this.state.editLocations,
      station: this.state.station,
      area_name: this.state.editlocArea_name,
      location_group: this.state.editlocArea_location_group,
      module: 'haulout'
    }
    Http.put('/work_arealocation', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            locations: '',
            id_wal: null,
            isWALEdit: false,
            locWorkArea: '',
            lduplicate: null,
          },()=>{
            if(this.state?.selworkArea?.area_name)
              this.setState({ locWorkArea: this.state.station+' / '+this.state.selworkArea.area_name })
            this.clearTableForm()
            this.fetchWorkAreaLocations()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          this.setState({ lduplicate:data?.data?.data ? data.data.data[0] : null },()=>setTimeout(() => {this.setState({ lduplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  deleteHandler = (record, type) => {
    let title = type === 'work_areas' ? '_workarea' : type === 'work_area_locations' ? '_workarealocations' : ''
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.haulout_area_mapping + title
    }
    if(type === 'work_areas'){
      let formdata= {
        is_delete: true,
        station: this.state.station,
        area_name: record.area_name,
        module: 'haulout'
      }
      Http.put('/work_area/'+record.id, formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              area_name: '',
              id_wa: null,
              isWAEdit: false,
            },()=>{
              // this.clearForm('work_areas')
              this.fetchWorkAreas()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    } else if (type === 'work_area_locations') {
      let formdata = {
        ids: [...record.ids],
        area_name: record.area_name,
        station: this.state.station,
        location_group: record.location_group,
        module: 'haulout'
      }
      Http.post('/work_arealocation_del', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              locations: '',
              id_wal: null,
              isWALEdit: false,
              locWorkArea: '',
            },()=>{
              this.clearForm('work_area_locations')
              this.fetchWorkAreaLocations()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  clearForm = (type) =>{
    if(!type || type === 'work_areas'){
      this.setState({
        area_name: '',
        id_wa: null,
        isWAEdit: false,

        p_code: '',
        transfer_type: 'Local',
        dom_intl: 'DOM',
        id_wap: null,
        isWAPEdit: false,
        //prodWorkArea: 'ATL /',

        locations: '',
        id_wal: null,
        isWALEdit: false,
        //locationWorkArea: 'ATL /',
      })
      if(this.state.selworkArea && this.state.selworkArea.area_name)
        this.setState({
          prodWorkArea: this.state.station+' / '+this.state.selworkArea.area_name,
          locWorkArea: this.state.station+' / '+this.state.selworkArea.area_name
        })
      else {
        this.setState({
          prodWorkArea: '',
          locWorkArea: ''
        })
      }
    }
    if(!type || type === 'work_area_locations'){
      this.setState({
        locations: '',
        id_wal: null,
        isWALEdit: false,
        //locationWorkArea: 'ATL /',
      })
      if(this.state.selworkArea && this.state.selworkArea.area_name)
        this.setState({ locWorkArea: this.state.station+' / '+this.state.selworkArea.area_name })
    }

  }

  editTableForm = (record, type) => {
    if( type === 'work_areas' ){
      this.setState({
        waEditRecord: record,
        editArea_name: record.area_name,
      })
    }
    if(type === 'work_area_locations'){
      this.setState({
        walEditRecord :record,
        editLocations: record.location,
        editLocWorkArea: record.station+' / '+record.area_name,
        editlocArea_id: record.id,
        editlocArea_name: record.area_name,
        editlocArea_location_group: record.location_group,
      })
    }
  }

  setSearchValue (e){
    this.setState({ search: e.target.value },()=>{
      if( !e.target.value || e.target.value === null || e.target.value ==='' ){
        this.fetchWorkAreaLocations()
      }
    })
  }

  locationColumnSearch = () => ({
    filterDropdown: () => (
      <div />
    ),
    filterIcon: () =>
      <Form.Item label='' labelCol={{
        lg: { span: 24 },
        xl: { span: 0 },
      }} wrapperCol={{
        lg: { span: 24 },
        xl: { span: 24 },
      }}
      className='w-100 mb-0'
      >
        <Search
          placeholder='Search'
          allowClear
          // enterButton='Search'
          size='small'
          onChange={(e) => this.setSearchValue(e)}
          onSearch={(e)=> !e || e === null || e ==='' ? null : this.fetchWorkAreaLocations()}
          className='search-field w-100 br-input colheader-search'
        />
      </Form.Item>
  })

  clearTableForm = (type) => {
    if( !type || type === 'work_areas'){
      this.setState({
        waEditRecord: null,
        editArea_name: null,
      })
    }
    if(!type || type === 'work_area_locations'){
      this.setState({
        walEditRecord :null,
        editLocations: null,
        editLocWorkArea: null,
        editlocArea_id: null,
        editlocArea_name: null,
        editlocArea_location_group: null,
      })
    }
  }
  saveAmberValue = async () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.haulout_area_mapping
    }
    let formdata = {
      station: this.state.station,
      onlybooked_haulout: this.state.only_booked, //boolean
      red_locations_haulout: this.state.red_locations, //comma seperated string
      amber_locations_haulout: this.state.amber_locations, //comma seperated string
      module: 'haulout'
    }
    if(this.state.system_details?.length > 0) {
      Http.put(`/station_detail/${this.state.system_details[0]?.id}`, formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.fetchAmberValue()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    } else {
      Http.post('/station_detail', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.fetchAmberValue()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  render() {

    const hauloutWorkAreaCol = [
      {
        title: 'Work Area',
        key: 'workarea',
        render: (string, record) =>(
          this.state.waEditRecord && this.state.waEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editArea_name}
              onChange={(e) => this.setState({ editArea_name: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record.area_name? record.area_name : '-' }
            </span>
        )
      },
      {
        title: 'Action',
        // dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (string, record) =>
          <div>
            { this.state.waEditRecord && this.state.waEditRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateWorkAreas('work_areas')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm('work_areas')} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record, 'work_areas')} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandler(record, 'work_areas')} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    const hauloutLocationCol = [
      {
        title: 'Work Area',
        key: 'workarea',
        width: '30%',
        render: (text, record) => (
          this.state.walEditRecord && this.state.walEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              disabled
              value={this.state.editLocWorkArea}
            />
            :
            <span className={record.id === this.state.lduplicate?.id ?'red-border': ''}>{
              record && record.area_name ? record.area_name  : '-'}
            </span>
        )
      },
      {
        title: 'Location(s)',
        key: 'location',
        width: '60%',
        className:'table-header-search',
        render: (text, record) => (
          this.state.walEditRecord && this.state.walEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editLocations}
              onChange={(e) => this.setState({ editLocations: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.lduplicate?.id ?'red-border': ''}>{
              record && record.location ? record.location.split(',').join(', ') : '-'}
            </span>
        ),
        ...this.locationColumnSearch(),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: '10%',
        key: 'action',
        align: 'center',
        render: (string, record) =>
          <div>
            { this.state.walEditRecord && this.state.walEditRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateWorkAreaLocations('work_area_locations')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm('work_area_locations')} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record, 'work_area_locations')} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandler(record, 'work_area_locations')} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    // rowSelection object indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.clearForm('work_areas')
        this.setState({
          selworkArea: cloneDeep(selectedRows[0]),
          prodWorkArea: this.state.station+' / '+cloneDeep(selectedRows[0]).area_name,
          locWorkArea: this.state.station+' / '+cloneDeep(selectedRows[0]).area_name
        },()=>{
          this.fetchWorkAreaLocations()
        }
        )
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      })
    }


    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          {/* <Col xs={24} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>General station specific settings | Haulout | {this.state.station || ''}</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={24} lg={12} xl={9} className=''>
                        <Form.Item label=''>
                          <Radio.Group className='f-spacebtw mt-4' onChange={(e) => this.setState({ only_booked: e.target.value })} value={this.state.only_booked}>
                            <Radio value={false}>Display booked and assigned cargo for haulout lists</Radio>
                            <Radio value={true}>Display only booked cargo on haulout lists</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={6} xl={7} className=''>
                        <Form.Item label='Enter locations to be displayed in red Comma Separated'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.red_locations}
                            onChange={(e) => this.setState({ red_locations: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={6} xl={7} className=''>
                        <Form.Item label='Enter locations to be displayed in amber Comma Separated'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.amber_locations}
                            onChange={(e) => this.setState({ amber_locations: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col className='ml-auto'>
                        <label className='custom-label transparent d-block'>-</label>
                        <Button className='custom-icon-button ml-auto mt-5px' icon={<SaveOutlined />} onClick={this.saveAmberValue}></Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col> */}

          <Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Work Areas</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} flex='auto' className=''>
                        <Form.Item label='Work Area Name'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.area_name}
                            onChange={(e) => this.setState({ area_name: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWAEdit ? this.updateWorkAreas() : this.addWorkAreas()}>
                          <Tooltip title={this.state.isWAEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      rowKey='id'
                      className='custom-table w-100 haul-table'
                      columns={hauloutWorkAreaCol}
                      dataSource={this.state.workAreasList}
                      pagination={false}
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={16} xl={16} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Locations to work area mapping</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={8} lg={9} xl={10} xxl={8} className=''>
                        <Form.Item label='Station / Work Area'>
                          <Input
                            size='small'
                            placeholder=''
                            disabled
                            value={this.state.prodWorkArea}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={14} lg={14} xl={10} xxl={14} flex='auto' className=''>
                        <Form.Item label='Enter up to 10 locations comma separated'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.locations}
                            onChange={(e) => this.setState({ locations: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWALEdit ? this.updateWorkAreaLocations() : this.addWorkAreaLocations()} >
                          <Tooltip title={this.state.isWALEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table w-100 haul-table'
                      columns={hauloutLocationCol}
                      dataSource={this.state.workAreasLocationsList}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default HauloutAreaMapping
