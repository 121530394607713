/* eslint-disable react/react-in-jsx-scope */
export const constants = {
  DeleteText: 'Are you sure to delete this record?',
  Dashboard: 'Dashboard',
  SERVER_CONNECTION_ERROR: 'Failed to connect to server',
  NO_DATA_ERROR: 'No Flights Available',
  NO_DATA_AVAILABLE: 'No Data Available'
}

export const systemParameterTabs = {
  fleet: 'fleet',
  recovery_action: 'recovery_action',
  rebooking_teams_sla: 'rebooking_teams_sla',
  dynamic_info: 'dynamic_info',
  breakdown_work_area_mapping: 'breakdown_work_area_mapping',
  product_buildup_priority: 'product_buildup_priority',
  handling_times: 'handling_times',
  grouping: 'grouping',
  buildup_area_mapping: 'buildup_area_mapping',
  uld_mapping: 'uld_mapping',
  haulout_area_mapping: 'haulout_area_mapping',
}

export const pages = {
  INBOUND: 'Inbound Flight Preparation',
  inbound_flight_prep: 'Inbound Flight Preparation',
  BREAKDOWN: 'Breakdown Control',
  breakdown_control: 'Breakdown Control',
  REBOOKING: 'Rebooking Queue',
  rebooking_queue: 'Rebooking Queue',
  BUILDUP: 'Build Up Control',
  buildup_control: 'Build Up Control',
  buildup_dashboard: 'Build Up Dashboard',
  recovery_dashboard: 'Recovery Dashboard',
  flight_capacity_planning: 'Flight Capacity Planning',
  haulout_dashboard: 'Haulout Dashboard'
}

export const rebookingQueueTabs = {
  pending: 'Pending',
  completed: 'Completed',
  successful: 'Successful',
}

export const gatekeeperTabs = {
  processed: 'Last Processed Messages',
  search: 'Search Messages',
  performance: 'Performance info',
  audit_trail: 'Audit Trail',
}

export const changeLogTabs = {
  admin_logs: 'Admin Log',
  system_logs: 'System Log',
}

export const developerDomainTabs = {
  clear_data: 'Clear Data',
  unprocessed_msgs: 'Unprocessed Messages'
}

export const messageInterfaceTabs = {
  airline_msg: 'Airline Messages',
  steps: 'Steps'
}

export const events = {
  FFM_OUT_RECEIVED: 'FFM',
  FFM_RECEIVED: 'FFM Received',
  FLIGHT_EVENT: 'Flight Event',
  AWB_UPDATED: 'AWB UPDATED',
  ULD_UPDATED: 'ULD UPDATE',
  BOOKING_MESSAGE: 'ECIBOOK',
  FSU: 'FSU',
  SEGMENT: 'SEGMENT',
  SYS_PARAMS: 'SYSTEM_PARAMETERS',
  RETURN: 'RETURN',
  CANCEL: 'CANCEL',
}

export const station =[
  {
    label: 'ATL',
    value: 'ATL'
  },
  {
    label: 'LAX',
    value: 'LAX'
  },
  {
    label: 'BOS',
    value: 'BOS'
  },
  {
    label: 'JFK',
    value: 'JFK'
  },
  {
    label: 'SEA',
    value: 'SEA'
  },
  {
    label: 'DTW',
    value: 'DTW'
  },
  {
    label: 'MSP',
    value: 'MSP'
  },
  {
    label: 'SLC',
    value: 'SLC'
  },
  {
    label: 'ORD',
    value: 'ORD'
  },
  {
    label: 'HNL',
    value: 'HNL'
  },
  {
    label: 'SFO',
    value: 'SFO'
  }
]
export const stationTime ={
  ATL: '-0500',
  LAX: '-0800',
  BOS: '-0500',
  JFK: '-0500',
  SEA: '-0800',
  DTW: '-0500',
  MSP: '-0600',
  SLC: '-0700',
  ORD: '-0600',
  HNL: '-1000',
  SFO: '-0800',
}

export const timezones = {
  ATL: 'America/New_York',
  LAX: 'America/Los_Angeles',
  BOS: 'America/New_York',
  JFK: 'America/New_York',
  SEA: 'America/Los_Angeles',
  DTW: 'America/New_York',
  MSP: 'America/Chicago',
  SLC: 'America/Denver',
  ORD: 'America/Chicago',
  HNL: 'Pacific/Honolulu',
  SFO: 'America/Los_Angeles',
}

export const timezone = [
  {
    value: 'ATL',
    label: 'ATL',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'LAX',
    label: 'LAX',
    zone: 'America/Los_Angeles',
    ext: 'PST',
  },
  {
    value: 'BOS',
    label: 'BOS',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'JFK',
    label: 'JFK',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'SEA',
    label: 'SEA',
    zone: 'America/Los_Angeles',
    ext: 'PST',
  },
  {
    value: 'DTW',
    label: 'DTW',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'MSP',
    label: 'MSP',
    zone: 'America/Chicago',
    ext: 'CST',
  },
  {
    value: 'SLC',
    label: 'SLC',
    zone: 'America/Denver',
    ext: 'MST',
  },
  {
    value: 'ORD',
    label: 'ORD',
    zone: 'America/Chicago',
    ext: 'CST',
  },
  {
    value: 'HNL',
    label: 'HNL',
    zone: 'America/Honolulu',
    ext: 'HST',
  },
  {
    value: 'SFO',
    label: 'SFO',
    zone: 'America/Los_Angeles',
    ext: 'PST',
  },
]

export const department =[
  {
    label: 'Dept1',
    value: 'Dept1'
  },
  {
    label: 'Dept2',
    value: 'Dept2'
  },
]

export const roles = [
  {
    key: 'SuperAdmin',
    value: 'Super Admin'
  },
  {
    key: 'StationLeader',
    value: 'Station Leader'
  },
  {
    key: 'RebookingTeam',
    value: 'Re-booking Team',
  },
  {
    key: 'User',
    value: 'User',
  },
  {
    key: 'WarehouseTV',
    value: 'Warehouse TV',
  },
]

export const pagelist = [
  {
    key : '1',
    link : '/gatekeeper',
    title: 'Gatekeeper',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader'],
    is_menu: true
  },
  {
    key : '2',
    link : '/system-parameter',
    title: 'System Parameters',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader'],
    is_menu: true
  },
  {
    key : '3',
    link : '/notes',
    title: 'Notes',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'Re-booking Team', 'User'],
    is_menu: true
  },
  {
    key : '4',
    link : '/inbound-flight',
    title: 'Inbound Flight Preparation',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '5',
    link : '/breakdown-control',
    title: 'Breakdown Control',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '6',
    link : '/rebooking-queue',
    title: 'Rebooking Queue',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'Re-booking Team', 'User'],
    is_menu: true
  },
  {
    key : '7',
    link : '/manage-users',
    title: 'Manage Users',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader'],
    is_menu: true
  },
  {
    key : '8',
    link : '/flight-capacity-planning',
    title: 'Flight Capacity Planning',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '9',
    link : '/buildup-control',
    title: 'Buildup Control',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '10',
    link : '/breakdown-dashboard',
    title: 'Breakdown Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '11',
    link : '/recovery-dashboard',
    title: 'Recovery Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '12',
    link : '/buildup-dashboard',
    title: 'Buildup Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '13',
    link : '/haulout-dashboard',
    title: 'Haulout Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '14',
    link : '/reports',
    title: 'Reports',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '15',
    link : '/demo',
    title: 'Message Interface',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
]

export const errormsg = { sel_work_area: 'Select Work Area' }

export const jsonTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#0000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

export const changeLogLabels = {
  //FLEET
  ac_code: 'AC Code',
  aft_conf: 'Aft Hold Config',
  key: 'Aft Hold Config Key',
  name: 'Attachment',
  path: 'Aft Hold Config Path',
  alwd_uld_type: 'Allowed ULDs',
  cont_type: 'Cont Type',
  fwd_conf: 'Fwd Hold Config',
  gen_info: 'Gen Info',
  is_loose: 'Loose',
  is_uld: 'ULD',
  pax_cap: 'Pax Cap',
  //REBOOKING TEAMS & SLA
  q_priority: 'Queue Priority',
  rebook_team: 'Rebooking Team',
  sla_time: 'SLA',
  //RECOVERY ACTION
  action: 'Recovery Action',
  email: 'Email',
  is_breakdown: 'Breakdown',
  is_buildup: 'Buildup',
  is_gen_rebook: 'Gen Rebook',
  is_inbound: 'Inbound',
  is_notify: 'Notify',
  q_to: 'Queue to',
  station_limit: 'Limit to Station',
  recovery_teams_id: 'Recovery Teams ID',
  //DYNAMIC INFO
  ac_type: 'AC Type',
  attach_conf: 'Attachment',
  contry_code: 'Country Code',
  enddate: 'End Date',
  flightno: 'Flight #',
  prod_code: 'Prod Code',
  remarks: 'Remarks',
  spl_code: 'Special Codes',
  startdate: 'Start Date',
  uld_type: 'ULD Type',
  station: 'Station',
  //PWAM
  area_name: 'Work Area Name',
  area_id: 'Area ID',
  p_code: 'Product Code',
  transfer_type: 'Transfer Type',
  location: 'Location',
  //BUP
  priority: 'Priority',
  //HANDLING TIMES
  ac_load: 'AC LOAD',
  ac_offld: 'AC OFFLD',
  at_gate: 'AT GATE',
  bd_store: 'BD & STORE',
  build_up: 'BUILD UP',
  exp_acpt: 'ACCEPT',
  fpe: 'FPE',
  mvt: 'MVT',
  process: 'PROCESS',
  tfr_to_wh: 'TFR TO WH',
  trf_to_ac: 'TFR TO AC',
  indicator_time: 'Amber Indicator',
  //COMMON
  updatedAt: 'Updated At',
  //INBOUND
  uld_dispo: 'ULD Dispo',
  deliver_to: 'Deliver To',
  //REBOOKING
  reason: 'Reason',
  status: 'Status',
  rebook_req_datetime: 'Rebooking Requested',
  rebook_comp_datetime: 'Rebooking Completed',
  rebook_undo_datetime: 'Undo Rebooking',
}

export const stepDetails = {
  ssim: 'SSIM for Incoming Flights.',
  // eslint-disable-next-line react/react-in-jsx-scope
  inbound_start: <>Flight Manifest : YY2541, YY7000M, YY2035, YY980, YY726
    <ul className='mt-1'><li>YY2541 <br />(111-71051680,111-71063451,111-71023120)</li>
      <li>YY1263 <br />(111-71028425,111-71028042,111-71027946,111-71026852,111-71026362)</li>
      <li>YY2035 <br />(111-71028705,111-71027003,111-71036324,111-71036232)</li></ul></>,
  inbound_booking: 'Booking for 111-71028705 moved from Unconfirmed to Confirmed.',
  inbound_flight_events: 'Flight event for YY980.',
  inbound_flight_completed: 'Awbs in YY7000M have been Checked In.',

  inbound_checkin: 'RCF message for 111-71051680, 111-71063451 & DQL16123YY.',
  inbound_dlv: '	DLV message to take 111-71063451 off the recovery dashboard.',
  inbound_move_bd: 'FPS message to move 111-71051680 & DQL16123YY to a breakdown location and removes from the recovery dashboard.',

  breakdown_start:'All inbound flights get arrived status, all awbs receive an RCF message which moves them to the breakdown screen.',
  breakdown_nfd: <><span>NFD processed for the below awbs and the same are taken off the breakdown control screen and dashboard.</span>
    <ul className='mt-1'>
      <li>111-71028425 </li>
      <li>111-71028042</li>
      <li>111-71027946</li>
      <li>111-71026852</li> 
      <li>111-71026362</li>
      <li>111-71036324</li>
      <li>111-71036232</li>
      <li>111-71029280</li></ul>
  </>,
  breakdown:<><span>Breakdown message sent which acts as a partial breakdown for the container DPL54418YY & also moves the below AWBs to the completed list.</span>
    <ul className='mt-1'>
      <li>DQL16123YY</li>
      <li>DPL53939YY</li>
      <li>DPL53552YY</li>
    </ul>
  </>,
  breakdown_move_bu: 'Sends an FPS message which moves the AWB (111-71023120) to the BUILDUP location 3BUILDUP.',
 
  buildup_start:'All transfer and export shipments are moved to the buildup screen.',
  buildup_build_uld: <>Builds 2 new ULDs (AKY14994YY & PMC95370YY) for the flight YY032 & Builds 2 new ULDs (AKE30837YY & AKY14569YY) for flight YY014.</>,
  buildup_build_cart:  'Builds a cart DPL53140# (111-71021215) for the flight YY1332L.', 
  buildup_asgn_flt: 'Assigns the AWB 111-61141640 from its booked flight YY014 to flight YY032.',
  buildup_pre: 'PRE received for ULDs AKY14994YY (111-72269422) & PMC95370YY (111-72246996) for flight YY032.	',

  haulout_start: <><span>Moves the below AWBs to the Haulout dashboard.</span>
    <ul className='mt-1'>
      <li>111-71028705</li>
      <li>PMC95370YY (111-72246996)</li>
      <li>AKY14994YY (111-72269422)</li>
      <li>DPL53140# (111-71021215)</li>
      <li>111-71051680</li>
      <li>111-71023120</li>
      <li>DPL54417YY</li>
      <li>DPL54921YY</li>
    </ul>
  </>,
  haulout_gt_mismatch: <><span>Moves the below AWBs to a gate location which does not match the outgoing flight gate.</span>
    <ul className='mt-1'>
      <li>PMC95370YY (111-72246996)</li>
      <li>AKY14994YY (111-72269422)</li>
      <li>111-71023120</li>
    </ul>
  </>,
  haulout_depart: 'Departs all flights and clears all dashboards.',
}


export default constants