import React, { Component } from 'react'
import { Row, Col, Typography, Card, Table, Spin, Button, Modal } from 'antd'
import HeaderSearch from '../../search/index'
import { jsonTheme } from '../../../constants/constants'
import JSONTree from 'react-json-tree'
import Http from '../../../utils/http'
import './index.css'
import moment from 'moment'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

let format = 'DDMMMYY HH:mm'
const { Title } = Typography

const PrettyPrintJson = ({ data }) => (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>)
class SearchMessages extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      loading: false,
      visible:false,
      record: null,
    }
  }

  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    //this.fetchData()
  }

  //FETCH HANDLING TIMES
  fetchData = async (search) => {
    this.setState({ loading: true })
    let url = search ? `gatekeeper/search?search=${search}` : 'gatekeeper'
    await Http.get(url, { headers: '' }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        let data = resp?.data?.data.map((item, index) => {
          return {
            ...item,
            key: index + 1
          }
        })
        this.setState({
          data: data,
          loading: false
        })
      } else {
        this.setState({
          data: [],
          loading: false
        })
      }
    }).catch(() => {
      this.setState({
        data: [],
        loading: false
      })
    })
  }

  getSearchResults = (URL) => {
    if(URL) {
      URL = URL.split('=')[1]
      this.fetchData(URL)
    } else this.setState({ data: [] })
  }

  viewAsJSON = () => {
    return (
      <Modal visible={this.state.visible} width={1000} onCancel={() => this.setState({ visible: false })} footer={null}>
        <PrettyPrintJson data={this.state.record} />
      </Modal>
    )
  }

  render() {
    let { data, loading } = this.state
    const columns = [
      {
        title: 'Sr.No.',
        dataIndex: 'key',
        key: 'key',
        width: '1%',
        className: 'space-nowrap',
      },
      {
        title: 'Type & Timestamp',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '1%',
        className: 'space-nowrap',
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        render: (_, record) => (
          <>
            <span>{record?.type}</span>
            <br />
            <span>{record?.createdAt ? moment(record.createdAt).format(format) + ' UTC' : 'N/A'}</span>
          </>
        )
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        width: '85%',
        className:'json-tree-td',
        render: (_, record) => (
          <>
            <JSONTree data={record?.msg} theme={jsonTheme} invertTheme={true} className='json-tree' />
          </>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        className: 'space-nowrap',
        render: (_, record) => (
          <>
            <Button type='link' onClick={() => {
              this.setState({
                visible: true,
                record: record?.msg
              })
            }}>View as JSON</Button>
          </>
        )
      },
    ]

    return(
      <div>
        {this.state.visible && this.viewAsJSON()}
        {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 24,
              lg: 24,
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
            },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100'>
              <Row
                gutter={[
                  {
                    xs: 0,
                    sm: 0,
                    md: 24,
                    lg: 24,
                  },
                  {
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24,
                  },
                ]}
                className='with-table'
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw header-messages'>
                  <Title level={5} className='mb-0'>Search Messages</Title>
                  <HeaderSearch placeholder='Search' className='position-static' searchResults={this.getSearchResults} page='GATEKEEPER' />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='table-overflow'
                >
                  <div className='table-outer'>
                    <Table
                      className='custom-table msg-table td-top'
                      dataSource={data}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SearchMessages