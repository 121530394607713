/* eslint-disable no-console */
import React, { Component } from 'react'
import Http from '../../../utils/http'
import JSONTree from 'react-json-tree'
import { jsonTheme } from '../../../constants/constants'
// import moment from 'moment'
import { Row, Col, Card, Table, notification } from 'antd'

// let format = 'DDMMMYY HH:mm:ss'

class UnprocessedMessages extends Component {
  constructor() {
    super()
    this.state = {
      startDate: '',
      endDate: '',
      displayData: [],
      filteredData:[],
      displayJson: '',
      search: '',
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  //FETCH UNPROCESSED MESSAGES
  fetchData = async () => {
    let headers = {
      // station: station,
      search: this.state.search
    }
    // let fromDate = moment(this.state.startDate).format('YYYY-MM-DD')
    // let toDate = moment(this.state.endDate).format('YYYY-MM-DD')
    // let url = `cache/failed?start=${fromDate}&end=${toDate}`
    let url = 'cache/failed'
    await Http.get(url, { headers }).then(resp => {
      if(resp && resp?.data && resp?.data?.data?.length > 0 ) {
        let data = resp?.data?.data.map(item => {
          return { ...item }
        })
        data.map((s) => {
          let valArr = []
          s.value.map(val => {
            let arr = JSON.parse(val)
            valArr.push(arr)
          })
          s.valArr = valArr
        })
        this.setState({ displayData: data })
        notification.destroy()
        notification.success({
          message: 'Success',
          description: resp?.data?.data.message,
        })
      }
      else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.data.message,
        })
      }
    }).catch(error => {
      console.log(error)
    })
  }

  // onChange = (date, dateString) => {
  //   console.log(date, dateString)
  //   let endDateq = moment(date).format('YYYY-MM-DD')
  //   this.setState({ endDate: endDateq })
  //   // console.log(this.state.endDate)
  //   // this.fetchData()
  // }

  render() {
    const columns = [
      {
        title: 'Awb Number',
        dataIndex: '',
        key: 'awb',
        width: '30%',
        render: (_, record) => (
          <>
            {record?.key ?  record?.key : ''}
          </>
        )
      },
      {
        title: 'Message',
        dataIndex: '',
        key: 'message',
        width: '30%',
        render: (_, record) => {
          return record?.value ? <JSONTree data={record.valArr} datatheme={jsonTheme} invertTheme={true} className='json-tree' /> : 'myJSON'
        }
      },
    ]
    return (
      <Card className='custom-card h-100'>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 12,
          lg: 12
        }, {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12
        },]} className=''>
          {/* <Col xs={24} sm={12} 
            md={{
              span: 7,
              offset: 5
            }} lg={{
              span: 7,
              offset: 5
            }} className=''>
            <Form.Item label='To'>
              <DatePicker
                size='small'
                onChange={(date) => this.setState({ startDate: date })}
                value={this.state.startDate}
                placeholder='Select Date'
              />
            </Form.Item>

          </Col>
          <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={7} className=''>
            <Form.Item label='To'>
              <DatePicker
                size='small'
                onChange={(date) => this.setState({ endDate: date })}
                value={this.state.endDate}
                placeholder='Select Date'
              />
            </Form.Item>
          </Col> */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className='table-overflow'
          >
            <div className='table-outer'>
              <Table
                className='custom-table unpr-msg-table'
                dataSource={this.state.displayData}
                columns={columns}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    )
  }
}
export default UnprocessedMessages
