import React, { Component } from 'react'
import {
  Row,
  Col,
  Card,
  Input,
  DatePicker,
  Space,
  Typography,
  Form,
  Radio,
  Tooltip,
  notification
} from 'antd'
import moment from 'moment'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { InfoCircleOutlined } from '@ant-design/icons'
import { constants } from '../../constants/constants'
import cssconstants from '../../constants/cssconstants'
import './index.css'

const { Text } = Typography

const stationTooltip = 'Select the dropdown in the header to change station'
const URL = process.env.REACT_APP_API_URL 
let ENV = process.env.REACT_APP_ENV
const weekFormat = 'MM/DD'

switch (ENV) {
case 'development':
  ENV = 'dev'.toUpperCase()
  break
case 'stage':
  ENV = 'stage'.toUpperCase()
  break
case 'production':
  ENV = 'prod'.toUpperCase()
}

class Reports extends Component {
  constructor() {
    super()
    this.state = {
      type: 'weekly',
      module: 'rebooking_report',
      moduleLabel: 'Rebooking',
      station: new Cookies().get('station'),
      startDate: moment().startOf('week').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      today: moment().format('YYYY-MM-DD'),
      flight: 'planned_flights',
    }
  }

  onChangeModule = (e) => {
    this.setState({
      module: e.target.value,
      moduleLabel: e.target.label,
    })
  }
  
  onChangeRange = () => {
    this.setState({ 
      startDate: '',
      endDate: '',
    })
    let today = this.state.today    
    if(this.state.type === 'monthly') {
      let monthStart = moment().startOf('month').format('YYYY-MM-DD')
      this.setState({ 
        startDate: monthStart,
        endDate: today,
      })
      this.setState({ startDate: monthStart }, () => {
      })
    }
    else {
      let thisWeekStart = moment().startOf('week').format('YYYY-MM-DD')
      this.setState({ 
        startDate: thisWeekStart,
        endDate: today,
      })
      this.setState({ startDate: thisWeekStart }, () => {
      })
    }
  }

  downloadReport = (e) => {
    let reportURL = `${URL}${this.state.module}`
    document.body.style.cursor = 'wait'
    axios({
      url: reportURL,
      method: 'GET',
      responseType: 'blob',
      params: {
        ...(this.state.module === 'user_login_report'? {} : {
          start: this.state.startDate,
          end: this.state.endDate 
        }),
        station: this.state.station,
        ...(e.target.id === 'measurement' ? { is_measurement: true } : {} ),
        ...(this.state.module === 'fcp_report' ? {
          is_planned: this.state.flight === 'planned_flights' ? true : false ,
          is_nb: true  
        } : {} )
      } 
    }).then((response) => {
      document.body.style.cursor = 'default'
      if(response && response?.status === 200) {
        var reportModule = this.state.module
        var reportName = reportModule.charAt(0).toUpperCase() + reportModule.slice(1)
        if(e.target.id === 'measurement') {
          var measurementArray = reportName.split('_')
          var measurementName = measurementArray[0]+ '_' + 'measurement' + '_' + measurementArray[1]
          reportName = measurementName
        }
        let fileName = ''
        let file = `${this.state.station}_${ENV}_${reportName}`
        if(this.state.module === 'user_login_report'){
          fileName = `${file}`
        }
        else{
          fileName = `${file}_${this.state.startDate}_${this.state.endDate}`
        }
        let fileType = `${fileName}.csv` //or any other extension
        // create file link in browser's memory
        const href = window.URL.createObjectURL(response.data)
        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', fileType) 
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        window.URL.revokeObjectURL(href)
      }
      else {
        // eslint-disable-next-line no-console
        console.log('error')
      }
    })
      .catch((error) => {
        document.body.style.cursor = 'default'
        // eslint-disable-next-line no-console
        console.error(error)
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.NO_DATA_AVAILABLE,
        })
      })
  }

  handleWeekChange = (date) => {
    this.setState({ 
      startDate: '',
      endDate: '',
    })
    let currentDate =  date
    let weekStart = currentDate.clone().startOf('week').format('YYYY-MM-DD')
    let weekEnd = currentDate.clone().endOf('week').format('YYYY-MM-DD')
    let thisWeekStart = moment().startOf('week').format('YYYY-MM-DD')
    let selectedDate = currentDate.format('YYYY-MM-DD')
    let today = this.state.today
    if( thisWeekStart < selectedDate &&  selectedDate < today) {
      this.setState({ 
        startDate: thisWeekStart,
        endDate: today,
      })
    }
    else {
      this.setState({ 
        startDate: weekStart,
        endDate: weekEnd,
      })
    }
  }
  
  customWeekStartEndFormat = (value) => {
    let valueD = moment(value._d)
    let valueDate = valueD.clone().format(weekFormat)
    let weekStart = moment().startOf('week').format(weekFormat)
    let weekEnd = moment().endOf('week').format(weekFormat)
    if(weekStart <= valueDate && valueDate <= weekEnd) {
      return`${weekStart} ~ ${moment().format(weekFormat)}`
    }
    else {
      return`${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value)
        .endOf('week')
        .format(weekFormat)}`
    }
  }
  
  handleMonthChange = (date) => {
    this.setState({ 
      startDate: '',
      endDate: '',
    })
    let currentDate =  date
    let monthStart = currentDate.startOf('month').format('YYYY-MM-DD')
    let monthEnd   = currentDate.endOf('month').format('YYYY-MM-DD')    
    let currentMonth = moment().month()
    let selectedMonth = currentDate.month()
    let today = this.state.today
    if( selectedMonth === currentMonth) {
      this.setState({ 
        startDate: monthStart,
        endDate: today,
      })
    }
    else {
      this.setState({ 
        startDate: monthStart,
        endDate: monthEnd,
      })
    }
  }

  render() {
    return (
      <div>      
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} className=''>
            <Card
              title={'Report - ' + this.state.station}
              className='custom-card'
            >
              <Row className='mb-4'>
                <Col xs={24} className=''>
                  <label className='custom-label label-border'>Module</label>
                  <Radio.Group
                    onChange={this.onChangeModule}
                    className='f-spacebtw f-wrap module-radio'
                    value={this.state.module}
                    label={this.state.moduleLabel}
                  >
                    <Radio value='rebooking_report' label='Rebooking'>
                      Rebooking
                    </Radio>
                    <Radio value='inbound_report' label='Inbound Flight Prep'>
                      Inbound Flight Prep
                    </Radio>
                    <Radio value='recovery_report' label='Recovery'>
                      Recovery
                    </Radio>
                    <Radio value='breakdown_report' label='Breakdown'>
                      Breakdown
                    </Radio>
                    <Radio value='buildup_report' label='Buildup'>
                      Buildup
                    </Radio>
                    <Radio value='fcp_report' label='Flight Capacity Planning'>
                      Flight Capacity Planning
                    </Radio>
                    <Radio value='haulout_report' label='Haulout'>
                      Haulout
                    </Radio>
                    <Radio value='user_login_report' label='Station Usage'>
                      Station Usage
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              className='custom-card'
              title={
                <>
                  <Text type='secondary rep-sub-title'>Module</Text>
                  <div className=''>{this.state.moduleLabel}</div>
                </>
              }
            >
              <Form
                layout='vertical'
              >
                <Row
                  gutter={[
                    {
                      xs: 0,
                      sm: 24,
                      md: 24,
                      lg: 24,
                    },
                    {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 0,
                    },
                  ]}
                >
                  <Col xs={24} sm={6} md={5} lg={6} xl={6} xxl={6}>
                    <Form.Item
                      className='label-w100'
                      label={
                        <div className='f-spacebtw w-100'>
                          <span>Station</span>
                          <Tooltip
                            title={stationTooltip}
                            overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs, }}
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                      }
                    >
                      <Input
                        size='small'
                        placeholder=''
                        disabled
                        value={this.state.station}
                      />
                    </Form.Item>
                  </Col>

                  { this.state.module === 'fcp_report' ? 
                    <>
                      {/* <Col className=''>
                        <Form.Item>
                          <label className='custom-label label-border'>Narrow Body</label>
                          <Radio.Group
                            onChange={ e => this.setState(
                              { narrowBody: e.target.value }
                            )}
                            value={this.state.narrowBody}
                            className='radio-nowrap range-radio'
                          >
                            <Radio value='include'>Include</Radio>
                            <Radio value='exclude' disabled>Exclude</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col> */}
                      <Col flex={'280px'} className=''>
                        <Form.Item>
                          <label className='custom-label label-border'>Flights</label>
                          <Radio.Group
                            onChange={ e => this.setState(
                              { flight: e.target.value }
                            )}
                            value={this.state.flight}
                            className='radio-nowrap range-radio'
                          >
                            <Radio value='planned_flights'>Planned Flights</Radio>
                            <Radio value='all_flights'>All Flights</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col></>  
                    : ''
                  }

                  <Col flex={'200px'} className=''>
                    <Form.Item>
                      <label className='custom-label label-border'>Range</label>
                      <Radio.Group
                        onChange={(e) =>
                          this.setState(
                            { type: e.target.value },
                            this.onChangeRange
                          )
                        }
                        disabled={this.state.module === 'user_login_report' ? true : false}
                        value={this.state.type}
                        className='radio-nowrap range-radio'
                      >
                        <Radio value='weekly'>Weekly</Radio>
                        <Radio value='monthly'>Monthly</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} md={5} lg={6} xl={6} xxl={6}>
                    {this.state.type === 'monthly' ? (
                      <Form.Item label='Select Month'>
                        <DatePicker
                          onChange={this.handleMonthChange}
                          picker='month'
                          disabled={this.state.module === 'user_login_report' ? true : false}
                          defaultValue={moment(this.state.today)}
                          disabledDate={(current) => {
                            let customDate = moment().format('YYYY-MM-DD')
                            return current && current > moment(customDate, 'YYYY-MM-DD')
                          }} 
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item label='Select Week'>
                        <DatePicker
                          onChange={this.handleWeekChange}
                          picker='week'
                          disabled={this.state.module === 'user_login_report' ? true : false}
                          defaultValue={moment()} format={this.customWeekStartEndFormat}
                          disabledDate={(current) => {
                            let customDate = moment().add(1,'day').format('YYYY-MM-DD')
                            return current && current > moment(customDate, 'YYYY-MM-DD')
                          }} 
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col className='d-flex ml-auto'>
                    <Space direction='horizontal' className='ml-auto'>
                      <button
                        type='primary'
                        id='report' 
                        className='custom-button pointer download-btn'
                        onClick={this.downloadReport}
                      >
                        Download Report
                      </button>
                      {this.state.module === 'recovery_report' || this.state.module === 'buildup_report' || this.state.module === 'inbound_report' || this.state.module === 'breakdown_report' || this.state.module === 'haulout_report' || this.state.module === 'rebooking_report' ?
                        <button
                          type='primary'
                          id='measurement' 
                          className='custom-button pointer download-btn'
                          onClick={this.downloadReport}
                        >
                          Download Measurements
                        </button>
                        : ''
                        
                      }
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Reports