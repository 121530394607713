import React, { Component } from 'react'
import { Button, Input, InputNumber, Row, Col, Typography, Select, Form, Card, Table, Tooltip, notification, Popconfirm } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import { SaveOutlined } from '@ant-design/icons'
import cssconstants from '../../../constants/cssconstants'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import './tabs.css'
import Http from '../../../utils/http'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
import Cookies from 'universal-cookie'

const { Title } = Typography
const { Option } = Select
const { Search } = Input
let cookies = new Cookies()
const station = cookies.get('station')

class HandlingTimes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      amberValue: [],
      amber: [],
      amber2: [],
      search: '',
      defaultHTdata: [],
      handlingTimesdata: [],

      station: station,
      mvt: null,
      p_code: '',
      bd_storage: null,
      ac_load: null,
      ac_offld: null,
      tfr_to_wh: null,
      trf_to_ac: null,
      exp_acpt: null,
      build_up: null,
      at_gate: null,
      ttl_imp: null,
      ttl_exp: null,
      ttl_tfr: null,
      process: null,
      fpe: null,

      id: null,
      is_default: false,

      duplicate: null,

      isEdit: false,
      editRecord: null,
      editDefaultRecord: null
    }
  }
  //CDM
  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'DEFAULT_HANDLING_TIMES'){
          this.fetchDefaultHandlingTimes()
        }
        if(parsedData.TYPE === 'HANDLING_TIMES'){
          this.fetchHandlingTimes()
        }
      }
    }
    this.fetchDefaultHandlingTimes()
    this.fetchHandlingTimes()
    this.fetchAmberValue()
  }
  //CALCULATE SIDE COLUMN FIELDS AND RETURN ARRAY
  add(array) {
    array && array.map(({ details }) => {
      let offld = details.ac_offld ? parseInt(details.ac_offld) : 0
      let tfr_wh = details.tfr_to_wh ? parseInt(details.tfr_to_wh) : 0
      let store = details.bd_store ? parseInt(details.bd_store) : 0
      let acpt = details.exp_acpt ? parseInt(details.exp_acpt) : 0
      let build_up = details.build_up ? parseInt(details.build_up) : 0
      let tfr_ac = details.trf_to_ac ? parseInt(details.trf_to_ac) : 0
      let gate = details.at_gate ? parseInt(details.at_gate) : 0
      let load = details.ac_load ? parseInt(details.ac_load) : 0

      if(details?.process?.toUpperCase()?.includes('IMPORT')) {
        details.total = offld + tfr_wh + store
      } else if(details?.process?.toUpperCase()?.includes('EXPORT')) {
        details.total = acpt + build_up + tfr_ac + gate + load
      } else if(details?.process?.toUpperCase()?.includes('TRANSFER')) {
        details.total = offld + tfr_wh + store + build_up + tfr_ac + gate + load
      }
    })
    return array
  }
  //FETCH DEFAULT HANDLING DATA
  fetchDefaultHandlingTimes = () => {
    Http.get(`/handling_times?default=true&station=${station}`)
      .then((data) => {
        if(data && data.data && data.data.data[0]){
          this.sort(data.data.data, 'process')
          this.sort(data.data.data, 'station')

          this.setState({ defaultHTdata: this.add(data.data.data) })
        }
      })
  }
  //FETCH HANDLING DATA
  fetchHandlingTimes = () => {
    Http.get(`/handling_times?station=${station}`,{ headers: { search: this.state.search } })
      .then((data) => {
        if(data && data.data && data.data.data.length>0){
          this.sort(data.data.data, 'process')
          this.sort(data.data.data, 'p_code')
          this.sort(data.data.data, 'station')

          this.setState({ handlingTimesdata: this.add(data.data.data) })
        } else {
          this.setState({ handlingTimesdata : [] },()=>{notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })})
        }
      })
  }
  //FETCH HANDLING DATA
  fetchAmberValue = () => {
    Http.get(`/station_details?station=${station}`)
      .then((data) => {
        if(data && data.data && data.data.data.length>0) {
          this.setState({
            amberValue: data.data.data,
            amber: data.data.data[0]?.indicator_time,
            amber2: data.data.data[0]?.indicator_time2
          })
        } else {
          this.setState({ amberValue : [] })
        }
      })
  }

  sort = (array, type) => {
    array && array.sort(function(a, b) {
      var keyA = type === 'station' ? a[type] : a?.details?.[type]
      var keyB = type === 'station' ? b[type] : b?.details?.[type]
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
  }

  //ADD NEW HANDLING RECORD
  addHandlingTime = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.handling_times
    }
    //eslint-disable-next-line
    let formdata= {
      station: this.state.station,
      //mvt: this.state.mvt,
      p_code: this.state.p_code,
      ac_offld: this.state.ac_offld,
      tfr_to_wh: this.state.tfr_to_wh,
      bd_store: this.state.bd_storage,
      exp_acpt: this.state.exp_acpt,
      build_up: this.state.build_up,
      trf_to_ac: this.state.trf_to_ac,
      at_gate: this.state.at_gate,
      ac_load: this.state.ac_load,
      is_default: this.state.is_default,
      process: this.state.process,
      fpe: this.state.fpe,
    }
    Http.post('/handling_time', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearForm()
          this.fetchHandlingTimes()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          var tempDatap = data && data.data && data.data.data && data.data.data[0] ? data.data.data[0] : null
          this.setState({ duplicate:tempDatap },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  //UPDATE HANDLING RECORD
  updateHandlingTime = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.handling_times
    }
    let formdata= {
      station: this.state.editStation,
      //mvt: this.state.editMvt,
      p_code: this.state.editPCode,
      ac_offld: this.state.editOffload,
      tfr_to_wh: this.state.editTfrWh,
      bd_store: this.state.editBdStore,
      exp_acpt: this.state.editExpAcpt,
      build_up: this.state.editBuildUp,
      trf_to_ac: this.state.editTfrAc,
      at_gate: this.state.editAtGate,
      ac_load: this.state.editAcLoad,
      is_default: this.state.editIs_default,
      process: this.state.editProcess,
      fpe: this.state.editFpe,
    }
    Http.put('/handling_time/'+this.state.editRecord.id, formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearForm()
          this.clearTableForm()
          this.fetchDefaultHandlingTimes()
          this.fetchHandlingTimes()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          var tempData = data && data.data && data.data.data && data.data.data[0] ? data.data.data[0] : null
          this.setState({ duplicate:tempData },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  //UDATE DEFAULT HANDLING RECORD
  updateDefaultHandlingTime = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.handling_times
    }
    let defaultFormData = {
      station: this.state.editDefaultStation,
      //mvt: this.state.editDefaultMvt,
      ac_offld: this.state.editDefaultOffload,
      tfr_to_wh: this.state.editDefaultTfrWh,
      bd_store: this.state.editDefaultBdStore,
      exp_acpt: this.state.editDefaultExpAcpt,
      build_up: this.state.editDefaultBuildUp,
      trf_to_ac: this.state.editDefaultTfrAc,
      at_gate: this.state.editDefaultAtGate,
      ac_load: this.state.editDefaultAcLoad,
      is_default: this.state.editDefaultIs_default,
      process: this.state.editDefaultProcess,
      fpe: this.state.editDefaultFpe,
    }
    Http.put('/handling_time/'+this.state.editDefaultRecord.id, defaultFormData, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearForm()
          this.clearDefaultTableForm()
          this.fetchDefaultHandlingTimes()
          this.fetchHandlingTimes()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          var tempData = data && data.data && data.data.data && data.data.data[0] ? data.data.data[0] : null
          this.setState({ duplicate:tempData },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  //DELETE ANY HANDLING RECORD
  deleteHandlingTime = (record) => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.handling_times
    }
    let formdata= { is_delete: true }
    Http.put('/handling_time/'+record.id, formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearForm()
          this.fetchDefaultHandlingTimes()
          this.fetchHandlingTimes()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  //CLEAR INPUT FORM
  clearForm = () =>{
    this.setState({
      station: station,
      //mvt: null,
      p_code: '',
      bd_storage: null,
      ac_load: null,
      ac_offld: null,
      tfr_to_wh: null,
      trf_to_ac: null,
      exp_acpt: null,
      build_up: null,
      at_gate: null,
      is_default: false,
      id: null,
      isEdit: false,
      duplicate: null,
      process: null,
      fpe: null
    })
  }
  //FETCH SEARCH DATA
  setSearchValue (e){
    this.setState({ search: e.target.value },()=>{
      if(!e.target.value || e.target.value === null || e.target.value ===''){
        this.fetchHandlingTimes()
      }
    })
  }
  //SET HANDLING RECORD EDITABLE
  editTableForm = (record) => {
    if(record?.details?.process?.toUpperCase()?.includes('IMPORT')) {
      this.setState({
        editExpAcpt: null,
        editBuildUp: null,
        editTfrAc: null,
        editAtGate: null,
        editAcLoad: null,
        editFpe: null,
        editRecord: record,
        editStation: record?.station,
        //editMvt: record?.details.mvt,
        editPCode: record?.details?.p_code,
        editOffload: record?.details?.ac_offld,
        editTfrWh: record?.details?.tfr_to_wh,
        editBdStore: record?.details?.bd_store,
        editIs_default: record?.details?.is_default,
        editProcess: record?.details?.process,
      })
    } else if(record?.details?.process?.toUpperCase()?.includes('TRANSFER')) {
      this.setState({
        editExpAcpt: null,
        editRecord: record,
        editStation: record?.station,
        //editMvt: record?.details.mvt,
        editPCode: record?.details?.p_code,
        editOffload: record?.details?.ac_offld,
        editTfrWh: record?.details?.tfr_to_wh,
        editBdStore: record?.details?.bd_store,
        editBuildUp: record?.details?.build_up,
        editTfrAc: record?.details?.trf_to_ac,
        editAtGate: record?.details?.at_gate,
        editAcLoad: record?.details?.ac_load,
        editIs_default: record?.details?.is_default,
        editProcess: record?.details?.process,
        editFpe: record?.details?.fpe,
      })
    } else if(record?.details?.process?.toUpperCase()?.includes('EXPORT')) {
      this.setState({
        editOffload: null,
        editTfrWh: null,
        editBdStore: null,
        editRecord: record,
        editStation: record?.station,
        //editMvt: record?.details.mvt,
        editPCode: record?.details?.p_code,
        editExpAcpt: record?.details?.exp_acpt,
        editBuildUp: record?.details?.build_up,
        editTfrAc: record?.details?.trf_to_ac,
        editAtGate: record?.details?.at_gate,
        editAcLoad: record?.details?.ac_load,
        editIs_default: record?.details?.is_default,
        editProcess: record?.details?.process,
        editFpe: record?.details?.fpe,
      })
    } else {
      this.setState({
        editRecord: record,
        editStation: record?.station,
        //editMvt: record?.details.mvt,
        editPCode: record?.details?.p_code,
        editOffload: record?.details?.ac_offld,
        editTfrWh: record?.details?.tfr_to_wh,
        editBdStore: record?.details?.bd_store,
        editExpAcpt: record?.details?.exp_acpt,
        editBuildUp: record?.details?.build_up,
        editTfrAc: record?.details?.trf_to_ac,
        editAtGate: record?.details?.at_gate,
        editAcLoad: record?.details?.ac_load,
        editIs_default: record?.details?.is_default,
        editProcess: record?.details?.process,
        editFpe: record?.details?.fpe,
      })
    }

  }
  //CLEAR HANDLING EDIT RECORD
  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editStation: null,
      //editMvt: null,
      editPCode: null,
      editOffload: null,
      editTfrWh: null,
      editBdStore: null,
      editExpAcpt: null,
      editBuildUp: null,
      editTfrAc: null,
      editAtGate: null,
      editAcLoad: null,
      editIs_default: null,
      editProcess: null,
      editFpe: null,
    })
  }
  //SET DEFAULT HANDLING RECORD EDITABLE
  editDefaultTableForm = (record) => {
    if(record?.details?.process?.toUpperCase()?.includes('IMPORT')) {
      this.setState({
        editDefaultExpAcpt: null,
        editDefaultBuildUp: null,
        editDefaultTfrAc: null,
        editDefaultAtGate: null,
        editDefaultAcLoad: null,
        editDefaultFpe: null,
        editDefaultRecord: record,
        editDefaultStation: record?.station,
        //editDefaultMvt: record?.details.mvt,
        editDefaultOffload: record?.details?.ac_offld,
        editDefaultTfrWh: record?.details?.tfr_to_wh,
        editDefaultBdStore: record?.details?.bd_store,
        editDefaultIs_default: record?.details?.is_default,
        editDefaultProcess: record?.details?.process,
      })
    } else if(record?.details?.process?.toUpperCase()?.includes('TRANSFER')) {
      this.setState({
        editDefaultExpAcpt: null,
        editDefaultRecord: record,
        editDefaultStation: record?.station,
        //editDefaultMvt: record?.details.mvt,
        editDefaultOffload: record?.details?.ac_offld,
        editDefaultTfrWh: record?.details?.tfr_to_wh,
        editDefaultBdStore: record?.details?.bd_store,
        editDefaultBuildUp: record?.details?.build_up,
        editDefaultTfrAc: record?.details?.trf_to_ac,
        editDefaultAtGate: record?.details?.at_gate,
        editDefaultAcLoad: record?.details?.ac_load,
        editDefaultIs_default: record?.details?.is_default,
        editDefaultProcess: record?.details?.process,
        editDefaultFpe: record?.details?.fpe,
      })
    } else if(record?.details?.process?.toUpperCase()?.includes('EXPORT')) {
      this.setState({
        editDefaultOffload: null,
        editDefaultTfrWh: null,
        editDefaultBdStore: null,
        editDefaultRecord: record,
        editDefaultStation: record?.station,
        //editDefaultMvt: record?.details.mvt,
        editDefaultExpAcpt: record?.details?.exp_acpt,
        editDefaultBuildUp: record?.details?.build_up,
        editDefaultTfrAc: record?.details?.trf_to_ac,
        editDefaultAtGate: record?.details?.at_gate,
        editDefaultAcLoad: record?.details?.ac_load,
        editDefaultIs_default: record?.details?.is_default,
        editDefaultProcess: record?.details?.process,
        editDefaultFpe: record?.details?.fpe,
      })
    } else {
      this.setState({
        editDefaultRecord: record,
        editDefaultStation: record?.station,
        //editDefaultMvt: record?.details.mvt,
        editDefaultOffload: record?.details?.ac_offld,
        editDefaultTfrWh: record?.details?.tfr_to_wh,
        editDefaultBdStore: record?.details?.bd_store,
        editDefaultExpAcpt: record?.details?.exp_acpt,
        editDefaultBuildUp: record?.details?.build_up,
        editDefaultTfrAc: record?.details?.trf_to_ac,
        editDefaultAtGate: record?.details?.at_gate,
        editDefaultAcLoad: record?.details?.ac_load,
        editDefaultIs_default: record?.details?.is_default,
        editDefaultProcess: record?.details?.process,
        editDefaultFpe: record?.details?.fpe,
      })
    }
  }
  //CLEAR DEFAULT HANDLING EDIT RECORD
  clearDefaultTableForm = () => {
    this.setState({
      editDefaultRecord: null,
      editDefaultStation: null,
      //editDefaultMvt: null,
      editDefaultPCode: null,
      editDefaultOffload: null,
      editDefaultTfrWh: null,
      editDefaultBdStore: null,
      editDefaultExpAcpt: null,
      editDefaultBuildUp: null,
      editDefaultTfrAc: null,
      editDefaultAtGate: null,
      editDefaultAcLoad: null,
      editDefaultIs_default: null,
      editDefaultProcess: null,
      editDefaultFpe: null,
    })
  }

  //CLEAR INPUTS IF PROCESS CHANGED
  clearAndSetInputValues = (e) => {
    if(e?.toUpperCase() ?.includes('IMPORT')) {
      this.setState({
        exp_acpt: null,
        build_up: null,
        trf_to_ac: null,
        at_gate: null,
        ac_load: null,
        fpe: null,
      })
    } else if(e?.toUpperCase()?.includes('TRANSFER')) {
      this.setState({ exp_acpt: null })
    } else if(e?.toUpperCase()?.includes('EXPORT')) {
      this.setState({
        ac_offld: null,
        tfr_to_wh: null,
        bd_storage: null,
      })
    }
    this.setState({ process: e })
  }
  clearAndSetDefaultValues = (e) => {
    if(e?.toUpperCase()?.includes('IMPORT')) {
      this.setState({
        editDefaultExpAcpt: null,
        editDefaultBuildUp: null,
        editDefaultTfrAc: null,
        editDefaultAtGate: null,
        editDefaultAcLoad: null,
        editDefaultFpe: null,
      })
    } else if(e?.toUpperCase()?.includes('TRANSFER')) {
      this.setState({ editDefaultExpAcpt: null })
    } else if(e?.toUpperCase()?.includes('EXPORT')) {
      this.setState({
        editDefaultOffload: null,
        editDefaultTfrWh: null,
        editDefaultBdStore: null,
      })
    }
    this.setState({ editDefaultProcess:e })
  }
  clearAndSetProductValues = (e) => {
    if(e?.toUpperCase()?.includes('IMPORT')) {
      this.setState({
        editExpAcpt: null,
        editBuildUp: null,
        editTfrAc: null,
        editAtGate: null,
        editAcLoad: null,
        editFpe: null,
      })
    } else if(e?.toUpperCase()?.includes('TRANSFER')) {
      this.setState({ editExpAcpt: null })
    } else if(e?.toUpperCase()?.includes('EXPORT')) {
      this.setState({
        editOffload: null,
        editTfrWh: null,
        editBdStore: null,
      })
    }
    this.setState({ editProcess: e })
  }

  saveAmberValue = async () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.handling_times
    }
    let formdata = {
      station: station,
      indicator_time: this.state.amber || null,
      indicator_time2: this.state.amber2 || null,
    }
    if(this.state.amberValue?.length > 0) {
      Http.put(`/station_detail/${this.state.amberValue[0]?.id}`, formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.fetchAmberValue()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    } else {
      Http.post('/station_detail', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.fetchAmberValue()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  render() {
    let { defaultHTdata, handlingTimesdata } = this.state

    const defaulthandlingcolumns = [
      {
        title: 'STN',
        dataIndex: 'station',
        key: 'station',
        className: 'space-nowrap',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editDefaultStation}
              //onChange={(e) => this.setState({ editDefaultStation: e.target.value.toUpperCase() })}
              disabled
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.station ? record.station : '-'}
            </span>
        )
      },
      // {
      //   title: 'DOM/INTL',
      //   dataIndex: 'mvt',
      //   key: 'mvt',
      //   render: (text, record) => (
      //     this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
      //       <Select size='small'
      //         placeholder='Select mvt'
      //         onChange={(e)=>this.setState({ editDefaultMvt:e })}
      //         value={this.state.editDefaultMvt}
      //         className='w-100'
      //       >
      //         <Option value='DOM'>DOM</Option>
      //         <Option value='INT'>INT</Option>
      //       </Select>
      //       :
      //       <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
      //         record && record.details && record.details.mvt ? record.details.mvt : '-'}
      //       </span>
      //   )
      // },
      {
        title: 'PROCESS',
        dataIndex: 'process',
        key: 'process',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <Select size='small'
              placeholder='Select process'
              onChange={(e)=> this.clearAndSetDefaultValues(e)}
              value={this.state.editDefaultProcess}
              className='w-100'
            >
              <Option value='Import-D'>Import-D</Option>
              <Option value='Import-I'>Import-I</Option>
              <Option value='Export-D'>Export-D</Option>
              <Option value='Export-I'>Export-I</Option>
              <Option value='Transfer D/D'>Transfer D/D</Option>
              <Option value='Transfer D/I'>Transfer D/I</Option>
              <Option value='Transfer I/D'>Transfer I/D</Option>
              <Option value='Transfer I/I'>Transfer I/I</Option>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.process ? record.details.process : '-'}
            </span>
        )
      },
      {
        title: '1 - AC OFFLD',
        dataIndex: 'ac_offld',
        key: 'ac_offld',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultOffload}
              onChange={(e) => this.setState({ editDefaultOffload: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('EXPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.ac_offld ? record.details.ac_offld : '-'}
            </span>
        )
      },
      {
        title: '2 - TFR TO WH',
        dataIndex: 'tfr_to_wh',
        key: 'tfr_to_wh',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultTfrWh}
              onChange={(e) => this.setState({ editDefaultTfrWh: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('EXPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.tfr_to_wh ? record.details.tfr_to_wh : '-'}
            </span>
        )
      },
      {
        title: '3 - BD & STORE',
        dataIndex: 'bd_store',
        key: 'bd_store',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultBdStore}
              onChange={(e) => this.setState({ editDefaultBdStore: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('EXPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.bd_store ? record.details.bd_store : '-'}
            </span>
        )
      },
      {
        title: '4 - ACCEPT',
        dataIndex: 'exp_acpt',
        key: 'exp_acpt',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultExpAcpt}
              onChange={(e) => this.setState({ editDefaultExpAcpt: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('IMPORT') || this.state.editDefaultProcess?.toUpperCase()?.includes('TRANSFER')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.exp_acpt ? record.details.exp_acpt : '-'}
            </span>
        )
      },
      {
        title: '5 - BUILD UP',
        dataIndex: 'build_up',
        key: 'build_up',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultBuildUp}
              onChange={(e) => this.setState({ editDefaultBuildUp: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.build_up ? record.details.build_up : '-'}
            </span>
        )
      },
      {
        title: '6 - TFR TO AC',
        dataIndex: 'trf_to_ac',
        key: 'trf_to_ac',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultTfrAc}
              onChange={(e) => this.setState({ editDefaultTfrAc: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.trf_to_ac ? record.details.trf_to_ac : '-'}
            </span>
        )
      },
      {
        title: '7 - AT GATE',
        dataIndex: 'at_gate',
        key: 'at_gate',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultAtGate}
              onChange={(e) => this.setState({ editDefaultAtGate: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.at_gate ? record.details.at_gate : '-'}
            </span>
        )
      },
      {
        title: '8 - AC LOAD',
        dataIndex: 'ac_load',
        key: 'ac_load',
        className:'',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultAcLoad}
              onChange={(e) => this.setState({ editDefaultAcLoad: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.ac_load ? record.details.ac_load : '-'}
            </span>
        )
      },
      {
        title: 'FPE',
        dataIndex: 'fpe',
        key: 'fpe',
        className:'',
        render: (text, record) => (
          this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editDefaultFpe}
              onChange={(e) => this.setState({ editDefaultFpe: e })}
              disabled={this.state.editDefaultProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.fpe ? record.details.fpe : '-'}
            </span>
        )
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (text, record) => (
          <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
            record && record.details && record.details.total ? record.details.total : '-'}
          </span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            {this.state.editDefaultRecord && this.state.editDefaultRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateDefaultHandlingTime()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearDefaultTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editDefaultTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandlingTime(record)} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]
    const producthandlingcolumns = [
      {
        title: 'STN',
        dataIndex: 'station',
        key: 'station',
        className: 'space-nowrap',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editStation}
              //onChange={(e) => this.setState({ editStation: e.target.value.toUpperCase() })}
              disabled
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.station ? record.station : '-'}
            </span>
        )
      },
      // {
      //   title: 'DOM/INTL',
      //   dataIndex: 'mvt',
      //   key: 'mvt',
      //   render: (text, record) => (
      //     this.state.editRecord && this.state.editRecord.id === record.id ?
      //       <Select size='small'
      //         placeholder='Select mvt'
      //         onChange={(e)=>this.setState({ editMvt:e })}
      //         value={this.state.editMvt}
      //         className='w-100'
      //       >
      //         <Option value='DOM'>DOM</Option>
      //         <Option value='INT'>INT</Option>
      //       </Select>
      //       :
      //       <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
      //         record && record.details && record.details.mvt ? record.details.mvt : '-'}
      //       </span>
      //   )
      // },
      {
        title: 'PROD',
        dataIndex: 'p_code',
        key: 'p_code',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editPCode}
              onChange={(e) => this.setState({ editPCode: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.p_code ? record.details.p_code : '-'}
            </span>
        )
      },
      {
        title: 'PROCESS',
        dataIndex: 'process',
        key: 'process',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select size='small'
              placeholder='Select process'
              onChange={(e)=> this.clearAndSetProductValues(e)}
              value={this.state.editProcess}
              className='w-100'
            >
              <Option value='Import-D'>Import-D</Option>
              <Option value='Import-I'>Import-I</Option>
              <Option value='Export-D'>Export-D</Option>
              <Option value='Export-I'>Export-I</Option>
              <Option value='Transfer D/D'>Transfer D/D</Option>
              <Option value='Transfer D/I'>Transfer D/I</Option>
              <Option value='Transfer I/D'>Transfer I/D</Option>
              <Option value='Transfer I/I'>Transfer I/I</Option>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.process ? record.details.process : '-'}
            </span>
        )
      },
      {
        title: '1 - AC OFFLD',
        dataIndex: 'ac_offld',
        key: 'ac_offld',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editOffload}
              onChange={(e) => this.setState({ editOffload: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('EXPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.ac_offld ? record.details.ac_offld : '-'}
            </span>
        )
      },
      {
        title: '2 - TFR TO WH',
        dataIndex: 'tfr_to_wh',
        key: 'tfr_to_wh',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editTfrWh}
              onChange={(e) => this.setState({ editTfrWh: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('EXPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.tfr_to_wh ? record.details.tfr_to_wh : '-'}
            </span>
        )
      },
      {
        title: '3 - BD & STORE',
        dataIndex: 'bd_store',
        key: 'bd_store',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editBdStore}
              onChange={(e) => this.setState({ editBdStore: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('EXPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.bd_store ? record.details.bd_store : '-'}
            </span>
        )
      },
      {
        title: '4 - ACCEPT',
        dataIndex: 'exp_acpt',
        key: 'exp_acpt',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editExpAcpt}
              onChange={(e) => this.setState({ editExpAcpt: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('IMPORT') || this.state.editProcess?.toUpperCase()?.includes('TRANSFER')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.exp_acpt ? record.details.exp_acpt : '-'}
            </span>
        )
      },
      {
        title: '5 - BUILD UP',
        dataIndex: 'build_up',
        key: 'build_up',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editBuildUp}
              onChange={(e) => this.setState({ editBuildUp: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.build_up ? record.details.build_up : '-'}
            </span>
        )
      },
      {
        title: '6 - TFR TO AC',
        dataIndex: 'trf_to_ac',
        key: 'trf_to_ac',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editTfrAc}
              onChange={(e) => this.setState({ editTfrAc: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.trf_to_ac ? record.details.trf_to_ac : '-'}
            </span>
        )
      },
      {
        title: '7 - AT GATE',
        dataIndex: 'at_gate',
        key: 'at_gate',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editAtGate}
              onChange={(e) => this.setState({ editAtGate: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.at_gate ? record.details.at_gate : '-'}
            </span>
        )
      },
      {
        title: '8 - AC LOAD',
        dataIndex: 'ac_load',
        key: 'ac_load',
        className:'',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editAcLoad}
              onChange={(e) => this.setState({ editAcLoad: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.ac_load ? record.details.ac_load : '-'}
            </span>
        )
      },
      {
        title: 'FPE',
        dataIndex: 'fpe',
        key: 'fpe',
        className:'',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              size='small'
              placeholder=''
              value={this.state.editFpe}
              onChange={(e) => this.setState({ editFpe: e })}
              disabled={this.state.editProcess?.toUpperCase()?.includes('IMPORT')}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.fpe ? record.details.fpe : '-'}
            </span>
        )
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (text, record) => (
          <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
            record && record.details && record.details.total ? record.details.total : '-'}
          </span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            {this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateHandlingTime()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandlingTime(record)} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]
    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8} className=''>
                  <Title level={5} className='mb-0'>Default Station Handling Times <span className='grey-title'>(All times in mins)</span></Title>
                </Col>
                <Col xs={24} sm={24} md={11} lg={12} xl={10} xxl={8} className='handling-times-ip'>
                  <span> Breakdown Amber Indicator:
                    <InputNumber
                      size='small'
                      className='w-50px small-input mx-1 green'
                      min={0}
                      value={this.state.amber}
                      onChange={(e) => this.setState({ amber: e })}
                      controls={false}
                    />
                    <span className=''> mins before turning red</span></span>
                </Col>
                <Col xs={24} sm={24} md={11} lg={12} xl={13} xxl={7} className='handling-times-ip text-right'>
                  <span> Build Up Amber Indicator:
                    <InputNumber
                      size='small'
                      min={0}
                      className='w-50px small-input mx-1'
                      value={this.state.amber2}
                      onChange={(e) => this.setState({ amber2: e })}
                      controls={false}
                    />
                    <span className=''> mins before FPE</span></span>
                </Col>
                <Col>
                  <Button className='custom-icon-button custom-sm-icon-button ml-2' icon={<SaveOutlined />} onClick={this.saveAmberValue}></Button>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-pr-0'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table ht-table'
                      dataSource={defaultHTdata}
                      columns={defaulthandlingcolumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                  <Title level={5} className='mb-0'>Product Specific Handling Times <span className='grey-title'>(All times in mins)</span></Title>
                  <Search
                    allowClear
                    className='search-input'
                    placeholder='Search'
                    onChange={(e) => this.setSearchValue(e)}
                    onSearch={(e)=> !e || e === null || e ==='' ? null : this.fetchHandlingTimes()}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]}
                      className='product-ht'>

                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='STN'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.station}
                            //onChange={(e) => this.setState({ station: e.target.value.toUpperCase() })}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='DOM/INTL'>
                          <Select size='small'
                            placeholder='Select'
                            onChange={(e)=>this.setState({ mvt:e })}
                            value={this.state.mvt}
                          >
                            <Option value='DOM'>DOM</Option>
                            <Option value='INT'>INT</Option>
                          </Select>
                        </Form.Item>
                      </Col> */}
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='PROD'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.p_code}
                            onChange={(e) => this.setState({ p_code: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='Process'>
                          <Select size='small'
                            placeholder='Select process'
                            onChange={(e)=>this.clearAndSetInputValues(e)}
                            value={this.state.process}
                            className='w-100'
                          >
                            <Option value='Import-D'>Import-D</Option>
                            <Option value='Import-I'>Import-I</Option>
                            <Option value='Export-D'>Export-D</Option>
                            <Option value='Export-I'>Export-I</Option>
                            <Option value='Transfer D/D'>Transfer D/D</Option>
                            <Option value='Transfer D/I'>Transfer D/I</Option>
                            <Option value='Transfer I/D'>Transfer I/D</Option>
                            <Option value='Transfer I/I'>Transfer I/I</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='1 - AC OFFLD'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.ac_offld}
                            onChange={(e) => this.setState({ ac_offld: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('EXPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='2 - TFR TO WH'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.tfr_to_wh}
                            onChange={(e) => this.setState({ tfr_to_wh: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('EXPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='3 - BD & STORE'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.bd_storage}
                            onChange={(e) => this.setState({ bd_storage: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('EXPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='4 - ACCEPT'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.exp_acpt}
                            onChange={(e) => this.setState({ exp_acpt: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('IMPORT') || this.state.process?.toUpperCase()?.includes('TRANSFER')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='5 - BUILD UP'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.build_up}
                            onChange={(e) => this.setState({ build_up: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('IMPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='6 - TFR TO AC'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.trf_to_ac}
                            onChange={(e) => this.setState({ trf_to_ac: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('IMPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='7 - AT GATE'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.at_gate}
                            onChange={(e) => this.setState({ at_gate: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('IMPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='8 - AC LOAD'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.ac_load}
                            onChange={(e) => this.setState({ ac_load: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('IMPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={2} className=''>
                        <Form.Item label='FPE'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.fpe}
                            onChange={(e) => this.setState({ fpe: e })}
                            disabled={this.state.process?.toUpperCase()?.includes('IMPORT')}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isEdit ? this.updateHandlingTime() : this.addHandlingTime()} >
                          <Tooltip title={this.state.isEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    {  this.state.isEdit ? (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn handling-btn-placement' onClick={() => this.clearForm()}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )
                      : null
                    }
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-pr-0'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table w-100 ht-table'
                      dataSource={handlingTimesdata}
                      columns={producthandlingcolumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default HandlingTimes
