//import React, { createContext, useState, useEffect } from 'react'
import React, { createContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Redirect as BRedirect, SystemParameter, InboundFlight, RebookingQueue, Login, BreakdownControl, NotesContainer, ChangePassword, FlightControl, BuildupControl, IncomingMessages, ManageUsers, Warehouse, BreakdownControlUnknown, ChangeLog, RecoveryDashboard, BuildUpDashboard, HauloutDashboard, DeveloperDomain, Dashboard, LandingPage, Reports, MessageInterface } from './pages'
import RouteWithLayout from './routeHandler'
import DashboardLayout from './Components/layout'
import 'antd/dist/antd.min.css'
import './App.css'
//import Notifications from './Components/Notifications/Notifications'

export const State = createContext()


const initialState = {}
function App(...rest) {
  // const [ facts, setFacts ] = useState([])
  // const [ listening, setListening ] = useState(false)

  // useEffect( () => {
  //   if (!listening) {
  //     const events = new EventSource(process.env.REACT_APP_API_URL+'/events')
  //     events.onmessage = (event) => {
  //       const parsedData = JSON.parse(event.data)
  //       // eslint-disable-next-line no-console
  //       console.log('test',event.data)
  //       setFacts((facts) => facts.concat(parsedData))
  //     }
  //     setListening(true)
  //   }
  // }, [listening, facts])

  return (
    <State.Provider value={initialState}>
      <Router>
        <Switch>
          <RouteWithLayout
            exact
            path='/system-parameter/:tab?'
            layout={DashboardLayout}
            title='System Parameters'
            component={SystemParameter}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/inbound-flight'
            layout={DashboardLayout}
            title='Inbound Flight Preparation'
            component={InboundFlight}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/rebooking-queue'
            layout={DashboardLayout}
            title='Rebooking Queue'
            component={RebookingQueue}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/breakdown-control'
            layout={DashboardLayout}
            title='BreakdownControl'
            component={BreakdownControl}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/unknown-breakdown-control'
            layout={DashboardLayout}
            title='BreakdownControlUnknown'
            component={BreakdownControlUnknown}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/notes'
            layout={DashboardLayout}
            title='Notes'
            component={NotesContainer}
            {...rest}
          />
          {/* <RouteWithLayout
            exact
            path='/gatekeeper'
            layout={DashboardLayout}
            title='Gatekeeper'
            component={Gatekeeper}
            {...rest}
          /> */}
          <RouteWithLayout
            exact
            path='/change-password'
            layout={DashboardLayout}
            title='Change Password'
            component={ChangePassword}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/flight-capacity-planning'
            layout={DashboardLayout}
            title='Flight Capacity Planning'
            component={FlightControl}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/buildup-control'
            layout={DashboardLayout}
            title='Buildup Control'
            component={BuildupControl}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/gatekeeper'
            layout={DashboardLayout}
            title='Gatekeeper'
            component={IncomingMessages}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/change-log'
            layout={DashboardLayout}
            title='Change Log'
            component={ChangeLog}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/manage-users'
            layout={DashboardLayout}
            title='Manage Users'
            component={ManageUsers}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/developer-domain'
            layout={DashboardLayout}
            title='Developer Domain'
            component={DeveloperDomain}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/manage-dashboard'
            layout={DashboardLayout}
            title='dashboard'
            component={Dashboard}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/reports'
            layout={DashboardLayout}
            title='Reports'
            component={Reports}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/demo'
            layout={DashboardLayout}
            title='Demo'
            component={MessageInterface}
            {...rest}
          />
          <Route
            exact
            path='/landing-page'
            component={LandingPage}
          />
          <Route
            exact
            path='/login'
            component={Login}
          />
          <Route
            exact
            path='/breakdown-dashboard'
            component={Warehouse}
          />
          <Route
            exact
            path='/redirect'
            component={BRedirect}
          />
          <Route
            exact
            path='/recovery-dashboard'
            component={RecoveryDashboard}
          />
          <Route
            exact
            path='/buildup-dashboard'
            component={BuildUpDashboard}
          />
          <Route
            exact
            path='/haulout-dashboard'
            component={HauloutDashboard}
          />
          <Redirect from='/*' to='/redirect' />
        </Switch>
      </Router>
      {/* <Notifications /> */}
    </State.Provider>
  )
}

export default App
