import React, { Component } from 'react'
import { Switch, notification, Typography, Button, DatePicker, Spin, Card, Row, Col } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'
const { Title } = Typography
import Http from '../../../utils/http'
import { constants } from '../../../constants/constants'
import { COOKIE, getCookie } from '../../../utils/cookie'
import moment from 'moment'
import '../index.css'

const dateFormat = 'YYYY-MM-DD'
class ClearData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      hideMD: true,
      data: null,
      station: getCookie(COOKIE.STATION),
      user_id: getCookie(COOKIE.ID),
      timezone: getCookie(COOKIE.TIMEZONE),
      hld_date: null,
      bld_date: null,
      rcd_date: null,
      brd_date: null,
    }
  }

  componentDidMount() {
    this.fetchGlobalDetails()
  }

  //SET DATES DISABLED ON CONDITION
  disabledDate = (current) => {
    let next = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
    return current && (current > moment(next, dateFormat))
  }

  //FETCH GLOBAL DETAILS
  fetchGlobalDetails = async () => {
    await Http.get('global_details').then(resp => {
      if(resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          let filtered = resp?.data?.data?.find((item) => item?.details?.stations?.includes(this.state.station)) || null
          if(filtered) {
            this.setState({
              hideMD: filtered?.show_md ? false : true,
              data: filtered,
            })
          } else {
            this.setState({ hideMD: false })
          }
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
        })
      }
    })
  }

  submit = async () => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let formdata = {
      show_md: !this.state.hideMD,
      stations: this.state.data ? this.state.data?.details?.stations?.toString() : [],
    }
    await Http.put('/global_detail/1', formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }
  handleDatechange = (moments, string, type) => {
    if(type === 'brd')
      this.setState({ brd_date: moments })
    else if(type === 'rcd')
      this.setState({ rcd_date: moments })
    else if(type === 'bld')
      this.setState({ bld_date: moments })
    else if(type === 'hld')
      this.setState({ hld_date: moments })
  }

  clearData = async(type) => {
    this.setState({ loading: true })
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let url = '/clear'
    if(type === 'brd'){
      if(!this.state.brd_date){
        this.setState({ loading: false })
        return false
      }
      url = url+`/breakdown?date=${this.state.brd_date.format(dateFormat)}`
    }
    else if(type === 'rcd' ){
      if(!this.state.rcd_date){
        this.setState({ loading: false })
        return false
      }
      url = url+`/recovery?date=${this.state.rcd_date.format(dateFormat)}`
    }
    else if(type === 'bld' ){
      if(!this.state.bld_date){
        this.setState({ loading: false })
        return false
      }
      url = url+`/buildup?date=${this.state.bld_date.format(dateFormat)}`
    }
    else if(type === 'hld' ){
      if(!this.state.hld_date){
        this.setState({ loading: false })
        return false
      }
      url = url+`/haulout?date=${this.state.hld_date.format(dateFormat)}`
    }
    await Http.get(url, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({
            loading: false,
            hld_date: null,
            bld_date: null,
            rcd_date: null,
            brd_date: null,
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ loading: false })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <Card className='custom-card'>
            <div>
              <Title level={5} className='mb-0'>
                Hide/Show MD
              </Title>
              
              <Switch
                checked={this.state.hideMD}
                checkedChildren='Hide MD'
                unCheckedChildren='Show MD'
                onChange={(e) => this.setState({ hideMD: e }, () => this.submit()) }
                className='uld-switch'
              />
            </div>

            <hr className='my-4 hr-color' />

            <div>
              <Title level={5} className='mb-0'>
                Clear Data
              </Title>

              <Row className='grid-wrapper'>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className='clear-wrapper'>
                  <UnorderedListOutlined className='dd-icon' />
                  <div className='flex-auto dd-wrapper'>
                    <Title level={5} className=''>
                      Recovery Dashboard
                    </Title>
                    <DatePicker
                      allowClear={false}
                      value={this.state.rcd_date && moment(this.state.rcd_date, dateFormat)}
                      size='small'
                      onChange={(date, string) => this.handleDatechange(date, string, 'rcd')}
                      disabledDate={(current) => this.disabledDate(current) }
                    />
                    <Button type='primary' className='custom-button mr-0 mt-4' onClick={()=>this.clearData('rcd')}>Clear Data</Button>
                  </div>            
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className='clear-wrapper'>
                  <UnorderedListOutlined className='dd-icon' />
                  <div className='flex-auto dd-wrapper'>
                    <Title level={5} className=''>
                      Breakdown Dashboard
                    </Title>
                    <DatePicker
                      allowClear={false}
                      value={this.state.brd_date && moment(this.state.brd_date, dateFormat)}
                      size='small'
                      onChange={(date, string) => this.handleDatechange(date, string, 'brd')}
                      disabledDate={(current) => this.disabledDate(current) }
                    />
                    <Button type='primary' className='custom-button mr-0 mt-4' onClick={()=>this.clearData('brd')}>Clear Data</Button>
                  </div>
                </Col>
              
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className='clear-wrapper'>
                  <UnorderedListOutlined className='dd-icon' />
                  <div className='flex-auto dd-wrapper'>
                    <Title level={5} className=''>
                      Buildup Dashboard
                    </Title>
                    <DatePicker
                      allowClear={false}
                      value={this.state.bld_date && moment(this.state.bld_date, dateFormat)}
                      onChange={(date, string) => this.handleDatechange(date, string, 'bld')}
                      disabledDate={(current) => this.disabledDate(current) }
                    />
                    <Button type='primary' className='custom-button mr-0 mt-4' onClick={()=>this.clearData('bld')}>Clear Data</Button>
                  </div>            
                </Col>
              
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className='clear-wrapper'>
                  <UnorderedListOutlined className='dd-icon' />
                  <div className='flex-auto dd-wrapper'>
                    <Title level={5} className=''>
                      Haulout Dashboard
                    </Title>
                    <DatePicker
                      allowClear={false}
                      value={this.state.hld_date && moment(this.state.hld_date, dateFormat)}
                      onChange={(date, string) => this.handleDatechange(date, string, 'hld')}
                      disabledDate={(current) => this.disabledDate(current) }
                    />
                    <Button type='primary' className='custom-button mr-0 mt-4' onClick={()=>this.clearData('hld')}>Clear Data</Button>
                  </div>            
                </Col> */}
              </Row>
            </div>

          </Card>
        </Spin>
      </>
    )
  }
}

export default ClearData